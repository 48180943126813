import COLORS from "constants/colors";
import { ReactElement } from "react";
import styled from "styled-components";
import BaseButton, { BaseBtnProps } from "./BaseButton";

const StyledButton = styled(BaseButton)`
  &.btn-default {
    background-color: ${COLORS.defaultColor};

    :hover,
    :focus-visible {
      border: none;
      background-color: ${COLORS.defaultColorHover} !important;
    }

    :focus-within {
      background-color: ${COLORS.defaultActive};
    }
  }
`;

function DefaultButton({ className, ...props }: Omit<BaseBtnProps, "type">): ReactElement {
  return <StyledButton type="default" {...props} className={`btn-default ${className ?? ""}`} />;
}

export default DefaultButton;
