import { Typography } from "antd";
import COLORS from "constants/colors";
import DATE_FORMAT from "constants/dateFormat";
import TIME_FORMAT from "constants/timeFormat";
import { useAuthContext } from "contexts";
import dayjs from "dayjs";
import { LeaveRequestsDayCompleteness, LeaveRequestsReason, LeaveRequestsStatus, leaveTypeLabels } from "model/Leave";
import { isAdmin, isManager } from "model/User";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

type Props = {
  fromDate: string;
  toDate: string;
  reason: LeaveRequestsReason;
  dayCompleteness: LeaveRequestsDayCompleteness;
  fromTime?: string;
  toTime?: string;
  employeeNote?: string;
  status: LeaveRequestsStatus;
  userId: string;
};

const LeaveDetail = ({
  fromDate,
  toDate,
  reason,
  dayCompleteness,
  fromTime,
  toTime,
  employeeNote,
  status,
  userId,
}: Props) => {
  const { user } = useAuthContext();

  const navigate = useNavigate();

  const generateDateAndTimeString = () => {
    const dateString = [];
    if (fromDate) {
      dateString.push(`${dayjs(fromDate).format(DATE_FORMAT.fullDateDefault)}`);
    }
    if (toDate && dayjs(fromDate).date() !== dayjs(toDate).date()) {
      dateString.push(`${dayjs(toDate).format(DATE_FORMAT.fullDateDefault)}`);
    }
    if (dayCompleteness) {
      switch (dayCompleteness) {
        case LeaveRequestsDayCompleteness.HalfInMorning:
          dateString.push(`Half day (Morning)`);
          break;
        case LeaveRequestsDayCompleteness.HalfInAfternoon:
          dateString.push(`Half day (Afternoon)`);
          break;
        default:
          break;
      }
    }
    if (fromTime) {
      dateString.push(`${dayjs(fromTime, TIME_FORMAT.timeFormatWith24Hour).format(TIME_FORMAT.timeWithPeriodPrimary)}`);
    }
    if (toTime) {
      dateString.push(`${dayjs(toTime, TIME_FORMAT.timeFormatWith24Hour).format(TIME_FORMAT.timeWithPeriodPrimary)}`);
    }
    return dateString.join(" - ");
  };

  return (
    <StyledWrapper>
      {status !== LeaveRequestsStatus.Pending ? (
        <>
          <span>
            <strong>{leaveTypeLabels[reason]}</strong>
          </span>
          <span>{`${generateDateAndTimeString()}`}</span>
          {employeeNote && <span>{employeeNote}</span>}
        </>
      ) : (
        <StyledPendingTooltip>
          Leave not yet approved
          {((!isManager(user) && isAdmin(user)) || (isManager(user) && user?.managedUserIds?.includes(userId))) && (
            <Typography.Text onClick={() => navigate("/home")}>
              <strong>Approve</strong>
            </Typography.Text>
          )}
        </StyledPendingTooltip>
      )}
    </StyledWrapper>
  );
};

export default LeaveDetail;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  span,
  span strong {
    font-size: 12px;
    color: ${COLORS.white} !important;
  }
  cursor: default;
`;

const StyledPendingTooltip = styled.span`
  & span strong {
    text-decoration: underline;
    text-underline-offset: 5px;
    margin-left: 5px;
    cursor: pointer;
  }
`;
