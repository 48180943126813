import { useMutation } from "@apollo/client";
import { useAuthContext } from "contexts";
import { LoginMutation } from "model/User";
import { LOGIN_DOCUMENT } from "services/graphql/user";

export function useLogin() {
  const { login } = useAuthContext();
  return useMutation<LoginMutation>(LOGIN_DOCUMENT, {
    onCompleted: (response) => {
      if (response.login) {
        login(response.login.token, response.login.user);
      }
    },
  });
}

export default useLogin;
