import { ReactElement } from "react";
import COLORS from "constants/colors";
import styled from "styled-components";
import BaseButton, { BaseBtnProps } from "./BaseButton";

const StyledButton = styled(BaseButton)`
  &.ant-btn.ant-btn-default.btn-decline {
    background-color: ${COLORS.cancelColor};

    :hover,
    :focus-visible {
      border: none;
      background-color: ${COLORS.cancelHover} !important;
    }

    :focus-within {
      background-color: ${COLORS.cancelActive};
    }
  }
`;

function DeclineButton({ className, ...props }: Omit<BaseBtnProps, "type">): ReactElement {
  return <StyledButton type="default" {...props} className={`btn-decline ${className ?? ""}`} />;
}

export default DeclineButton;
