import {
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardCvcElementChangeEvent,
} from "@stripe/stripe-js";
import { Rule, RuleObject } from "antd/lib/form";
import { checkValidFileExtension } from "utils/checkPermission";

export interface MessagesRule {
  [x: string]: Rule;
}

export const ALERTS: MessagesRule = {
  required: { required: true, message: "Please fill this field." },
  email: { type: "email", message: "Please enter a valid email." },
  phone: { type: "string", min: 10, max: 15, message: "Please enter a valid phone number." },
  characterLength: { type: "string", min: 1, max: 150, message: "Value must be less than 150 characters." },
  imageFile: {
    validator: (_, value) => {
      const validFileType = value && checkValidFileExtension(value, ["jpeg", "png", "jpg"]);
      if (!value || validFileType) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Please upload a image file!"));
    },
  },
  xlsxFile: {
    validator: (_, value) => {
      const validFileType = value && checkValidFileExtension(value, "xlsx");
      if (!value || validFileType) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Please upload a XLSX file!"));
    },
  },
  stripeCardNumber: {
    validator: (_, value) => {
      if (value && !value.complete && !value.empty) {
        if (value.error) {
          if (value.error.code === "invalid_number") {
            return Promise.reject(new Error("Your card number is invalid."));
          }
        }
        return Promise.reject(new Error("Your card number is incomplete."));
      }
      return Promise.resolve();
    },
  },
  stripeExpiryDate: {
    validator: (_, value) => {
      if (value && value.error) {
        return Promise.reject(new Error(value.error.message));
      }
      return Promise.resolve();
    },
  },
  checkBox: {
    validator: (_, value) => {
      if (value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Please fill this field."));
    },
  },
};

export const fileSize = (file: File | null | undefined) => ({
  validator: (_: RuleObject, value: string) => {
    const isImage = value && checkValidFileExtension(value, ["jpeg", "png", "jpg"]);
    const maxSize = (isImage ? 2 : 10) * 1024 * 1024;
    if ((file && file.size <= maxSize) || !file) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(`File must be less than or equal to ${isImage ? 2 : 20}MB!`));
  },
});

export const stripeCardElementsRequiredValidation = () => ({
  validator: (
    _: RuleObject,
    value: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
  ) => {
    if (!value || (value && value.empty)) {
      return Promise.reject(new Error("Please fill this field."));
    }
    return Promise.resolve();
  },
});

export default ALERTS;
