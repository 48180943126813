import { Tabs as AntdTabs, TabsProps } from "antd";
import COLORS from "constants/colors";
import styled from "styled-components";

export const Tabs = (props: TabsProps) => <StyledTabs {...props} />;

export default Tabs;

const StyledTabs = styled(AntdTabs)`
  .ant-tabs-tab-btn {
    font-weight: 600;
    color: ${COLORS.headerText};
    text-shadow: unset;
  }
  &.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
  }
  .ant-tabs-tab-btn:hover {
    color: ${COLORS.primaryColor};
  }

  & .ant-tabs-nav .ant-tabs-ink-bar {
    height: 2px;
    background: ${COLORS.successColor};
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid ${COLORS.defaultActive};
  }

  &.ant-tabs .ant-tabs-tab-btn:focus-visible {
    outline: 1px solid ${COLORS.outlineColor};
    outline-offset: -1px;
  }
`;
