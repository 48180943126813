import { useMutation, useQuery } from "@apollo/client";
import { DefaultOptionType } from "antd/es/select";
import PERMISSION from "config/permission";
import LABEL from "constants/label";
import { CreateDepartmentResponse, DepartmentResponse, Tag } from "model/AdminSetting";
import React, { useState } from "react";
import { CREATE_DEPARTMENT, FETCH_DEPARTMENT } from "services/graphql/adminSetting";
import { PageInfo } from "model/Common";
import { UseNotifyResponse } from "services/api";
import { useAuthContext } from "contexts";
import useCheckPermission from "./useCheckPermission";

interface Props {
  notify?: UseNotifyResponse;
  after?: boolean;
  onCompleted?: (response: CreateDepartmentResponse) => void;
  skip?: boolean;
}

interface Variables {
  name: string;
}

export const useDepartments = ({ notify, onCompleted, after, skip }: Props) => {
  const [departments, setDepartments] = useState<DefaultOptionType[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const { user } = useAuthContext();
  const { companies } = PERMISSION;
  const { hasCompanies } = useCheckPermission({ companies });

  const { loading, refetch } = useQuery<DepartmentResponse>(FETCH_DEPARTMENT, {
    variables: { first: LABEL.rowsPerPage },
    onCompleted: (response) => {
      setDepartments((d) => [
        ...d,
        ...(response.departments.nodes
          .filter((item) => !d.map((item) => item.value).includes(item.id))
          .map((item) => ({ label: item.name, value: item.id })) ?? []),
      ]);
      if (response.departments.pageInfo) setPageInfo(response.departments.pageInfo);
    },
    skip: hasCompanies || !user?.subscriptionActive || skip,
  });

  const [mutation] = useMutation<CreateDepartmentResponse, Omit<Tag, "id">>(CREATE_DEPARTMENT, {
    onCompleted: (response) => {
      if (notify) {
        if (response?.createDepartment) {
          if (response?.createDepartment?.errors?.fullMessages?.length) {
            response.createDepartment.errors.fullMessages.map((error: string) => notify.error(undefined, error));
          } else {
            notify.success({
              message: "Department created successfully.",
            });
            if (departments.length >= LABEL.rowsPerPage && pageInfo?.endCursor && after) {
              setDepartments((d) => [
                ...d,
                { label: response.createDepartment.department.name, value: response.createDepartment.department.id },
              ]);
              refetch({ first: LABEL.rowsPerPage, after: pageInfo.endCursor }).then(() => {
                if (onCompleted) {
                  onCompleted(response);
                }
              });
            } else if (after) {
              setDepartments([]);
              setPageInfo(undefined);
              refetch({ first: LABEL.rowsPerPage, after: undefined }).then(() => {
                if (onCompleted) {
                  onCompleted(response);
                }
              });
            } else if (!after && onCompleted) {
              onCompleted(response);
            }
          }
        }
      }
    },
  });
  const handleDropdownScroll = (e: React.UIEvent<EventTarget>) => {
    if (
      (e.target as HTMLElement).scrollTop + (e.target as HTMLElement).clientHeight ===
        (e.target as HTMLElement).scrollHeight &&
      !loading &&
      pageInfo?.hasNextPage &&
      pageInfo?.endCursor
    ) {
      refetch({ first: LABEL.rowsPerPage, after: pageInfo?.endCursor });
    }
  };
  const createDepartment = (variables: Variables) => {
    mutation({ variables: { ...variables } });
  };

  return { departments, loading, createDepartment, handleDropdownScroll };
};

export default useDepartments;
