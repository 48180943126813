import { Col, Modal, Row, Typography } from "antd";
import { BreadCrumb } from "components/BreadCrumb";
import { DefaultButton, PrimaryButton, SuccessButton } from "components/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ConfirmModal } from "components/modal";
import useFormModal from "hooks/useFormModal";
import { useNotify } from "services/api";
import { Container, LoadingPage } from "components/core";
import { DocumentDetailResponse, SendDocumentReminderResponse, documentShareTypeLabel } from "model/Document";
import { useMutation, useQuery } from "@apollo/client";
import { FETCH_DOCUMENT_SHARED_DETAIL, SEND_DOCUMENT_REMINDER } from "services/graphql/document";
import { removeExtensionFromName } from "features/employee/leave";
import { useState } from "react";
import DocumentSharedListTable from "./DocumentSharedListTable";
import EditShareWith from "./EditShareWith";
import AddEmployeeAccess from "./AddEmployeeAccess";

const DocumentSharedDetail = () => {
  const navigate = useNavigate();
  const { id: documentID } = useParams();
  const { success } = useNotify();

  const { isModalVisible, hideModal, showModal } = useFormModal();
  const [openAddEmployeeForm, setOpenAddEmployeeForm] = useState<boolean>(false);

  const {
    isModalVisible: isEditShareWithModalVisible,
    hideModal: hideEditShareWithModal,
    showModal: showEditShareWithModal,
  } = useFormModal();

  const { data, loading, refetch } = useQuery<DocumentDetailResponse>(FETCH_DOCUMENT_SHARED_DETAIL, {
    variables: { id: documentID },
  });

  const sendReminderHandler = () => {
    hideModal();
    if (data?.fetchDocument) {
      success({
        message: `Email reminder was sent to ${data.fetchDocument.actionPendingCount} employees`,
        duration: 2,
        closeIcon: false,
        className: "notification-bar",
      });
    }
  };

  const [sendReminder] = useMutation<SendDocumentReminderResponse>(SEND_DOCUMENT_REMINDER, {
    onCompleted: (response) => {
      if (response?.sendDocumentReminder?.success) {
        sendReminderHandler();
      }
    },
  });

  const userHaveAccess = data ? data.fetchDocument.userDocumentConnections.map((item) => item.user.id) : [];

  return (
    <Row id="main" className="h-100">
      <Col span={24}>
        <Row id="document_shared_list_title">
          <Col span={24}>
            <StyledWrapper>
              <BreadCrumb
                label={
                  loading
                    ? "Loading document name..."
                    : data?.fetchDocument.attachmentName
                    ? removeExtensionFromName(data.fetchDocument.attachmentName)
                    : "Loading Failed."
                }
                onClick={() => navigate("/document")}
              />
            </StyledWrapper>
          </Col>
        </Row>
        <Row id="document_shared_list_edit">
          <Col span={24}>
            {loading && (
              <StyledContainer className="section-box-shadow">
                <LoadingPage />
              </StyledContainer>
            )}
            {!loading && data?.fetchDocument && (
              <StyledContainer className="section-box-shadow">
                <Row>
                  {data?.fetchDocument.shareType && (
                    <Col span={16}>
                      <div className="d-flex align-items-center gap-2">
                        <StyledTitle level={2} className="mb-0">
                          Shared with:
                        </StyledTitle>
                        <Typography.Text>{documentShareTypeLabel[data.fetchDocument.shareType]}</Typography.Text>
                        <DefaultButton onClick={showEditShareWithModal}>Edit</DefaultButton>
                      </div>
                    </Col>
                  )}
                  <Col span={data?.fetchDocument.shareType ? 8 : 24}>
                    <div className="d-flex justify-content-end align-items-center gap-2">
                      {data?.fetchDocument.shareType && (
                        <div className="position-relative">
                          <PrimaryButton onClick={() => setOpenAddEmployeeForm(true)}>Add employees</PrimaryButton>
                          {openAddEmployeeForm && (
                            <AddEmployeeAccess
                              closeForm={() => setOpenAddEmployeeForm(false)}
                              userHaveAccess={userHaveAccess}
                              refetch={refetch}
                              documentId={data?.fetchDocument.id}
                            />
                          )}
                        </div>
                      )}
                      <SuccessButton
                        onClick={showModal}
                      >{`Resend actions pending (${data?.fetchDocument.actionPendingCount})`}</SuccessButton>
                    </div>
                  </Col>
                </Row>
              </StyledContainer>
            )}
          </Col>
        </Row>
        <Row className="mt-4 section-box-shadow">
          <Col span={24}>
            <DocumentSharedListTable
              tableData={data?.fetchDocument.userDocumentConnections}
              refetch={refetch}
              loading={loading}
              actionDescription={data?.fetchDocument.action?.description}
            />
          </Col>
        </Row>
      </Col>
      {data && (
        <>
          <ConfirmModal
            title={`This will send a reminder to action to 
            ${data.fetchDocument.actionPendingCount} employees - do you wish to proceed?`}
            onCancel={hideModal}
            open={isModalVisible}
            onOk={() => sendReminder({ variables: { documentId: data?.fetchDocument.id } })}
            width={380}
            okText="Yes, send reminder"
            cancelText="No"
          />
          <Modal
            title="Shared with"
            open={isEditShareWithModalVisible}
            onCancel={hideEditShareWithModal}
            footer={null}
            width={330}
            centered
            destroyOnClose
            closeIcon={false}
          >
            <EditShareWith
              onCancel={hideEditShareWithModal}
              refetch={refetch}
              initialData={data.fetchDocument.shareType}
              documentId={data.fetchDocument.id}
            />
          </Modal>
        </>
      )}
    </Row>
  );
};

export default DocumentSharedDetail;

const StyledWrapper = styled.div`
    display:  flex;
    justify-content: start;
    align-items-center:
    gap: 31px;

    .ant-row{
        margin-top: 0 !important;
    }
`;

const StyledContainer = styled(Container)`
  padding: 9px 9px 9px 34px;
  margin-top: 28px;
  min-height: 54px;

  & > div:first-child {
    padding: 0 !important;
  }
`;

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 14px;
    font-weight: 600;
  }
`;
