import styled from "styled-components";
import backgroundImage from "assets/images/employee-bg-gradient.svg";
import { Container } from "components/core";
import { Typography } from "antd";
import COLORS from "constants/colors";

interface SimpleContentProps {
  title: string;
  content: string[];
}

interface ListContentProps {
  title: string;
  content: { title: string; description: string; subContent?: string[] }[];
  extraClassName?: string;
}

const SimpleContent = ({ title, content }: SimpleContentProps) => (
  <div className="flex-column-align-start-justify-start pb-41">
    <Typography.Title level={2} className="pb-2">
      {title}
    </Typography.Title>
    {content.map((item, index) => (
      <Typography.Text className={index !== content.length - 1 ? "pb-2" : ""} key={`content${content.length - index}`}>
        {item}
      </Typography.Text>
    ))}
  </div>
);

const ListContent = ({ title, content, extraClassName }: ListContentProps) => (
  <div className={`flex-column-align-start-justify-start ${extraClassName}`}>
    <Typography.Title level={2} className="pb-2">
      {title}
    </Typography.Title>
    <ul className="p-0">
      {content.map((item, index) => (
        <div key={`listContent${content.length - index}`}>
          <StyledLi className="pb-2 pt-2">
            <span>
              <strong>{item.title}: </strong>
              {item.description}
            </span>
          </StyledLi>

          {item.subContent &&
            item.subContent.length &&
            item.subContent.map((subItem, index, array) => (
              <ul key={`subList${array.length - index}`} className="ps-4">
                <li>{subItem}</li>
              </ul>
            ))}
        </div>
      ))}
    </ul>
  </div>
);

const TermsOfService = () => (
  <div>
    <StyledDiv className="position-absolute top-0 start-0" />
    <StyledContainer className="mt-5 section-box-shadow">
      <Typography.Title level={1} className="pb-41">
        Humanshine Terms of Service
      </Typography.Title>

      <SimpleContent
        title="Welcome to Humanshine"
        content={[
          `Humanshine provides human resource management solutions designed to streamline 
          HR processes and operations for businesses of all sizes. Our services include 
          solutions for On-boarding and Employee Database Management, Leave Management, 
          Document &Training Management as well as a Manager Overview & Analytics capabilities 
          all aimed at enhancing your HR processes and reducing.`,
          `Our mission is to help companies streamline their HR processes, empower
           employee autonomy and enhance operational efficiency to achieve happier and more 
           productive workplaces. With a dedicated team of seasoned HR and technology experts, 
           we are positioned to transform HR management by focusing on customer collaboration, 
           growth and innovative solutions. As companies embrace the future of digital HR management, 
           Humanshine bridges the gap between tradition and innovation, empowering  organizations to radiate 
           excellence from within.
          `,
        ]}
      />

      <div className="flex-column-align-start-justify-start pb-41">
        <Typography.Title level={2} className="pb-2">
          Definitions
        </Typography.Title>
        <Typography.Text className="pb-2">
          <strong>&quot;Service&quot;</strong> refers to the applications, websites, content, products, and services
          provided by Humanshine.
        </Typography.Text>
        <Typography.Text className="pb-2">
          <strong>&quot;User&quot;</strong> or <strong>&quot;You&quot;</strong> means any individual or entity that uses
          our Service.
        </Typography.Text>
        <Typography.Text className="pb-2">
          <strong>&quot;Content&quot;</strong> includes all text, images, audio, video, and other forms of data or
          communication.
        </Typography.Text>
        <Typography.Text className="pb-2">
          <strong>&quot;Third-Party Services&quot;</strong> are services or content provided by entities or individuals
          other than Humanshine, which may be accessible or used in conjunction with the Service.
        </Typography.Text>
        <Typography.Text className="pb-2">
          <strong>&quot;Intellectual Property Rights&quot;</strong> include copyrights, trademarks, patents, trade
          secrets, and all other proprietary rights.
        </Typography.Text>
        <Typography.Text>
          <strong>&quot;Confidential Information&quot;</strong> refers to any data or information, oral or written,
          treated as confidential that a User comes into contact with as a consequence of using the Service, which is
          not publicly known and is owned by Humanshine.
        </Typography.Text>
      </div>

      <SimpleContent
        title="Acceptance of the Terms of Service"
        content={[
          `By accessing or using our Service, you acknowledge that you have read, 
          understood, and agreed to be bound by these Terms of Service ("ToS"). If you 
          are entering into these ToS on behalf of a business or another entity, 
          you represent that you have the authority to bind such entity and its 
          affiliates to these ToS, in which case the terms "you" or "your" shall 
          refer to such entity and its affiliates. If you do not have such authority, 
          or if you do not agree with these ToS, you must not accept these ToS and may 
          not use the service.`,
        ]}
      />

      <SimpleContent
        title="Modification of Terms"
        content={[
          `We reserve the right to update or change our ToS at any time and for any
            reason. We will attempt to notify you of any material changes to these 
            ToS but also encourage you to review these ToS periodically for any updates
            or changes.`,
        ]}
      />

      <SimpleContent
        title="Service Provision"
        content={[
          `Humanshine is provided on an "as is" and "as available" basis. While we strive to provide a
           seamless service experience, we do not guarantee that the service will be uninterrupted or error-free.
          `,
        ]}
      />

      <ListContent
        title="Account Sign Up/ Registration and Use"
        extraClassName="pb-41"
        content={[
          {
            title: "Account Creation",
            description: `You must sign-up/ register an account to access and use certain features of 
            the Service. By creating an account, you agree to provide accurate, current, 
            and complete information about yourself.`,
          },
          {
            title: "Account Responsibilities",
            description: `You are responsible for safeguarding your account, ensuring that any 
            activities or actions under your account comply with these ToS. You should notify us 
            immediately upon becoming aware of any breach of security or unauthorized use of your account.`,
          },
        ]}
      />

      <ListContent
        title="Content and Data Protection"
        extraClassName="pb-41"
        content={[
          {
            title: "User Content",
            description: `You retain all rights to any content you submit, post, or display on or through the Service.`,
          },
          {
            title: "Data Protection",
            description: `We are committed to protecting the privacy and security of your data. We undertake 
            to comply with applicable data protection laws and regulations. You agree to comply with all applicable 
            data protection laws in your use of the Service, including any data you may collect from your employees.`,
          },
        ]}
      />

      <ListContent
        title="Fees, Payments & Subscription Terms"
        extraClassName="pb-3"
        content={[
          {
            title: "Fee",
            description: `The service costs €6 per employee per month with. We offer special discounts for users who 
            opt for an annual subscription and early adopter customers.  Payments are due monthly or annually, based 
            on your subscription choice.`,
          },
          {
            title: "Employee Accounts",
            description: `In utilizing the Subscribed Services, you and your Affiliates are permitted to create an 
            account within the Platform for each employee (Employee Account) whom you wish to manage using the 
            Subscribed Services.`,
            subContent: [
              `You have the flexibility to remove an individual’s record from the Platform and replace 
              it with a record for another employee.`,
              `You are required to ensure that you and your employees maintain the confidentiality of passwords or 
              access details for the Subscribed Services and not share these with any third party.`,
              `Should the number of Employee Accounts exceed the number agreed upon in your Sales Agreement, 
              the provisions for Additional Employee Accounts will come into effect.`,
            ],
          },
          {
            title: "Additional Employee Accounts",
            description: `If at any point the number of Employee Accounts surpasses the number initially subscribed:`,
            subContent: [
              `A pro-ration calculation will be conducted to determine the pro-rated charges for the additional 
              Employee Accounts added, based on the remaining time in the Subscription Period.`,
              `You will be invoiced for the charges associated with additional Employee Accounts on a monthly basis, 
              payable in arrears.`,
              `Payment of our invoices shall be made in accordance with the agreed payment method, or we may directly 
              debit the charges for additional Employee Accounts from your bank account, credit card, or debit card, 
              utilizing the default payment method you have provided to us.`,
            ],
          },
          {
            title: "Minimum Employee Accounts",
            description: `Throughout your Subscription Period, the number of Employee Accounts cannot be reduced below 
            the minimum number agreed upon in the Sales Agreement (Minimum Employee Threshold).`,
            subContent: [
              `If the total number of active Employee Accounts falls below the Minimum Employee Threshold, you will 
              still be billed based on the Minimum Employee Threshold. This billing practice will continue for the 
              remainder of your subscription period.`,
            ],
          },
        ]}
      />

      <ListContent
        title="Payment Terms:"
        extraClassName="pb-41"
        content={[
          {
            title: "Invoicing",
            description: `Unless specified differently within a Sales Agreement, we will issue invoices for 
            Subscription Fees in advance of the upcoming Subscription Period or any subsequent renewal period.
            `,
          },
          {
            title: "Commitment to Payment",
            description: `By agreeing to these terms, you commit to paying the 
            Subscription Fees for the agreed Subscription Period.`,
          },
          {
            title: "Renewal Notification",
            description: `If you do not terminate the subscription in advance of the renewal date, we will 
            automatically issue an invoice for the Subscription Fees applicable to the next renewal period.
            `,
          },
          {
            title: "Outstanding Invoices",
            description: `Should there be any additional charges accumulated during the current Subscription 
            Period, these will remain due even if you decide not to renew your subscription.`,
          },
          {
            title: "Adjustments at Renewal",
            description: `The Subscription Fees for any renewal period will reflect the current number of 
            active employees at the time of renewal.
            `,
          },
        ]}
      />

      <ListContent
        title="Payment Methods:"
        extraClassName="pb-41"
        content={[
          {
            title: "Automatic Debit",
            description: `On the Start Date and at each renewal, we will automatically charge the Subscription 
            Fees to your designated bank account, credit card, or debit card, as per the authorization provided 
            in the Sales Agreement.
            `,
          },
          {
            title: "Invoice Payments",
            description: `Should you receive an invoice from us, it is required that the payment, including any 
            agreed additional charges, be made within xx days from the invoice date. Payments should be transferred 
            to our bank account using the details provided on the invoice.`,
          },
          {
            title: "Fee Adjustments",
            description: `We reserve the right to adjust the Subscription Fees at the time of each renewal to 
            reflect current pricing.
            `,
          },
          {
            title: "VAT",
            description: `The Subscription Fees do not include VAT which must be paid in accordance with applicable 
            laws and regulations.
            `,
          },
        ]}
      />

      <SimpleContent
        title="Duration"
        content={[
          `The Agreement becomes effective on the Effective Date and, unless terminated earlier in accordance with 
          its terms, continues until the end of the Subscription Period (the Term). `,
          `The Effective Date is the date on which the Agreement comes into effect, which is the day the account 
          is created or the day the Sales Agreement is signed, whichever comes first.`,
          `At the end of the Subscription Period, and any renewal thereof, the Subscription Period shall automatically 
          renew on the then-current terms (notwithstanding that we may amend our Fees as outlined in a separate clause) 
          unless the customer cancels`,
        ]}
      />

      <SimpleContent
        title="Termination and Account Cancellation"
        content={[
          `You can end your subscription with Humanshine whenever you choose. To streamline this process, Humanshine 
          offers an in-platform cancellation option that is designed to be quick and user-friendly, ensuring that 
          your account termination is processed instantly and without the need for manual intervention.`,
          `Please note, the responsibility to cancel your account lies with you, utilizing the provided automated 
          feature. Humanshine will not process cancellations communicated through email or written correspondence.`,
          `If you find yourself facing any challenges while attempting to cancel, our support team is ready to provide 
          assistance. We strongly advise performing a comprehensive backup of your data prior to cancellation, as 
          account termination will lead to immediate loss of access to the Humanshine platform. After your account 
          is cancelled, your data will be systematically removed from our active servers and, after a period of 10 to 
          14 days, from our backup systems as well, making recovery impossible.`,
          `Please be aware that Humanshine does not offer refunds on a pro-rata basis for any unused portion of the 
          service. Once your cancellation is confirmed, there will be no further charges to your account.
          `,
          `Humanshine retains the authority to suspend or terminate your account at our discretion, should there be a 
          breach of our terms of service, a legal requirement for such action, or an inability on our part to 
          continue offering the service. Account suspension will be lifted once the underlying issue is 
          resolved satisfactorily.`,
          `Should Humanshine terminate your account for any breach of terms or other stipulated reasons, 
          it is important to understand that you will not be entitled to any form of refund.
          `,
        ]}
      />

      <SimpleContent
        title="Disclaimer of Warranties"
        content={[
          `The Service is provided on an "as is" and "as available" basis without any warranties of any kind, 
          including that the Service will operate error-free or that the Service, its servers, or the content 
          are free of computer viruses or similar contamination or destructive features.
          `,
        ]}
      />

      <SimpleContent
        title="Limitation of Liability"
        content={[
          `Humanshine’s liability, if any, for any damages arising from or related to this agreement (for any 
            cause whatsoever and regardless of the form of the action), will at all times be limited to your 
            direct damages and will not exceed the fees paid by you to Humanshine under this agreement in the 
            twelve (12) months preceding the claim.
          `,
        ]}
      />

      <SimpleContent
        title="Intellectual Property"
        content={[
          `All rights, title, and interest in and to the service (excluding content provided by users) 
          are and will remain the exclusive property of Humanshine and its licensors.

          `,
        ]}
      />

      <SimpleContent
        title="Governing Law and Jurisdiction"
        content={[
          `These ToS and any disputes arising out of or related to these ToS or the Service will be governed 
          by and construed in accordance with the laws of Ireland, without giving effect to any choice or 
          conflict of law provision or rule.
          `,
        ]}
      />

      <ListContent
        title="General Provisions"
        extraClassName="pb-41"
        content={[
          {
            title: "Entire Agreement",
            description: `These ToS constitute the entire agreement between you and Humanshine regarding 
            the use of the Service.`,
          },
          {
            title: "Severability",
            description: `If any provision of these ToS is held to be invalid or unenforceable, that provision 
            will be enforced to the maximum extent permissible, and the other provisions of these ToS will remain 
            in full force and effect.`,
          },
          {
            title: "No Waiver",
            description: `The failure of Humanshine to enforce any right or provision of these ToS will not be deemed 
            a waiver of such right or provision.`,
          },
        ]}
      />

      <div className="flex-column-align-start-justify-start">
        <Typography.Title level={2} className="pb-2">
          Contact Information
        </Typography.Title>
        <Typography.Text>
          If you have any questions about these ToS, please contact us at
          <StyledLinkedStrong className="link">info@humanshine</StyledLinkedStrong>.
        </Typography.Text>
      </div>
    </StyledContainer>
  </div>
);

export default TermsOfService;

const StyledDiv = styled.div`
  background-image: url(${backgroundImage});
  width: 100%;
  height: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 28px 36px;
`;

const StyledContainer = styled(Container)`
  padding: 51px 106px 52px 58px;
  position: relative;

  & .pb-41 {
    padding-bottom: 41px;
  }

  & .flex-column-align-start-justify-start {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }

  & strong {
    color: ${COLORS.inputFieldTextColor};
  }

  & ul ul li {
    list-style: disc;
  }
`;

const StyledLi = styled.li`
  list-style: none;

  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    background: ${COLORS.successColor};
    transform: rotate(45deg);
  }
`;

const StyledLinkedStrong = styled.strong`
  &.link {
    color: ${COLORS.tertiary};
    margin-left: 5px;
  }
`;
