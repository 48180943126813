import { useMutation } from "@apollo/client";
import { LeaveRequestResponse } from "model/Leave";
import { useNotify } from "services/api";
import { CREATE_LEAVE_REQUEST, FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE } from "services/graphql/leave";

export const useCreateLeaveRequest = (id?: string, successCallback?: () => void, type?: "record" | "request") => {
  const notify = useNotify();
  const [mutate, { loading }] = useMutation<LeaveRequestResponse>(CREATE_LEAVE_REQUEST, {
    onCompleted: (response) => {
      if (response?.createLeaveRequest) {
        if (response.createLeaveRequest.errors) {
          notify.error(response.createLeaveRequest.errors.fullMessages);
        } else {
          notify.success({ message: `Leave ${type === "record" ? "recorded" : "requested"} successfully.` });
          if (successCallback) successCallback();
        }
      }
    },
    refetchQueries: [{ query: FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE, variables: { userId: id } }],
  });

  return { mutate, loading };
};

export default useCreateLeaveRequest;
