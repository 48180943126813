import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DefaultButton } from "components/Buttons";
import COLORS from "constants/colors";
import STRIPE from "constants/stripe";
import TABLE from "constants/table";
import { getFilenameFromUrl } from "features/employee/leave";
import { BillingHistory } from "model/AdminSetting";
import styled from "styled-components";
import { downloadLink } from "utils";

interface Props {
  data?: BillingHistory[];
}

const PaymentHistoryTable = ({ data }: Props) => {
  const columns: ColumnsType<BillingHistory> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "id",
      align: "left",
      width: "32%",
      render: (_, { fromDate }) => <span className="date">{fromDate}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      align: "left",
      width: "23%",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "id",
      align: "left",
      render: (_, { price, currency }) => (
        <span className="price">{`${
          STRIPE.currencySymbols[currency as keyof typeof STRIPE.currencySymbols]
        }${price}`}</span>
      ),
      width: "22%",
    },
    {
      title: "Receipt",
      dataIndex: "download",
      render: (_, { invoicePdf }) => (
        <DefaultButton onClick={() => downloadLink(invoicePdf, getFilenameFromUrl(invoicePdf), true, "_blank")}>
          Download
        </DefaultButton>
      ),
      width: "23%",
    },
  ];
  return (
    <StyledWrapper>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        scroll={{
          scrollToFirstRowOnChange: false,
          y: TABLE.tableDefaultHeight,
        }}
      />
    </StyledWrapper>
  );
};

export default PaymentHistoryTable;

const StyledWrapper = styled.div`
  & .ant-table .ant-table-tbody tr td:first-child,
  .ant-table .ant-table-thead tr th:first-child,
  .ant-table .ant-table-thead tr td:first-child {
    padding-left: 0;
  }

  & .ant-table .ant-table-tbody tr td:last-child,
  .ant-table .ant-table-thead tr th:last-child,
  .ant-table .ant-table-thead tr td:last-child {
    padding-right: 0;
  }

  & .date {
    font-weight: 500;
  }

  & .price {
    color: ${COLORS.disabledColor};
  }
`;
