import { useSafeContext, useUpdater } from "hooks";
import {
  NotificationType,
  OtherNotificationMsg,
  messageItem,
  notificationsFilteredData,
  OtherUpdateData,
} from "model/Notification";
import { isAdmin, isManager } from "model/User";
import React, { useMemo } from "react";
import { EnhancedLeaveRequest, FetchUserLeaveListResponse, LeaveRequestsStatus } from "model/Leave";
import { useQuery } from "@apollo/client";
import { FETCH_LEAVE_REQUESTS_STATUS } from "services/graphql/leave";
import { expectNumber, includesInArray } from "utils";
import {
  BirthdaysThisWeek,
  ProbationaryPeriodEndingThisWeek,
  StartingThisWeek,
  TodayDrawerResponse,
  WorkAnniversaryThisWeek,
} from "model/Calendar";
import dayjs from "dayjs";
import { FETCH_TODAY_DRAWER_DATA } from "services/graphql/calendar";
import EMPLOYEE from "constants/employee";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import {
  DocumentActionDataModify,
  DocumentReviewDataType,
  DocumentReviewResponse,
  FetchDocumentsResponse,
} from "model/Document";
import { FETCH_DOCUMENTS, FETCH_DOCUMENT_REVIEW } from "services/graphql/document";
import { removeExtensionFromName } from "features/employee/leave";
import { useAuthContext } from "./AuthProvider";

const NotificationContext = React.createContext<{
  notificationsCount: number;
  refresh: () => void;
  refetchEmployeePending: () => void;
  employeePendingData?: EnhancedLeaveRequest[];
  refetchMyNotificationLeave: () => void;
  myNotificationLeaveData?: EnhancedLeaveRequest[];
  otherNotificationData?: OtherUpdateData[];
  refetchOtherNotification: () => void;
  employeeNotificationCount: number;
  myNotificationCount: number;
  employeeActionedData: DocumentActionDataModify[];
  employeeDocumentRefetch: () => void;
  myDocumentData: DocumentReviewDataType[];
  myDocumentReviewRefetch: () => void;
} | null>(null);

export const useNotificationContext = () => useSafeContext(NotificationContext);

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuthContext();
  const [counter, updater] = useUpdater();
  const { companies, employeeDocumentNotification } = PERMISSION;
  const { hasCompanies, hasEmployeeDocumentNotification } = useCheckPermission({
    companies,
    employeeDocumentNotification,
  });

  const { data: employeePendingData, refetch: refetchEmployeePending } = useQuery<FetchUserLeaveListResponse>(
    FETCH_LEAVE_REQUESTS_STATUS,
    {
      variables: { statuses: [LeaveRequestsStatus.Pending] },
      fetchPolicy: "no-cache",
      skip: !user?.subscriptionActive || !isAdmin(user),
    },
  );
  const { data: myNotificationLeaveData, refetch: refetchMyNotificationLeave } = useQuery<FetchUserLeaveListResponse>(
    FETCH_LEAVE_REQUESTS_STATUS,
    {
      variables: { statuses: [LeaveRequestsStatus.Approved, LeaveRequestsStatus.Declined], userId: user?.id },
      skip: hasCompanies || !user?.subscriptionActive || !user?.id,
    },
  );

  const modifyOtherNotification = (response: TodayDrawerResponse) => {
    const otherData = Object.entries(response).reduce((result, [key, { nodes }]) => {
      if (nodes.length > 0) {
        result.push(
          ...nodes.map((item: messageItem, index: string) => ({
            id: expectNumber(`${item.id}${index}`),
            name: item.name,
            message: (EMPLOYEE.messages[key as keyof typeof EMPLOYEE.messages] as OtherNotificationMsg)(
              key === "birthdaysThisWeek"
                ? (item as BirthdaysThisWeek).dateOfBirth
                : (item as ProbationaryPeriodEndingThisWeek | StartingThisWeek | WorkAnniversaryThisWeek).startedAt,
            ),
          })),
        );
      }
      return result;
    }, [] as OtherUpdateData[]);

    return otherData;
  };

  const { data: otherNotification, refetch: refetchOtherNotification } = useQuery<TodayDrawerResponse>(
    FETCH_TODAY_DRAWER_DATA,
    {
      variables: {
        calendarYear: dayjs().year(),
        month: dayjs().month() + 1,
        day: dayjs().date(),
        skipPeopleOff: true,
      },
      skip: hasCompanies || !user?.subscriptionActive || !user?.id,
    },
  );

  const { data: employeeDocumentActionedData, refetch: employeeDocumentRefetch } = useQuery<FetchDocumentsResponse>(
    FETCH_DOCUMENTS,
    {
      variables: { notCompleted: true },
      skip: hasCompanies || !user?.subscriptionActive || !hasEmployeeDocumentNotification,
    },
  );

  const { data: MyDocumentReviewData, refetch: myDocumentReviewRefetch } = useQuery<DocumentReviewResponse>(
    FETCH_DOCUMENT_REVIEW,
    {
      variables: { notActioned: true },
      skip: hasCompanies || !user?.subscriptionActive || !user?.id,
      fetchPolicy: "no-cache",
    },
  );

  const approvedLeaveData = useMemo(() => {
    const data = myNotificationLeaveData ? [...myNotificationLeaveData?.leaveRequests.nodes] : [];
    return [...data.sort((a, b) => expectNumber(a.id) - expectNumber(b.id))];
  }, [myNotificationLeaveData]);

  const employeePendingLeaveData = useMemo(() => {
    const data = [...(employeePendingData?.leaveRequests.nodes ?? [])].sort(
      (a, b) => expectNumber(a.id) - expectNumber(b.id),
    );
    return isManager(user) ? data.filter((item) => includesInArray(user?.managedUserIds, item.user?.id)) : data;
  }, [employeePendingData?.leaveRequests.nodes, user]);

  const otherDataLength = otherNotification
    ? notificationsFilteredData(modifyOtherNotification(otherNotification), NotificationType.Other).length
    : 0;
  const pendingDataLength = employeePendingLeaveData?.length ?? 0;
  const approvedDataLength = notificationsFilteredData(approvedLeaveData, NotificationType.Holiday).length;

  const employeeActionedData = useMemo(() => {
    const data: DocumentActionDataModify[] =
      employeeDocumentActionedData?.documents.nodes.map((item) => ({
        id: item.id,
        name: removeExtensionFromName(item.attachmentName),
        actionPending: item.actionPendingCount,
        action: item.action?.description,
      })) ?? ([] as DocumentActionDataModify[]);
    return [...data];
  }, [employeeDocumentActionedData]);

  const employeeDocumentLength = employeeActionedData
    ? notificationsFilteredData(employeeActionedData, NotificationType.Document).length
    : 0;

  const myDocumentLength = MyDocumentReviewData
    ? notificationsFilteredData(MyDocumentReviewData.documentReview.documents, NotificationType.MyDocument).length
    : 0;

  const adminNotificationsCount = isAdmin(user) ? pendingDataLength + employeeDocumentLength : 0;
  const notificationsCount = otherDataLength + approvedDataLength + adminNotificationsCount + myDocumentLength;

  const employeeNotificationCount = otherDataLength + (isAdmin(user) ? pendingDataLength : 0) + employeeDocumentLength;
  const myNotificationCount = approvedDataLength + (!isAdmin(user) ? otherDataLength : 0) + myDocumentLength;

  const value = useMemo(
    () => ({
      notificationsCount,
      refresh: updater,
      employeePendingData: employeePendingLeaveData,
      refetchEmployeePending,
      myNotificationLeaveData: notificationsFilteredData(approvedLeaveData, NotificationType.Holiday) ?? [],
      refetchMyNotificationLeave,
      otherNotificationData: otherNotification
        ? notificationsFilteredData(modifyOtherNotification(otherNotification), NotificationType.Other)
        : [],
      refetchOtherNotification,
      employeeNotificationCount,
      myNotificationCount,
      employeeActionedData: employeeActionedData
        ? notificationsFilteredData(employeeActionedData, NotificationType.Document)
        : [],
      employeeDocumentRefetch,
      myDocumentData: MyDocumentReviewData
        ? notificationsFilteredData(MyDocumentReviewData.documentReview.documents, NotificationType.MyDocument)
        : [],
      myDocumentReviewRefetch,
    }),
    [
      notificationsCount,
      updater,
      employeePendingLeaveData,
      refetchEmployeePending,
      approvedLeaveData,
      refetchMyNotificationLeave,
      otherNotification,
      refetchOtherNotification,
      employeeNotificationCount,
      myNotificationCount,
      employeeActionedData,
      employeeDocumentRefetch,
      MyDocumentReviewData,
      myDocumentReviewRefetch,
    ],
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
