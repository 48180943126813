import { gql } from "@apollo/client";

export const PAGE_INFO_FRAGMENT = gql`
  fragment PageInfoFragment on PageInfo {
    endCursor
    startCursor
    hasNextPage
    hasPreviousPage
  }
`;

export const ERROR_FRAGMENT = gql`
  fragment ErrorFragment on ValidationErrors {
    details
    fullMessages
  }
`;

export const WORKING_CONDITION_FRAGMENT = gql`
  fragment WorkingConditionFragment on WorkingCondition {
    endTime
    isFriday
    isMonday
    isSaturday
    isSunday
    isThursday
    isTuesday
    isWednesday
    startTime
  }
`;
