import { Col, Row, Empty } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { FETCH_COMPANY } from "services/graphql/company";
import { BreadCrumb } from "components/BreadCrumb";
import { Container, LoadingPage } from "components/core";
import COLORS from "constants/colors";
import { FetchCompanyResponse, PaymentTypeLabel } from "model/Company";
import dayjs from "dayjs";
import DATE_FORMAT from "constants/dateFormat";

type CompanyFieldItemProps = {
  title: string;
  value: string;
};

const CompanyFieldItem = ({ title, value }: CompanyFieldItemProps) => (
  <StyleListItem>
    <StyledTitle>{title}</StyledTitle>
    <StyledData>{value}</StyledData>
  </StyleListItem>
);

const CompanyDetail = () => {
  const { id } = useParams();
  const {
    loading,
    data: company,
    error,
  } = useQuery<FetchCompanyResponse>(FETCH_COMPANY, {
    variables: { id },
  });

  const navigate = useNavigate();

  return (
    <div>
      <BreadCrumb
        label={loading ? "Loading company name..." : company?.fetchCompany?.companyName ?? "Loading Failed."}
        onClick={() => navigate("/home")}
      />
      <Row className="mt-4 pt-1">
        <Col span={24}>
          <StyledDetailContainer className="section-box-shadow">
            {loading && <LoadingPage />}
            {company?.fetchCompany && (
              <StyledList>
                <CompanyFieldItem title="Company ID" value={company.fetchCompany.companyId} />
                <CompanyFieldItem
                  title="Sign up date"
                  value={
                    company.fetchCompany.signedAt
                      ? dayjs(company.fetchCompany.signedAt).format(DATE_FORMAT.fullDateDefault)
                      : ""
                  }
                />
                <CompanyFieldItem title="Super User" value={company.fetchCompany.superuserName} />
                <CompanyFieldItem title="Superuser Email" value={company.fetchCompany.superuserEmail} />
                <CompanyFieldItem title="Superuser phone" value={company.fetchCompany.superuserPhone} />
                <CompanyFieldItem title="Payment type" value={PaymentTypeLabel[company.fetchCompany.paymentType]} />
              </StyledList>
            )}
            {error && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          </StyledDetailContainer>
        </Col>
      </Row>
    </div>
  );
};
export default CompanyDetail;

const StyledDetailContainer = styled(Container)`
  min-height: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 12px 37px 9px 37px;
  width: 100%;
`;

const StyleListItem = styled.li`
  border-bottom: 1px solid ${COLORS.borderLight};
  width: 100%;
  padding: 6px 0;
  display: flex;
  gap: 4%;

  &:last-child {
    border: none;
  }
`;

const StyledTitle = styled.span`
  width: 140px;
  font-weight: 400;
  line-height: 38px;
  color: ${COLORS.inputFieldTextColor};
`;

const StyledData = styled(StyledTitle)`
  font-weight: 600;
  color: ${COLORS.primaryColor};
  width: 100%;
`;
