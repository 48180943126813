import { useMutation } from "@apollo/client";
import { Row } from "antd";
import Form, { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { LinkButton, PrimaryButton } from "components/Buttons";
import { InputPassword } from "components/Inputs";
import ALERTS from "config/alerts";
import AUTH from "constants/auth";
import { MutationResetPasswordArgs, ResetPasswordPayload } from "model/User";
import { ReactElement, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { useNotify } from "services/api";
import { RESET_PASSWORD_DOCUMENT } from "services/graphql/user";

export function ResetForm(): ReactElement {
  const [form] = useForm();
  const [params] = useSearchParams();
  const token = params.get(AUTH.resetPasswordToken);
  const [isSuccess, setIsSuccess] = useState(false);
  const notify = useNotify();

  const [mutate, { loading }] = useMutation<ResetPasswordPayload>(RESET_PASSWORD_DOCUMENT, {
    onCompleted: (response) => {
      if (response.resetPassword) {
        if (!response.resetPassword.success) notify.error(undefined, response.resetPassword.errors?.fullMessages[0]);
        else
          notify.success({
            duration: 2,
            message: "Reset password done.",
            onClose: () => setIsSuccess(response.resetPassword.success),
          });
      }
    },
  });

  if (isSuccess || !token) return <Navigate to="login" />;

  const onFinish = ({
    password,
    passwordConfirmation,
  }: Pick<MutationResetPasswordArgs, "password" | "passwordConfirmation">) => {
    mutate({ variables: { password, passwordConfirmation, resetPasswordToken: token } });
  };

  return (
    <Form labelCol={{ span: 24 }} form={form} initialValues={{}} onFinish={onFinish} autoComplete="off">
      <FormItem rules={[ALERTS.required]} label="Enter new password here" name="password">
        <InputPassword placeholder="Password" data-testid="password" />
      </FormItem>
      <FormItem
        dependencies={["password"]}
        hasFeedback
        rules={[
          ALERTS.required,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("The two passwords that you entered do not match!"));
            },
          }),
        ]}
        name="passwordConfirmation"
      >
        <InputPassword placeholder="Password confirmation" data-testid="passwordConfirmation" />
      </FormItem>
      <PrimaryButton htmlType="submit" className="w-100" loading={loading}>
        Reset Password
      </PrimaryButton>
      <Row justify="center" className="mt-4">
        <Link to="/login" tabIndex={-1}>
          <LinkButton>Login Form</LinkButton>
        </Link>
      </Row>
    </Form>
  );
}

export default ResetForm;
