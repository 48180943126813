import { Typography } from "antd";
import { PrimaryButton } from "components/Buttons";

type props = {
  onClick: () => void;
};
const LeaveHeader = ({ onClick }: props) => (
  <div className="d-flex justify-content-between align-items-center">
    <Typography.Title level={2} className="m-0 title">
      My leave
    </Typography.Title>
    <PrimaryButton onClick={onClick} onKeyDown={(e) => e.stopPropagation()}>
      Request leave
    </PrimaryButton>
  </div>
);

export default LeaveHeader;
