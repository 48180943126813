import { Col, Row } from "antd";
import COLORS from "constants/colors";
import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Logo } from "assets/images/logo-login.svg";

const BackgroundLines = () => (
  <svg className="position-absolute w-100 h-100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1482.33 806.428C855.5 859 223 619.5 164.326 -262.125" stroke="url(#paint0_linear_4766_14550)" />
    <path
      opacity="0.4"
      d="M1712.45 250.403C1371.24 358.168 1009.09 142.634 1065 -436"
      stroke="url(#paint1_linear_4766_14550)"
    />
    <path opacity="0.5" d="M-590.753 617.02C81.0226 524.634 922.772 886.646 1031.63 1684.07" stroke="#9AEDAB" />
    <defs>
      <linearGradient
        id="paint0_linear_4766_14550"
        x1="707.5"
        y1="657.5"
        x2="230"
        y2="6.00029"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AEDAB" />
        <stop offset="1" stopColor="#9AEDAB" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4766_14550"
        x1="1262.5"
        y1="185"
        x2="1487"
        y2="313"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AEDAB" />
        <stop offset="1" stopColor="#9AEDAB" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

const Container = styled.div`
  background-color: ${COLORS.primaryColor};
  height: 100vh;
`;

const CustomCol = styled(Col)`
  padding: 55px 81px;
  background-color: white;
  border-radius: 4px;
  margin-top: 5%;
`;

const LoginLayout: React.VFC = () => {
  const location = useLocation();
  if (location.pathname === "/") return <Navigate to="login" />;
  return (
    <Container>
      <BackgroundLines />
      <Row justify="center">
        <Col xs={20} md={18} lg={14} xl={8} xxl={6}>
          <Row justify="center" className="my-5">
            <Logo />
          </Row>
          <CustomCol>
            <Outlet />
          </CustomCol>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginLayout;
