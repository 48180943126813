import { Checkbox, Col, Form, FormInstance, Modal, Popover, Row, Tag, Typography } from "antd";
import { BaseButton, SuccessButton } from "components/Buttons";
import Icon from "components/Icon";
import ALERTS, { stripeCardElementsRequiredValidation } from "config/alerts";
import COLORS from "constants/colors";
import React, { forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { BillingCustomerResponse, BillingDetailsRef } from "model/AdminSetting";
import { FETCH_BILLING_CUSTOMER } from "services/graphql/adminSetting";
import DATE_FORMAT from "constants/dateFormat";
import dayjs from "dayjs";
import STRIPE from "constants/stripe";
import { AddressElement, CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { InputText } from "components/Inputs";
import useFormModal from "hooks/useFormModal";
import { useNavigate } from "react-router-dom";
import { IsMutationCall } from "./BillingForm";
import PaymentHistoryTable from "./PaymentHistoryTable";
import EndSubScription from "./EndSubscription";
import { ActionArg, Actions, StateData } from "./useBilling";

type Props = {
  form: FormInstance;
  setIsMutationCall: (value: IsMutationCall) => void;
  dispatch: React.Dispatch<ActionArg>;
  billingState: StateData;
};

const BillingDetailsSection = forwardRef<BillingDetailsRef, Props>(
  ({ form, setIsMutationCall, dispatch, billingState }, ref) => {
    const navigate = useNavigate();
    const { isModalVisible, hideModal, showModal } = useFormModal();
    const {
      isModalVisible: isEndSubScriptionModalVisible,
      showModal: showEndSubScriptionModal,
      onCloseModal,
    } = useFormModal();

    const { data: customerData, refetch: refetchCustomerData } = useQuery<BillingCustomerResponse>(
      FETCH_BILLING_CUSTOMER,
      {
        onCompleted: (response) => {
          if (response.fetchCustomer) {
            const {
              email,
              subscription: { price, quantity, invoices, productName, currentPeriodEnd, promotionCode },
              paymentMethods,
            } = response.fetchCustomer;
            if (!!email && !!price && !!quantity) {
              form.setFieldsValue({
                email,
                interval: price.id,
                quantity,
              });
            }
            if (currentPeriodEnd) {
              dispatch({
                type: Actions.CurrentPackage,
                payload: { price, currentPeriodEnd, quantity },
              });
              dispatch({ type: Actions.PromoCode, payload: promotionCode });
            }
            if (invoices?.length) {
              const historyData = invoices.map(({ fromDate, invoicePdf, total, currency, id }) => ({
                id,
                fromDate: dayjs(fromDate).format(DATE_FORMAT.fullMonthAndYear),
                type: productName,
                price: total / 100,
                invoicePdf,
                currency,
              }));
              dispatch({ type: Actions.InvoicesData, payload: historyData });
            }
            if (paymentMethods?.length) {
              const card = paymentMethods.filter((item) => item.default);
              if (card.length) {
                dispatch({
                  type: Actions.DefaultCardDetails,
                  payload: {
                    cardBrand: card[0].cardBrand,
                    cardLast4: card[0].cardLast4,
                    cardExp: `${card[0].cardExpMonth}/${card[0].cardExpYear}`,
                  },
                });
              }
            }
          }
        },
      },
    );

    useImperativeHandle(ref, () => ({
      refetch() {
        refetchCustomerData();
      },
    }));

    return (
      <StyledSecondSection>
        <Col span={24}>
          <Row className="justify-content-between border-bottom-light">
            <Col md={5} lg={5} xl={3}>
              <StyledSubTitle level={5}>Payment method</StyledSubTitle>
            </Col>
            <Col md={18} lg={18} xl={20}>
              {billingState.defaultCardDetails && (
                <StyledDefaultCardContainer>
                  <div className="d-flex align-items-center gap-4">
                    <div className="d-flex align-items-center gap-1">
                      <StyledIcon name={STRIPE.cardIconName[billingState.defaultCardDetails.cardBrand]} />
                      <span className="cardNumber">{`${billingState.defaultCardDetails.cardBrand} 
                         ****${billingState.defaultCardDetails.cardLast4}`}</span>
                    </div>
                    <Tag color="default">default</Tag>
                    <span className="expiry">Expiry {billingState.defaultCardDetails.cardExp}</span>
                  </div>
                </StyledDefaultCardContainer>
              )}
              <Row className="justify-content-between">
                <Col md={18} lg={18} xl={7}>
                  <StyledStripeElement>
                    <Form.Item
                      label="Credit card number"
                      name="cardNumber"
                      className="w-100"
                      rules={[
                        ALERTS.stripeCardNumber,
                        ...(!billingState.defaultCardDetails ? [stripeCardElementsRequiredValidation()] : []),
                      ]}
                      required={false}
                    >
                      <CardNumberElement
                        className="stripContainer"
                        options={{ style: STRIPE.elementStyled }}
                        onChange={(value) => {
                          if (!value.empty) setIsMutationCall({ card: true });
                        }}
                      />
                    </Form.Item>
                  </StyledStripeElement>
                </Col>
                <Col md={18} lg={18} xl={9}>
                  <StyledStripeElement>
                    <Form.Item
                      label="Expiration date (MM/YY)"
                      className="w-75"
                      name="expirationDate"
                      rules={[
                        ALERTS.stripeExpiryDate,
                        ...(!billingState.defaultCardDetails ? [stripeCardElementsRequiredValidation()] : []),
                      ]}
                      required={false}
                    >
                      <CardExpiryElement
                        className="stripContainer"
                        options={{ style: STRIPE.elementStyled }}
                        onChange={(value) => {
                          if (!value.empty) setIsMutationCall({ card: true });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="CVV"
                      className="w-25"
                      name="cvv"
                      rules={!billingState.defaultCardDetails ? [stripeCardElementsRequiredValidation()] : []}
                      required={false}
                    >
                      <CardCvcElement
                        className="stripContainer"
                        options={{ style: STRIPE.elementStyled }}
                        onChange={(value) => {
                          if (!value.empty) setIsMutationCall({ card: true });
                        }}
                      />
                    </Form.Item>
                  </StyledStripeElement>
                </Col>
                <Col md={18} lg={18} xl={7}>
                  <Form.Item
                    name="cardHolderName"
                    label="Cardholder name"
                    className="mb-3"
                    required={false}
                    rules={!billingState.defaultCardDetails ? [ALERTS.required] : []}
                  >
                    <InputText
                      className="w-100"
                      data-testid="cardHolderName"
                      onChange={() => setIsMutationCall({ card: true })}
                    />
                  </Form.Item>
                </Col>
                {billingState.defaultCardDetails && (
                  <StyledValidationSpan className="cardNotice">
                    When you enter and save new card details, the system will automatically set these as your default
                    payment method
                  </StyledValidationSpan>
                )}
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-between border-bottom-light mt-4">
            <Col md={5} lg={5} xl={3}>
              <StyledSubTitle level={5}>Business details</StyledSubTitle>
            </Col>
            <Col md={18} lg={18} xl={20}>
              <Row className="justify-content-between">
                <Col md={18} lg={18} xl={7} className="addressElement">
                  {customerData && (
                    <Form.Item name="businessDetail" shouldUpdate>
                      <AddressElement
                        onChange={() => {
                          if (!billingState.isAddressElementCall) {
                            dispatch({ type: Actions.IsAddressElementCall, payload: true });
                          } else {
                            setIsMutationCall({ address: true });
                          }
                        }}
                        options={{
                          ...STRIPE.stripeAddressElementOptions,
                          defaultValues: customerData?.fetchCustomer
                            ? {
                                name: customerData.fetchCustomer.name,
                                phone: customerData.fetchCustomer.phone,
                                address: {
                                  country: customerData.fetchCustomer.country,
                                  city: customerData.fetchCustomer.city,
                                  line1: customerData.fetchCustomer.line1,
                                  line2: customerData.fetchCustomer.line2,
                                  postal_code: customerData.fetchCustomer.postalCode,
                                  state: customerData.fetchCustomer.state,
                                },
                              }
                            : undefined,
                        }}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-between border-bottom-light mt-4">
            <Col md={5} lg={5} xl={3}>
              <StyledSubTitle level={5}>Billing contact</StyledSubTitle>
            </Col>
            <Col md={18} lg={18} xl={20}>
              <Row className="mb-4">
                <Col md={18} lg={18} xl={7}>
                  <Form.Item
                    name="email"
                    label="Email address for billing"
                    className="mb-0"
                    required={false}
                    rules={[ALERTS.required, ALERTS.email]}
                  >
                    <InputText
                      type="email"
                      className="w-100"
                      data-testid="email"
                      onChange={() => setIsMutationCall({ address: true })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-between border-bottom-light mt-4">
            <Col md={5} lg={5} xl={3}>
              <StyledSubTitle level={5}>Payment history</StyledSubTitle>
            </Col>
            <Col md={18} lg={18} xl={20}>
              <Row className="mb-4">
                <Col span={4}>
                  <StyledBaseButton onClick={showModal}>View payment history</StyledBaseButton>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col span={24}>
              <Form.Item name="termsAndCondition" valuePropName="checked" rules={[ALERTS.checkBox]}>
                <Checkbox onChange={() => setIsMutationCall(billingState.isMutationCall)}>
                  <span className="checkBoxLabel">
                    By checking this box, you are agreeing to our
                    <Typography.Text onClick={() => navigate("/terms_of_service")}>terms of service</Typography.Text>
                  </span>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="justify-content-between mt-4">
            <Col span={24} className="d-flex align-items-center justify-content-between">
              {!billingState.isSaveDisabled ? (
                <SuccessButton htmlType="submit" loading={billingState.isProcessing}>
                  Save
                </SuccessButton>
              ) : (
                <Popover
                  title={<StyledPopoverTitle>Complete all fields before saving</StyledPopoverTitle>}
                  getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                >
                  <BaseButton disabled>Save</BaseButton>
                </Popover>
              )}
              <StyledWrapper onClick={showEndSubScriptionModal}>
                <Icon name="danger" />
                <StyledSubTitle level={5} className="danger mb-0">
                  End subscription and delete all company data
                </StyledSubTitle>
              </StyledWrapper>
            </Col>
          </Row>
        </Col>
        <Modal
          title="Payment history"
          open={isModalVisible}
          onCancel={hideModal}
          footer={null}
          width={545}
          centered
          destroyOnClose
        >
          <PaymentHistoryTable data={billingState.invoicesData} />
        </Modal>
        <Modal
          open={isEndSubScriptionModalVisible}
          onCancel={onCloseModal}
          footer={null}
          width={416}
          centered
          destroyOnClose
        >
          <EndSubScription onCloseModal={onCloseModal} />
        </Modal>
      </StyledSecondSection>
    );
  },
);

export default BillingDetailsSection;

const StyledSubTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 14px;
    margin: 0;
    margin-bottom: 0px;
    line-height: 22px;
  }
  &.danger {
    color: ${COLORS.dangerColor};
  }
`;

const StyledValidationSpan = styled.span`
  font-weight: 400;
  font-style: italic;
  color: ${COLORS.disabledColor};
  width: 510px;
  display: block;

  &.cardNotice {
    width: 100%;
    margin-bottom: 21px;
  }
`;

const StyledSecondSection = styled(Row)`
  margin-top: 30px;
  padding: 0px 27px;

  & .addressElement {
    margin-bottom: 3px;
  }
  & .checkBoxLabel {
    font-weight: 500;

    & span {
      text-decoration: underline;
      text-underline-offset: 2px;
      margin-left: 5px;
      color: ${COLORS.inputFieldTextColor};
    }
  }
`;

const StyledBaseButton = styled(BaseButton)`
  border: 1px solid ${COLORS.borderLight};
`;

const StyledWrapper = styled.div`
  cursor: pointer;
  display: inline-flex;
  gap: 10px;
  align-items: center;
`;

const StyledStripeElement = styled.div`
  display: flex;
  align-items: start;
  gap: 1rem;

  & .stripContainer {
    padding: 9px 11px;
    height: 36px;
    width: 100%;
    border: 0.6px solid ${COLORS.lightBorder};
    border-radius: 4px;
    transition: all 0.2s;
    :hover {
      border-color: ${COLORS.inputBorderHover};
    }
  }
  & .ant-form-item-has-error .stripContainer {
    border-color: ${COLORS.errorColor};
    box-shadow: none;
  }
  & .ant-form-item-has-error .stripContainer:hover {
    border-color: ${COLORS.inputErrorBorderHover};
  }
  & .StripeElement--focus {
    border-color: ${COLORS.outlineColor};
    box-shadow: none;
    outline: 0;
  }

  & .ant-form-item-label > label::after {
    display: none;
  }
`;

const StyledDefaultCardContainer = styled.div`
  margin-bottom: 21px;
  border-bottom: 1px solid ${COLORS.borderLight};
  padding-bottom: 21px;

  & .cardNumber,
  & .expiry,
  & .ant-tag.ant-tag-default {
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    color: ${COLORS.inputFieldTextColor};
  }
  & .ant-tag.ant-tag-default {
    background-color: ${COLORS.defaultTagBgColor};
    margin-right: 0px;
  }
`;

const StyledIcon = styled(Icon)`
  &,
  & svg {
    height: 34px;
    width: 34px;
  }
  margin-top: 5px;
`;

const StyledPopoverTitle = styled.span`
  width: 140px;
  text-align: center;
  display: block;
`;
