import { Col, Row, TabsProps, Typography } from "antd";
import { BaseButton, SuccessButton } from "components/Buttons";
import { useRef, useState } from "react";
import FORM from "constants/form";
import { usePreventUnsavedForm } from "contexts/PreventUnsavedFormProvider";
import { Tabs } from "components/Tabs";
import {
  AdminSettingTab,
  AssignRoleRef,
  BillingRef,
  DefaultNotificationSettingRef,
  DefaultSettingRef,
} from "model/AdminSetting";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import styled from "styled-components";
import COLORS from "constants/colors";
import { useAuthContext } from "contexts";
import Icon from "components/Icon";
import LabelsTab from "./settings/LabelsTab";
import EmployeeNotificationsTab from "./settings/EmployeeNotificationsTab";
import AssignAdminAndManagersTab from "./settings/AssignAdminAndManagersTab";
import DefaultSettingsTab from "./settings/DefaultSettingsTab";
import BillingTab from "./settings/BillingTab";

const AdminSetting = () => {
  const { user, refetch } = useAuthContext();
  const { defaultSettings, roleSettings, labels, defaultNotificationSettings, billingTab } = PERMISSION;
  const { hasDefaultSettings, hasRoleSettings, hasLabels, hasDefaultNotificationSettings, hasBillingTab } =
    useCheckPermission({
      defaultSettings,
      roleSettings,
      labels,
      defaultNotificationSettings,
      billingTab,
    });
  const [tabActiveKey, setTabActiveKey] = useState<string>(
    !user?.subscriptionActive
      ? AdminSettingTab.Billing
      : hasDefaultSettings
      ? AdminSettingTab.DefaultSettings
      : AdminSettingTab.Labels,
  );
  const [isDelayMessage, setIsDelayMessage] = useState<boolean>(false);

  const { showConfirmModal } = usePreventUnsavedForm();
  const defaultSettingRef = useRef<DefaultSettingRef>(null);
  const assignRoleRef = useRef<AssignRoleRef>(null);
  const defaultNotificationRef = useRef<DefaultNotificationSettingRef>(null);
  const billingRef = useRef<BillingRef>(null);

  const fetchDataAfterMutation = () => {
    const element = document.getElementById("main-header");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setIsDelayMessage(true);
    setTimeout(() => {
      if (billingRef.current) {
        billingRef.current.refetchCustomerDetails();
        refetch();
      }
      setIsDelayMessage(false);
    }, 5000);
  };

  const items: TabsProps["items"] = [
    {
      key: AdminSettingTab.DefaultSettings,
      label: "Default settings",
      children: <DefaultSettingsTab ref={defaultSettingRef} />,
      show: hasDefaultSettings,
    },
    {
      key: AdminSettingTab.AssignSuperadminMangers,
      label: "Assign superadmins/managers",
      children: <AssignAdminAndManagersTab ref={assignRoleRef} />,
      show: hasRoleSettings,
    },
    {
      key: AdminSettingTab.Labels,
      label: "Labels",
      children: <LabelsTab />,
      show: hasLabels,
    },
    {
      key: AdminSettingTab.EmployeeNotifications,
      label: "Employee notifications",
      children: <EmployeeNotificationsTab ref={defaultNotificationRef} />,
      show: hasDefaultNotificationSettings,
    },
    {
      key: AdminSettingTab.Billing,
      label: "Billing",
      children: <BillingTab ref={billingRef} fetchDataAfterMutation={fetchDataAfterMutation} />,
      show: hasBillingTab,
    },
  ].filter((item) => item.show);

  const onCancelSetting = (key: string) => {
    if (
      defaultSettingRef.current?.isFieldsChanged ||
      assignRoleRef.current?.isFieldsChanged ||
      defaultNotificationRef.current?.isFieldsChanged ||
      billingRef.current?.isFieldsChanged
    ) {
      showConfirmModal(() => {
        setTabActiveKey(key);
        if (defaultSettingRef.current) {
          defaultSettingRef.current?.resetForm();
          defaultSettingRef.current?.setIsFieldsChanged(false);
        } else if (assignRoleRef.current) {
          assignRoleRef.current?.resetForm();
          assignRoleRef.current?.setIsFieldsChanged(false);
        } else if (defaultNotificationRef.current) {
          defaultNotificationRef.current?.resetForm();
          defaultNotificationRef.current?.setIsFieldsChanged(false);
        } else if (billingRef.current) {
          billingRef.current?.resetForm();
          billingRef.current?.setIsFieldsChanged();
          billingRef.current?.setIsSaveDisabled();
        }
      });
    } else {
      setTabActiveKey(key);
    }
  };

  const getFormId = (form: string) => {
    const fromIds = {
      [AdminSettingTab.DefaultSettings]: FORM.defaultSettingForm,
      [AdminSettingTab.EmployeeNotifications]: FORM.employeeNotificationForm,
    };
    return fromIds[form as keyof typeof fromIds];
  };

  return (
    <div id="main" className="h-100">
      {tabActiveKey === AdminSettingTab.Billing && (
        <StyledBillingDelayMessage className={isDelayMessage ? "visible" : ""}>
          <Icon name="warningSvg" />
          <span>New changes will take 5 seconds to reflect</span>
        </StyledBillingDelayMessage>
      )}
      <StyledTitleRow align="middle" id="admin_settings_title">
        <Col span={18}>
          <Typography.Title level={1} className="mb-0">
            Admin settings
          </Typography.Title>
        </Col>
        {getFormId(tabActiveKey) && (
          <Col span={6}>
            <div className="d-flex gap-3 justify-content-end">
              <BaseButton onClick={() => onCancelSetting(tabActiveKey)}>Cancel</BaseButton>
              <SuccessButton
                htmlType="submit"
                form={getFormId(tabActiveKey)}
                loading={defaultSettingRef.current?.loading || defaultNotificationRef.current?.loading}
              >
                Save
              </SuccessButton>
            </div>
          </Col>
        )}
      </StyledTitleRow>

      <StyledTab
        items={items}
        activeKey={tabActiveKey}
        onTabClick={(activeKey) => {
          if (tabActiveKey !== activeKey) {
            onCancelSetting(activeKey);
          }
        }}
        id="admin_settings_tabs"
        destroyInactiveTabPane
      />
    </div>
  );
};

export default AdminSetting;

const StyledTab = styled(Tabs)`
  .ant-tabs-nav-list {
    margin-left: 4px;
  }
`;

const StyledTitleRow = styled(Row)`
  margin-bottom: 24px;
  padding-bottom: 4px;
  align-items: center;
  height: 40px;
`;

const StyledBillingDelayMessage = styled.div`
  height: 0;
  background: ${COLORS.warningMessageColor};
  transition: 0.5s ease-in-out;
  visibility: hidden;
  padding: 0;
  padding: 0 34px;
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
  margin: -28px -36px 24px -36px;

  &.visible {
    transition: height 0.5s ease-in-out;
    height: 46px;
    visibility: unset;
  }
`;
