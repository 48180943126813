export default () => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6826 0.269215C12.0862 0.646207 12.1078 1.279 11.7308 1.68261L4.52271 9.39951L0.331552 5.63264C-0.0792105 5.26346 -0.112921 4.6312 0.256258 4.22044C0.625437 3.80967 1.2577 3.77596 1.66847 4.14514L4.40039 6.6005L10.2692 0.317399C10.6462 -0.0862039 11.279 -0.107776 11.6826 0.269215Z"
      fill="#7B899C"
    />
  </svg>
);
