import { Row, Typography } from "antd";
import Form, { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { LinkButton, SuccessButton } from "components/Buttons";
import { InputPassword, InputText } from "components/Inputs";
import ALERTS from "config/alerts";
import COLORS from "constants/colors";
import { useLogin } from "hooks";
import { MutationLoginArgs } from "model/User";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const LoginForm = () => {
  const [form] = useForm();
  const [isValueChanged, setIsValueChanged] = useState<boolean>(false);
  const [login, { loading, error }] = useLogin();

  const onFinish = ({ email, password }: MutationLoginArgs) => {
    login({ variables: { email, password } });
  };

  return (
    <Form
      labelCol={{ span: 24 }}
      layout="vertical"
      form={form}
      initialValues={{}}
      onValuesChange={() => setIsValueChanged(true)}
      onFinish={(value: MutationLoginArgs) => {
        onFinish(value);
        setIsValueChanged(false);
      }}
      autoComplete="off"
    >
      <Row justify="center" className="pb-4">
        <Typography.Title level={2}>Log in</Typography.Title>
      </Row>
      {error && !isValueChanged && (
        <Row className="mb-4">
          <StyledErrorMessage level={4}>{error.message}</StyledErrorMessage>
        </Row>
      )}
      <FormItem rules={[ALERTS.required, ALERTS.email]} label="Email" name="email">
        <InputText data-testid="email" />
      </FormItem>
      <FormItem rules={[ALERTS.required]} label="Password" name="password">
        <InputPassword data-testid="password" />
      </FormItem>
      {/* <FormItem name="rememberMe" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </FormItem> */}
      <SuccessButton className="w-100" loading={loading} htmlType="submit">
        Log in
      </SuccessButton>
      <Row justify="center" className="mt-4">
        <Link to="/forgot_password" tabIndex={-1}>
          <LinkButton>Forgot your password?</LinkButton>
        </Link>
      </Row>
    </Form>
  );
};

export default LoginForm;

const StyledErrorMessage = styled(Typography.Title)`
  &.ant-typography {
    background-color: ${COLORS.lightErrorColor};
    margin-bottom: 0px;
    color: ${COLORS.errorColor};
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${COLORS.errorColor};
  }
`;
