export default () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#a)">
      <rect x="11" y="6" width="34" height="34" rx="6.115" fill="#fff" />
      <rect x="10.873" y="5.873" width="34.255" height="34.255" rx="6.243" stroke="#D6DCE5" strokeWidth=".255" />
      <path
        d="m27.874 18.86-1.738 8.8h-2.1l1.737-8.8zm8.839 5.683 1.106-3.305.636 3.305zm2.344 3.117H41l-1.696-8.8h-1.793c-.403 0-.744.255-.895.646l-3.152 8.154h2.206l.438-1.314h2.696zm-5.483-2.873c.009-2.322-2.964-2.45-2.944-3.488.006-.316.284-.651.891-.737.302-.043 1.13-.075 2.072.394l.368-1.866a5.3 5.3 0 0 0-1.965-.39c-2.076 0-3.538 1.196-3.55 2.909-.013 1.266 1.043 1.973 1.84 2.394.817.432 1.093.708 1.089 1.094-.005.59-.653.85-1.257.86-1.056.019-1.67-.308-2.158-.555l-.38 1.928c.49.245 1.396.457 2.336.468 2.207 0 3.65-1.182 3.658-3.01m-8.702-5.926-3.404 8.8h-2.221l-1.675-7.023c-.102-.433-.19-.591-.5-.774-.504-.296-1.338-.575-2.072-.748l.05-.255h3.575c.455 0 .865.328.969.897l.884 5.092 2.186-5.99z"
        fill="#1434CB"
      />
    </g>
    <defs>
      <filter
        id="a"
        x=".553"
        y=".649"
        width="54.894"
        height="54.894"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy=".51" />
        <feGaussianBlur stdDeviation="2.548" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_302_2805" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="5.096" />
        <feGaussianBlur stdDeviation="5.096" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="effect1_dropShadow_302_2805" result="effect2_dropShadow_302_2805" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_302_2805" result="shape" />
      </filter>
    </defs>
  </svg>
);
