import { useQuery } from "@apollo/client";
import { useSafeContext } from "hooks";
import { MeResponse, User } from "model/User";
import * as React from "react";
import { ReactNode, createContext, useState } from "react";
import { ME_DOCUMENT } from "services/graphql/user";
import storage from "utils/storage";
import { apolloClient } from "./ApolloProvider";
import { rollbarConfigAfterLogin } from "./RollbarProvider";

interface AuthenticationState {
  user?: User;
  token?: string;
}
const initialState: AuthenticationState = {};

export interface ContextValue {
  user?: User;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (token: string, user?: User) => void;
  logout: () => void;
  refetch: () => void;
}

export const AuthContext = createContext<ContextValue | null>(null);
AuthContext.displayName = "AuthContext";

export const useAuthContext = () => useSafeContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authentication, setAuthentication] = useState<AuthenticationState>(initialState);

  const logout = React.useCallback(async () => {
    storage.clearAuthTokens();
    setAuthentication(initialState);
    await apolloClient().clearStore();
  }, []);

  const { loading: isLoading, refetch } = useQuery<MeResponse>(ME_DOCUMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      const token = storage.getAuthToken();
      const needUpdateProfile = [
        ...Object.values(response.me.personalDetail || {}),
        ...Object.values(response.me.emergencyContact || {}),
      ].map((item) => !item);

      if (token) {
        rollbarConfigAfterLogin({
          id: response.me.id,
          name: response.me.personalDetail.name,
          roles: response.me.roles.map((item) => item.name),
        });
        setAuthentication((prev) => ({
          ...prev,
          token,
          user: {
            id: response.me.id,
            name: response.me.personalDetail.name,
            profileImageUrl: response.me.personalDetail.profileImageUrl,
            email: response.me.jobDetail?.email,
            startedAt: response.me.jobDetail?.startedAt,
            roles: response.me.roles,
            needUpdateProfile: needUpdateProfile?.includes(true),
            permissions: response.me.permissionsList,
            workingCondition: response.me.jobDetail?.workingCondition,
            departments: response.me.jobDetail?.departments,
            jobTitle: response.me.jobDetail?.jobTitle,
            location: response.me.jobDetail?.location,
            lineManagers: response.me.jobDetail?.lineManagers,
            employeeNumber: response.me.personalDetail?.employeeNumber,
            phone: response.me.jobDetail?.phone,
            subscriptionActive: response.me?.subscriptionActive,
            managedUserIds: response.me.managedUserIds,
            paymentVat: response.me.paymentVat,
          },
        }));
      }
    },
    onError: logout,
    skip: !storage.getAuthToken(),
  });

  const login = React.useCallback(
    (token: string, user?: User) => {
      setAuthentication((prev) => ({ ...prev, token, ...(user ? { user: { id: user.id, name: user.name } } : {}) }));
      storage.saveAuthToken(token);
      refetch();
    },
    [refetch],
  );

  const isAuthenticated = !isLoading && !!authentication.token && !!authentication.user;

  return (
    <AuthContext.Provider value={{ user: authentication.user, isAuthenticated, isLoading, login, logout, refetch }}>
      {children}
    </AuthContext.Provider>
  );
};
