import { Modal, ModalProps, Typography } from "antd";
import { DefaultButton, SuccessButton } from "components/Buttons";
import { MouseEventHandler } from "react";

interface Props extends ModalProps {
  onCloseModal?: () => void;
}

export function ConfirmModal({ onOk, onCancel, title, cancelText, okText, onCloseModal, ...props }: Props) {
  return (
    <Modal {...props} closable={false} footer={null} destroyOnClose centered zIndex={1001} onCancel={onCloseModal}>
      <div className="px-2">
        <Typography.Title level={2} className="mb-3">
          {title}
        </Typography.Title>
        <div className="d-flex justify-content-start align-items-center gap-2">
          <SuccessButton onClick={onOk as MouseEventHandler}>{okText}</SuccessButton>
          <DefaultButton onClick={onCancel as MouseEventHandler}>{cancelText}</DefaultButton>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmModal;
