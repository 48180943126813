import { ItemType } from "components/layout/Navbar/NavBar";

export const NAV_ITEMS: ItemType[] = [{ name: "Calendar", path: "/calendar", icon: "calendar" }];

export const ADMIN_MANAGER_NAV_ITEMS: ItemType[] = [{ name: "Employees", path: "/employees", icon: "user" }];

export const ADMIN_NAV_ITEMS: ItemType[] = [{ name: "Admin settings", path: "/admin/setting", icon: "settings" }];

export const ADMIN_MANAGER_DOCUMENT_NAV_ITEM: ItemType[] = [
  { name: "Documents", path: "/document", icon: "documentPage" },
];

export const COMMON_ITEM: ItemType[] = [{ name: "Home", path: "/home", icon: "home" }];

export default { NAV_ITEMS, ADMIN_NAV_ITEMS, COMMON_ITEM, ADMIN_MANAGER_DOCUMENT_NAV_ITEM };
