const itemNotFoundMessage = "Item couldn't be found";

export const OPERATION_ERRORS = {
  stop: {
    fetchEmployeeFullDetail: [
      { errorMessage: itemNotFoundMessage, path: "fetchEmergencyContact" },
      { errorMessage: itemNotFoundMessage, path: "fetchSalary" },
    ],
  },
  authenticationErrorMessage: "You need to authenticate to perform this action.",
};

export default OPERATION_ERRORS;
  