export function includesInArray<T>(arr: T[] | undefined, value: T | T[]): boolean {
  if (arr && !Array.isArray(value)) {
    return arr.includes(value);
  }
  if (arr && Array.isArray(value)) {
    return value.every((value: T) => arr.includes(value));
  }
  return false;
}
export function checkPermission(permissionsList: string[] | undefined, permissions: (string | string[])[] | string) {
  if (Array.isArray(permissions)) {
    const resultArray = permissions.map((element) => includesInArray(permissionsList, element));
    return includesInArray(resultArray, true);
  }
  return includesInArray(permissionsList, permissions);
}

export function checkValidFileExtension(filename: string, extension: string | string[]) {
  if (Array.isArray(extension)) {
    // eslint-disable-next-line no-bitwise
    return extension.includes(filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2).toLocaleLowerCase());
  }
  // eslint-disable-next-line no-bitwise
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2).toLocaleLowerCase() === extension;
}
export default { includesInArray, checkPermission, checkValidFileExtension };
