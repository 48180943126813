import DATE_FORMAT from "constants/dateFormat";
import dayjs from "dayjs";
import { CalendarLeaveData, GenerateClassNameArg, PublicHolidaysType, UserLeaveRequest } from "model/Calendar";
import styled from "styled-components";
import { LeaveRequestsDayCompleteness, LeaveRequestsReason, LeaveRequestsStatus } from "model/Leave";
import EMPLOYEE from "constants/employee";
import { WorkingCondition } from "model/Common";
import { expectNumber, includesInArray, isToday } from "utils";
import { Tooltip } from "antd";
import CalendarData from "./CalendarData";
import LeaveDetail from "./LeaveDetail";

type Props = {
  leave: UserLeaveRequest[];
  dateNo: string[];
  currentDate: dayjs.Dayjs;
  currentWorkingCondition: WorkingCondition;
  userId: string;
  publicHolidays: PublicHolidaysType[];
};

const CalendarCell = ({ leave, dateNo, currentDate, currentWorkingCondition, publicHolidays, userId }: Props) => {
  const isWeekendDay = (date: string, workingCondition: WorkingCondition) => {
    const getWeekendDayId = EMPLOYEE.days
      .filter((day) => !workingCondition?.[day.value as keyof WorkingCondition])
      .map((item) => item.id);
    let dayOfWeekNumber = currentDate.set("date", expectNumber(date)).day() + 1;
    if (dayOfWeekNumber === 1) {
      dayOfWeekNumber = 7;
    } else {
      dayOfWeekNumber -= 1;
    }
    return getWeekendDayId.includes(dayOfWeekNumber);
  };

  const getDatesBetweenTwoDate = (range: UserLeaveRequest[]) => {
    const calendarLeaveData: CalendarLeaveData[] = [];
    range.forEach((item) => {
      let startDate = dayjs(item.fromDate);
      const stopDate = dayjs(item.toDate);
      const leaveDates = [];

      while (startDate <= stopDate) {
        if (currentDate && startDate.month() === currentDate.month()) {
          leaveDates.push(dayjs(startDate).format(DATE_FORMAT.dayOfMonth));
        }
        startDate = dayjs(startDate).add(1, "day");
      }

      const fromDateNo = dayjs(item.fromDate).date().toString() === leaveDates[0] ? leaveDates[0] : undefined;
      const toDateNo =
        dayjs(item.toDate).date().toString() === leaveDates[leaveDates.length - 1]
          ? leaveDates[leaveDates.length - 1]
          : undefined;
      calendarLeaveData.push({
        dates: leaveDates,
        reason: item.reason,
        fromDateNo,
        toDateNo,
        fromDate: item.fromDate,
        fromTime: item.dayCompleteness === LeaveRequestsDayCompleteness.Custom ? item.fromTime : undefined,
        toTime: item.dayCompleteness === LeaveRequestsDayCompleteness.Custom ? item.toTime : undefined,
        toDate: item.toDate,
        dayCompleteness: item.dayCompleteness,
        employeeNote: item.employeeNote?.body,
        workingConditionDuringLeave: item.workingCondition,
        usedDays: item.dayCompleteness === LeaveRequestsDayCompleteness.Custom ? item.usedDays : undefined,
        status: item.status,
      });
    });
    return calendarLeaveData;
  };

  const modifyDateNo = () => {
    const range = getDatesBetweenTwoDate(leave);
    const replacedValue = dateNo.map((value) => {
      const matchingSubarray = range.find((subarray) => subarray.dates.includes(value));

      if (matchingSubarray) {
        return matchingSubarray;
      }
      return value;
    });
    const uniqueValue = Array.from(new Set(replacedValue));

    return uniqueValue;
  };

  const generateClassName = ({
    date,
    dayCompleteness,
    fromDateNo,
    reason,
    toDateNo,
    isPrevDateLeave = false,
    isNextDateLeave = false,
    workingCondition,
    status,
  }: GenerateClassNameArg) => {
    const className = [];
    const prevDate = (expectNumber(date) - 1).toString();
    const nextDate = (expectNumber(date) + 1).toString();
    const publicHolidaysDates = publicHolidays
      .filter((item) => dayjs(item.date).month() === currentDate.month())
      .map((item) => dayjs(item.date).date());
    if (isToday(expectNumber(date) - 1, currentDate)) {
      className.push("today");
    }
    if (isWeekendDay(date, workingCondition) || publicHolidaysDates.includes(expectNumber(date))) {
      className.push("disabled");
      if (
        (!isWeekendDay(prevDate, workingCondition) && !publicHolidaysDates.includes(expectNumber(prevDate))) ||
        !includesInArray(dateNo, prevDate)
      ) {
        className.push("start");
      }
      if (
        (!isWeekendDay(nextDate, workingCondition) && !publicHolidaysDates.includes(expectNumber(nextDate))) ||
        !includesInArray(dateNo, nextDate)
      ) {
        className.push("end");
      }
      return className.join(" ");
    }
    if (reason) {
      switch (reason) {
        case LeaveRequestsReason.Holiday:
          if (!isWeekendDay(date, workingCondition)) {
            className.push("holiday");
            if (status === LeaveRequestsStatus.Pending) {
              className.push("pending");
            }
          }
          break;
        default:
          if (!isWeekendDay(date, workingCondition)) {
            className.push("leave");
            if (status === LeaveRequestsStatus.Pending) {
              className.push("pending");
            }
          }
          break;
      }
    }
    if (
      (date === fromDateNo && !isPrevDateLeave) ||
      (!isWeekendDay(date, workingCondition) &&
        (isWeekendDay(prevDate, workingCondition) || publicHolidaysDates.includes(expectNumber(prevDate)))) ||
      !includesInArray(dateNo, prevDate)
    ) {
      className.push("start");
    }
    if (
      (date === toDateNo && !isNextDateLeave) ||
      (!isWeekendDay(date, workingCondition) &&
        (isWeekendDay(nextDate, workingCondition) || publicHolidaysDates.includes(expectNumber(nextDate)))) ||
      !includesInArray(dateNo, nextDate)
    ) {
      className.push("end");
    }
    if (dayCompleteness && status !== LeaveRequestsStatus.Pending) {
      switch (dayCompleteness) {
        case LeaveRequestsDayCompleteness.HalfInMorning:
          className.push("halfInMorning");
          break;
        case LeaveRequestsDayCompleteness.HalfInAfternoon:
          className.push("halfInAfternoon");
          break;
        case LeaveRequestsDayCompleteness.Custom:
          className.push("custom");
          break;
        default:
          className.push("full");
      }
    }

    return className.join(" ");
  };

  return (
    <StyledCalendarCell>
      {modifyDateNo().map((data, index, array) => {
        if (typeof data !== "string") {
          return (
            <Tooltip
              title={
                <LeaveDetail
                  fromDate={data.fromDate}
                  toDate={data.toDate}
                  reason={data.reason}
                  dayCompleteness={data.dayCompleteness}
                  fromTime={data.fromTime}
                  toTime={data.toTime}
                  employeeNote={data.employeeNote}
                  status={data.status}
                  userId={userId}
                />
              }
              destroyTooltipOnHide
              placement={(data as CalendarLeaveData).status === LeaveRequestsStatus.Pending ? "top" : "bottom"}
              key={`data${data.fromDateNo}${data.toDateNo}`}
            >
              {data.dates.map((item) => (
                <CalendarData
                  key={item}
                  dateNo={item}
                  className={`${generateClassName({
                    date: item,
                    reason: data.reason,
                    dayCompleteness: data.dayCompleteness,
                    fromDateNo: data.fromDateNo,
                    toDateNo: data.toDateNo,
                    isPrevDateLeave:
                      index === 0
                        ? false
                        : typeof array[index - 1] !== "string"
                        ? !!(
                            (array[index - 1] as CalendarLeaveData)?.status !== LeaveRequestsStatus.Pending &&
                            data.status !== LeaveRequestsStatus.Pending
                          )
                        : false,
                    isNextDateLeave:
                      index === array.length - 1
                        ? false
                        : typeof array[index + 1] !== "string"
                        ? !!(
                            (array[index + 1] as CalendarLeaveData)?.status !== LeaveRequestsStatus.Pending &&
                            data.status !== LeaveRequestsStatus.Pending
                          )
                        : false,
                    workingCondition: data.workingConditionDuringLeave,
                    status: data.status,
                  })}`}
                  usedDays={data.usedDays}
                />
              ))}
            </Tooltip>
          );
        }
        return (
          <CalendarData
            dateNo={data}
            className={`${generateClassName({ date: data, workingCondition: currentWorkingCondition })}`}
            key={data}
          />
        );
      })}
    </StyledCalendarCell>
  );
};

export default CalendarCell;

const StyledCalendarCell = styled.div`
  display: flex;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .ant-tooltip .ant-tooltip-inner {
    display: flex;
  }
`;
