import { Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { DefaultButton } from "components/Buttons";
import COLORS from "constants/colors";
import TABLE from "constants/table";
import { useNotificationContext } from "contexts/NotificationProvider";
import { NotificationType, notificationsFilteredData, OtherUpdateData } from "model/Notification";
import { useMemo } from "react";
import styled from "styled-components";
import storage from "utils/storage";

const OtherUpdates = () => {
  const { refresh, otherNotificationData } = useNotificationContext();

  const data = useMemo(
    () => (otherNotificationData ? notificationsFilteredData(otherNotificationData, NotificationType.Other) : []),
    [otherNotificationData],
  );

  const columns: ColumnsType<OtherUpdateData> = [
    {
      key: "id",
      dataIndex: "name",
      width: 300,
    },
    {
      dataIndex: "message",
    },
    {
      title: "",
      render: (_, r) => (
        <DefaultButton
          onClick={() => {
            storage.addReadNotification(r.id, NotificationType.Other);
            refresh();
          }}
        >
          Ok
        </DefaultButton>
      ),
      width: 65,
    },
  ];
  return (
    <StyledWrapper className="section-box-shadow">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        rowKey="id"
        title={() => (
          <div className="d-flex align-items-center">
            <Typography.Title level={1} className="m-0 p-2 table-title">
              Other updates
            </Typography.Title>
          </div>
        )}
        scroll={{
          scrollToFirstRowOnChange: false,
          y: TABLE.tableHeight,
        }}
      />
    </StyledWrapper>
  );
};

export default OtherUpdates;

const StyledWrapper = styled.div`
  .ant-table-title {
    border-bottom: 1px solid ${COLORS.borderLight};
  }
`;
