import { useMutation } from "@apollo/client";
import { useAuthContext } from "contexts";
import { DestroyProfileImageResponse, UpsertProfileImageResponse } from "model/Employee";
import { useNotify } from "services/api";
import { DESTROY_PROFILE_IMAGE, UPSERT_PROFILE_IMAGE } from "services/graphql/employee";

export const useUploadProfileImage = () => {
  const notify = useNotify();
  const { refetch } = useAuthContext();
  const [mutate, { loading: uploading }] = useMutation<UpsertProfileImageResponse>(UPSERT_PROFILE_IMAGE, {
    onCompleted: (response) => {
      const { upsertProfileImage } = response;
      if (upsertProfileImage?.errors) {
        notify.error(upsertProfileImage.errors.fullMessages);
        return;
      }

      refetch();
      notify.success({ message: "Update profile picture successfully." });
    },
  });

  const [mutateDestroyProfile, { loading: removing }] = useMutation<DestroyProfileImageResponse>(
    DESTROY_PROFILE_IMAGE,
    {
      onCompleted: (response) => {
        const { destroyProfileImage } = response;
        if (!destroyProfileImage?.success) {
          notify.error(undefined, "Remove profile picture failed.");
          return;
        }

        refetch();
        notify.success({ message: "Removed profile picture successfully." });
      },
    },
  );

  return {
    mutateDestroyProfile,
    mutate,
    loading: uploading || removing,
  };
};

export default useUploadProfileImage;
