import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { InputProps } from "antd";
import { ReactElement } from "react";
import styled from "styled-components";
import InputText from "../InputText/InputText";

export const StyledInput = styled(InputText.Password)`
  height: 36px;
  font-weight: 500;
`;

export const InputPassword = (props: InputProps): ReactElement => (
  <StyledInput
    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
    autoComplete="new-password"
    {...props}
  />
);

export default InputPassword;
