import { NotificationStorage, NotificationType } from "model/Notification";

export const AUTH_TOKEN = "auth_token";
export const NOTIFICATIONS = "notifications";
/* Let's save our user tokens so when the app resets we can try and get them later */

const saveAuthToken = (accessToken: string) => localStorage.setItem(AUTH_TOKEN, accessToken);

const getAuthToken = () => localStorage.getItem(AUTH_TOKEN);

const clearAuthTokens = () => localStorage.removeItem(AUTH_TOKEN);

const addReadNotification = (id: number, type: NotificationType) => {
  const notification = { id, type };
  localStorage.setItem(
    NOTIFICATIONS,
    JSON.stringify([
      ...JSON.parse(localStorage.getItem(NOTIFICATIONS) || "[]").filter((item: number) => item !== id),
      notification,
    ]),
  );
};

const getReadNotification = () =>
  JSON.parse(localStorage.getItem(NOTIFICATIONS) || "[]") as Omit<NotificationStorage, "message">[];

const removeReadNotification = (id: number) => {
  localStorage.setItem(
    NOTIFICATIONS,
    JSON.stringify(
      JSON.parse(localStorage.getItem(NOTIFICATIONS) || "[]").filter((n: NotificationStorage) => n.id !== id),
    ),
  );
};

const clearNotifications = () => {
  localStorage.removeItem(NOTIFICATIONS);
};

const storage = {
  saveAuthToken,
  getAuthToken,
  clearAuthTokens,
  clearNotifications,
  addReadNotification,
  getReadNotification,
  removeReadNotification,
};

export default storage;
