import { Row } from "antd";
import Form, { useForm } from "antd/lib/form/Form";
import { DefaultButton, PrimaryButton } from "components/Buttons";
import { Container } from "components/core";
import { useResponsive } from "hooks";
import { FC } from "react";
import { Link } from "react-router-dom";
import UserForm from "./UserForm";

export const AddUser: FC = () => {
  const [form] = useForm();
  const isLoading = false;
  const { isSmall } = useResponsive();
  const onFinish = () => undefined;
  return (
    <Container isSmall={isSmall}>
      <Form role="form" form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
        <UserForm />
        <Row className="gap-3 mt-4">
          <Link to="/manage/users">
            <DefaultButton>Cancel</DefaultButton>
          </Link>
          <PrimaryButton htmlType="submit" loading={isLoading}>
            Save
          </PrimaryButton>
        </Row>
      </Form>
    </Container>
  );
};

export default AddUser;
