import LEAVE from "constants/leave";
import { LeaveRequestsReason, LeaveRequestsStatus, fetchHolidayBalance, leaveDataResponse } from "model/Leave";

export const getLeaveData = (response: leaveDataResponse[]) => {
  const modifiedLeaveData = {} as Record<LeaveRequestsReason, number>;
  response.forEach((item) => {
    const { amount, reason } = item;
    modifiedLeaveData[reason as LeaveRequestsReason] = amount;
  });
  return modifiedLeaveData;
};

export const getChartData = (response: fetchHolidayBalance) => {
  const { chartData } = LEAVE;
  if (response) {
    const convertedData = chartData.map((chartItem) => {
      const { name } = chartItem;
      const taken = response?.takenDays ?? 0;
      const booked = response?.bookedDays ?? 0;
      const requested =
        Number(
          (response.usedDaysStats.find((item) => item.status === LeaveRequestsStatus.Pending)?.amount ?? 0).toFixed(2),
        ) || 0;
      const total =
        name === "accrued"
          ? Number((response?.accruedBalance || 0).toFixed(2))
          : Number((response?.accumulativeBalance || 0).toFixed(2));
      const remaining = Number((total - taken).toFixed(2));
      const available = Number((total - taken - booked).toFixed(2));

      return { name, taken, booked, requested, total, remaining, available };
    });
    return convertedData;
  }
  return chartData;
};

export default { getLeaveData, getChartData };
