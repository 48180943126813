import { Form, FormInstance, Modal, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import DefaultButton from "components/Buttons/DefaultButton";
import SuccessButton from "components/Buttons/SuccessButton";
import { InputFile } from "components/Inputs";
import ALERTS, { fileSize } from "config/alerts";
import { forwardRef, useState } from "react";
import useFormModal from "hooks/useFormModal";
import Icon from "components/Icon";
import styled from "styled-components";
import { downloadLink } from "utils";
import { useMutation } from "@apollo/client";
import { IMPORT_EMPLOYEE } from "services/graphql/employee";
import { ImportEmployeesResponse } from "model/Employee";
import EMPLOYEE from "constants/employee";
import { assertError } from "utils/misc";
import { FeedBackModal } from "components/modal";

type Props = {
  onCancel: () => void;
  onFieldsChange: () => void;
  refetchEmployeeList: () => void;
};

type NotifyPointsProps = {
  allow: boolean;
  point: string;
};

const NotifyPoints = ({ allow, point }: NotifyPointsProps) => (
  <StyledNotifyPoints>
    <Icon name={allow ? "successCheck" : "danger"} />
    <Typography.Text>{point}</Typography.Text>
  </StyledNotifyPoints>
);

const shortVersionTemplate =
  process.env.REACT_APP_IMPORT_EMPLOYEES_SHORT_TEMPLATE_URL ??
  assertError("REACT_APP_IMPORT_EMPLOYEES_SHORT_TEMPLATE_URL is not defined");

const advancedVersionTemplate =
  process.env.REACT_APP_IMPORT_EMPLOYEES_ADVANCED_TEMPLATE_URL ??
  assertError("REACT_APP_IMPORT_EMPLOYEES_ADVANCED_TEMPLATE_URL is not defined");

const ImportEmployeeForm = forwardRef<FormInstance, Props>(({ onCancel, onFieldsChange, refetchEmployeeList }, ref) => {
  const [form] = useForm();

  const { isModalVisible, showModal, hideModal } = useFormModal();
  const { isModalVisible: isErrorModalVisible, showModal: showErrorModal, hideModal: hideErrorModal } = useFormModal();
  const [importFile, setImportFile] = useState<File | null>(null);

  const [mutate, { loading }] = useMutation<ImportEmployeesResponse>(IMPORT_EMPLOYEE, {
    onCompleted: (response) => {
      if (response?.bulkImport) {
        if (response?.bulkImport?.importedRecords) {
          onCancel();
          form.resetFields();
          showModal();
          if (response.bulkImport.importedRecords) {
            refetchEmployeeList();
          }
        } else {
          onCancel();
          form.resetFields();
          showErrorModal();
        }
      }
    },
  });

  return (
    <div>
      {EMPLOYEE.importEmployeeNotifyPoints.map(({ allow, point }) => (
        <NotifyPoints allow={allow} point={point} key={point} />
      ))}
      <DefaultButton block className="mt-3" onClick={() => downloadLink(shortVersionTemplate, "", false)}>
        Download basic template
      </DefaultButton>
      <DefaultButton block className="mt-2" onClick={() => downloadLink(advancedVersionTemplate, "", false)}>
        Download advanced template
      </DefaultButton>
      <Form
        form={form}
        layout="vertical"
        className="mt-4"
        onFieldsChange={onFieldsChange}
        onFinish={() => mutate({ variables: { uploadAttachment: importFile } })}
        ref={ref}
      >
        <Form.Item
          className="mb-3"
          label="Upload Excel"
          name="uploadExcel"
          rules={[ALERTS.required, ALERTS.xlsxFile, fileSize(importFile)]}
        >
          <InputFile
            onChange={(e) => {
              if (e.target.files) {
                setImportFile(e.target.files[0]);
              }
            }}
          />
        </Form.Item>
        <SuccessButton className="mt-4" block htmlType="submit" loading={loading}>
          Import
        </SuccessButton>
      </Form>

      <Modal open={isModalVisible} closable={false} footer={null} width={389} onCancel={hideModal} centered>
        <div className="text-center">
          <Icon name="uploadComplete" />
          <StyledConfirmTitle className="title">Upload Complete</StyledConfirmTitle>
          <SuccessButton onClick={hideModal} block>
            ok
          </SuccessButton>
        </div>
      </Modal>

      <FeedBackModal
        open={isErrorModalVisible}
        type="error"
        onCancel={() => {
          hideErrorModal();
        }}
        onOk={() => {
          hideErrorModal();
        }}
        title="Please note"
        description="No employees were imported, please review your Excel file"
      />
    </div>
  );
});

export default ImportEmployeeForm;

const StyledConfirmTitle = styled(Typography.Title)`
  &.title {
    margin-top: 24px;
    margin-bottom: 56px;
    font-size: 18px;
  }
`;

const StyledNotifyPoints = styled.div`
  display: flex;
  align-items: start;
  gap: 13px;
  margin-bottom: 4px;
  & svg {
    width: 17px;
    height: 17px;
    margin-top: 3px;
  }
`;
