import { TabsProps } from "antd";
import BadgeCount from "components/BadgeCount/BadgeCount";
import { Tabs } from "components/Tabs";
import COLORS from "constants/colors";
import { useAuthContext } from "contexts";
import { useFetchRequestList } from "contexts/LeaveRequestListProvider";
import { isAdmin } from "model/User";
import styled from "styled-components";
import { useNotificationContext } from "contexts/NotificationProvider";
import EmployeeNotifications from "./notificationTables/employeeNotifications/EmployeeNotifications";
import MyNotifications from "./notificationTables/myNotifications/MyNotifications";

const NotificationTabs = () => {
  const { setData } = useFetchRequestList();
  const { employeeNotificationCount, myNotificationCount } = useNotificationContext();
  const { user } = useAuthContext();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div className="d-flex justify-content-between align-items-center">
          <span>Employee notifications</span>
          <BadgeCount>{employeeNotificationCount ?? 0}</BadgeCount>
        </div>
      ),
      show: isAdmin(user),
      children: <EmployeeNotifications />,
    },

    {
      key: "2",
      label: (
        <div className="d-flex justify-content-between align-items-center">
          <span>My notifications</span>
          <BadgeCount>{myNotificationCount ?? 0}</BadgeCount>
        </div>
      ),
      children: <MyNotifications />,
      show: true,
    },
  ].filter((item) => item.show);

  return (
    <StyledTab
      defaultActiveKey="1"
      items={items}
      className="notifications"
      onTabClick={(key) => {
        if (key === "2") {
          setData([]);
        }
      }}
      destroyInactiveTabPane
    />
  );
};

export default NotificationTabs;

const StyledTab = styled(Tabs)`
  margin-top: 28px;
  .ant-tabs-tab-btn:hover span {
    color: ${COLORS.primaryColor};
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn span {
    color: unset;
  }
  &.notifications .ant-tabs-nav::before {
    border-bottom: unset;
  }
  .ant-tabs-nav-list {
    margin-left: 4px;
  }
`;
