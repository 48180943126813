import { Input, InputProps, InputRef } from "antd";
import { DefaultButton } from "components/Buttons";
import COLORS from "constants/colors";
import { ReactElement, useRef } from "react";
import styled from "styled-components";

export const InputFile = (props: Omit<InputProps, "type">): ReactElement => {
  const ref = useRef<InputRef>(null);

  return (
    <StyledWrapper className="position-relative">
      <StyledDefaultButton className="position-absolute btn" onClick={() => ref.current?.input?.click()} tabIndex={-1}>
        Browse
      </StyledDefaultButton>
      <StyledInput type="file" {...props} ref={ref} />
    </StyledWrapper>
  );
};

export default InputFile;

const StyledWrapper = styled.div`
  input[type="file"]::file-selector-button {
    display: none;
  }

  .btn:hover + input {
    border-color: ${COLORS.inputBorderHover};
  }
`;
const StyledDefaultButton = styled(DefaultButton)`
  top: 1px;
  right: 1px;
  height: 34px;
  z-index: 1;
}
`;

const StyledInput = styled(Input)`
  padding: 6px 11px;
  height: 36px;
  font-weight: 500;
`;
