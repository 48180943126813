import { ThemeConfig } from "antd";
import COLORS from "../constants/colors";

export const theme: ThemeConfig = {
  token: {
    // height rules
    controlHeight: 36,
    controlHeightLG: 48,
    controlHeightSM: 32,

    // -------- Colors -----------
    colorPrimary: COLORS.primaryColor,
    colorInfo: COLORS.primaryColor,
    colorSuccess: COLORS.successColor,
    colorError: COLORS.errorColor,
    colorText: COLORS.colorText,
    colorTextHeading: COLORS.primaryColor, // heading text color
    colorTextSecondary: COLORS.colorTextSecondary,
    colorTextDisabled: COLORS.colorTextDisabled, // disable state color
    colorBorder: COLORS.lightBorder, // major border color

    // Border
    lineWidth: 0.6, // width of the border for a component
    lineType: "solid", // style of a components border
    borderRadius: 4, // major border radius
    boxShadow:
      "0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05)", // major shadow for layers

    // TypoGraphy

    fontFamily: "Inter",
    fontSize: 14, // major text font size
    fontSizeHeading1: 22,
    fontSizeHeading2: 18,
    fontSizeHeading3: 15,
    fontSizeHeading4: 14,
  },

  components: {
    Layout: {
      bodyBg: COLORS.layoutBgColor,
    },
    Button: {
      fontWeightStrong: 700,
      colorText: COLORS.primaryColor,
      colorBorder: COLORS.buttonBorderColor,
      colorTextDisabled: COLORS.disabledColor,
      colorLink: COLORS.tertiary,
      colorLinkHover: COLORS.tertiary,
      colorLinkActive: COLORS.tertiary,
      colorError: COLORS.dangerColor,
    },
    Input: {
      colorTextLabel: COLORS.labelColor,
      colorText: COLORS.inputFieldTextColor,
      colorBorder: COLORS.inputBorderColor,
      colorTextDisabled: COLORS.disabledColor,
      colorTextPlaceholder: COLORS.inputPlaceHolderTextColor,
      paddingBlock: 9,
      paddingInline: 11,
    },
    InputNumber: {
      colorBorder: COLORS.inputBorderColor,
      colorTextDisabled: COLORS.disabledColor,
      colorTextPlaceholder: COLORS.inputPlaceHolderTextColor,
      paddingInline: 11,
    },
    Select: {
      colorTextPlaceholder: COLORS.inputPlaceHolderTextColor,
      colorText: COLORS.inputFieldTextColor,
      colorBorder: COLORS.inputBorderColor,
    },
    DatePicker: {
      colorTextPlaceholder: COLORS.inputPlaceHolderTextColor,
      colorBorder: COLORS.inputBorderColor,
      colorText: COLORS.inputFieldTextColor,
      paddingInline: 11,
    },
    Radio: {
      colorText: COLORS.inputFieldTextColor,
    },
    Checkbox: {
      colorText: COLORS.inputFieldTextColor,
    },
    Tabs: {
      margin: 28,
      horizontalItemPadding: "0px 0px 12px 0px",
    },
    Popover: {
      colorBgElevated: COLORS.primaryColor,
      colorText: COLORS.white,
      colorTextHeading: COLORS.white,
    },
    Tooltip: {
      colorBgSpotlight: COLORS.primaryColor,
      colorText: COLORS.white,
      colorTextLightSolid: COLORS.white,
    },
    Table: {
      headerSortHoverBg: "transparent",
      rowHoverBg: COLORS.rowBgHover,
      bodySortBg: "transparent",
      headerSortActiveBg: "transparent",
    },
  },
};

export default theme;
