import { gql } from "@apollo/client";
import { ERROR_FRAGMENT, PAGE_INFO_FRAGMENT } from "./common";

export const COMPANY_RESULT_FRAGMENT = gql`
  fragment CompanyResultFragment on Company {
    id
    companyId
    companyName
    superuserEmail
    superuserName
    superuser {
      id
      name
      loggedIn
    }
    superuserPhone
    paymentType
    signedAt
  }
`;

export const COMPANIES = gql`
  query companies($first: Int!, $after: String, $searchValue: String) {
    companies(first: $first, after: $after, searchValue: $searchValue) {
      nodes {
        ...CompanyResultFragment
      }

      edges {
        node {
          id
          companyId
          companyName
        }
        cursor
      }

      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${COMPANY_RESULT_FRAGMENT}
`;

export const FETCH_COMPANY = gql`
  query fetchCompany($id: ID!) {
    fetchCompany(id: $id) {
      ...CompanyResultFragment
    }
  }
  ${COMPANY_RESULT_FRAGMENT}
`;

export const CREATE_COMPANY = gql`
  mutation createCompany(
    $companyId: String!
    $companyName: String!
    $paymentType: CompaniesPaymentTypeEnum!
    $signedAt: ISO8601Date!
    $superuserEmail: String!
    $superuserName: String!
    $superuserPhone: String!
    $notifySuperuser: Boolean
  ) {
    createCompany(
      companyId: $companyId
      companyName: $companyName
      paymentType: $paymentType
      signedAt: $signedAt
      superuserEmail: $superuserEmail
      superuserName: $superuserName
      superuserPhone: $superuserPhone
      notifySuperuser: $notifySuperuser
    ) {
      company {
        ...CompanyResultFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${COMPANY_RESULT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $id: ID!
    $companyId: String!
    $companyName: String!
    $paymentType: CompaniesPaymentTypeEnum!
    $signedAt: ISO8601Date!
    $superuserEmail: String!
    $superuserName: String!
    $superuserPhone: String!
  ) {
    updateCompany(
      id: $id
      companyId: $companyId
      companyName: $companyName
      paymentType: $paymentType
      signedAt: $signedAt
      superuserEmail: $superuserEmail
      superuserName: $superuserName
      superuserPhone: $superuserPhone
    ) {
      company {
        ...CompanyResultFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${COMPANY_RESULT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

export const DESTROY_COMPANY = gql`
  mutation destroyCompany($id: ID!) {
    destroyCompany(id: $id) {
      success
    }
  }
`;

export default { COMPANIES, FETCH_COMPANY, CREATE_COMPANY, UPDATE_COMPANY, DESTROY_COMPANY };
