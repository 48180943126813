export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3915_12355)">
      <path
        d="M11.9999 2.66666H3.99992C2.52716 2.66666 1.33325 3.86056 1.33325 5.33332V12C1.33325 13.4728 2.52716 14.6667 3.99992 14.6667H11.9999C13.4727 14.6667 14.6666 13.4728 14.6666 12V5.33332C14.6666 3.86056 13.4727 2.66666 11.9999 2.66666Z"
        stroke="#778496"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33325 6.66666H14.6666M5.33325 1.33333V3.99999V1.33333ZM10.6666 1.33333V3.99999V1.33333Z"
        stroke="#778496"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3915_12355">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
