function enumKeys<E>(enumType: E): (keyof E)[] {
  return Object.keys(enumType as keyof E) as (keyof E)[];
}

export function enumValues<E>(enumType: E): keyof E extends never[] ? never[] : E[keyof E][] {
  return enumKeys(enumType).map((key) => enumType[key]) as any;
}

export function parseEnum<E>(enumType: E): (s: string) => E[keyof E] | undefined {
  const values = enumValues(enumType) as any[];

  return (s: any) => (values.includes(s) ? s : undefined);
}

export function assertError(text: string): never {
  throw new Error(text);
}

export function assertWarning(text: string): string {
  // eslint-disable-next-line no-console
  console.warn(text);
  return "";
}

export function isPromise(fn: any): fn is Promise<any> {
  return typeof fn === "function" && typeof fn.then === "function";
}

export function notUndefined<T>(value: T | undefined, msg?: string): T {
  if (value === undefined) {
    throw new Error(`Unexpected undefined${msg ? `: ${msg}` : ""}`);
  } else {
    return value;
  }
}
