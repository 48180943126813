import { LoadingPage } from "components/core";
import MainLayout from "components/layout/MainLayout";
import { ForgotForm, LoginForm, ResetForm, AcceptInviteForm } from "features/users";
import { useAuthContext } from "contexts";
import useCheckPermission from "hooks/useCheckPermission";
import AdminSettingPage from "pages/Admin/AdminSettingPage";
import CalendarPage from "pages/Calendar/CalendarPage";
import CompanyDetailPage from "pages/Company/CompanyDetailPage";
import EmployeeDetailPage from "pages/Employee/EmployeeDetailPage";
import EmployeeEditPage from "pages/Employee/EmployeeEditPage";
import EmployeesPage from "pages/Employee/EmployeesPage";
import HomePage from "pages/Home/HomePage";
import LoginLayout from "pages/LoginPages/LoginLayout";
import PreferencesPage from "pages/Preferences/PreferencesPage";
import React, { Suspense } from "react";
import { Navigate, Outlet, Routes as ReactRoutes, Route } from "react-router-dom";
import PERMISSION from "config/permission";
import { RollbarContext } from "@rollbar/react";
import { isAdmin, isManager } from "model/User";
import { rollbarAccessToken, rollbarEnvironment } from "contexts/RollbarProvider";
import DocumentsPage from "./Documents/DocumentsPage";
import DocumentSharedDetailPage from "./Documents/DocumentSharedDetailPage";
import NoSubscriptionPage from "./NoSubscription/NoSubscriptionPage";
import TermsOfServicePage from "./TermsOfService/TermsOfServicePage";

type ProtectedRouteProps = {
  userPermission: (string | string[])[];
};

const ProtectedRoute = ({ userPermission }: ProtectedRouteProps) => {
  const { hasUserPermission } = useCheckPermission({ userPermission });

  if (!hasUserPermission) {
    return <Navigate to="/home" />;
  }

  return <Outlet />;
};

const WrapWithRollbarContext = (context: string, page: React.ReactNode) =>
  rollbarAccessToken && rollbarEnvironment ? <RollbarContext context={context}>{page}</RollbarContext> : page;

const AuthRoutes: React.FC = () => {
  const {
    companies,
    showEmployeeEditPage,
    showAdminSettingPage,
    showEmployeeList,
    showDocumentsPage,
    showEmployeeDetailPage,
  } = PERMISSION;
  const { hasCompanies } = useCheckPermission({ companies });
  const { user } = useAuthContext();

  return (
    <Suspense fallback={<LoadingPage />}>
      <ReactRoutes>
        <Route path="/" element={WrapWithRollbarContext("/", <MainLayout />)}>
          {(hasCompanies || user?.subscriptionActive) && (
            <Route path="home" element={WrapWithRollbarContext("home", <HomePage />)} />
          )}
          <Route element={<ProtectedRoute userPermission={[companies]} />}>
            <Route
              path="company/:id/view"
              element={WrapWithRollbarContext("company/:id/view", <CompanyDetailPage />)}
            />
          </Route>
          <Route path="preferences" element={WrapWithRollbarContext("preferences", <PreferencesPage />)} />
          {!hasCompanies && (
            <>
              {user?.subscriptionActive && (
                <>
                  <Route
                    path="my_profile/view"
                    element={WrapWithRollbarContext("my_profile/view", <EmployeeDetailPage />)}
                  />
                  <Route
                    path="my_profile/edit"
                    element={WrapWithRollbarContext("my_profile/edit", <EmployeeEditPage />)}
                  />
                </>
              )}
              <Route element={<ProtectedRoute userPermission={showEmployeeList} />}>
                <Route path="employees" element={WrapWithRollbarContext("employees", <EmployeesPage />)} />
              </Route>
              <Route element={<ProtectedRoute userPermission={showEmployeeDetailPage} />}>
                <Route
                  path="employee/:id/view"
                  element={WrapWithRollbarContext("employee/:id/view", <EmployeeDetailPage />)}
                />
              </Route>
              {user?.subscriptionActive && (
                <Route path="calendar" element={WrapWithRollbarContext("calendar", <CalendarPage />)} />
              )}
              <Route element={<ProtectedRoute userPermission={[showDocumentsPage]} />}>
                <Route path="document" element={WrapWithRollbarContext("document", <DocumentsPage />)} />
                <Route
                  path="document/:id/view"
                  element={WrapWithRollbarContext("document/:id/view", <DocumentSharedDetailPage />)}
                />
              </Route>
            </>
          )}
          <Route element={<ProtectedRoute userPermission={showEmployeeEditPage} />}>
            <Route
              path="employee/:id/edit"
              element={WrapWithRollbarContext("employee/:id/edit", <EmployeeEditPage />)}
            />
          </Route>
          <Route element={<ProtectedRoute userPermission={showAdminSettingPage} />}>
            <Route path="admin/setting" element={WrapWithRollbarContext("admin/setting", <AdminSettingPage />)} />
          </Route>
          {!hasCompanies && !user?.subscriptionActive && (isManager(user) || !isAdmin(user)) && (
            <Route path="no_subscription" element={WrapWithRollbarContext("no_subscription", <NoSubscriptionPage />)} />
          )}
          {(!isManager(user) || isAdmin(user)) && (
            <Route
              path="terms_of_service"
              element={WrapWithRollbarContext("terms_of_service", <TermsOfServicePage />)}
            />
          )}
          <Route
            path="*"
            element={WrapWithRollbarContext(
              "*",
              !hasCompanies && !user?.subscriptionActive && (isManager(user) || !isAdmin(user)) ? (
                <Navigate to="no_subscription" />
              ) : !hasCompanies && !user?.subscriptionActive ? (
                <Navigate to="admin/setting" />
              ) : (
                <Navigate to="home" />
              ),
            )}
          />
        </Route>
      </ReactRoutes>
    </Suspense>
  );
};

const LoginRoutes: React.FC = () => (
  <Suspense fallback={<LoadingPage />}>
    <ReactRoutes>
      <Route path="/" element={WrapWithRollbarContext("/", <LoginLayout />)}>
        <Route path="login" element={WrapWithRollbarContext("login", <LoginForm />)} />
        <Route path="forgot_password" element={WrapWithRollbarContext("forgot_password", <ForgotForm />)} />
        <Route path="forgot" element={WrapWithRollbarContext("forgot", <ResetForm />)} />
        <Route path="accept_invite" element={WrapWithRollbarContext("accept_invite", <AcceptInviteForm />)} />
        <Route path="*" element={WrapWithRollbarContext("*", <Navigate to="login" />)} />
      </Route>
    </ReactRoutes>
  </Suspense>
);

const Routes = () => {
  const { isAuthenticated, isLoading } = useAuthContext();
  const AuthenticatedPage = isAuthenticated ? AuthRoutes : LoginRoutes;
  return isLoading ? <LoadingPage /> : <AuthenticatedPage />;
};

export default Routes;
