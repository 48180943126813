import { useMutation, useQuery } from "@apollo/client";
import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DefaultOptionType } from "antd/lib/select";
import { DefaultButton, SuccessButton } from "components/Buttons";
import { InputDropDown } from "components/Inputs";
import COLORS from "constants/colors";
import { CreateDocumentAccessResponse } from "model/Document";
import { FetchEmployeeResponse } from "model/Employee";
import { useState } from "react";
import { useNotify } from "services/api";
import { CREATE_DOCUMENT_ACCESS } from "services/graphql/document";
import { FETCH_USER } from "services/graphql/employee";
import styled from "styled-components";

type Props = {
  closeForm: () => void;
  userHaveAccess: string[];
  refetch: () => void;
  documentId: string;
};

const AddEmployeeAccess = ({ closeForm, userHaveAccess, refetch, documentId }: Props) => {
  const [form] = useForm();
  const [userNameData, setUserNameData] = useState<DefaultOptionType[]>([]);
  const selectedEmployees = Form.useWatch("employees", form);
  const notify = useNotify();

  const { loading: userLoading } = useQuery<FetchEmployeeResponse>(FETCH_USER, {
    variables: {
      fetchBirthdaysThisWeek: false,
      fetchWorkAnniversaryThisWeek: false,
    },
    onCompleted: (response) => {
      const options = response.users.nodes
        .map((user) => ({
          value: user.id,
          label: user.name,
        }))
        .filter((option) => !userHaveAccess.includes(option.value) && option);

      setUserNameData((d) => [...d, ...(options ?? [])]);
    },
  });

  const [addEmployee, { loading }] = useMutation<CreateDocumentAccessResponse>(CREATE_DOCUMENT_ACCESS, {
    onCompleted: (response) => {
      if (response?.createUserDocumentConnection?.importedRecordsCount) {
        notify.success({ message: "Selected employees successfully got the access." });
        refetch();
        closeForm();
      }
    },
  });

  return (
    <StyledForm>
      <Form
        layout="vertical"
        className="flex"
        form={form}
        onFinish={(value) => {
          if (value?.employees?.length) {
            addEmployee({ variables: { documentId, userIds: value.employees } });
          }
        }}
      >
        <Form.Item name="employees">
          <InputDropDown
            placeholder="Search employee"
            options={userNameData}
            open
            loading={userLoading}
            mode="multiple"
            maxTagCount="responsive"
            data-testid="departments"
            showSearch
            selectedValue={selectedEmployees}
            optionLabelProp="label"
            optionFilterProp="label"
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>
        <div className="btn-container">
          <Form.Item className="flex gap-3 mb-0">
            <SuccessButton htmlType="submit" loading={loading}>
              Add
            </SuccessButton>
            <DefaultButton onClick={closeForm}>Cancel</DefaultButton>
          </Form.Item>
        </div>
      </Form>
    </StyledForm>
  );
};

export default AddEmployeeAccess;

const StyledForm = styled.div`
  form {
    width: 340px;
    height: 375px;
    position: absolute;
    top: 55px;
    z-index: 100;
    background: white;
    border: 1.5px solid ${COLORS.successColor};
    border-radius: 5px;

    .ant-select-dropdown {
      box-shadow: none;
    }

    .ant-select-selector {
      border-radius: 0;
    }

    .ant-select-arrow {
      display: none;
    }

    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
      .ant-select-selector {
      border: none;
      border-bottom: 0.6px solid ${COLORS.borderLight};
      border-color: ${COLORS.borderLight};
    }
  }

  .btn-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid ${COLORS.borderLight};
  }

  .btn-container .ant-form-item-control-input-content {
    display: flex;
    gap: 13px;
    padding: 12px 14px 15px 14px;
  }
`;
