export default () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#a)">
      <rect x="11" y="6" width="34" height="34" rx="6" fill="#fff" />
      <rect x="10.875" y="5.875" width="34.25" height="34.25" rx="6.125" stroke="#D6DCE5" strokeWidth=".25" />
      <path
        d="M17.398 22.935c0-5.036 4.11-9.12 9.18-9.12s9.18 4.084 9.18 9.12c0 5.037-4.11 9.12-9.18 9.12s-9.18-4.083-9.18-9.12"
        fill="#fff"
      />
      <path
        d="M32.13 22.756a5.49 5.49 0 0 0-3.544-5.116v10.23a5.49 5.49 0 0 0 3.544-5.115m-7.494 5.113V17.641a5.49 5.49 0 0 0-3.541 5.115 5.49 5.49 0 0 0 3.541 5.113m1.976-13.761c-4.807.002-8.703 3.872-8.703 8.648s3.896 8.645 8.703 8.645c4.808 0 8.705-3.87 8.706-8.645-.002-4.776-3.898-8.646-8.706-8.648m-.021 18.11C21.33 32.242 17 28.008 17 22.854c0-5.632 4.33-9.528 9.59-9.527h2.467C34.255 13.326 39 17.219 39 22.854c0 5.152-4.745 9.364-9.943 9.364z"
        fill="#069"
      />
    </g>
    <defs>
      <filter
        id="a"
        x=".75"
        y=".75"
        width="54.5"
        height="54.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy=".5" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_302_2830" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="effect1_dropShadow_302_2830" result="effect2_dropShadow_302_2830" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_302_2830" result="shape" />
      </filter>
    </defs>
  </svg>
);
