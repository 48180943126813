import { Modal, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { DefaultButton } from "components/Buttons";
import DetailModal, { DetailItemProps } from "components/modal/DetailModal";
import COLORS from "constants/colors";
import DATE_FORMAT from "constants/dateFormat";
import TABLE from "constants/table";
import { useNotificationContext } from "contexts/NotificationProvider";
import dayjs from "dayjs";
import CancelOrAmendForm from "features/employee/employeeDetail/leave/CancelOrAmendForm";
import { mapLeaveRequestToFullDetail } from "features/employee/employeeDetail/leave/LeaveDetailTable";
import useFormModal from "hooks/useFormModal";
import {
  BookingStatus,
  EnhancedLeaveRequest,
  LeaveRequestsStatusLabels,
  bookingStatusLabel,
  leaveTypeLabels,
} from "model/Leave";
import { NotificationType, notificationsFilteredData } from "model/Notification";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { expectNumber } from "utils";
import storage from "utils/storage";

const HolidayApprovedDeclinedNotifications = () => {
  const { myNotificationLeaveData, refetchMyNotificationLeave: refetch, refresh } = useNotificationContext();
  const [fullDetail, setFullDetail] = useState<DetailItemProps[]>([]);
  const [getRecord, setRecord] = useState<EnhancedLeaveRequest>();
  const [notificationId, setNotificationId] = useState<number>();

  const getRequestFullDetail = (record: EnhancedLeaveRequest) => {
    setFullDetail(mapLeaveRequestToFullDetail(record));
  };

  const data = useMemo(
    () => (myNotificationLeaveData ? notificationsFilteredData(myNotificationLeaveData, NotificationType.Other) : []),
    [myNotificationLeaveData],
  );

  const { isModalVisible, hideModal, showModal } = useFormModal();
  const {
    isModalVisible: isLeaveCancelModalVisible,
    hideModal: hideLeaveCancelModal,
    showModal: showLeaveCancelModal,
    onCloseModal: onCloseLeaveCancelModal,
    onFieldsChange: onLeaveCancelFieldsChange,
  } = useFormModal();

  const columns: ColumnsType<EnhancedLeaveRequest> = [
    {
      key: "name",
      title: "Employee",
      render: (r) => r.user.name,
    },
    {
      title: "Leave type",
      dataIndex: "reason",
      render: (_, { reason }) => <span>{leaveTypeLabels[reason]}</span>,
    },
    {
      title: "Date started",
      dataIndex: "fromDate",
      render: (_, { fromDate }) => <span>{fromDate ? dayjs(fromDate).format(DATE_FORMAT.fullDateDefault) : "-"}</span>,
    },
    {
      title: "Date finished",
      dataIndex: "toDate",
      render: (_, { toDate }) => <span>{toDate ? dayjs(toDate).format(DATE_FORMAT.fullDateDefault) : "-"}</span>,
    },
    {
      title: "Approval status",
      dataIndex: "status",
      render: (_, { status }) => <span>{LeaveRequestsStatusLabels[status]}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, { bookingStatus }) => <span>{bookingStatus ? `${bookingStatusLabel[bookingStatus]}` : "-"}</span>,
    },
    {
      title: "Details",
      dataIndex: "Details",
      align: "center",
      width: "10%",
      render: (_, r) => (
        <DefaultButton
          onClick={() => {
            getRequestFullDetail(r);
            showModal();
          }}
        >
          Details
        </DefaultButton>
      ),
    },
    {
      title: "",
      align: "end",
      render: (r) =>
        r.bookingStatus === BookingStatus.Booked ? (
          <DefaultButton
            onClick={() => {
              setRecord(r);
              setNotificationId(expectNumber(`${r.user.id}${r.id}`));
              showLeaveCancelModal();
            }}
            className="me-2"
          >
            Cancel/Amend
          </DefaultButton>
        ) : (
          <DefaultButton
            onClick={() => {
              storage.addReadNotification(expectNumber(`${r.user.id}${r.id}`), NotificationType.Holiday);
              refresh();
            }}
            className="me-2"
          >
            Ok
          </DefaultButton>
        ),
      width: "16%",
    },
  ];
  return (
    <StyledWrapper className="section-box-shadow">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader
        rowKey="id"
        title={() => (
          <div className="d-flex align-items-center">
            <Typography.Title level={1} className="m-0 p-2 table-title">
              Holidays approved/declined
            </Typography.Title>
          </div>
        )}
        scroll={{
          scrollToFirstRowOnChange: false,
          y: TABLE.tableHeight,
        }}
      />

      <DetailModal
        title="Details"
        open={isModalVisible}
        onCancel={hideModal}
        width={519}
        data={fullDetail}
        destroyOnClose
      />
      <Modal
        title="Cancel / Amend request"
        open={isLeaveCancelModalVisible}
        onCancel={onCloseLeaveCancelModal}
        footer={null}
        width={430}
        destroyOnClose
        centered
      >
        <CancelOrAmendForm
          removeNotification={() => {
            if (notificationId) {
              refetch();
              refresh();
            }
          }}
          onFieldsChange={onLeaveCancelFieldsChange}
          data={getRecord}
          hideModal={hideLeaveCancelModal}
        />
      </Modal>
    </StyledWrapper>
  );
};

export default HolidayApprovedDeclinedNotifications;

const StyledWrapper = styled.div`
  .ant-table-title {
    border-bottom: 1px solid ${COLORS.borderLight};
  }
`;
