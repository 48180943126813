export const downloadLink = (link: string, fileName?: string, isAttachment: boolean = true, target?: string) => {
  const linkTag = document.createElement("a");
  linkTag.href = isAttachment ? `${link}?disposition=attachment` : link;
  if (target) {
    linkTag.target = target;
  }
  if (fileName) {
    linkTag.download = fileName;
  }
  linkTag.click();
};

export default { downloadLink };
