export const getTableHeight = (mainDivId: string, subtractHeightIds: string[]) => {
  const divElement = document.getElementById(mainDivId);
  const tableHead = document.getElementsByClassName("ant-table-header")[0] as HTMLElement;

  const subtractHeight = subtractHeightIds
    .map((item) => {
      const element = document.querySelector(item) as HTMLElement;
      if (element) {
        const style = window.getComputedStyle(element);
        const height = element.offsetHeight;
        const borderTopWidth = parseFloat(style.borderTopWidth);
        const borderBottomWidth = parseFloat(style.borderBottomWidth);
        const marginTop = parseFloat(style.marginTop);
        const marginBottom = parseFloat(style.marginBottom);
        return height + borderTopWidth + borderBottomWidth + marginTop + marginBottom;
      }
      return 0;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  if (divElement && subtractHeight && tableHead) {
    const mainDivHeight = divElement.offsetHeight;
    const style = window.getComputedStyle(divElement);
    const paddingTop = parseFloat(style.paddingTop);
    const paddingBottom = parseFloat(style.paddingBottom);
    const tableHeadHeight = tableHead.offsetHeight;
    const totalHeight = mainDivHeight - paddingTop - paddingBottom;
    return totalHeight - subtractHeight - tableHeadHeight - 24;
  }
  return 500;
};

export default getTableHeight;
