export default () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#a)">
      <rect x="11" y="6" width="34" height="34" rx="6.115" fill="url(#b)" />
      <rect x="10.873" y="5.873" width="34.255" height="34.255" rx="6.243" stroke="#D6DCE5" strokeWidth=".255" />
      <path
        d="M11.49 11.857a5.096 5.096 0 0 1 5.096-5.096H26.8c8.968 0 16.238 7.27 16.238 16.239 0 8.968-7.27 16.239-16.238 16.239H16.585a5.096 5.096 0 0 1-5.096-5.096z"
        fill="#fff"
      />
      <path
        d="M23.005 22.584c0 1.065.836 1.89 1.911 1.89.305 0 .565-.06.886-.21v-.832c-.282.282-.532.396-.853.396-.711 0-1.216-.516-1.216-1.249 0-.695.521-1.244 1.183-1.244.337 0 .593.12.886.407v-.83a1.8 1.8 0 0 0-.869-.223c-1.07 0-1.928.842-1.928 1.895m-1.813-.879c0 .195.124.299.548.456.804.294 1.042.554 1.042 1.13 0 .7-.515 1.189-1.25 1.189-.537 0-.928-.212-1.253-.69l.456-.44c.163.315.434.484.771.484.316 0 .549-.218.549-.511a.43.43 0 0 0-.212-.375 3 3 0 0 0-.488-.206c-.664-.239-.892-.494-.892-.993 0-.593.49-1.038 1.13-1.038.397 0 .76.136 1.064.402l-.37.483c-.183-.206-.357-.293-.569-.293-.305 0-.526.174-.526.402"
        fill="#251F1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.037 20.77H16v3.623h1.032c.548 0 .944-.13 1.292-.419.413-.341.657-.857.657-1.39 0-1.069-.798-1.813-1.944-1.813m.825 2.722c-.222.2-.51.287-.966.287h-.19v-2.394h.19c.456 0 .733.081.966.293.245.217.391.554.391.901 0 .348-.146.695-.39.913"
        fill="#251F1F"
      />
      <path
        d="M20.013 20.77h-.707v3.623h.707zm10.424 0 .966 2.433.979-2.432h.766l-1.565 3.715h-.38l-1.537-3.715zm3.03 3.623h2.004v-.614h-1.298v-.977h1.25v-.614h-1.25v-.803h1.297v-.614h-2.003z"
        fill="#251F1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.986 20.77c.815 0 1.282.392 1.282 1.07 0 .555-.294.919-.826 1.027l1.14 1.526h-.868l-.978-1.455h-.092v1.455h-.706V20.77zm-.342 1.668h.206c.452 0 .69-.196.69-.56 0-.352-.238-.537-.679-.537h-.217zm2.186-1.478c0-.063-.043-.098-.12-.098h-.103v.32h.076v-.124l.09.124h.093l-.105-.132a.09.09 0 0 0 .07-.09m-.134.044h-.013v-.083h.014q.057 0 .057.04 0 .043-.058.043"
        fill="#251F1F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.447 21.021a.277.277 0 1 1 .553 0 .28.28 0 0 1-.275.279.28.28 0 0 1-.278-.279m.055 0c0 .128.1.23.222.23s.22-.104.22-.23a.226.226 0 0 0-.22-.228.225.225 0 0 0-.222.229"
        fill="#251F1F"
      />
      <g filter="url(#c)">
        <path
          d="M27.943 24.502a1.917 1.917 0 0 1-1.917-1.916v-.003c0-.527.213-1.006.558-1.35l.003-.006.005-.003a1.9 1.9 0 0 1 1.35-.558h.003c1.059 0 1.917.858 1.917 1.917v.003c0 .526-.213 1.003-.558 1.35l-.002.006-.006.002a1.9 1.9 0 0 1-1.35.558z"
          fill="url(#d)"
        />
      </g>
    </g>
    <defs>
      <linearGradient id="b" x1="21.697" y1="6" x2="44.84" y2="31.23" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E05026" />
        <stop offset="1" stopColor="#F9A020" />
      </linearGradient>
      <linearGradient id="d" x1="26.939" y1="20.666" x2="30.091" y2="23.334" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EF3823" />
        <stop offset=".49" stopColor="#F04D22" />
        <stop offset="1" stopColor="#F7911D" />
      </linearGradient>
      <filter
        id="a"
        x=".553"
        y=".649"
        width="54.894"
        height="54.894"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy=".51" />
        <feGaussianBlur stdDeviation="2.548" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_302_2799" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="5.096" />
        <feGaussianBlur stdDeviation="5.096" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="effect1_dropShadow_302_2799" result="effect2_dropShadow_302_2799" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_302_2799" result="shape" />
      </filter>
      <filter
        id="c"
        x="26.026"
        y="20.666"
        width="3.977"
        height="4.047"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx=".14" dy=".211" />
        <feGaussianBlur stdDeviation=".14" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend in2="shape" result="effect1_innerShadow_302_2799" />
      </filter>
    </defs>
  </svg>
);
