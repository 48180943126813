export const expectNumber = (s: string): number => {
  const n = Number(s);
  const validInput = /^-?\d+$/.test(s);
  if (validInput && Number.isSafeInteger(n)) {
    return n;
  }
  throw new Error(`Expected finite integer, got ${s}`);
};

export default { expectNumber };
