import { useMutation } from "@apollo/client";
import { Modal, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { DefaultButton } from "components/Buttons";
import { tableColumnCommonData, sortingTableData, SortTypeEnum, SortOrderEnum } from "components/Table";
import { Container } from "components/core";
import { useModal } from "components/modal";
import COLORS from "constants/colors";
import TABLE from "constants/table";
import { useNotificationContext } from "contexts/NotificationProvider";
import { DocumentActionDataModify, SendDocumentReminderResponse } from "model/Document";
import { NotificationType } from "model/Notification";
import { useState } from "react";
import { useNotify } from "services/api";
import { SEND_DOCUMENT_REMINDER } from "services/graphql/document";
import styled from "styled-components";
import { expectNumber } from "utils";
import storage from "utils/storage";

const DocumentsActionedTable = () => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DocumentActionDataModify>>({});
  const { employeeActionedData: data, refresh } = useNotificationContext();
  const notify = useNotify();
  const [actionRequired, setActionRequired] = useState<string>();
  const {
    isModalVisible: isDescriptionModalVisible,
    hideModal: hideDescriptionModal,
    showModal: showDescriptionModal,
  } = useModal();

  const [sendReminder, { loading }] = useMutation<SendDocumentReminderResponse>(SEND_DOCUMENT_REMINDER, {
    onCompleted: (response) => {
      if (response?.sendDocumentReminder?.success) {
        notify.success({ message: "Send reminder to users successfully." });
      }
    },
  });

  const columns: ColumnsType<DocumentActionDataModify> = [
    {
      ...tableColumnCommonData({
        title: "Document name",
        dataIndex: "name",
        defaultSortOrder: SortOrderEnum.Descend,
        width: "40%",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.String, firstData: a.name, secondData: b.name }),
    },
    {
      ...tableColumnCommonData({
        title: "Number of employees not actioned",
        dataIndex: "actionPending",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { actionPending }) => actionPending,
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.Number, firstData: a.actionPending, secondData: b.actionPending }),
    },
    {
      title: "",
      dataIndex: "viewDetails",
      render: (_, { action }) => (
        <DefaultButton
          onClick={() => {
            setActionRequired(action);
            showDescriptionModal();
          }}
        >
          View details
        </DefaultButton>
      ),
      width: 115,
    },
    {
      title: "",
      dataIndex: "sendReminder",
      render: (_, { id }) => (
        <DefaultButton onClick={() => sendReminder({ variables: { documentId: id } })} loading={loading}>
          Send reminder
        </DefaultButton>
      ),
      width: 130,
    },
    {
      title: "",
      dataIndex: "ignore",
      render: (_, r) => (
        <DefaultButton
          onClick={() => {
            storage.addReadNotification(expectNumber(r.id), NotificationType.Document);
            refresh();
          }}
        >
          Ignore
        </DefaultButton>
      ),
      width: 87,
    },
  ];

  return (
    <StyledWrapper className="section-box-shadow">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        title={() => (
          <Typography.Title level={1} className="m-0 p-2 table-title">
            Documents actioned
          </Typography.Title>
        )}
        scroll={{
          scrollToFirstRowOnChange: false,
          y: TABLE.tableHeight,
        }}
        sortDirections={TABLE.tableSortDirections}
        onChange={(_pagination, _filter, sorter) => setSortedInfo(sorter as SorterResult<DocumentActionDataModify>)}
      />
      <Modal
        title="Details"
        open={isDescriptionModalVisible}
        onCancel={hideDescriptionModal}
        footer={null}
        width={519}
        centered
        destroyOnClose
      >
        <StyledContainer>{actionRequired}</StyledContainer>
      </Modal>
    </StyledWrapper>
  );
};

export default DocumentsActionedTable;

const StyledWrapper = styled.div`
  .ant-table-column-sorter {
    display: none;
  }
`;

const StyledContainer = styled(Container)`
  background-color: ${COLORS.defaultColor};
  padding: 28px 38px;
`;
