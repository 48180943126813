import { DocumentNode } from "@apollo/client";
import { Typography } from "antd";
import { SuccessButton } from "components/Buttons";
import { Container } from "components/core";
import COLORS from "constants/colors";
import { usePreventUnsavedForm } from "contexts/PreventUnsavedFormProvider";
import { LabelRefetch } from "model/AdminSetting";
import { useRef, useState, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";
import AddForm from "./AddForm";
import LabelTable from "./LabelTable";

type Props = {
  title: string;
  btnText: string;
  getQuery: DocumentNode;
  onAdd: (name: string) => void;
  deleteMutation: DocumentNode;
  editMutation: DocumentNode;
};

const Labels = forwardRef<LabelRefetch, Props>(
  ({ btnText, title, onAdd, getQuery, editMutation, deleteMutation }, ref) => {
    const [isAddNewData, setIsAddNewData] = useState<boolean>(false);
    const [isFieldsChanged, setIsFieldsChanged] = useState<boolean>(false);
    const [isEditLabel, setIsEditLabel] = useState(false);

    const LabelTableRef = useRef<LabelRefetch>(null);

    const { showConfirmModal } = usePreventUnsavedForm();

    useImperativeHandle(ref, () => ({
      refetch() {
        setIsAddNewData(false);
        LabelTableRef.current?.refetch();
      },
    }));

    const onCancelHandler = () => {
      if (isFieldsChanged) {
        showConfirmModal(() => {
          setIsAddNewData(false);
          setIsFieldsChanged(false);
        });
      } else {
        setIsAddNewData(false);
      }
    };

    return (
      <>
        <Container className="p-0 pt-2 overflow-hidden section-box-shadow">
          <StyledWrapper className="d-flex justify-content-between align-items-center pb-2 pe-2">
            <Typography.Title level={2} className="px-4 title mb-0 table-title">
              {title}
            </Typography.Title>
            <SuccessButton onClick={() => !isEditLabel && setIsAddNewData(true)}>{btnText}</SuccessButton>
          </StyledWrapper>
          {isAddNewData && (
            <AddForm
              onCancel={onCancelHandler}
              onSubmit={(variables) => {
                onAdd(variables.name.trim());
              }}
              onFieldsChange={() => setIsFieldsChanged(true)}
            />
          )}
          <LabelTable
            deleteMutation={deleteMutation}
            isAddNewData={isAddNewData}
            getQuery={getQuery}
            ref={LabelTableRef}
            setIsEditLabel={setIsEditLabel}
            editMutation={editMutation}
            title={title}
          />
        </Container>
      </>
    );
  },
);
export default Labels;

const StyledWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.borderLight};
`;
