import COLORS, { ColorType } from "constants/colors";
import LEAVE from "constants/leave";

type Props = {
  cardSize: string;
  type: string;
};

const LeaveCardSvg = ({ cardSize, type }: Props) =>
  cardSize === LEAVE.leaveCard1 ? (
    <svg width="355" height="153" viewBox="0 0 355 153" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 153C0 153 64.1882 82.5168 162.435 42.1179C260.683 1.71906 355 0 355 0V149C355 151.209 353.209 153 351 153H0Z"
        fill={`url(#paint0_linear_12422_16396${type})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_12422_16396${type}`}
          x1="148.7"
          y1="38.1808"
          x2="164.313"
          y2="118.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={COLORS[`${type.toLocaleLowerCase()}DarkCurve` as ColorType]} />
          <stop offset="1" stopColor={COLORS[`${type.toLocaleLowerCase()}LightCurve` as ColorType]} />
        </linearGradient>
      </defs>
    </svg>
  ) : [LEAVE.leaveCard56, LEAVE.leaveCard78].includes(cardSize) ? (
    <svg width="110" height="97" viewBox="0 0 110 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.87207 96.0653C0.87207 96.0653 20.5435 51.8996 50.6529 26.5851C73.9522 6.99611 96.5303 1.92052 105.552 0.626644C107.796 0.30472 109.667 2.07765 109.667 4.3452V92.0653C109.667 94.2745 107.876 96.0653 105.667 96.0653H0.87207Z"
        fill={`url(#paint0_linear_12422_19753${type})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_12422_19753${type}`}
          x1="46.4433"
          y1="24.118"
          x2="64.3889"
          y2="69.4937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={COLORS[`${type.toLocaleLowerCase()}DarkCurve` as ColorType]} />
          <stop offset="1" stopColor={COLORS[`${type.toLocaleLowerCase()}LightCurve` as ColorType]} />
        </linearGradient>
      </defs>
    </svg>
  ) : [LEAVE.leaveCard910, LEAVE.leaveCard1112].includes(cardSize) ? (
    <svg width="117" height="53" viewBox="0 0 117 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.68574 53C1.25209 53 -0.452696 49.0898 2.02687 46.7145C10.5741 38.527 27.549 24.1346 49.7803 14.5899C75.347 3.61319 100.111 0.879856 109.603 0.211448C111.836 0.0541679 113.667 1.83792 113.667 4.07685V49C113.667 51.2091 111.876 53 109.667 53H4.68574Z"
        fill={`url(#paint0_linear_12422_21474${type})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_12422_21474${type}`}
          x1="45.2232"
          y1="13.226"
          x2="50.852"
          y2="41.0974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={COLORS[`${type.toLocaleLowerCase()}DarkCurve` as ColorType]} />
          <stop offset="1" stopColor={COLORS[`${type.toLocaleLowerCase()}LightCurve` as ColorType]} />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width="167" height="110" viewBox="0 0 167 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 110C0 110 30.1956 59.3258 76.4133 30.2809C122.631 1.23592 167 0 167 0V106C167 108.209 165.209 110 163 110H0Z"
        fill={`url(#paint0_linear_10609_14064${type})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_10609_14064${type}`}
          x1="69.9517"
          y1="27.4502"
          x2="86.3191"
          y2="82.8174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={COLORS[`${type.toLocaleLowerCase()}DarkCurve` as ColorType]} />
          <stop offset="1" stopColor={COLORS[`${type.toLocaleLowerCase()}LightCurve` as ColorType]} />
        </linearGradient>
      </defs>
    </svg>
  );

export default LeaveCardSvg;
