import { useMutation, useQuery } from "@apollo/client";
import { DefaultOptionType } from "antd/es/select";
import PERMISSION from "config/permission";
import LABEL from "constants/label";
import { CreateLocationResponse, LocationResponse, Tag } from "model/AdminSetting";
import React, { useState } from "react";
import { CREATE_LOCATION, FETCH_LOCATION } from "services/graphql/adminSetting";
import { PageInfo } from "model/Common";
import { UseNotifyResponse } from "services/api";
import { useAuthContext } from "contexts";
import useCheckPermission from "./useCheckPermission";

interface Props {
  notify?: UseNotifyResponse;
  after?: boolean;
  onCompleted?: (_response: CreateLocationResponse) => void;
  skip?: boolean;
}

interface Variables {
  name: string;
}

export const useLocations = ({ notify, onCompleted, after, skip }: Props) => {
  const [locations, setLocations] = useState<DefaultOptionType[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const { user } = useAuthContext();
  const { companies } = PERMISSION;
  const { hasCompanies } = useCheckPermission({ companies });

  const { loading, refetch } = useQuery<LocationResponse>(FETCH_LOCATION, {
    variables: { first: LABEL.rowsPerPage },
    onCompleted: (response) => {
      setLocations((d) => [
        ...d,
        ...(response.locations.nodes
          .filter((item) => !d.map((item) => item.value).includes(item.id))
          .map((item) => ({ label: item.name, value: item.id })) ?? []),
      ]);
      if (response.locations.pageInfo) setPageInfo(response.locations.pageInfo);
    },
    skip: hasCompanies || !user?.subscriptionActive || skip,
  });

  const [mutation] = useMutation<CreateLocationResponse, Omit<Tag, "id">>(CREATE_LOCATION, {
    onCompleted: (response) => {
      if (notify) {
        if (response?.createLocation) {
          if (response?.createLocation?.errors?.fullMessages?.length) {
            response.createLocation.errors.fullMessages.map((error: string) => notify.error(undefined, error));
          } else {
            notify.success({
              message: "Location created successfully.",
            });
            if (locations.length >= LABEL.rowsPerPage && pageInfo?.endCursor && after) {
              setLocations((d) => [
                ...d,
                { label: response.createLocation.location.name, value: response.createLocation.location.id },
              ]);
              refetch({ first: LABEL.rowsPerPage, after: pageInfo.endCursor }).then(() => {
                if (onCompleted) {
                  onCompleted(response);
                }
              });
            } else if (after) {
              setLocations([]);
              setPageInfo(undefined);
              refetch({ first: LABEL.rowsPerPage, after: undefined }).then(() => {
                if (onCompleted) {
                  onCompleted(response);
                }
              });
            } else if (!after && onCompleted) {
              onCompleted(response);
            }
          }
        }
      }
    },
  });

  const handleDropdownScroll = (e: React.UIEvent<EventTarget>) => {
    if (
      (e.target as HTMLElement).scrollTop + (e.target as HTMLElement).clientHeight ===
        (e.target as HTMLElement).scrollHeight &&
      !loading &&
      pageInfo?.hasNextPage &&
      pageInfo?.endCursor
    ) {
      refetch({ first: LABEL.rowsPerPage, after: pageInfo.endCursor });
    }
  };

  const createLocation = (variables: Variables) => {
    mutation({ variables: { ...variables } });
  };

  return { locations, loading, createLocation, handleDropdownScroll };
};

export default useLocations;
