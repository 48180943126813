export default () => (
  <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#a)">
      <path
        d="M1 2.6c0-.424.171-.831.476-1.131.305-.3.718-.469 1.149-.469h6.5c.215 0 .422.084.574.234l4.063 4c.152.15.238.354.238.566v9.6c0 .424-.171.831-.476 1.131-.305.3-.718.469-1.149.469h-9.75c-.431 0-.844-.169-1.149-.469A1.588 1.588 0 0 1 1 15.4V2.6Zm11.039 3.2L9.125 2.931V5.8h2.914ZM7.5 2.6H2.625v12.8h9.75v-8H8.312a.819.819 0 0 1-.574-.234A.794.794 0 0 1 7.5 6.6v-4Z"
        fill="url(#b)"
      />
    </g>
    <defs>
      <linearGradient id="b" x1="-1.786" y1="-.333" x2="5.353" y2="-5.571" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A4DEB0" />
        <stop offset=".479" stopColor="#99DAE0" />
        <stop offset="1" stopColor="#CCB5F1" />
      </linearGradient>
      <filter id="a" x="0" y="0" width="21" height="24" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3" dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.890139 0 0 0 0 0.905444 0 0 0 0 0.966667 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4623_15708" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_4623_15708" result="shape" />
      </filter>
    </defs>
  </svg>
);
