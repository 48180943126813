import { useQuery } from "@apollo/client";
import { Checkbox, DatePicker } from "antd";
import Form, { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { SuccessButton } from "components/Buttons";
import { InputDropDown, InputText } from "components/Inputs";
import ALERTS from "config/alerts";
import DATE_FORMAT from "constants/dateFormat";
import dayjs from "dayjs";
import { Company, CompanyId, FetchCompanyResponse, PaymentTypeLabel, PaymentTypes } from "model/Company";
import { FETCH_COMPANY } from "services/graphql/company";
import { enumValues } from "utils/misc";

type Props = {
  id?: CompanyId;
  onSubmit: (data: Company) => void;
  onFieldsChange: () => void;
};

export function CompanyForm({ id, onSubmit, onFieldsChange }: Props) {
  const isNew = id === undefined;

  const [form] = useForm();

  useQuery<FetchCompanyResponse>(FETCH_COMPANY, {
    variables: { id },
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      form.setFieldsValue({ ...response.fetchCompany, signedAt: dayjs(response.fetchCompany.signedAt) });
    },
    skip: isNew,
  });

  return (
    <Form
      layout="vertical"
      className="p-0"
      form={form}
      initialValues={{ notifySuperuser: true }}
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
      data-testid="company-form"
    >
      <FormItem label="Company ID" name="companyId" rules={[ALERTS.required, ALERTS.characterLength]}>
        <InputText data-testid="companyId" />
      </FormItem>
      <FormItem label="Company Name" name="companyName" rules={[ALERTS.required, ALERTS.characterLength]}>
        <InputText data-testid="companyName" />
      </FormItem>
      <FormItem label="Sign up date" name="signedAt" rules={[ALERTS.required]}>
        <DatePicker
          className="w-100"
          changeOnBlur
          format={DATE_FORMAT.datePickerAllowDate}
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          data-testid="signedAt"
        />
      </FormItem>
      <FormItem label="Superuser" name="superuserName" rules={[ALERTS.required, ALERTS.characterLength]}>
        <InputText data-testid="superuserName" />
      </FormItem>
      <FormItem label="Superuser email" name="superuserEmail" rules={[ALERTS.required, ALERTS.email]}>
        <InputText type="email" data-testid="superuserEmail" />
      </FormItem>
      <FormItem label="Superuser phone" name="superuserPhone" rules={[ALERTS.required, ALERTS.phone]}>
        <InputText type="tel" data-testid="superuserPhone" />
      </FormItem>
      <FormItem label="Payment type" name="paymentType" rules={[ALERTS.required]}>
        <InputDropDown
          className="w-100"
          options={enumValues(PaymentTypes).map((p) => ({ label: PaymentTypeLabel[p], value: p }))}
          data-testid="paymentType"
          placeholder="Please select"
        />
      </FormItem>

      {isNew && (
        <FormItem name="notifySuperuser" valuePropName="checked">
          <Checkbox data-testid="notifySuperuser"> Notify superadmin</Checkbox>
        </FormItem>
      )}

      <SuccessButton htmlType="submit" className="w-100">
        {isNew ? "Save" : "Save changes"}
      </SuccessButton>
    </Form>
  );
}

export default CompanyForm;
