import * as React from "react";

export const useUpdater = () => {
  const [counter, setCounter] = React.useState(0);
  const update = React.useCallback(() => {
    setCounter((prev) => prev + 1);
  }, []);

  return [counter, update] as const;
};

export default useUpdater;
