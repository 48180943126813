export default () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.16667C5.33122 1.16667 4.69556 1.41248 4.23146 1.84141C3.76839 2.26938 3.51429 2.84334 3.51429 3.43518C3.51429 4.02703 3.76839 4.60099 4.23146 5.02896C4.69556 5.45789 5.33122 5.7037 6 5.7037C6.66878 5.7037 7.30444 5.45789 7.76854 5.02896C8.23161 4.60099 8.48571 4.02703 8.48571 3.43518C8.48571 2.84334 8.23161 2.26938 7.76854 1.84141C7.30444 1.41248 6.66878 1.16667 6 1.16667ZM3.40468 0.995835C4.09795 0.35511 5.03202 0 6 0C6.96798 0 7.90205 0.35511 8.59532 0.995835C9.28962 1.63751 9.68571 2.51431 9.68571 3.43518C9.68571 4.35606 9.28962 5.23286 8.59532 5.87454C7.90205 6.51526 6.96798 6.87037 6 6.87037C5.03202 6.87037 4.09795 6.51526 3.40468 5.87454C2.71038 5.23286 2.31429 4.35606 2.31429 3.43518C2.31429 2.51431 2.71038 1.63751 3.40468 0.995835ZM1.76823 9.46489C2.89551 8.42305 4.41823 7.84259 6 7.84259C7.58177 7.84259 9.10449 8.42305 10.2318 9.46489C11.3601 10.5077 12 11.9285 12 13.4167C12 13.7388 11.7314 14 11.4 14H0.6C0.44087 14 0.288258 13.9385 0.175736 13.8291C0.0632141 13.7197 0 13.5714 0 13.4167C0 11.9285 0.639921 10.5077 1.76823 9.46489ZM6 9.00926C4.71743 9.00926 3.49313 9.48042 2.59501 10.3105C1.84964 10.9993 1.37983 11.8877 1.24224 12.8333H10.7578C10.6202 11.8877 10.1504 10.9993 9.40499 10.3105C8.50687 9.48042 7.28257 9.00926 6 9.00926Z"
      fill="white"
    />
  </svg>
);
