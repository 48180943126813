import { useLazyQuery } from "@apollo/client";
import { useSafeContext } from "hooks";
import { PageInfo } from "model/Common";
import { FetchUserLeaveListResponse, EnhancedLeaveRequest } from "model/Leave";
import React, { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { FETCH_LEAVE_REQUESTS_USER_ID } from "services/graphql/leave";
import { expectNumber } from "utils";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import { useAuthContext } from "./AuthProvider";

type RefetchVariables = {
  variables: {
    userId?: string;
  };
};

interface ContextValue {
  loading: boolean;
  data: EnhancedLeaveRequest[];
  refetch: ({ variables }: RefetchVariables) => void;
  refetchList: (userId?: string) => void;
  setData: React.Dispatch<React.SetStateAction<EnhancedLeaveRequest[]>>;
}

const LeaveRequestListContext = createContext<ContextValue | null>(null);
LeaveRequestListContext.displayName = "FetchLeaveRequestList";

export const useFetchRequestList = () => useSafeContext(LeaveRequestListContext);

export const LeaveRequestListProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<EnhancedLeaveRequest[]>([]);
  const { user } = useAuthContext();
  const { companies } = PERMISSION;
  const { hasCompanies } = useCheckPermission({
    companies,
  });

  const [fetch, { loading }] = useLazyQuery<FetchUserLeaveListResponse>(FETCH_LEAVE_REQUESTS_USER_ID, {
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      setData((d) => [...d, ...(response.leaveRequests.nodes ?? [])]);
    },
  });

  const refetchList = useCallback(
    (id?: string) => {
      const userId = id || user?.id;
      if (!userId) return;

      if (!hasCompanies && user?.subscriptionActive) {
        setData([]);
        fetch({ variables: { userId } });
      }
    },
    [fetch, hasCompanies, user?.id, user?.subscriptionActive],
  );

  const refetch = useCallback(
    ({ variables }: RefetchVariables) => {
      fetch({ variables });
    },
    [fetch],
  );

  const value = useMemo(
    () => ({
      data: data.sort((a, b) => expectNumber(a.id) - expectNumber(b.id)),
      refetch,
      refetchList,
      loading,
      setData,
    }),
    [data, refetch, refetchList, loading],
  );

  useEffect(() => {
    refetchList();
  }, [refetchList]);

  return <LeaveRequestListContext.Provider value={value}>{children}</LeaveRequestListContext.Provider>;
};
