import { Popover } from "antd";
import { BaseButton } from "components/Buttons";
import Icon from "components/Icon";
import useLoginReminder from "hooks/useLoginReminder";
import { ReactElement } from "react";
import styled from "styled-components";

type Props = {
  userId: string;
  type: "login" | "updateDetail";
};

export const ReminderPopover = ({ userId, type }: Props): ReactElement => {
  const [mutate, { loading }] = useLoginReminder();
  return (
    <Popover
      content={
        <div className="d-flex flex-column align-items-center">
          <StyledTitle>{type === "login" ? "Not yet logged in" : "Send reminder to manager to update"}</StyledTitle>
          <BaseButton
            className="mt-2"
            onClick={(e) => {
              e.stopPropagation();
              if (type === "login") {
                mutate({ variables: { userId } });
              }
            }}
            loading={loading}
          >
            Send reminder
          </BaseButton>
        </div>
      }
    >
      <StyledIcon name="popover" className="popoverIcon" onClick={(e: MouseEvent) => e.stopPropagation()} />
    </Popover>
  );
};

export default ReminderPopover;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledTitle = styled.span`
  width: 140px;
  text-align: center;
`;
