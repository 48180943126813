import { ReactComponent as Logo } from "assets/images/logo-nav.svg";
import { ICustomIconList } from "components/Icon/Icon";
import NavLink from "components/Links/NavLink";
import {
  NAV_ITEMS,
  ADMIN_NAV_ITEMS,
  COMMON_ITEM,
  ADMIN_MANAGER_NAV_ITEMS,
  ADMIN_MANAGER_DOCUMENT_NAV_ITEM,
} from "config/nav-items";
import PERMISSION from "config/permission";
import COLORS from "constants/colors";
import { useAuthContext } from "contexts";
import { RequestLeave } from "features/users";
import { useResponsive } from "hooks";
import useCheckPermission from "hooks/useCheckPermission";
import { ReactElement } from "react";
import styled from "styled-components";

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1.5rem;
  background-color: ${COLORS.primaryColor};
  z-index: 2;
  .buttons {
    a {
      color: white;
    }
  }
`;

const BackgroundLines = () => (
  <svg
    className="position-absolute w-100 h-100"
    width="244"
    height="451"
    viewBox="0 0 244 451"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ left: 0 }}
  >
    <path d="M298.392 135.105C173.544 181.592 -3.35656 148.234 -58.384 0" stroke="#283A4F" />
    <path opacity="0.5" d="M312.93 15.3617C197.245 81.4298 17.2785 77.1234 -61 -60.2588" stroke="#283A4F" />
    <path d="M-37.3548 373.608C13.6628 250.542 158.592 143.762 304.597 204.459" stroke="#283A4F" />
  </svg>
);

export type ItemType = {
  name: string;
  path?: string;
  icon: ICustomIconList;
};

const renderItems = (
  adminPermission: boolean,
  companiesPermission: boolean,
  employeeListPermission: boolean,
  documentsPermission: boolean,
  subscriptionActive?: boolean,
): ReactElement[] => {
  const items = companiesPermission
    ? COMMON_ITEM
    : adminPermission
    ? [
        ...(subscriptionActive ? COMMON_ITEM : []),
        ...(employeeListPermission ? ADMIN_MANAGER_NAV_ITEMS : []),
        ...(subscriptionActive ? NAV_ITEMS : []),
        ...(documentsPermission ? ADMIN_MANAGER_DOCUMENT_NAV_ITEM : []),
        ...ADMIN_NAV_ITEMS,
      ]
    : subscriptionActive
    ? [...COMMON_ITEM, ...NAV_ITEMS]
    : [];
  return items.map((item) => (
    <NavLink key={item.name} to={item.path as string} icon={item.icon}>
      {item.name}
    </NavLink>
  ));
};

function NavBar(): ReactElement {
  const { showAdminSettingPage, companies, showEmployeeList, showDocumentsPage } = PERMISSION;
  const { hasShowAdminSettingPage, hasCompanies, hasShowEmployeeList, hasShowDocumentsPage } = useCheckPermission({
    showAdminSettingPage,
    companies,
    showEmployeeList,
    showDocumentsPage,
  });
  const { isLarge } = useResponsive();
  const { user } = useAuthContext();
  const navbarItems = renderItems(
    hasShowAdminSettingPage,
    hasCompanies,
    hasShowEmployeeList,
    hasShowDocumentsPage,
    user?.subscriptionActive,
  );
  return (
    <>
      {/* <HamburgerMenu onLogout={logOut} email={user.email} /> */}
      <Nav id="nav">
        <BackgroundLines />
        <div className="p-4 mr-auto">
          <Logo />
        </div>

        {isLarge && (
          <div className="buttons d-flex flex-grow-1 flex-column justify-content-between">
            <div className="position-relative">{navbarItems}</div>
            {!hasCompanies && user?.subscriptionActive && <RequestLeave />}
          </div>
        )}
      </Nav>
    </>
  );
}

export default NavBar;
