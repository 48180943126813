import { Col, Row, Typography } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { InputDropDown, InputPassword, InputText } from "components/Inputs";
import ALERTS from "config/alerts";
import { FC } from "react";

export const UserForm: FC<{ isEditUser?: boolean }> = ({ isEditUser }) => (
  <>
    <Row>
      <Typography.Title level={2} className="mb-4">
        Add user
      </Typography.Title>
    </Row>
    <Row gutter={24}>
      <Col xl={12} xs={24}>
        <FormItem rules={[ALERTS.required]} label="Email" name="email">
          <InputText type="email" />
        </FormItem>
        <FormItem rules={[ALERTS.required]} label="School" name="school">
          <InputDropDown options={[]} />
        </FormItem>
        <FormItem rules={[ALERTS.required]} label="Name" name="name">
          <InputText />
        </FormItem>
      </Col>
      <Col xl={12} xs={24}>
        <FormItem rules={isEditUser ? [] : [ALERTS.required]} label="Password" name="password">
          <InputPassword />
        </FormItem>
        <FormItem rules={[ALERTS.required]} label="Type" name="type">
          <InputDropDown options={[]} />
        </FormItem>
      </Col>
    </Row>
  </>
);

export default UserForm;

UserForm.defaultProps = {
  isEditUser: false,
};
