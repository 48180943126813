import { Modal, ModalProps } from "antd";
import { Container } from "components/core";
import COLORS from "constants/colors";
import styled from "styled-components";
import Icon from "components/Icon";
import { BaseButton } from "components/Buttons";
import { getFilenameFromUrl } from "features/employee/leave";
import { downloadLink } from "utils";

export type DetailItemProps = {
  label: string;
  value?: string | number | null;
};

interface Data {
  label: string;
  value?: string | number | null;
}

type Props = {
  data: Data[];
};

const EmployeeFieldItem = ({ label, value }: DetailItemProps) => (
  <StyleListItem>
    <StyledTitle>{label}</StyledTitle>
    <StyledData>
      {label.toLowerCase() === "attachments" && value ? (
        <span className="d-flex align-items-center gap-2">
          <Icon name="document" />
          {getFilenameFromUrl(value as string)}
          <StyledBaseButton
            icon="download"
            className="btn-base"
            onClick={() => {
              downloadLink(value as string, getFilenameFromUrl(value as string));
            }}
          />
        </span>
      ) : (
        value || "-"
      )}
    </StyledData>
  </StyleListItem>
);

export function DetailModal({ data, ...props }: Omit<ModalProps, "footer" | "centered"> & Props) {
  return (
    <Modal {...props} footer={null} centered>
      <StyledContainer>
        {data.map((item) => (
          <EmployeeFieldItem label={item.label} value={item.value} key={item.label} />
        ))}
      </StyledContainer>
    </Modal>
  );
}

export default DetailModal;

const StyledContainer = styled(Container)`
  background: ${COLORS.tableBgColor};
  padding: 30px 20px;
`;

const StyleListItem = styled.li`
  width: 100%;
  padding: 6px 0;
  display: flex;
  gap: 4%;
`;

const StyledTitle = styled.span`
  width: 350px;
  line-height: 26px;
  color: ${COLORS.inputFieldTextColor};
`;

const StyledData = styled(StyledTitle)`
  font-weight: 600;
  color: ${COLORS.primaryColor};
  width: 100%;
`;

const StyledBaseButton = styled(BaseButton)`
  &.btn-base {
    background: ${COLORS.downloadIconBg};
    width: 23px;
    height: 23px;
    border-radius: 2px;
    svg {
      color: ${COLORS.tertiary};
    }
  }
`;
