import { useMutation } from "@apollo/client";
import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { SuccessButton } from "components/Buttons";
import { InputFile } from "components/Inputs";
import ALERTS, { fileSize } from "config/alerts";
import { DocumentRefetch, DocumentVisibilityType, UploadDocumentResponse } from "model/Document";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useNotify } from "services/api";
import { UPLOAD_DOCUMENT } from "services/graphql/document";

interface Props {
  onCancel: () => void;
  employeeDocumentTableRef: React.RefObject<DocumentRefetch>;
}

const UploadDocumentForm = ({ onCancel, employeeDocumentTableRef }: Props) => {
  const [form] = useForm();
  const notify = useNotify();
  const { id } = useParams();
  const [documentFile, setDocumentFile] = useState<File | null>();

  const [uploadDocument, { loading }] = useMutation<UploadDocumentResponse>(UPLOAD_DOCUMENT, {
    onCompleted: (response) => {
      if (response?.createDocument) {
        if (response?.createDocument?.errors?.fullMessages?.length) {
          response.createDocument.errors.fullMessages.map((error: string) => notify.error(undefined, error));
        } else {
          notify.success({ message: "Document uploaded successfully." });
          employeeDocumentTableRef.current?.documentRefetch();
          onCancel();
        }
      }
    },
  });

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={() => {
        if (id && documentFile) {
          uploadDocument({
            variables: {
              attachment: documentFile,
              userIds: id,
              visibilityType: DocumentVisibilityType.Individual,
            },
          });
        }
      }}
    >
      <Form.Item
        label="Upload document"
        name="uploadDocument"
        className="mb-5"
        rules={[ALERTS.required, fileSize(documentFile)]}
      >
        <InputFile
          onChange={(e) => {
            if (e.target.files) {
              setDocumentFile(e.target.files[0]);
            }
          }}
        />
      </Form.Item>
      <SuccessButton block htmlType="submit" loading={loading}>
        Save
      </SuccessButton>
    </Form>
  );
};

export default UploadDocumentForm;
