export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.18 3.49961C22.637 2.14289 23.9193 1.16464 25.4314 1.16464C27.3256 1.16464 28.8611 2.70012 28.8611 4.59426C28.8611 5.94469 28.0805 7.11248 26.9478 7.67133C26.6507 7.81795 26.2909 7.69591 26.1443 7.39874C25.9977 7.10157 26.1197 6.74181 26.4169 6.59519C27.1553 6.23088 27.6611 5.47098 27.6611 4.59426C27.6611 3.36286 26.6628 2.36464 25.4314 2.36464C24.4499 2.36464 23.6148 2.99928 23.3172 3.88267C23.2114 4.19671 22.8711 4.36553 22.5571 4.25975C22.243 4.15397 22.0742 3.81365 22.18 3.49961Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.42455 15.2936C6.56041 15.5959 6.42552 15.951 6.12328 16.0869L3.18283 17.4085L3.18279 17.4085C2.12849 17.8823 1.45 18.9308 1.45 20.0876V26.4649C1.45 27.5984 2.36922 28.5177 3.50277 28.5177H10.341C10.6724 28.5177 10.941 28.7863 10.941 29.1177C10.941 29.449 10.6724 29.7177 10.341 29.7177H3.50277C1.70648 29.7177 0.25 28.2612 0.25 26.4649V20.0876C0.25 18.4585 1.2056 16.9815 2.69086 16.314C2.69088 16.314 2.69089 16.314 2.6909 16.314L5.63131 14.9923C5.93356 14.8565 6.2887 14.9914 6.42455 15.2936Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.51877 15.2813C9.6559 14.9796 10.0116 14.8462 10.3133 14.9834L13.2325 16.3103C13.8459 16.5895 14.3684 17.0069 14.7691 17.5159C14.974 17.7762 14.9291 18.1535 14.6688 18.3585C14.4084 18.5634 14.0312 18.5185 13.8262 18.2582C13.5419 17.8971 13.1717 17.6011 12.7359 17.4028L9.8167 16.0758C9.51503 15.9387 9.38165 15.583 9.51877 15.2813Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62344 22.3867C5.95481 22.3867 6.22344 22.6553 6.22344 22.9867V25.2163H10.5937C10.925 25.2163 11.1937 25.485 11.1937 25.8163C11.1937 26.1477 10.925 26.4163 10.5937 26.4163H5.62344C5.29207 26.4163 5.02344 26.1477 5.02344 25.8163V22.9867C5.02344 22.6553 5.29207 22.3867 5.62344 22.3867Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66797 7.18808C2.66797 4.90327 4.52018 3.05106 6.805 3.05106H9.16301C11.4478 3.05106 13.3 4.90327 13.3 7.18808V8.8387C13.3 9.17007 13.0314 9.43869 12.7 9.43869C12.3687 9.43869 12.1 9.17007 12.1 8.8387V7.18808C12.1 5.56601 10.7851 4.25106 9.16301 4.25106H6.805C5.18293 4.25106 3.86797 5.56601 3.86797 7.18808V8.8387C3.86797 9.17007 3.59934 9.43869 3.26797 9.43869C2.9366 9.43869 2.66797 9.17007 2.66797 8.8387V7.18808Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.86892 15.0278C10.2003 15.0278 10.4689 15.2964 10.4689 15.6278V17.3276C10.4689 18.7006 9.35553 19.814 7.98251 19.814C6.60948 19.814 5.49609 18.7006 5.49609 17.3276V15.6751C5.49609 15.3437 5.76472 15.0751 6.09609 15.0751C6.42746 15.0751 6.69609 15.3437 6.69609 15.6751V17.3276C6.69609 18.0379 7.27222 18.614 7.98251 18.614C8.69279 18.614 9.26892 18.0379 9.26892 17.3276V15.6278C9.26892 15.2964 9.53755 15.0278 9.86892 15.0278Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.62405 6.94385C7.83739 6.78388 8.13069 6.7839 8.344 6.94388L8.61848 7.14974C9.56093 7.85659 10.7072 8.23869 11.8853 8.23869H12.7C13.0314 8.23869 13.3 8.50732 13.3 8.83869V11.1967C13.3 14.1327 10.92 16.5127 7.984 16.5127C5.04803 16.5127 2.66797 14.1327 2.66797 11.1967V8.83869C2.66797 8.50732 2.9366 8.23869 3.26797 8.23869H4.08266C5.26072 8.23869 6.40702 7.85659 7.34947 7.14974L7.62405 6.94385ZM7.98398 8.1728C6.85032 8.99508 5.48492 9.43869 4.08266 9.43869H3.86797V11.1967C3.86797 13.4699 5.71077 15.3127 7.984 15.3127C10.2572 15.3127 12.1 13.4699 12.1 11.1967V9.43869H11.8853C10.483 9.43869 9.11764 8.99509 7.98398 8.1728Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5003 15.3257C20.6374 15.6273 20.504 15.983 20.2023 16.1202L17.3807 17.4026C16.9448 17.601 16.5745 17.897 16.2902 18.2581C16.0852 18.5185 15.708 18.5634 15.4476 18.3584C15.1873 18.1534 15.1424 17.7762 15.3473 17.5158C15.748 17.0068 16.2702 16.5895 16.8837 16.3104L19.7058 15.0277C20.0074 14.8906 20.3631 15.024 20.5003 15.3257Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6711 15.2863C23.8069 14.9841 24.1621 14.8491 24.4643 14.985L27.422 16.314L27.422 16.314C28.9073 16.9815 29.8629 18.4585 29.8629 20.0876V26.4649C29.8629 28.2612 28.4064 29.7177 26.6101 29.7177H19.7719C19.4405 29.7177 19.1719 29.449 19.1719 29.1177C19.1719 28.7863 19.4405 28.5177 19.7719 28.5177H26.6101C27.7437 28.5177 28.6629 27.5984 28.6629 26.4649V20.0876C28.6629 18.9308 27.9844 17.8824 26.9301 17.4086C26.9301 17.4086 26.9301 17.4086 26.9301 17.4086L23.9725 16.0795C23.6702 15.9437 23.5353 15.5886 23.6711 15.2863Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.488 22.3867C24.8193 22.3867 25.088 22.6553 25.088 22.9867V25.8163C25.088 26.1477 24.8193 26.4163 24.488 26.4163H19.2992C18.9678 26.4163 18.6992 26.1477 18.6992 25.8163C18.6992 25.485 18.9678 25.2163 19.2992 25.2163H23.888V22.9867C23.888 22.6553 24.1566 22.3867 24.488 22.3867Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8164 8.36709C16.8164 5.43111 19.1965 3.05106 22.1324 3.05106C25.0684 3.05106 27.4485 5.43111 27.4485 8.36709V8.8387C27.4485 9.17007 27.1799 9.43869 26.8485 9.43869C26.5171 9.43869 26.2485 9.17007 26.2485 8.8387V8.36709C26.2485 6.09385 24.4057 4.25106 22.1324 4.25106C19.8592 4.25106 18.0164 6.09385 18.0164 8.36709V8.8387C18.0164 9.17007 17.7478 9.43869 17.4164 9.43869C17.085 9.43869 16.8164 9.17007 16.8164 8.8387V8.36709Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2445 15.0683C20.5759 15.0683 20.8445 15.337 20.8445 15.6683V17.3276C20.8445 18.0379 21.4207 18.614 22.1309 18.614C22.8412 18.614 23.4174 18.0379 23.4174 17.3276V15.763C23.4174 15.4316 23.686 15.163 24.0174 15.163C24.3487 15.163 24.6174 15.4316 24.6174 15.763V17.3276C24.6174 18.7006 23.504 19.814 22.1309 19.814C20.7579 19.814 19.6445 18.7006 19.6445 17.3276V15.6683C19.6445 15.337 19.9132 15.0683 20.2445 15.0683Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7156 6.94388C22.929 6.78388 23.2223 6.78388 23.4356 6.94388L23.8129 7.22685L23.8129 7.22685C24.6887 7.88365 25.7538 8.23869 26.8485 8.23869C27.1799 8.23869 27.4485 8.50732 27.4485 8.83869V11.1967C27.4485 14.1327 25.0684 16.5127 22.1324 16.5127C19.1965 16.5127 16.8164 14.1327 16.8164 11.1967V8.83869C16.8164 8.50732 17.085 8.23869 17.4164 8.23869H19.3028C20.3975 8.23869 21.4626 7.88365 22.3384 7.22685L22.3384 7.22685L22.7156 6.94388ZM23.0756 8.17388L23.0584 8.18684C23.0584 8.18684 23.0584 8.18684 23.0584 8.18685C21.9749 8.99944 20.6572 9.43869 19.3028 9.43869H18.0164V11.1967C18.0164 13.4699 19.8592 15.3127 22.1324 15.3127C24.4057 15.3127 26.2485 13.4699 26.2485 11.1967V9.40988C25.1081 9.30007 24.0156 8.87883 23.0929 8.18685C23.0929 8.18684 23.0929 8.18684 23.0929 8.18684L23.0756 8.17388Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7602 23.5995C13.8903 23.9043 13.7487 24.2568 13.4439 24.3869C11.9723 25.0151 10.9422 26.4755 10.9422 28.1744V28.5176H19.1743V28.1744C19.1743 27.038 18.7141 26.0098 17.969 25.264C17.5989 24.8945 17.1597 24.595 16.6724 24.3868C16.3676 24.2566 16.2261 23.9041 16.3563 23.5993C16.4865 23.2946 16.8391 23.1531 17.1438 23.2833C17.7741 23.5526 18.3406 23.9392 18.817 24.415L18.8175 24.4154C19.7782 25.3768 20.3743 26.7065 20.3743 28.1744V29.1176C20.3743 29.449 20.1056 29.7176 19.7743 29.7176H10.3422C10.0108 29.7176 9.74219 29.449 9.74219 29.1176V28.1744C9.74219 25.9779 11.0749 24.0934 12.9728 23.2832C13.2776 23.1531 13.6301 23.2947 13.7602 23.5995Z"
      fill="#8E91C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0575 18.3992C13.5656 18.3992 12.3563 19.6086 12.3563 21.1004C12.3563 22.5923 13.5656 23.8016 15.0575 23.8016C16.5493 23.8016 17.7587 22.5923 17.7587 21.1004C17.7587 19.6086 16.5493 18.3992 15.0575 18.3992ZM11.1562 21.1004C11.1562 18.9458 12.9029 17.1992 15.0575 17.1992C17.2121 17.1992 18.9587 18.9458 18.9587 21.1004C18.9587 23.255 17.2121 25.0016 15.0575 25.0016C12.9029 25.0016 11.1562 23.255 11.1562 21.1004Z"
      fill="#8E91C6"
    />
  </svg>
);
