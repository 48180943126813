export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.3523 4.80866H5.19401L2.45456 2.19965C2.20847 1.96501 1.81761 1.97376 1.58117 2.22106C1.34593 2.46806 1.35558 2.85891 1.60288 3.09415L3.40312 4.80866H2.64637C1.28381 4.80866 0.175781 5.97248 0.175781 7.4029V9.13219V12.8381V23.2144C0.175781 24.6448 1.28381 25.8087 2.64637 25.8087H25.4531L27.5441 27.8C27.6635 27.914 27.8167 27.9704 27.9699 27.9704C28.1328 27.9704 28.2956 27.9062 28.4175 27.7786C28.6527 27.5316 28.643 27.1408 28.3957 26.9055L27.244 25.8087H27.3523C28.7148 25.8087 29.8228 24.6448 29.8228 23.2144V12.8381V9.13219V7.4029C29.8228 5.97248 28.7148 4.80866 27.3523 4.80866ZM27.3523 6.04396C28.0332 6.04396 28.5875 6.65346 28.5875 7.4029V8.51454H9.08522L6.49109 6.04396H27.3523ZM28.5875 12.2204H12.9764L10.3823 9.74984H28.5875V12.2204ZM1.41108 9.74984H8.59137L11.1855 12.2204H1.41108V9.74984ZM2.64637 6.04396H4.70017L7.29429 8.51454H1.41108V7.4029C1.41108 6.65346 1.96539 6.04396 2.64637 6.04396ZM2.64637 24.5734C1.96539 24.5734 1.41108 23.9639 1.41108 23.2144V13.4557H12.4825L24.1561 24.5734H2.64637ZM22.4111 20.2498C22.4111 19.5686 22.9654 19.0145 23.6464 19.0145C24.3274 19.0145 24.8817 19.5686 24.8817 20.2498C24.8817 20.9311 24.3274 21.4851 23.6464 21.4851C22.9654 21.4851 22.4111 20.9311 22.4111 20.2498ZM27.3523 24.5734H25.947L23.9673 22.688C25.1771 22.5287 26.117 21.5022 26.117 20.2498C26.117 18.8876 25.0089 17.7792 23.6464 17.7792C22.3527 17.7792 21.3004 18.7818 21.1961 20.0488L14.2734 13.4557H28.5875V23.2144C28.5875 23.9639 28.0332 24.5734 27.3523 24.5734Z"
      fill="#A0ADC3"
    />
    <path
      d="M3.26608 19.6322H6.97197C7.31336 19.6322 7.58961 19.3557 7.58961 19.0146C7.58961 18.6735 7.31336 18.3969 6.97197 18.3969H3.26608C2.92469 18.3969 2.64844 18.6735 2.64844 19.0146C2.64844 19.3557 2.92469 19.6322 3.26608 19.6322Z"
      fill="#A0ADC3"
    />
    <path
      d="M8.20726 20.8676H3.26608C2.92469 20.8676 2.64844 21.1441 2.64844 21.4852C2.64844 21.8263 2.92469 22.1028 3.26608 22.1028H8.20726C8.54866 22.1028 8.82491 21.8263 8.82491 21.4852C8.82491 21.1441 8.54866 20.8676 8.20726 20.8676Z"
      fill="#A0ADC3"
    />
  </svg>
);
