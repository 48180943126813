import { useMutation } from "@apollo/client";
import { Col, Input, Modal, notification, Row, Typography } from "antd";
import { LinkButton, SuccessButton } from "components/Buttons";
import Icon from "components/Icon";
import CompanyForm from "features/company/CompanyForm";
import { Company, CompanyId, CompanyRefetch, CreateCompanyResponse, UpdateCompanyResponse } from "model/Company";
import { ReactElement, useState, useRef } from "react";
import { Link } from "react-router-dom";
import company from "routes/company";
import { CREATE_COMPANY, FETCH_COMPANY, UPDATE_COMPANY } from "services/graphql/company";
import useFormModal from "hooks/useFormModal";
import dayjs from "dayjs";
import DATE_FORMAT from "constants/dateFormat";
import { useDebounce } from "hooks";
import CompanyTable from "./CompanyTable";

export function CompanyPage(): ReactElement {
  const { isModalVisible, showModal, onCloseModal, onFieldsChange } = useFormModal();
  const [api, contextHolder] = notification.useNotification();
  const [editId, setEditId] = useState<CompanyId>();
  const [searchValue, setSearchValue] = useState<string>();

  const companyTableRef = useRef<CompanyRefetch>(null);

  const searchDeferredValue = useDebounce<string | undefined>(searchValue);

  const [createCompany] = useMutation<CreateCompanyResponse>(CREATE_COMPANY, {
    onCompleted: (response) => {
      if (response?.createCompany) {
        if (response.createCompany?.errors?.fullMessages?.length) {
          response.createCompany.errors.fullMessages.map((error: string) =>
            api.error({
              message: error,
            }),
          );
        } else {
          api.success({
            message: "Create company successfully.",
            description: (
              <Link to={company.view(response.createCompany.company.id)}>
                <LinkButton className="p-0">View Company</LinkButton>
              </Link>
            ),
          });
          companyTableRef.current?.companyRefetch();
          onCloseModal();
        }
      }
    },
  });

  const [updateCompany] = useMutation<UpdateCompanyResponse>(UPDATE_COMPANY, {
    onCompleted: (response) => {
      if (response?.updateCompany) {
        if (response.updateCompany?.errors?.fullMessages?.length) {
          response.updateCompany.errors.fullMessages.map((error: string) =>
            api.error({
              message: error,
            }),
          );
        } else {
          api.success({
            message: "Update company successfully.",
            description: (
              <Link to={company.view(response.updateCompany.company.id)}>
                <LinkButton className="p-0">View Company</LinkButton>
              </Link>
            ),
          });
          companyTableRef.current?.companyRefetch();
          onCloseModal();
        }
      }
    },
    refetchQueries: [{ query: FETCH_COMPANY, variables: { id: editId } }],
  });

  const submitHandler = (data: Company) => {
    if (editId) {
      onFieldsChange(false);
      updateCompany({
        variables: { ...data, id: editId, signedAt: dayjs(data.signedAt).format(DATE_FORMAT.isoFormat) },
      });
    } else {
      onFieldsChange(false);
      createCompany({ variables: { ...data, signedAt: dayjs(data.signedAt).format(DATE_FORMAT.isoFormat) } });
    }
  };

  const addHandler = () => {
    setEditId(undefined);
    showModal();
  };

  const editHandler = (id: CompanyId) => {
    setEditId(id);
    showModal();
  };

  return (
    <div id="main" className="h-100">
      {contextHolder}
      <Row gutter={21} align="middle" id="company_filter">
        <Col lg={5} xl={6}>
          <Typography.Title level={1}>Companies</Typography.Title>
        </Col>
        <Col lg={13} xl={14}>
          <Input
            placeholder="Search"
            prefix={<Icon name="search" />}
            onChange={(event) => setSearchValue(event.currentTarget.value)}
          />
        </Col>
        <Col lg={6} xl={4}>
          <SuccessButton className="w-100" onClick={addHandler}>
            Add new company
          </SuccessButton>
        </Col>
      </Row>
      <Row className="mt-4 pt-1 section-box-shadow">
        <Col span={24}>
          <CompanyTable onEdit={editHandler} searchValue={searchDeferredValue} ref={companyTableRef} />
        </Col>
      </Row>
      <Modal
        title={editId ? "Edit company details" : "Add Company"}
        open={isModalVisible}
        onCancel={onCloseModal}
        footer={null}
        width={430}
        destroyOnClose
        centered
      >
        <CompanyForm id={editId} onSubmit={submitHandler} onFieldsChange={onFieldsChange} />
      </Modal>
    </div>
  );
}

export default CompanyPage;
