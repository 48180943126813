import { useMutation } from "@apollo/client";
import { Row, Typography } from "antd";
import Form, { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { ReactComponent as EmailSvg } from "assets/images/email.svg";
import { LinkButton, SuccessButton } from "components/Buttons";
import { InputText } from "components/Inputs";
import ALERTS from "config/alerts";
import { ForgotPasswordPayload, MutationForgotPasswordArgs } from "model/User";
import { ReactElement, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useNotify } from "services/api";
import { FORGOT_PASSWORD_DOCUMENT } from "services/graphql/user";

interface SuccessEmailSentProps {
  onClose: () => void;
}
const SuccessEmailSent = ({ onClose }: SuccessEmailSentProps) => (
  <>
    <Row justify="center" className="mb-4">
      <EmailSvg />
    </Row>
    <Row justify="center">
      <Typography.Title level={2}>Check your email</Typography.Title>
    </Row>
    <Row className="px-4 py-2 text-center">We sent an email with instructions to reset your password.</Row>
    <Row className="px-4 pb-4 text-center">If you don&apos;t get the email soon, please check your spam folder.</Row>
    <SuccessButton className="w-100" onClick={onClose}>
      Got it
    </SuccessButton>
  </>
);

export function ForgotForm(): ReactElement {
  const [form] = useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSent, setSent] = useState(false);
  const notify = useNotify();
  const [mutate, { loading }] = useMutation<ForgotPasswordPayload>(FORGOT_PASSWORD_DOCUMENT, {
    onCompleted: (response) => {
      if (response.forgotPassword) {
        if (!response.forgotPassword.success) notify.error(undefined, response.forgotPassword.errors?.fullMessages[0]);
        else setSent(true);
      }
    },
  });

  if (isSuccess) return <Navigate to="/login" />;

  const onFinish = ({ email }: MutationForgotPasswordArgs) => mutate({ variables: { email } });

  return isSent ? (
    <SuccessEmailSent onClose={() => setIsSuccess(true)} />
  ) : (
    <Form labelCol={{ span: 24 }} form={form} initialValues={{}} onFinish={onFinish} autoComplete="off">
      <Row justify="start">
        <Typography.Title level={2}>Forgot your password?</Typography.Title>
      </Row>
      <FormItem rules={[ALERTS.required, ALERTS.email]} label="Email" name="email" required>
        <InputText placeholder="Email" data-testid="email" />
      </FormItem>
      <SuccessButton className="w-100" loading={loading} htmlType="submit">
        Send me reset password instruction
      </SuccessButton>
      <Row justify="center" className="mt-4">
        <Link to="/login" tabIndex={-1}>
          <LinkButton>Return to log in</LinkButton>
        </Link>
      </Row>
    </Form>
  );
}

export default ForgotForm;
