export default () => (
  <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M104.593 76.148h-1.5V71.92a7.68 7.68 0 0 0-4.661-7.37 7.677 7.677 0 0 0-10.678 7.37v4.278h-1.488a1.24 1.24 0 0 0-1.24 1.24v15.426a1.24 1.24 0 0 0 1.24 1.24h18.327a1.24 1.24 0 0 0 1.24-1.24V77.388a1.24 1.24 0 0 0-1.24-1.24M90.234 71.92a5.195 5.195 0 1 1 10.379 0v4.278H90.234zm13.119 19.704H87.506V78.628h15.847zm2.48-31.199V26.127a4.77 4.77 0 0 0-4.774-4.762H10.936a4.76 4.76 0 0 0-4.737 4.762V84.17a4.76 4.76 0 0 0 4.762 4.762h33.133v7.44h-9.747a3.126 3.126 0 1 0 0 6.2H77.66a3.125 3.125 0 0 0 0-6.2h-9.746v-7.44h6.87a22.394 22.394 0 1 0 31-28.52zM77.66 98.865a.645.645 0 0 1 0 1.24H34.347a.64.64 0 0 1-.754-.332.645.645 0 0 1 .754-.908zm-31.086-2.48v-7.44h18.86v7.44zm-35.638-9.92a2.294 2.294 0 0 1-2.257-2.294v-8.047h64.753a21.86 21.86 0 0 0 .496 10.329zm63.091-12.821H8.68V26.127a2.294 2.294 0 0 1 2.282-2.282h90.098a2.295 2.295 0 0 1 2.294 2.282v33.195a22.2 22.2 0 0 0-7.874-1.476 22.43 22.43 0 0 0-21.452 15.798m21.452 26.511a19.913 19.913 0 0 1-19.615-23.754 19.915 19.915 0 0 1 39.455 3.84 19.925 19.925 0 0 1-19.84 19.914M57.597 80.129H54.41a1.24 1.24 0 0 0 0 2.48h3.187a1.24 1.24 0 1 0 0-2.48"
      fill="#000"
    />
    <path fill="#AFECF4" d="M13 29h18v39H13z" />
    <mask id="a" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="13" y="29" width="18" height="39">
      <path fill="#A6D0D6" d="M13 29h18v39H13z" />
    </mask>
    <g mask="url(#a)" stroke="#70B1BA">
      <path transform="matrix(-.47885 .8779 -.90907 -.41665 17.273 27)" d="M0-.5h11.391" />
      <path transform="matrix(-.50012 .86595 -.89966 -.4366 21.637 27)" d="M0-.5h19.631" />
      <path transform="matrix(-.50873 .86093 -.89567 -.44472 26 27)" d="M0-.5h27.877" />
      <path transform="matrix(-.5029 .86435 -.89838 -.43921 29.273 28)" d="M0-.5h34.708" />
      <path d="m32.43 31.255-20.181 34M32 39 13.449 70.22m19.705-25.961-15.726 26m15.58-18.037L20 72.947m15.008-16.725L22 76.947" />
    </g>
    <path fill="#AFECF4" d="M36 29h62v9H36z" />
    <mask id="b" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="36" y="29" width="62" height="9">
      <path fill="#A6D0D6" d="M36 29h62v9H36z" />
    </mask>
    <g mask="url(#b)" stroke="#70B1BA">
      <path d="m50.449 26.224-7 14m19-14-7 14m18.998-14-7 14m19-14-7 14m-36.998-14-7 14m19-14-7 14m18.998-14-7 14m19-14-7 14m19-14-7 14m15-14-7 14m-44.998-14-7 14m19-14-7 14m18.998-14-7 14m19-14-7 14m19-14-7 14m15-14-7 14" />
    </g>
    <path fill="#AFECF4" d="M36 44h62v9H36z" />
    <mask id="c" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="36" y="44" width="62" height="9">
      <path fill="#A6D0D6" d="M36 44h62v9H36z" />
    </mask>
    <g mask="url(#c)" stroke="#70B1BA">
      <path d="m50.449 41.224-7 14m19-14-7 14m18.998-14-7 14m19-14-7 14m-36.998-14-7 14m19-14-7 14m18.998-14-7 14m19-14-7 14m19-14-7 14m15-14-7 14m-44.998-14-7 14m19-14-7 14m18.998-14-7 14m19-14-7 14m19-14-7 14m15-14-7 14" />
    </g>
    <path d="M36 59h52s-4.5 1.5-7.5 4.5-4 4.5-4 4.5H36z" fill="#AFECF4" />
    <mask id="d" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="36" y="59" width="53" height="9">
      <path d="M36 59h52.302S84.356 60.203 81 63s-4.287 5-4.287 5H36z" fill="#A6D0D6" />
    </mask>
    <g mask="url(#d)" stroke="#70B1BA">
      <path d="m50.449 56.224-7 14m19-14-7 14m18.998-14-7 14m19-14-7 14m-36.998-14-7 14m19-14-7 14m18.998-14-7 14m19-14-7 14m19-14-7 14m-36.998-14-7 14m19-14-7 14m18.998-14-7 14m19-14-7 14m19-14-7 14" />
    </g>
  </svg>
);
