import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import ApproveLeaveTable from "./ApproveLeaveTable";
import DocumentsActionedTable from "./DocumentsActionedTable";
import OtherUpdates from "./OtherUpdates";

const EmployeeNotifications = () => {
  const { employeeDocumentNotification } = PERMISSION;
  const { hasEmployeeDocumentNotification } = useCheckPermission({ employeeDocumentNotification });
  return (
    <div>
      <div className="mb-4">
        <ApproveLeaveTable />
      </div>
      <div className={`${hasEmployeeDocumentNotification ? "mb-4" : "mb-0"}`}>
        <OtherUpdates />
      </div>
      {hasEmployeeDocumentNotification && <DocumentsActionedTable />}
    </div>
  );
};

export default EmployeeNotifications;
