import { Typography, Image, Modal, ModalProps } from "antd";
import { DefaultButton, PrimaryButton, SuccessButton } from "components/Buttons";
import { MouseEventHandler } from "react";
import styled from "styled-components";
import COLORS from "constants/colors";
import InfoImage from "../../assets/images/info-modal.svg";
import WarningImage from "../../assets/images/warning-modal.svg";
import ErrorImage from "../../assets/images/error-modal.svg";
import SuccessImage from "../../assets/images/success-modal.svg";

type Props = {
  title?: string;
  description?: string;
  type: "info" | "error" | "warning" | "success";
  className?: string;
  mode?: "notification" | "confirmation";
  okText?: string;
  cancelText?: string;
  okButtonMode?: "primary" | "success";
  onCancelButton?: () => void;
};

const ModalImage: Record<string, string> = {
  info: InfoImage,
  error: ErrorImage,
  warning: WarningImage,
  success: SuccessImage,
};

export function FeedBackModal({
  onOk,
  title,
  description,
  open,
  onCancel,
  className,
  type,
  mode = "notification",
  okText,
  cancelText,
  okButtonMode,
  confirmLoading,
  onCancelButton,
  ...props
}: Omit<ModalProps, "title"> & Props) {
  return (
    <StyledModal
      open={open}
      closable={false}
      onCancel={onCancel}
      destroyOnClose
      className={className}
      footer={null}
      centered
      width={308}
      {...props}
    >
      <div className="Images">
        <Image src={ModalImage[type]} preview={false} />
      </div>
      <div className="px-4 py-4">
        <Typography.Title className="mb-2 text-center">{title}</Typography.Title>
        <StyledDescription>{description}</StyledDescription>
        <div className="d-flex justify-content-center align-items-center gap-2 mt-3">
          {mode === "notification" && (
            <DefaultButton onClick={onOk as MouseEventHandler} className="w-100">
              Ok
            </DefaultButton>
          )}
          {mode === "confirmation" && (
            <>
              {okButtonMode === "success" && (
                <SuccessButton onClick={onOk as MouseEventHandler} className="w-100" loading={confirmLoading}>
                  {okText}
                </SuccessButton>
              )}
              <DefaultButton onClick={onCancelButton as MouseEventHandler} className="w-100" loading={confirmLoading}>
                {cancelText}
              </DefaultButton>
              {okButtonMode === "primary" && (
                <PrimaryButton onClick={onOk as MouseEventHandler} className="w-100" loading={confirmLoading}>
                  {okText}
                </PrimaryButton>
              )}
            </>
          )}
        </div>
      </div>
    </StyledModal>
  );
}

export default FeedBackModal;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  &.shareDocumentFeedBack .ant-modal-body h1 {
    font-size: 18px;
    padding: 0 10px;
  }
`;

const StyledDescription = styled(Typography.Text)`
  display: flex;
  text-align: center;
  font-weight: 400;
  color: ${COLORS.inputFieldTextColor};
  justify-content: center;
`;
