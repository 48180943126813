import { StripeElementsOptions, StripeElementStyle, StripeAddressElementOptions } from "@stripe/stripe-js";
import COLORS from "./colors";

const stripeElementsOptions: StripeElementsOptions = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css2?family=Inter:wght@500",
    },
  ],
  appearance: {
    variables: {
      gridRowSpacing: "21px",
      gridColumnSpacing: "20px",
      fontFamily: "Inter",
      fontSmooth: "auto",
    },
    rules: {
      ".Input--empty::placeholder": {
        color: "transparent",
      },
      ".Input": {
        color: COLORS.inputFieldTextColor,
        fontWeight: "500",
        fontSize: "14px",
        padding: "9px 11px",
      },
      ".Input:focus": {
        borderColor: COLORS.outlineColor,
        boxShadow: "none",
        outline: "0",
      },
      ".Input:hover": {
        borderColor: COLORS.inputBorderHover,
      },
      ".Label": {
        color: COLORS.labelColor,
        fontWeight: "500",
        fontSize: "14px",
        marginBottom: "8px",
        lineHeight: "22px",
      },
      ".Input--invalid": {
        color: COLORS.inputFieldTextColor,
        border: `0.6px solid ${COLORS.errorColor}`,
        boxShadow: "none",
      },
      ".Input--invalid:focus": {
        boxShadow: "none",
        borderColor: COLORS.errorColor,
      },
      ".Input--invalid:hover": {
        borderColor: COLORS.inputErrorBorderHover,
      },
    },
  },
};

const elementStyled: StripeElementStyle = {
  base: {
    "::placeholder": {
      color: "transparent",
    },
    color: COLORS.inputFieldTextColor,
    fontWeight: "500",
    fontFamily: "Inter",
  },
  invalid: {
    color: COLORS.inputFieldTextColor,
  },
};

const stripeAddressElementOptions: StripeAddressElementOptions = {
  mode: "billing",
  blockPoBox: true,
  fields: {
    phone: "always",
  },
  validation: {
    phone: {
      required: "always",
    },
  },
  autocomplete: {
    mode: "automatic",
  },
  display: {
    name: "organization",
  },
};

const cardIconName: Record<string, string> = {
  visa: "visaCard",
  mastercard: "masterCard",
  amex: "amexCard",
  discover: "discoverCard",
  diners: "dinersCard",
  jcb: "jcbCard",
  unionpay: "unionPayCard",
};

const currencySymbols = {
  eur: "€",
  gbp: "£",
  usd: "$",
};

export type StripeCurrencySymbol = keyof typeof currencySymbols;

const STRIPE = {
  stripeElementsOptions,
  elementStyled,
  stripeAddressElementOptions,
  cardIconName,
  currencySymbols,
};

export default STRIPE;
