import EMPLOYEE from "constants/employee";
import { IntlType } from "model/AdminSetting";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import dayjs, { Dayjs } from "dayjs";
import { expectNumber } from "utils";

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(weekday);

export const getUserTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return timeZone;
};

export const getSequenceOfDays = (data: string[]) => {
  const days = EMPLOYEE.days.map((item) => item.label);
  const daysIdArray = EMPLOYEE.days.filter((item) => data.includes(item.value as string)).map((item) => item.id);
  const ranges = daysIdArray
    .reduce((acc: (string | number)[][], val: number, i: number) => {
      if (i === 0 || val !== daysIdArray[i - 1] + 1) acc.push([]);
      acc[acc.length - 1].push(val);
      return acc;
    }, [])
    .map((range: (string | number)[]) =>
      range.length === 1
        ? days[expectNumber(range[0] as string) - 1]
        : `${days[expectNumber(range[0] as string) - 1]}-${days[expectNumber(range[range.length - 1] as string) - 1]}`,
    );
  return ranges.join(",");
};

export const getTimeZone = () => {
  const timeZoneIdentifiers: string[] = (Intl as typeof Intl & typeof IntlType).supportedValuesOf("timeZone");
  return [...timeZoneIdentifiers, ...(!timeZoneIdentifiers.includes("UTC") ? ["UTC"] : []), "GMT"];
};

export const isToday = (date: number, currentDate: Dayjs) => {
  const monthDate = dayjs(currentDate).startOf("month");
  const newDay = monthDate.clone().add(date, "day");
  const isToday = dayjs().isSame(newDay, "day") && dayjs().isSame(newDay, "month") && dayjs().isSame(newDay, "year");
  return isToday;
};

export default {
  getUserTimeZone,
  getSequenceOfDays,
  getTimeZone,
  isToday,
};
