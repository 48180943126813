import { Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { DefaultButton } from "components/Buttons";
import DATE_FORMAT from "constants/dateFormat";
import TABLE from "constants/table";
import dayjs from "dayjs";
import { mapLeaveRequestToFullDetail } from "features/employee/employeeDetail/leave/LeaveDetailTable";
import useFormModal from "hooks/useFormModal";
import { EnhancedLeaveRequest, LeaveRequestsReason, leaveTypeLabels } from "model/Leave";
import { useState } from "react";
import styled from "styled-components";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import { useNotificationContext } from "contexts/NotificationProvider";
import { tableColumnCommonData, sortingTableData, SortTypeEnum, SortOrderEnum } from "components/Table";
import UpdateLeaveModal, { Data } from "./UpdateLeaveModal";

const ApproveLeaveTable = () => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<EnhancedLeaveRequest>>({});
  const { isModalVisible, hideModal, showModal } = useFormModal();
  const { employeePendingData: data, refetchEmployeePending: refetch } = useNotificationContext();
  const { approveOrDeclineLeave } = PERMISSION;
  const { hasApproveOrDeclineLeave } = useCheckPermission({ approveOrDeclineLeave });

  const columns: ColumnsType<EnhancedLeaveRequest> = [
    {
      ...tableColumnCommonData({
        title: "Employee",
        dataIndex: "name",
        defaultSortOrder: SortOrderEnum.Descend,
        width: 180,
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, r) => r.user!.name,
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.String, firstData: a.user!.name, secondData: b.user!.name }),
    },
    {
      ...tableColumnCommonData({
        title: "Leave type",
        dataIndex: "reason",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.String,
          firstData: leaveTypeLabels[a.reason],
          secondData: leaveTypeLabels[b.reason],
        }),
      render: (_, { reason }) => <span>{leaveTypeLabels[reason]}</span>,
    },
    {
      ...tableColumnCommonData({
        title: "Start day",
        dataIndex: "fromDate",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { fromDate }) => <span>{fromDate ? dayjs(fromDate).format(DATE_FORMAT.fullDateDefault) : "-"}</span>,
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.fromDate, secondData: b.fromDate }),
    },
    {
      ...tableColumnCommonData({
        title: "End day",
        dataIndex: "toDate",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { toDate }) => <span>{toDate ? dayjs(toDate).format(DATE_FORMAT.fullDateDefault) : "-"}</span>,
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.toDate, secondData: b.toDate }),
    },
    {
      ...tableColumnCommonData({
        title: "Total days",
        dataIndex: "accumulativeBalance",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, r) => (r?.usedDays ?? 0).toFixed(2),
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.Number,
          firstData: (a?.usedDays ?? 0).toFixed(2),
          secondData: (b?.usedDays ?? 0).toFixed(2),
        }),
    },
    {
      ...tableColumnCommonData({
        title: "Number of remaining days leave prior to request",
        dataIndex: "leavePriorToRequest",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, r) =>
        r.reason === LeaveRequestsReason.Holiday
          ? ((r?.accumulativeBalance ?? 0) - (r?.precedingUsedHolidayDays ?? 0)).toFixed(2)
          : "-",
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.Number,
          firstData: parseFloat(((a?.accumulativeBalance ?? 0) - (a?.precedingUsedHolidayDays ?? 0)).toFixed(2)),
          secondData: parseFloat(((b?.accumulativeBalance ?? 0) - (b?.precedingUsedHolidayDays ?? 0)).toFixed(2)),
        }),
    },
    {
      ...tableColumnCommonData({
        title: "Number of remaining days leave following request",
        dataIndex: "leaveFollowingRequest",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, r) =>
        r.reason === LeaveRequestsReason.Holiday
          ? ((r?.accumulativeBalance ?? 0) - (r?.precedingUsedHolidayDays ?? 0) - (r?.usedDays ?? 0)).toFixed(2)
          : "-",
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.Number,
          firstData: parseFloat(
            ((a?.accumulativeBalance ?? 0) - (a?.precedingUsedHolidayDays ?? 0) - (a?.usedDays ?? 0)).toFixed(2),
          ),
          secondData: parseFloat(
            ((b?.accumulativeBalance ?? 0) - (b?.precedingUsedHolidayDays ?? 0) - (b?.usedDays ?? 0)).toFixed(2),
          ),
        }),
    },
    {
      title: "",
      dataIndex: "approve request",
      render: (_, r) =>
        hasApproveOrDeclineLeave && (
          <DefaultButton
            onClick={() => {
              setSelectedRow(mapLeaveRequestToFullDetail(r));
              setRequestId(r.id);
              showModal();
            }}
          >
            Approve/Decline
          </DefaultButton>
        ),
      width: 150,
    },
  ];

  const [selectedRow, setSelectedRow] = useState<Data[]>();
  const [requestId, setRequestId] = useState<string>();

  return (
    <StyledWrapper className="section-box-shadow">
      <Table
        columns={columns}
        dataSource={data ?? []}
        pagination={false}
        rowKey="id"
        title={() => (
          <Typography.Title level={1} className="m-0 p-2 table-title">
            Leave requests
          </Typography.Title>
        )}
        scroll={{
          scrollToFirstRowOnChange: false,
          y: TABLE.tableHeight,
        }}
        sortDirections={TABLE.tableSortDirections}
        onChange={(_pagination, _filter, sorter) => setSortedInfo(sorter as SorterResult<EnhancedLeaveRequest>)}
      />
      {selectedRow && requestId && (
        <UpdateLeaveModal
          requestId={requestId}
          title="Leave request"
          data={selectedRow}
          okText="Approve request"
          cancelText="Decline request"
          open={isModalVisible}
          onClose={() => {
            hideModal();
            refetch();
          }}
        />
      )}
    </StyledWrapper>
  );
};

export default ApproveLeaveTable;

const StyledWrapper = styled.div`
  .ant-table-column-sorter {
    display: none;
  }
`;
