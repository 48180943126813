import { useMutation, useQuery } from "@apollo/client";
import { Form, Modal, Popover, Radio, Space, Typography } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import Icon from "components/Icon";
import { InputDropDown, InputFile } from "components/Inputs";
import TABLE from "constants/table";
import {
  DocumentVisibilityType,
  ShareType,
  UploadDocumentFormData,
  documentVisibilityLabel,
  documentShareTypeLabel,
  UploadDocumentFormFirstStepArg,
  DocumentRefetch,
  UploadDocumentResponse,
} from "model/Document";
import { FetchEmployeeResponse } from "model/Employee";
import { FETCH_USER } from "services/graphql/employee";
import styled from "styled-components";
import { DefaultOptionType } from "antd/es/select";
import { enumValues } from "utils/misc";
import React, { useState } from "react";
import { PageInfo } from "model/Common";
import { SuccessButton } from "components/Buttons";
import useFormModal from "hooks/useFormModal";
import ALERTS, { fileSize } from "config/alerts";
import { useNotify } from "services/api";
import { UPLOAD_DOCUMENT } from "services/graphql/document";
import { FeedBackModal } from "components/modal";
import { useNotificationContext } from "contexts/NotificationProvider";
import AssociatedAction from "./AssociatedAction";

type Props = {
  onCloseModal: () => void;
  setDestroyModal: () => void;
  documentListRef: React.RefObject<DocumentRefetch>;
};

const UploadDocument = ({ onCloseModal, setDestroyModal, documentListRef }: Props) => {
  const [form] = useForm();
  const notify = useNotify();
  const visibilityTypeValue = useWatch("visibilityType", form);
  const [userNameData, setUserNameData] = useState<DefaultOptionType[]>([]);
  const [documentFile, setDocumentFile] = useState<File | null>();
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [formData, setFormData] = useState<UploadDocumentFormData>();
  const [buttonClicked, setButtonClicked] = useState<"save" | "share" | "yes" | "no">();

  const { isModalVisible, hideModal, showModal } = useFormModal();

  const { employeeDocumentRefetch, myDocumentReviewRefetch, refresh } = useNotificationContext();

  const {
    isModalVisible: isFeedbackModalVisible,
    hideModal: hideFeedbackModal,
    showModal: showFeedbackModal,
  } = useFormModal();

  const { refetch: fetchUserList, loading: userLoading } = useQuery<FetchEmployeeResponse>(FETCH_USER, {
    variables: {
      first: TABLE.rowsPerPage,
      fetchBirthdaysThisWeek: false,
      fetchWorkAnniversaryThisWeek: false,
    },
    onCompleted: (response) => {
      const options = response.users.nodes.map((user) => ({
        value: user.id,
        label: user.name,
      }));

      setUserNameData((d) => [...d, ...(options ?? [])]);

      if (response.users.pageInfo) setPageInfo(response.users.pageInfo);
    },
  });

  const handleUserDropdownScroll = (e: React.UIEvent<EventTarget>) => {
    if (
      (e.target as HTMLElement).scrollTop + (e.target as HTMLElement).clientHeight ===
        (e.target as HTMLElement).scrollHeight &&
      pageInfo?.hasNextPage &&
      pageInfo?.endCursor
    ) {
      fetchUserList({ after: pageInfo?.endCursor });
    }
  };

  const [uploadDocument, { loading }] = useMutation<UploadDocumentResponse>(UPLOAD_DOCUMENT, {
    onCompleted: (response) => {
      if (response?.createDocument) {
        if (response.createDocument?.errors?.fullMessages?.length) {
          response.createDocument.errors.fullMessages.map((error: string) => notify.error(undefined, error));
        } else {
          notify.success({ message: "Document uploaded successfully." });
          documentListRef.current?.documentRefetch();
          showFeedbackModal();
          employeeDocumentRefetch();
          myDocumentReviewRefetch();
          refresh();
        }
      }
    },
  });

  const onFinish = (value: UploadDocumentFormFirstStepArg) => {
    const userIds = value?.userIds ? [value.userIds] : undefined;
    const sharedWithEmployee = userIds?.length
      ? (userNameData.find((item) => item.value === value.userIds)?.label as string)
      : undefined;
    setFormData({ ...value, attachment: documentFile, sharedWithEmployee, userIds });
    if (buttonClicked === "yes") {
      onCloseModal();
      showModal();
    } else {
      uploadDocument({ variables: { ...value, attachment: documentFile, sharedWithEmployee, userIds } });
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item
        name="attachment"
        label="Upload document"
        className="mb-4 pb-2"
        rules={[ALERTS.required, fileSize(documentFile)]}
        required={false}
      >
        <InputFile
          onChange={(e) => {
            if (e.target.files) {
              setDocumentFile(e.target.files[0]);
            }
          }}
        />
      </Form.Item>
      <StyledTitle level={5} className="mb-3">
        Is this document:
      </StyledTitle>
      <Form.Item name="visibilityType" className="mb-3" rules={[ALERTS.required]} required={false}>
        <Radio.Group className="w-100" onChange={() => form.resetFields(["userIds", "sharedType"])}>
          <Space direction="vertical" className="w-100">
            {enumValues(DocumentVisibilityType).map((value) => (
              <div key={value}>
                <Radio value={value}>
                  <span className="me-2">{documentVisibilityLabel[value]}</span>
                </Radio>
              </div>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      {visibilityTypeValue === DocumentVisibilityType.Individual && (
        <Form.Item
          label="Employee name"
          name="userIds"
          className="my-4 py-2"
          rules={[ALERTS.required]}
          required={false}
        >
          <InputDropDown
            className="w-100 mt-1"
            placeholder="Please select"
            options={userNameData}
            loading={userLoading}
            optionLabelProp="label"
            showSearch={false}
            onPopupScroll={handleUserDropdownScroll}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>
      )}
      {visibilityTypeValue === DocumentVisibilityType.Many && (
        <div className="my-4 py-2">
          <StyledTitle level={5} className="mb-3">
            Who do you want to share this with?
          </StyledTitle>
          <Form.Item name="shareType" className="mb-0" rules={[ALERTS.required]} required={false}>
            <Radio.Group className="w-100">
              <Space direction="vertical" className="w-100">
                {enumValues(ShareType).map((value) => (
                  <div key={value}>
                    <Radio value={value}>
                      <span className="me-2">{documentShareTypeLabel[value]}</span>
                      {value === ShareType.NewEmployee && (
                        <Popover
                          title={
                            <StyledPopOverTitle>
                              New employees are any employees who join after this date
                            </StyledPopOverTitle>
                          }
                        >
                          <Icon name="info" />
                        </Popover>
                      )}
                    </Radio>
                  </div>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
      )}
      {visibilityTypeValue && (
        <>
          <StyledTitle level={5} className="mb-3">
            Is there an action associated with this document?
          </StyledTitle>
          <Form.Item>
            <SuccessButton htmlType="submit" className="me-2" onClick={() => setButtonClicked("no")} loading={loading}>
              No, share
            </SuccessButton>
            <SuccessButton htmlType="submit" onClick={() => setButtonClicked("yes")} loading={loading}>
              Yes
            </SuccessButton>
          </Form.Item>
        </>
      )}
      <Modal
        width={453}
        title={
          <StyledActionFormModalTitle>
            <Typography.Title level={2} className="mb-0">
              Share /
            </Typography.Title>
            <span>{documentFile?.name}</span>
          </StyledActionFormModalTitle>
        }
        open={isModalVisible}
        footer={null}
        centered
        onCancel={() => {
          hideModal();
          setDestroyModal();
        }}
        destroyOnClose
      >
        <AssociatedAction
          formData={formData}
          loading={loading}
          uploadDocument={(formData) => uploadDocument({ variables: formData })}
        />
      </Modal>

      <FeedBackModal
        type="success"
        open={isFeedbackModalVisible}
        className="shareDocumentFeedBack"
        onCancel={() => {
          hideFeedbackModal();
          onCloseModal();
          setDestroyModal();
        }}
        onOk={() => {
          hideFeedbackModal();
          onCloseModal();
          setDestroyModal();
        }}
        title={
          formData?.sharedWithEmployee
            ? `This document has been shared with employee ${formData?.sharedWithEmployee}`
            : "This document has been shared with employees."
        }
      />
    </Form>
  );
};

export default UploadDocument;

const StyledPopOverTitle = styled.div`
  width: 279px;
  padding: 8px;
  display: block;
  text-align: center;
`;

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 14px;
  }
`;

const StyledActionFormModalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  & span {
    font-weight: 300;
  }
`;
