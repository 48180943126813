import { Table as AntTable } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import Icon from "components/Icon";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";

export enum SortOrderEnum {
  Descend = "descend",
  Ascend = "ascend",
}

export enum SortTypeEnum {
  String = "string",
  Number = "number",
  Date = "date",
}

type Props = {
  title: string;
  sortOrder?: SortOrder;
  className?: string;
};

interface TableColumnCommonDataProps {
  title: string;
  width?: string | number;
  dataIndex: string;
  defaultSortOrder?: SortOrderEnum;
  sortInfo: { sortOrder?: SortOrder; sortedColumn?: React.Key };
  align?: "start" | "end" | "left" | "right" | "center" | "justify" | "match-parent";
}

interface SortingProps {
  type: SortTypeEnum;
  firstData?: string | number | null;
  secondData?: string | number | null;
}

export const Table = styled(AntTable)`
  .ant-table-thead tr th {
    background: white;
  }
`;

export const ColumnTitleWithSortingIcon = ({ title, sortOrder, className }: Props) => (
  <div className={`d-flex gap-2 ${className ?? ""}`}>
    <span className="column-title">{title}</span>
    {sortOrder === SortOrderEnum.Descend ? (
      <Icon name="arrowDown" className="sortIcon" />
    ) : (
      <Icon name="arrowUp" className="sortIcon" />
    )}
  </div>
);

export const tableColumnCommonData = ({
  title: columnTitle,
  dataIndex,
  defaultSortOrder,
  sortInfo,
  width,
  align,
}: TableColumnCommonDataProps) => {
  const commonColumn = {
    title: (
      <ColumnTitleWithSortingIcon
        title={columnTitle}
        sortOrder={
          sortInfo.sortedColumn === dataIndex
            ? sortInfo.sortOrder
            : defaultSortOrder && !sortInfo.sortedColumn
            ? SortOrderEnum.Descend
            : null
        }
      />
    ),
    key: dataIndex,
    dataIndex,
    defaultSortOrder,
    sortOrder:
      sortInfo.sortedColumn === dataIndex
        ? sortInfo.sortOrder
        : defaultSortOrder && !sortInfo.sortedColumn
        ? SortOrderEnum.Descend
        : null,
    width,
    align,
  };

  return commonColumn;
};

export const sortingTableData = ({ type, firstData, secondData }: SortingProps) => {
  switch (type) {
    case SortTypeEnum.String: {
      const fData = firstData ? (firstData as String).toLocaleLowerCase() : "";
      const sData = secondData ? (secondData as String).toLocaleLowerCase() : "";
      return fData > sData ? 1 : fData === sData ? 0 : -1;
    }
    case SortTypeEnum.Number:
      return ((firstData as number) ?? 0) - ((secondData as number) ?? 0);
    case SortTypeEnum.Date:
      return (firstData ? dayjs(firstData).valueOf() : 0) - (secondData ? dayjs(secondData).valueOf() : 0);
    default:
      return 0;
  }
};

export default Table;
