import COLORS from "constants/colors";
import { ReactElement } from "react";
import styled from "styled-components";
import BaseButton, { BaseBtnProps } from "./BaseButton";

const StyledButton = styled(BaseButton)`
  &.btn-primary {
    :hover,
    :focus-visible {
      border: none;
      background-color: ${COLORS.primaryHover} !important;
    }
    :focus-within {
      background-color: ${COLORS.primaryActive};
    }
  }
`;

function PrimaryButton({ className, ...props }: Omit<BaseBtnProps, "type">): ReactElement {
  return <StyledButton type="primary" {...props} className={`btn-primary ${className ?? ""}`} />;
}

export default PrimaryButton;
