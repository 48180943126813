import { useMutation } from "@apollo/client";
import { Form, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DangerButton } from "components/Buttons";
import { InputText } from "components/Inputs";
import { useAuthContext } from "contexts";
import { DestroyCustomerResponse } from "model/AdminSetting";
import { DESTROY_BILLING_CUSTOMER } from "services/graphql/adminSetting";

type Props = {
  onCloseModal: () => void;
};

const EndSubScription = ({ onCloseModal }: Props) => {
  const [form] = useForm();
  const { logout } = useAuthContext();

  const [destroyCustomer] = useMutation<DestroyCustomerResponse>(DESTROY_BILLING_CUSTOMER, {
    onCompleted: (response) => {
      if (response.destroyCustomer.success) {
        onCloseModal();
        logout();
      }
    },
  });

  return (
    <div>
      <div>
        <Typography.Title className="mb-0 mt-3" level={2}>
          WARNING:
        </Typography.Title>
        <Typography.Title className="mt-0 mb-4" level={2}>
          THIS WILL DELETE ALL COMPANY DATA
        </Typography.Title>
        <Typography.Text>
          This will immediately delete all of your company data, including all data that each individual employee has
          uploaded.
        </Typography.Text>
      </div>
      <Form
        layout="vertical"
        form={form}
        className="mt-4"
        onFinish={({ deleteConfirm }) => {
          if (deleteConfirm === "Delete") {
            destroyCustomer();
          }
        }}
      >
        <Form.Item name="deleteConfirm" label="Type “Delete” to confirm">
          <InputText className="w-100" />
        </Form.Item>
        <Form.Item shouldUpdate>
          <DangerButton className="w-100 mt-1" htmlType="submit">
            End subscription and delete all company data
          </DangerButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EndSubScription;
