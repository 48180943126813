import Icon from "components/Icon";
import { ICustomIconList } from "components/Icon/Icon";
import COLORS from "constants/colors";
import { ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

interface Props {
  children: string;
  icon?: ICustomIconList;
  to: string;
  isRowReverse?: boolean;
}

interface LinkProps {
  readonly $isActive: boolean;
}

const StyledLink = styled(Link)<LinkProps>`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: ${(props) => (props.$isActive ? COLORS.activeNavbar : undefined)};
  border-radius: 4px;
  span[role="img"] {
    padding: 1px 16px 0 0;
  }

  font-weight: 400;
  opacity: 0.6;
  ${(props) =>
    props.$isActive &&
    `
  font-weight: 600;
  opacity: 1;
  `}
  &:hover {
    background-color: ${COLORS.activeNavbar};
  }
  &:focus-visible {
    outline: 1px solid;
    outline-offset: -1px;
    transition: outline-offset 0s, outline 0s;
    background-color: ${COLORS.activeNavbar};
  }
`;

function NavLink({ children, to, icon, isRowReverse }: Props): ReactElement {
  const location = useLocation();
  const isActive = to === location.pathname;
  const rowReverseClassName = isRowReverse ? "flex-row-reverse" : undefined;
  return (
    <StyledLink to={to} $isActive={isActive} className={rowReverseClassName} tabIndex={0}>
      {icon && <Icon name={icon} />}
      {children}
    </StyledLink>
  );
}

export default NavLink;

NavLink.defaultProps = {
  icon: null,
  isRowReverse: undefined,
};
