import { Avatar } from "antd";
import Icon from "components/Icon";
import COLORS from "constants/colors";
import styled from "styled-components";

type Props = {
  image?: string | null;
  section: "header" | "table";
};

export const UserAvatar = ({ image, section }: Props) => {
  const size = section === "header" ? 30 : 40;
  const Avatar = section === "header" ? StyledAvatar : TableAvatar;
  return <Avatar src={image} size={size} icon={!image ? <Icon name="userFilled" /> : undefined} alt="avatar" />;
};

export default UserAvatar;

const StyledAvatar = styled(Avatar)`
  background-color: ${COLORS.disabledBgColor};
`;

const TableAvatar = styled(StyledAvatar)`
  line-height: 59px !important;
  margin: 5px 0px;
`;
