import { DatePicker, Popover, Radio, Select, Space, TimePicker, Typography } from "antd";
import Form, { useForm, useWatch } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { SuccessButton } from "components/Buttons";
import Icon from "components/Icon";
import { InputDropDown, InputFile, InputText } from "components/Inputs";
import ALERTS, { fileSize } from "config/alerts";
import TIME_FORMAT from "constants/timeFormat";
import DATE_FORMAT from "constants/dateFormat";
import dayjs, { Dayjs } from "dayjs";
import {
  DayTypes,
  DayHalfTypes,
  dayTypeLabels,
  LeaveFormType,
  leaveTypeLabels,
  LeaveRequestsReason,
  LeaveRequestArg,
  LeaveRequestFormData,
  ModifyLeaveRequestArg,
  LeaveRequestInitialValue,
  EstimateLeaveResponse,
  LeaveRequestResponse,
  DestroyLeaveRequestResponse,
  UpdateLeaveRequestResponse,
} from "model/Leave";
import React, { useState } from "react";
import styled from "styled-components";
import { enumValues, isPromise } from "utils/misc";
import { useAuthContext } from "contexts";
import { PageInfo } from "model/Common";
import { useParams } from "react-router-dom";
import { FetchEmployeeResponse } from "model/Employee";
import { FETCH_USER } from "services/graphql/employee";
import TABLE from "constants/table";
import { useQuery, useMutation, FetchResult } from "@apollo/client";
import { ESTIMATE_LEAVE_REQUEST } from "services/graphql/leave";
import { useNotify } from "services/api";
import COLORS from "constants/colors";
import { DefaultOptionType } from "antd/es/select";
import { getLeaveBalanceCalculation, isShowingAnnualBalance } from "../employee/leave";

type Props = {
  initialValues?: LeaveRequestInitialValue;
  leaveFormType: "record" | "request";
  onFieldsChange: () => void;
  onSubmit: (
    values: LeaveRequestArg,
    btnClicked?: string,
  ) =>
    | Promise<FetchResult<LeaveRequestResponse>>
    | Promise<FetchResult<DestroyLeaveRequestResponse>>
    | Promise<FetchResult<UpdateLeaveRequestResponse>>
    | void;
  loading?: boolean;
  setUserIdForRecordLeave?: (value: string) => void;
};

const LeaveForm = ({
  initialValues,
  leaveFormType,
  onFieldsChange,
  onSubmit,
  loading,
  setUserIdForRecordLeave,
}: Props) => {
  const [form] = useForm();
  const [attachment, setAttachment] = useState<File | null>(null);
  const [isFiledValueChanged, setIsFieldValueChanged] = useState<boolean>(false);
  const { user } = useAuthContext();
  const { id } = useParams();
  const [dayType, setDayType] = useState<DayTypes | undefined>(initialValues?.dayCompleteness);
  const [buttonClicked, setButtonClicked] = useState<string>();
  const fromDate: Dayjs = useWatch("fromDate", form);
  const reason: LeaveRequestsReason = useWatch("reason", form);
  const toDate: Dayjs = useWatch("toDate", form);
  const [data, setData] = useState<DefaultOptionType[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const notify = useNotify();

  const [estimateLeaveRequest, { data: estimateData }] = useMutation<EstimateLeaveResponse>(ESTIMATE_LEAVE_REQUEST, {
    onCompleted: (response) => {
      if (response?.estimateLeaveRequest) {
        if (response.estimateLeaveRequest.errors) {
          notify.error(response.estimateLeaveRequest?.errors?.fullMessages);
        } else {
          const { estimatedDays } = response.estimateLeaveRequest;
          form.setFieldsValue({
            numberOfDays: estimatedDays?.toFixed(Number.isInteger(estimatedDays) ? 0 : 3) || 0,
          });
        }
      }
    },
  });

  const getEstimateLeaveRequest = () => {
    const formValue = form.getFieldsValue();
    if (formValue.fromDate && formValue.toDate) {
      const args = {
        ...formValue,
        dayCompleteness: formValue.dayHalfType?.toString() || formValue.dayCompleteness?.toString(),
        fromDate: dayjs(formValue.fromDate).format(DATE_FORMAT.isoFormat),
        toDate: dayjs(formValue.toDate).format(DATE_FORMAT.isoFormat),
        fromTime: formValue.fromTime ? dayjs(formValue.fromTime).format(TIME_FORMAT.timeFormatWith24Hour) : undefined,
        toTime: formValue.toTime ? dayjs(formValue.toTime).format(TIME_FORMAT.timeFormatWith24Hour) : undefined,
      } as ModifyLeaveRequestArg;
      ["numberOfDays", "dayHalfType", "employeeNote", "user"].map(
        (item) => delete args[item as keyof ModifyLeaveRequestArg],
      );
      if (
        dayjs(args.fromDate) &&
        dayjs(args.toDate) &&
        !dayjs(args.fromDate).startOf("day").isSame(dayjs(args.toDate).startOf("day"))
      ) {
        args.dayCompleteness = null;
      }
      if (isShowingAnnualBalance(formValue.fromDate, formValue.toDate)) {
        estimateLeaveRequest({
          variables: { ...args, userId: id || formValue?.user || user?.id, leaveRequestId: initialValues?.id },
        });
      }
    }
  };

  const onChange = () => {
    const dayTypeField = form.getFieldValue("dayCompleteness");
    const dayHalfType = form.getFieldValue("dayHalfType");
    const fromDate: Dayjs = form.getFieldValue("fromDate");
    const toDate: Dayjs = form.getFieldValue("toDate");
    const reason: LeaveRequestsReason = form.getFieldValue("reason");
    setDayType(dayTypeField);
    form.setFieldsValue({ numberOfDays: 0 });

    if (reason && fromDate && toDate && !fromDate.startOf("day").isSame(toDate.startOf("day"))) {
      form.setFieldsValue({
        fromTime: undefined,
        toTime: undefined,
      });
      setDayType(undefined);
      getEstimateLeaveRequest();
    }

    if (reason && fromDate && toDate && dayTypeField === DayTypes.Half && dayHalfType) {
      form.setFieldsValue({ fromTime: undefined, toTime: undefined });
      getEstimateLeaveRequest();
    }

    switch (dayTypeField) {
      case DayTypes.Full: {
        if (reason && fromDate && toDate && fromDate.startOf("day").isSame(toDate.startOf("day"))) {
          form.setFieldsValue({
            dayHalfType: undefined,
            fromTime: undefined,
            toTime: undefined,
          });
          getEstimateLeaveRequest();
        }
        break;
      }
      case DayTypes.Custom: {
        const fromTime = form.getFieldValue("fromTime");
        const toTime = form.getFieldValue("toTime");

        if (
          reason &&
          fromDate &&
          toDate &&
          fromTime &&
          toTime &&
          fromDate.startOf("day").isSame(toDate.startOf("day"))
        ) {
          form.setFieldsValue({
            dayHalfType: undefined,
          });
          getEstimateLeaveRequest();
        }
        break;
      }
      default:
    }
  };

  const { refetch: fetchUserList, loading: userLoading } = useQuery<FetchEmployeeResponse>(FETCH_USER, {
    variables: {
      first: !id ? TABLE.rowsPerPage : undefined,
      fetchBirthdaysThisWeek: false,
      fetchWorkAnniversaryThisWeek: false,
      userIds: id && [id],
    },
    onCompleted: (response) => {
      const options = response.users.nodes.map((user) => ({
        value: user.id,
        label: user.name,
      }));
      setData((d) => [...d, ...(options ?? [])]);
      if (id) {
        form.setFieldValue("user", options[0].label);
      }
      if (response.users.pageInfo) setPageInfo(response.users.pageInfo);
    },
    skip: leaveFormType !== LeaveFormType.Record,
  });

  const onFinish = (values: LeaveRequestFormData) => {
    const fieldValues = values;
    delete fieldValues.attachment;
    const args = {
      ...values,
      leaveRequestId: initialValues?.id || "",
      dayCompleteness: values.dayHalfType?.toString() || values.dayCompleteness?.toString(),
      fromDate: dayjs(values.fromDate).format(DATE_FORMAT.isoFormat),
      toDate: dayjs(values.toDate).format(DATE_FORMAT.isoFormat),
      fromTime: values.fromTime ? dayjs(values.fromTime).format(TIME_FORMAT.timeFormatWith24Hour) : undefined,
      toTime: values.toTime ? dayjs(values.toTime).format(TIME_FORMAT.timeFormatWith24Hour) : undefined,
      attachment,
    } as ModifyLeaveRequestArg;
    ["numberOfDays", "dayHalfType"].map((item) => delete args[item as keyof ModifyLeaveRequestArg]);

    if (
      dayjs(args.fromDate) &&
      dayjs(args.toDate) &&
      !dayjs(args.fromDate).startOf("day").isSame(dayjs(args.toDate).startOf("day"))
    ) {
      args.dayCompleteness = null;
    }

    const fn = buttonClicked !== "create" ? onSubmit({ ...args }, buttonClicked) : onSubmit({ ...args });
    if (isPromise(fn)) {
      fn.then(() => {
        setAttachment(null);
      });
    } else {
      setAttachment(null);
    }
  };

  const handleDropdownScroll = (e: React.UIEvent<EventTarget>) => {
    if (
      (e.target as HTMLElement).scrollTop + (e.target as HTMLElement).clientHeight ===
        (e.target as HTMLElement).scrollHeight &&
      !loading &&
      pageInfo?.hasNextPage &&
      pageInfo?.endCursor
    ) {
      fetchUserList({ after: pageInfo?.endCursor });
    }
  };

  return (
    <Form
      layout="vertical"
      className="p-0"
      form={form}
      autoComplete="off"
      onValuesChange={() => {
        setIsFieldValueChanged(true);
        onFieldsChange();
      }}
      onFinish={(values: LeaveRequestFormData) => {
        if (buttonClicked === "delete") {
          onFinish(values);
        }
        if (
          (buttonClicked === "create" || buttonClicked === "update") &&
          isFiledValueChanged &&
          !estimateData?.estimateLeaveRequest.errors
        ) {
          onFinish(values);
        }
      }}
      initialValues={initialValues}
    >
      {leaveFormType === LeaveFormType.Record && (
        <FormItem label="Employee name" name="user" rules={[ALERTS.required]}>
          {!id ? (
            <Select
              options={data}
              loading={userLoading}
              placeholder="Please select"
              className="w-100"
              onPopupScroll={handleDropdownScroll}
              onChange={(value) => {
                onChange();
                if (setUserIdForRecordLeave) {
                  setUserIdForRecordLeave(value);
                }
              }}
            />
          ) : (
            <InputText />
          )}
        </FormItem>
      )}
      <FormItem label="Leave type" name="reason" rules={[ALERTS.required]}>
        <InputDropDown
          options={enumValues(LeaveRequestsReason).map((l) => ({ label: leaveTypeLabels[l], value: l }))}
          placeholder="Please select"
          className="w-100"
          getPopupContainer={(trigger) => trigger.parentNode}
          onChange={() => {
            if (fromDate && toDate) {
              getEstimateLeaveRequest();
            }
          }}
        />
      </FormItem>
      <div className="d-flex gap-3 align-items-center">
        <FormItem label="Start date" name="fromDate" className="w-100" rules={[ALERTS.required]}>
          <DatePicker
            className="w-100"
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            onChange={() => {
              form.setFieldsValue({
                dayCompleteness: undefined,
                dayHalfType: undefined,
              });
              onChange();
            }}
            changeOnBlur
            format={DATE_FORMAT.datePickerAllowDate}
            disabledDate={(current) => {
              if (toDate) {
                return toDate.add(0, "day") <= current;
              }
              return false;
            }}
          />
        </FormItem>
        <FormItem label="End date" name="toDate" className="w-100" rules={[ALERTS.required]}>
          <DatePicker
            className="w-100"
            data-testid="toDate"
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            onChange={() => {
              form.setFieldsValue({
                dayCompleteness: undefined,
                dayHalfType: undefined,
              });
              onChange();
            }}
            changeOnBlur
            format={DATE_FORMAT.datePickerAllowDate}
            disabledDate={(current) => {
              if (fromDate) {
                return fromDate.add(0, "day") >= current;
              }
              return false;
            }}
          />
        </FormItem>
      </div>
      {fromDate && toDate && fromDate.startOf("day").isSame(toDate.startOf("day")) && (
        <FormItem name="dayCompleteness" className="flex-grow-1" rules={[ALERTS.required]}>
          <Radio.Group
            buttonStyle="solid"
            className="w-100 d-flex text-center"
            onChange={() => {
              form.validateFields(["reason"]);
              onChange();
            }}
          >
            {enumValues(DayTypes).map((day) => (
              <Radio.Button key={day} value={day} className="w-100">
                {dayTypeLabels[day]}
              </Radio.Button>
            ))}
          </Radio.Group>
        </FormItem>
      )}
      {dayType === DayTypes.Half && (
        <FormItem name="dayHalfType" className="flex-grow-1" rules={[ALERTS.required]}>
          <Radio.Group buttonStyle="solid" className="w-100 d-flex text-center" onChange={onChange}>
            {enumValues(DayHalfTypes).map((type) => (
              <StyledRadioButton key={type} value={type}>
                {dayTypeLabels[type]}
              </StyledRadioButton>
            ))}
          </Radio.Group>
        </FormItem>
      )}

      {dayType === DayTypes.Custom && (
        <div className="d-flex gap-3 align-items-center">
          <FormItem label="From" name="fromTime" className="w-100" rules={[ALERTS.required]}>
            <TimePicker
              className="w-100"
              minuteStep={30}
              showNow={false}
              changeOnBlur
              showSecond={false}
              format={TIME_FORMAT.timeWithPeriodTertiary}
              use12Hours
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              onChange={onChange}
            />
          </FormItem>
          <FormItem label="To" name="toTime" className="w-100" rules={[ALERTS.required]}>
            <TimePicker
              className="w-100"
              minuteStep={30}
              showNow={false}
              changeOnBlur
              showSecond={false}
              format={TIME_FORMAT.timeWithPeriodTertiary}
              use12Hours
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              onChange={onChange}
            />
          </FormItem>
        </div>
      )}
      <FormItem shouldUpdate label="Number of days" name="numberOfDays">
        <InputText disabled />
      </FormItem>
      <FormItem label="Notes" name="employeeNote">
        <InputText.TextArea />
      </FormItem>
      <FormItem label="Add attachment" name="attachment" rules={[fileSize(attachment)]}>
        <InputFile
          onChange={(e) => {
            if (e.target.files) {
              setAttachment(e.target.files[0]);
            }
          }}
        />
      </FormItem>
      <FormItem shouldUpdate>
        {({ getFieldValue }) => {
          if (
            isShowingAnnualBalance(getFieldValue("fromDate"), getFieldValue("toDate"), getFieldValue("reason")) &&
            (estimateData?.estimateLeaveRequest || (initialValues?.usedDays && initialValues?.accumulativeBalance))
          ) {
            const { annualBalance, annualBalanceFollowing } = estimateData?.estimateLeaveRequest
              ? getLeaveBalanceCalculation({
                  estimateData: {
                    totalBalance: estimateData?.estimateLeaveRequest.totalBalance,
                    estimatedDays: estimateData?.estimateLeaveRequest.estimatedDays,
                    usedDays: estimateData?.estimateLeaveRequest.usedDays,
                    usedDaysBeforeChange: estimateData?.estimateLeaveRequest.usedDaysBeforeChange,
                  },
                  numberOfDays: getFieldValue("numberOfDays"),
                })
              : getLeaveBalanceCalculation({
                  initialValues: {
                    accumulativeBalance: initialValues?.accumulativeBalance,
                    precedingUsedHolidayDays: initialValues?.precedingUsedHolidayDays,
                    usedDays: initialValues?.usedDays || 0,
                  },
                  numberOfDays: getFieldValue("numberOfDays"),
                });
            return (
              <InfoContainer>
                <Space>
                  Annual Balance:
                  <Typography.Text strong>{(annualBalance ?? 0).toFixed(2)}</Typography.Text>
                  <Popover
                    content={
                      <StyledPopoverSpan>
                        This is your remaining annual leave for this full calendar year
                      </StyledPopoverSpan>
                    }
                  >
                    <Icon name="info" inline />
                  </Popover>
                </Space>
                <Space>
                  Annual Balance following request:
                  <Typography.Text strong>{(annualBalanceFollowing ?? 0).toFixed(2)}</Typography.Text>
                </Space>
              </InfoContainer>
            );
          }
          return null;
        }}
      </FormItem>
      <FormItem>
        {initialValues && leaveFormType !== LeaveFormType.Record ? (
          <div className="d-flex justify-content-between gap-3">
            <StyledSuccessButton htmlType="submit" onClick={() => setButtonClicked("update")} loading={loading}>
              Send updated request
            </StyledSuccessButton>
            <StyledSuccessButton htmlType="submit" onClick={() => setButtonClicked("delete")} loading={loading}>
              Withdraw leave request
            </StyledSuccessButton>
          </div>
        ) : (
          <SuccessButton
            className="w-100"
            htmlType="submit"
            onClick={() => {
              if (isFiledValueChanged) {
                setButtonClicked("create");
              }
            }}
            loading={loading}
          >
            {leaveFormType === LeaveFormType.Record ? "Record Leave" : "Send request"}
          </SuccessButton>
        )}
      </FormItem>
    </Form>
  );
};

export default LeaveForm;
const StyledRadioButton = styled(Radio.Button)`
  width: 33.5%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background: ${COLORS.infoContainerBgColor};
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 1rem;
`;

const StyledSuccessButton = styled(SuccessButton)`
  & {
    padding: 6px;
    margin-top: 8px;
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
`;

const StyledPopoverSpan = styled.span`
  width: 280px;
  display: block;
  text-align: center;
`;
