import PERMISSION from "config/permission";
import CompanyPage from "features/company/CompanyPage";
import Notification from "features/notification/Notification";
import useCheckPermission from "hooks/useCheckPermission";
import { ReactElement } from "react";

function HomePage(): ReactElement {
  const { companies } = PERMISSION;
  const { hasCompanies } = useCheckPermission({ companies });

  return <div className="h-100">{!hasCompanies ? <Notification /> : <CompanyPage />}</div>;
}

export default HomePage;
