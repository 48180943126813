import { DatePicker, Form, Radio, Typography } from "antd";
import { InputText } from "components/Inputs";
import { useForm, useWatch } from "antd/lib/form/Form";
import { SuccessButton } from "components/Buttons";
import ALERTS from "config/alerts";
import DATE_FORMAT from "constants/dateFormat";
import { UploadDocumentFormData } from "model/Document";
import styled from "styled-components";
import dayjs from "dayjs";

type Props = {
  formData?: UploadDocumentFormData;
  loading: boolean;
  uploadDocument: (variables: UploadDocumentFormData) => void;
};

const AssociatedAction = ({ formData, loading, uploadDocument }: Props) => {
  const [form] = useForm();
  const isDate = useWatch("isDate", form);

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{ action: "For example, complete associated training or read document carefully", isDate: true }}
      onFinish={({ actionDescription, actionDeadlineDate }) => {
        if (formData) {
          uploadDocument({
            ...formData,
            ...{
              actionDescription,
              actionDeadlineDate: actionDeadlineDate
                ? dayjs(actionDeadlineDate).format(DATE_FORMAT.isoFormat)
                : undefined,
            },
          });
        }
      }}
    >
      <Form.Item name="actionDescription" label="Describe action" className="mb-4">
        <InputText.TextArea autoSize={{ minRows: 3 }} />
      </Form.Item>
      <StyledNotifyTitle level={5}>Is there a date by which this action should be completed?</StyledNotifyTitle>
      <Form.Item name="isDate" className="text-center mb-4">
        <Radio.Group buttonStyle="solid" className="d-flex">
          <Radio.Button value className="w-100">
            Yes
          </Radio.Button>
          <Radio.Button value={false} className="w-100">
            No
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      {isDate && (
        <Form.Item name="actionDeadlineDate" rules={[ALERTS.required]} className="mb-4">
          <DatePicker
            className="w-100"
            changeOnBlur
            format={DATE_FORMAT.datePickerAllowDate}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          />
        </Form.Item>
      )}
      <Form.Item>
        <SuccessButton htmlType="submit" loading={loading}>
          Share
        </SuccessButton>
      </Form.Item>
    </Form>
  );
};

export default AssociatedAction;

const StyledNotifyTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 14px;
    margin-bottom: 18px;
  }
`;
