import { useDepartments, useJobTitles, useLocations } from "hooks";
import { LabelRefetch } from "model/AdminSetting";
import { useRef } from "react";
import {
  DESTROY_DEPARTMENT,
  DESTROY_JOB_TITLE,
  DESTROY_LOCATION,
  FETCH_DEPARTMENT,
  FETCH_JOB_TITLE,
  FETCH_LOCATION,
  UPDATE_DEPARTMENT,
  UPDATE_JOB_TITLE,
  UPDATE_LOCATION,
} from "services/graphql/adminSetting";
import { useNotify } from "services/api";
import Labels from "./Labels/Labels";

const LabelsTab = () => {
  const notify = useNotify();
  const departmentRef = useRef<LabelRefetch>(null);
  const locationRef = useRef<LabelRefetch>(null);
  const jobTitleRef = useRef<LabelRefetch>(null);

  const { createDepartment } = useDepartments({
    notify,
    onCompleted: () => {
      departmentRef.current?.refetch();
    },
  });

  const { createLocation } = useLocations({
    notify,
    onCompleted: () => {
      locationRef.current?.refetch();
    },
  });

  const { createJobTitle } = useJobTitles({
    notify,
    onCompleted: () => {
      jobTitleRef.current?.refetch();
    },
  });

  return (
    <div className="d-flex flex-column gap-4">
      <Labels
        title="Departments"
        btnText="Add department"
        getQuery={FETCH_DEPARTMENT}
        onAdd={(name) => createDepartment({ name })}
        deleteMutation={DESTROY_DEPARTMENT}
        editMutation={UPDATE_DEPARTMENT}
        ref={departmentRef}
      />
      <Labels
        title="Locations"
        btnText="Add location"
        getQuery={FETCH_LOCATION}
        onAdd={(name) => createLocation({ name })}
        deleteMutation={DESTROY_LOCATION}
        editMutation={UPDATE_LOCATION}
        ref={locationRef}
      />
      <Labels
        title="Job titles"
        btnText="Add job title"
        getQuery={FETCH_JOB_TITLE}
        onAdd={(name) => createJobTitle({ name })}
        deleteMutation={DESTROY_JOB_TITLE}
        editMutation={UPDATE_JOB_TITLE}
        ref={jobTitleRef}
      />
    </div>
  );
};

export default LabelsTab;
