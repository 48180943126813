import { Modal, TabsProps } from "antd";
import { Tabs } from "components/Tabs";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import { useParams } from "react-router-dom";
import { useAuthContext } from "contexts";
import { useRef, useState } from "react";
import { useModal } from "components/modal";
import { PrimaryButton } from "components/Buttons";
import styled from "styled-components";
import { DocumentRefetch } from "model/Document";
import { isManager } from "model/User";
import { includesInArray } from "utils";
import { EmployeeId } from "model/Employee";
import LeaveTab from "./leave/LeaveTab";
import OverviewTab from "./overview/OverviewTab";
import DocumentTab from "./documents/DocumentTab";
import UploadDocumentForm from "./documents/UploadDocumentForm";

const EmployeeDetailTabs = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const userId = id || (user?.id as EmployeeId);
  const isMyProfile = userId === user?.id;
  const [tabActiveKey, setTabActiveKey] = useState<string>();
  const employeeDocumentTableRef = useRef<DocumentRefetch>(null);

  const { employeeOverViewTab, employeeLeaveTabTable, employeeLeaveOverview, employeeLeaveTab, documentTab } =
    PERMISSION;
  const {
    hasEmployeeOverViewTab,
    hasEmployeeLeaveTabTable,
    hasEmployeeLeaveOverview,
    hasEmployeeLeaveTab,
    hasDocumentTab,
  } = useCheckPermission({
    employeeOverViewTab,
    employeeLeaveTabTable,
    employeeLeaveOverview,
    employeeLeaveTab,
    documentTab,
  });

  const {
    isModalVisible: isUploadDocModalVisible,
    hideModal: hideUploadDocModal,
    showModal: showUploadDocModal,
  } = useModal();

  const items: TabsProps["items"] = [
    {
      key: "overview",
      label: `Overview`,
      children: <OverviewTab />,
      show: hasEmployeeOverViewTab || !id,
    },
    {
      key: "leave",
      label: !id ? `My leave` : `Leave `,
      children: <LeaveTab />,
      show:
        isMyProfile ||
        (isManager(user) &&
          includesInArray(user?.managedUserIds, id) &&
          (hasEmployeeLeaveOverview || hasEmployeeLeaveTabTable)) ||
        hasEmployeeLeaveTab,
    },
    {
      key: "documents",
      label: !id ? `My documents` : `Documents`,
      children: <DocumentTab ref={employeeDocumentTableRef} />,
      show: hasDocumentTab || !id,
    },
  ].filter((item) => item.show);

  return (
    <StyledWrapper>
      {tabActiveKey === "documents" && id && (
        <StyledPrimaryButton onClick={showUploadDocModal}>Upload a document</StyledPrimaryButton>
      )}
      <StyledTab defaultActiveKey="overview" items={items} onTabClick={(key) => setTabActiveKey(key)} />
      <Modal
        title="Upload a document"
        open={isUploadDocModalVisible}
        onCancel={hideUploadDocModal}
        footer={null}
        width={395}
        centered
        destroyOnClose
      >
        <UploadDocumentForm onCancel={hideUploadDocModal} employeeDocumentTableRef={employeeDocumentTableRef} />
      </Modal>
    </StyledWrapper>
  );
};

export default EmployeeDetailTabs;

const StyledWrapper = styled.div`
  position: relative;
  margin-top: 43px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  position: absolute;
  right: 0;
  top: -18px;
  z-index: 1;
`;

const StyledTab = styled(Tabs)`
  .ant-tabs-nav-list {
    margin-left: 4px;
  }
`;
