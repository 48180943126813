import { Table, Typography } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { ColumnsType } from "antd/lib/table";
import { DefaultButton } from "components/Buttons";
import Icon from "components/Icon";
import { tableColumnCommonData, sortingTableData, SortTypeEnum, SortOrderEnum } from "components/Table";
import DATE_FORMAT from "constants/dateFormat";
import dayjs from "dayjs";
import {
  DocumentRefetch,
  DocumentVisibilityType,
  Documents,
  FetchDocumentsResponse,
  ProfileDocumentResponse,
  documentSharedWith,
} from "model/Document";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useParams } from "react-router-dom";
import { FETCH_DOCUMENTS, FETCH_PROFILE_DOCUMENT } from "services/graphql/document";
import { useQuery } from "@apollo/client";
import { useVT } from "virtualizedtableforantd4";
import { PageInfo } from "model/Common";
import TABLE from "constants/table";
import { useAuthContext } from "contexts";
import { downloadLink } from "utils";
import styled from "styled-components";
import { getFilenameFromUrl, removeExtensionFromName } from "../../leave";

type Props = { tableFor: "companyDocuments" | "employeeDocuments" | "allDocuments" };

const DocumentTable = forwardRef<DocumentRefetch, Props>(({ tableFor }, ref) => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const userId = id || user?.id;
  const isMyProfile = userId === user?.id;

  const [data, setDocumentsData] = useState<Documents[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Documents>>({});

  const { loading, refetch } = useQuery<FetchDocumentsResponse>(FETCH_DOCUMENTS, {
    variables: { first: TABLE.rowsPerPage, after: "", userId },
    onCompleted: (response) => {
      let documents: Documents[];
      if (tableFor === "employeeDocuments") {
        documents =
          response.documents.nodes.filter(
            (d: Documents) => d.visibilityType === DocumentVisibilityType.Individual && { ...d },
          ) ?? [];
      } else {
        documents =
          response.documents.nodes.filter(
            (d: Documents) => d.visibilityType === DocumentVisibilityType.Many && { ...d },
          ) ?? [];
      }
      setDocumentsData((d) => [...d, ...documents]);
      if (response.documents.pageInfo) setPageInfo(response.documents.pageInfo);
    },
    fetchPolicy: "no-cache",
    skip: isMyProfile,
  });

  const { loading: profileDocumentsLoading } = useQuery<ProfileDocumentResponse>(FETCH_PROFILE_DOCUMENT, {
    variables: { notActioned: false },
    onCompleted: (response) => {
      const documents: Documents[] = response.profileDocuments.documents.map((d: Documents) => ({ ...d })) ?? [];
      setDocumentsData((d) => [...d, ...documents]);
    },
    fetchPolicy: "no-cache",
    skip: !isMyProfile,
  });

  const [vt] = useVT(
    () => ({
      onScroll: async ({ isEnd }) => {
        if (isEnd && pageInfo?.hasNextPage && pageInfo?.endCursor) {
          refetch({ first: TABLE.rowsPerPage, after: pageInfo?.endCursor, userId });
        }
      },
      offset: 80,
      scroll: {
        y: TABLE.tableDefaultHeight,
      },
      debug: false,
    }),
    [data, pageInfo],
  );

  useImperativeHandle(ref, () => ({
    documentRefetch() {
      if (tableFor === "employeeDocuments") {
        setDocumentsData([]);
        refetch({ first: TABLE.rowsPerPage, after: "", userId });
      }
    },
  }));

  const columns: ColumnsType<Documents> = [
    {
      ...tableColumnCommonData({
        title: "Document name",
        dataIndex: "attachmentName",
        defaultSortOrder: SortOrderEnum.Descend,
        width: 500,
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.String, firstData: a!.attachmentName, secondData: b!.attachmentName }),
      render: (_, { attachmentName }) => (
        <StyledDocumentName level={3} className="m-0 d-flex gap-2 py-2">
          <Icon name="document" />
          {removeExtensionFromName(attachmentName)}
        </StyledDocumentName>
      ),
    },
    ...(isMyProfile
      ? ([
          {
            ...tableColumnCommonData({
              title: "Shared with",
              dataIndex: "visibilityType",
              sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
            }),
            render: (_, { visibilityType }) => documentSharedWith[visibilityType],
            sorter: (a, b) =>
              sortingTableData({
                type: SortTypeEnum.String,
                firstData: documentSharedWith[a.visibilityType],
                secondData: documentSharedWith[b.visibilityType],
              }),
          },
        ] as ColumnsType<Documents>)
      : []),
    {
      ...tableColumnCommonData({
        title: "Date added",
        dataIndex: "dateAdded",
        width: !isMyProfile ? "45%" : undefined,
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { dateAdded }) => (dateAdded ? dayjs(dateAdded).format(DATE_FORMAT.fullDateDefault) : "N/A"),
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.dateAdded, secondData: b.dateAdded }),
    },
    {
      title: "",
      dataIndex: "Download",
      width: 100,
      align: "right",
      render: (_, { attachmentUrl }) => (
        <DefaultButton
          onClick={(event) => {
            event.stopPropagation();
            downloadLink(attachmentUrl, getFilenameFromUrl(attachmentUrl));
          }}
        >
          Download
        </DefaultButton>
      ),
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={isMyProfile ? profileDocumentsLoading : loading}
        components={vt}
        rowKey="id"
        scroll={{
          scrollToFirstRowOnChange: false,
          y: TABLE.tableDefaultHeight,
        }}
        sortDirections={TABLE.tableSortDirections}
        onChange={(_pagination, _filter, sorter) => setSortedInfo(sorter as SorterResult<Documents>)}
      />
    </>
  );
});

export default DocumentTable;

const StyledDocumentName = styled(Typography.Title)`
  font-size: 14px;
`;
