import { Checkbox } from "antd";
import { CheckboxGroupProps } from "antd/es/checkbox";
import COLORS from "constants/colors";
import styled from "styled-components";

const InputCheckboxButton = ({ ...props }: CheckboxGroupProps) => <StyledCheckBox {...props} />;

export default InputCheckboxButton;

const StyledCheckBox = styled(Checkbox.Group)`
  &.ant-checkbox-group {
    flex-wrap: unset;
    column-gap: unset;
  }
  & .ant-checkbox-wrapper {
    width: 100%;
    position: relative;
    .ant-checkbox {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      z-index: -1;
      opacity: 0;
    }
  }
  & .ant-checkbox-wrapper span:last-child {
    outline: 1px solid ${COLORS.inputBorderColor};
  }
  & .ant-checkbox-wrapper span {
    padding: 0 16px;
    margin-left: 1px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 50px;
  }
  & .ant-checkbox-wrapper:first-child span {
    border-radius: 4px 0px 0px 4px;
  }
  & .ant-checkbox-wrapper:last-child span {
    border-radius: 0px 4px 4px 0px;
  }
  & .ant-checkbox-wrapper span:hover,
  & .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span {
    outline: 1px solid ${COLORS.radioBorder};
    transition: 0.3s;
  }
  & .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    z-index: 1;
  }
  & .ant-checkbox-wrapper span:hover {
    background: ${COLORS.radioBgHover};
    z-index: 1;
  }
  & .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span::before {
    position: absolute;
    width: 1px;
    height: 100%;
    content: "";
  }
  & .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span {
    background: ${COLORS.radioBgColor};
  }
  & .ant-checkbox-wrapper .ant-checkbox:has(.ant-checkbox-input:focus-visible) + span {
    outline: 1px solid ${COLORS.outlineColor};
    z-index: 1;
    transition: outline-offset 0s, outline 0s;
  }
  & .ant-checkbox-wrapper:has(.ant-checkbox .ant-checkbox-input:focus-visible) {
    z-index: 2;
  }
`;
