import { useEffect } from "react";

type Props = {
  hasNextPage?: boolean;
  endCursor?: string;
  loading?: boolean;
  callback: () => void;
};

export const useWindowInfiniteScroll = ({ hasNextPage, endCursor, loading, callback }: Props) => {
  const mainLayout = document.getElementById("content_layout") as HTMLElement;
  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = mainLayout;
    if (hasNextPage && endCursor && !loading && scrollTop + clientHeight >= scrollHeight) {
      callback();
    }
  };

  useEffect(() => {
    if (mainLayout) mainLayout.addEventListener("scroll", handleScroll);

    return () => {
      if (mainLayout) mainLayout.removeEventListener("scroll", handleScroll);
    };
  });
};

export default useWindowInfiniteScroll;
