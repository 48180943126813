import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { Form, Table, Tooltip } from "antd";
import { DefaultButton, IconButton, PrimaryButton } from "components/Buttons";
import { InputText } from "components/Inputs";
import styled from "styled-components";
import COLORS from "constants/colors";
import {
  DepartmentResponse,
  EditableCellProps,
  ExtendedColumnsType,
  UpdateDepartmentResponse,
  Tag,
  JobTitleResponse,
  LabelRefetch,
  LocationResponse,
  UpdateLocationResponse,
  UpdateJobTitleResponse,
  DestroyDepartment,
  DestroyJobTitle,
  DestroyLocation,
} from "model/AdminSetting";
import ALERTS from "config/alerts";
import { DocumentNode, useMutation, useQuery } from "@apollo/client";
import { useVT } from "virtualizedtableforantd4";
import TABLE from "constants/table";
import { PageInfo } from "model/Common";
import { usePreventUnsavedForm } from "contexts/PreventUnsavedFormProvider";
import LABEL from "constants/label";
import { ConfirmModal, FeedBackModal } from "components/modal";
import useFormModal from "hooks/useFormModal";
import { useNotify } from "services/api";

type Props = {
  isAddNewData: boolean;
  getQuery: DocumentNode;
  editMutation: DocumentNode;
  deleteMutation: DocumentNode;
  setIsEditLabel: (isEdit: boolean) => void;
  title: string;
};

const EditableCell: React.FC<EditableCellProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { editing, dataIndex, title, inputType, record, index, children, ...restProps } = props;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} className="m-0" rules={[ALERTS.required, ALERTS.characterLength]}>
          <StyledInputText className="shadow-none" autoFocus data-testid="editLabel" />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const LabelTable = forwardRef<LabelRefetch, Props>(
  ({ isAddNewData, getQuery, setIsEditLabel, editMutation, title, deleteMutation }, ref) => {
    const [form] = Form.useForm<Tag>();
    const [editingKey, setEditingKey] = useState<string>("");
    const [data, setData] = useState<Tag[]>([]);
    const [pageInfo, setPageInfo] = useState<PageInfo>();
    const notify = useNotify();
    const [isFieldsChanged, setIsFieldsChanged] = useState<boolean>(false);
    const [initialEditLabel, setInitialEditLabel] = useState<string>("");
    const [deleteId, setDeleteId] = useState<string>();
    const [skipQuery, setSkipQuery] = useState<boolean>(false);
    const { showConfirmModal } = usePreventUnsavedForm();
    const { isModalVisible, hideModal, showModal } = useFormModal();
    const {
      isModalVisible: isDestroyConfirmModalVisible,
      hideModal: hideDestroyConfirmModal,
      showModal: showDestroyConfirmModal,
    } = useFormModal();

    const { refetch, loading } = useQuery<DepartmentResponse | LocationResponse | JobTitleResponse>(getQuery, {
      variables: { first: LABEL.rowsPerPage },
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        const labelResponse =
          title === LABEL.department
            ? (response as DepartmentResponse).departments
            : title === LABEL.location
            ? (response as LocationResponse).locations
            : (response as JobTitleResponse).jobTitles;
        setData((d) => [...d, ...(labelResponse.nodes ?? [])]);
        if (labelResponse.pageInfo) setPageInfo(labelResponse.pageInfo);
      },
      skip: skipQuery,
    });

    const [updateLabel] = useMutation<UpdateDepartmentResponse | UpdateLocationResponse | UpdateJobTitleResponse, Tag>(
      editMutation,
      {
        onCompleted: (response) => {
          const labelResponse =
            title === LABEL.department
              ? (response as UpdateDepartmentResponse).updateDepartment
              : title === LABEL.location
              ? (response as UpdateLocationResponse).updateLocation
              : (response as UpdateJobTitleResponse).updateJobTitle;

          const successMessage =
            title === LABEL.department
              ? "Department Updated successfully."
              : title === LABEL.location
              ? "Location Updated successfully."
              : "Job title Updated successfully.";

          if (labelResponse) {
            if (labelResponse?.errors?.fullMessages?.length) {
              labelResponse.errors.fullMessages.map((error: string) => notify.error(undefined, error));
            } else {
              notify.success({
                message: successMessage,
              });
              setData([]);
              setPageInfo(undefined);
              refetch({ first: LABEL.rowsPerPage, after: undefined });
              setEditingKey("");
              setIsEditLabel(false);
              setIsFieldsChanged(false);
              setSkipQuery(false);
            }
          }
        },
      },
    );

    const [deleteLabel] = useMutation<DestroyDepartment | DestroyJobTitle | DestroyLocation>(deleteMutation, {
      onCompleted: (response) => {
        if (notify) {
          const labelResponse =
            title === LABEL.department
              ? (response as DestroyDepartment).destroyDepartment
              : title === LABEL.location
              ? (response as DestroyLocation).destroyLocation
              : (response as DestroyJobTitle).destroyJobTitle;

          if (labelResponse) {
            if (labelResponse?.errors?.fullMessages?.length) {
              labelResponse?.errors?.fullMessages?.forEach((error: string) => {
                if (!error.includes("dependent user")) {
                  notify.error(undefined, error);
                } else {
                  showModal();
                }
              });
            } else {
              const successMessage =
                title === LABEL.department
                  ? "Department deleted successfully."
                  : title === LABEL.location
                  ? "Location deleted successfully."
                  : "Job title deleted successfully.";
              notify.success({
                message: successMessage,
              });
              setData([]);
              setPageInfo(undefined);
              refetch({ first: LABEL.rowsPerPage, after: undefined });
              setEditingKey("");
              setIsEditLabel(false);
              setIsFieldsChanged(false);
            }
          }
        }
      },
    });

    const [vt, setVT] = useVT(
      () => ({
        onScroll: async ({ isEnd }) => {
          if (isEnd && pageInfo?.hasNextPage && pageInfo?.endCursor) {
            refetch({ first: LABEL.rowsPerPage, after: pageInfo?.endCursor });
          }
        },
        offset: 80,
        scroll: {
          y: TABLE.tableMinHeight,
        },
        debug: false,
      }),
      [data, pageInfo],
    );

    useMemo(() => setVT({ body: { cell: EditableCell } }), [setVT]);

    const isEditing = (record: Tag) => record.id === editingKey;

    const onSubmit = (variables: Tag) => {
      if (initialEditLabel !== variables.name.trim()) {
        setSkipQuery(true);
        updateLabel({ variables: { name: variables.name.trim(), id: editingKey } });
      } else {
        setEditingKey("");
        setIsEditLabel(false);
        setIsFieldsChanged(false);
      }
    };

    const edit = (record: Partial<Tag> & { id: string }) => {
      if (isFieldsChanged) {
        showConfirmModal(() => {
          form.setFieldsValue(record);
          setIsEditLabel(true);
          setEditingKey(record.id);
          setIsFieldsChanged(false);
        });
      } else {
        form.setFieldsValue(record);
        setIsEditLabel(true);
        setEditingKey(record.id);
      }
    };

    const onCancelHandler = () => {
      if (isFieldsChanged) {
        showConfirmModal(() => {
          setEditingKey("");
          setIsEditLabel(false);
          setIsFieldsChanged(false);
        });
      } else {
        setEditingKey("");
        setIsEditLabel(false);
      }
    };

    useImperativeHandle(ref, () => ({
      refetch() {
        if (data.length >= LABEL.rowsPerPage && pageInfo?.endCursor) {
          refetch({ first: LABEL.rowsPerPage, after: pageInfo?.endCursor });
        } else {
          setData([]);
          setPageInfo(undefined);
          refetch({ first: LABEL.rowsPerPage, after: undefined });
        }
      },
    }));

    const columns: ExtendedColumnsType[] = [
      {
        title: "name",
        dataIndex: "name",
        width: "58%",
        editable: true,
      },
      {
        title: "operation",
        dataIndex: "operation",
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <div className="d-flex justify-content-end gap-2 pe-1">
                <DefaultButton onClick={onCancelHandler}>Cancel</DefaultButton>
                <PrimaryButton
                  form={
                    title === LABEL.department
                      ? "departmentsForm"
                      : title === LABEL.location
                      ? "locationForm"
                      : "jobTitleForm"
                  }
                  htmlType="submit"
                >
                  Save
                </PrimaryButton>
              </div>
            </span>
          ) : (
            <div className="d-flex justify-content-end gap-2 pe-3">
              <Tooltip title="Edit">
                <IconButton
                  icon="edit"
                  aria-label="edit"
                  disabled={isAddNewData}
                  onClick={() => {
                    edit(record);
                    setInitialEditLabel(record.name);
                  }}
                />
              </Tooltip>
              <Tooltip title="Remove">
                <IconButton
                  icon="remove"
                  aria-label="remove"
                  disabled={isAddNewData}
                  onClick={() => {
                    setDeleteId(record.id);
                    showDestroyConfirmModal();
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: Tag) => ({
          record,
          inputType: "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

    const getFeedBackMessage = (title: string) => {
      const message = `This ${title.toLowerCase()} cannot be deleted as there are active employees 
      with this ${title.toLowerCase()}. Delete or change this ${title.toLowerCase()} 
      in employee profile before deleting the label.`;

      return message;
    };

    return (
      <StyledWrapper>
        <Form
          form={form}
          id={
            title === LABEL.department ? "departmentsForm" : title === LABEL.location ? "locationForm" : "jobTitleForm"
          }
          onFinish={onSubmit}
          onFieldsChange={() => setIsFieldsChanged(true)}
        >
          <Table
            components={vt}
            loading={loading}
            scroll={{ scrollToFirstRowOnChange: false, y: TABLE.tableMinHeight }}
            size="large"
            rowKey="id"
            bordered={false}
            dataSource={data}
            columns={mergedColumns as ExtendedColumnsType[]}
            showHeader={false}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
        <StyledFeedBackModal
          open={isModalVisible}
          type="info"
          onCancel={hideModal}
          onOk={hideModal}
          title={getFeedBackMessage(title)}
        />
        <ConfirmModal
          onCancel={hideDestroyConfirmModal}
          open={isDestroyConfirmModalVisible}
          onOk={() => {
            deleteLabel({
              variables: {
                id: deleteId,
              },
            });
            hideDestroyConfirmModal();
          }}
          width={310}
          title="Are you sure you want to delete this label?"
          okText="Yes"
          cancelText="No"
        />
      </StyledWrapper>
    );
  },
);

export default LabelTable;

const StyledWrapper = styled.div`
  .ant-table-wrapper .ant-table-body {
    height: 277px;
    overflow-y: auto;
  }
  .ant-table-placeholder {
    height: 277px;
  }
`;

const StyledInputText = styled(InputText)`
  :hover,
  :active,
  :focus {
    border: 1.5px solid ${COLORS.successColor};
    border-radius: 3px;
  }
  border: 1.5px solid ${COLORS.successColor};
  border-radius: 3px;
`;

const StyledFeedBackModal = styled(FeedBackModal)`
  & .ant-modal-body h1 {
    font-size: 14px;
    font-weight: 400;
  }
`;
