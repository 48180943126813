export default () => (
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    style={{ transform: "rotate(180deg)" }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.627829 3.64645C0.432567 3.84171 0.432567 4.15829 0.627829 4.35355L3.80981 7.53553C4.00507 7.7308 4.32165 7.7308 4.51692 7.53553C4.71218 7.34027 4.71218 7.02369 4.51692 6.82843L1.68849 4L4.51692 1.17157C4.71218 0.97631 4.71218 0.659727 4.51692 0.464465C4.32165 0.269203 4.00507 0.269203 3.80981 0.464465L0.627829 3.64645ZM15.3877 3.5L0.981382 3.5L0.981382 4.5L15.3877 4.5L15.3877 3.5Z"
      fill="#2B439C"
    />
  </svg>
);
