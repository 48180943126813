export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11718_17522)">
      <path
        d="M7.50011 24C6.61009 24 5.74004 23.7361 5.00001 23.2417C4.25997 22.7472 3.68317 22.0444 3.34257 21.2221C3.00196 20.3999 2.91283 19.495 3.08646 18.6221C3.26009 17.7492 3.68868 16.9474 4.31802 16.318C4.94736 15.6887 5.7492 15.2601 6.62212 15.0865C7.49505 14.9128 8.39986 15.002 9.22213 15.3426C10.0444 15.6832 10.7472 16.26 11.2417 17C11.7361 17.74 12 18.6101 12 19.5001C11.9986 20.6931 11.524 21.8369 10.6804 22.6804C9.83686 23.524 8.69312 23.9986 7.50011 24ZM7.50011 16.1158C6.83065 16.1158 6.17621 16.3143 5.61957 16.6862C5.06292 17.0581 4.62906 17.5868 4.37285 18.2053C4.11664 18.8238 4.04959 19.5043 4.18019 20.161C4.31078 20.8176 4.63314 21.4207 5.10652 21.8941C5.57989 22.3675 6.18301 22.6898 6.83961 22.8205C7.49621 22.9511 8.17679 22.8841 8.7953 22.6279C9.4138 22.3717 9.94245 21.9378 10.3144 21.3812C10.6863 20.8246 10.8848 20.1701 10.8848 19.5007C10.884 18.6032 10.5272 17.7427 9.8926 17.108C9.25804 16.4733 8.39761 16.1163 7.50011 16.1153V16.1158Z"
        fill="#87C0DE"
        stroke="#87C0DE"
        strokeWidth="0.4"
      />
      <path
        d="M12.4928 30H2.50719C2.37267 30 2.24367 29.9388 2.14855 29.8298C2.05344 29.7209 2 29.5731 2 29.419V28.2438C2 25.7453 3.7143 24 6.1699 24H8.8301C11.2849 24 13 25.7451 13 28.2438V29.419C13 29.5731 12.9466 29.7209 12.8514 29.8298C12.7563 29.9388 12.6273 30 12.4928 30ZM3.01438 28.8379H11.9856V28.2438C11.9856 26.115 10.4008 25.1621 8.8301 25.1621H6.1699C4.59924 25.1621 3.01438 26.115 3.01438 28.2438V28.8379Z"
        fill="#87C0DE"
        stroke="#87C0DE"
        strokeWidth="0.4"
      />
      <path
        d="M15.5 30C15.3498 30 15.2057 29.9383 15.0995 29.8284C14.9933 29.7185 14.9336 29.5695 14.9336 29.4141C14.9336 29.2587 14.9933 29.1096 15.0995 28.9997C15.2057 28.8899 15.3498 28.8281 15.5 28.8281C18.1438 28.8281 20.7282 28.0171 22.9264 26.4977C25.1246 24.9782 26.8379 22.8186 27.8497 20.2918C28.8614 17.765 29.1261 14.9847 28.6103 12.3023C28.0946 9.61988 26.8215 7.15594 24.952 5.22204C23.0826 3.28815 20.7008 1.97115 18.1078 1.43758C15.5148 0.904024 12.8271 1.17787 10.3846 2.22448C7.94206 3.2711 5.85439 5.04349 4.38559 7.31751C2.91678 9.59153 2.13281 12.2651 2.13281 15C2.13281 15.1554 2.07314 15.3044 1.96692 15.4143C1.86069 15.5242 1.71663 15.5859 1.56641 15.5859C1.41619 15.5859 1.27212 15.5242 1.1659 15.4143C1.05967 15.3044 1 15.1554 1 15C1 12.0333 1.85041 9.13319 3.44369 6.66645C5.03697 4.19971 7.30156 2.27713 9.95109 1.14181C12.6006 0.00649917 15.5161 -0.290551 18.3288 0.288227C21.1415 0.867006 23.7252 2.29562 25.753 4.3934C27.7809 6.49119 29.1619 9.16394 29.7214 12.0737C30.2809 14.9834 29.9937 17.9994 28.8962 20.7403C27.7988 23.4811 25.9403 25.8238 23.5558 27.472C21.1713 29.1203 18.3678 30 15.5 30Z"
        fill="#87C0DE"
        stroke="#87C0DE"
        strokeWidth="0.4"
      />
      <path
        d="M18.456 17.5818C18.3531 17.5818 18.252 17.5547 18.163 17.5031L14.706 15.5074C14.6171 15.4559 14.5433 15.3818 14.4921 15.2928C14.4409 15.2037 14.414 15.1027 14.4141 15V6.75128C14.4141 6.67434 14.4292 6.59814 14.4587 6.52705C14.4881 6.45596 14.5313 6.39137 14.5857 6.33696C14.6401 6.28255 14.7047 6.23939 14.7758 6.20995C14.8469 6.1805 14.9231 6.16534 15 6.16534C15.0769 6.16534 15.1531 6.1805 15.2242 6.20995C15.2953 6.23939 15.3599 6.28255 15.4143 6.33696C15.4687 6.39137 15.5119 6.45596 15.5413 6.52705C15.5708 6.59814 15.5859 6.67434 15.5859 6.75128V14.6614L18.75 16.4883C18.8618 16.5527 18.9491 16.6523 18.9985 16.7715C19.0479 16.8907 19.0566 17.0228 19.0232 17.1475C18.9898 17.2721 18.9162 17.3822 18.8138 17.4607C18.7115 17.5392 18.586 17.5818 18.457 17.5818H18.456Z"
        fill="#87C0DE"
        stroke="#87C0DE"
        strokeWidth="0.4"
      />
      <path
        d="M15.4348 27C15.2878 27 15.1468 26.9394 15.0429 26.8316C14.9389 26.7237 14.8805 26.5775 14.8805 26.425C14.8805 26.2725 14.9389 26.1262 15.0429 26.0184C15.1468 25.9105 15.2878 25.8499 15.4348 25.8499C17.4348 25.8505 19.393 25.2561 21.0774 24.1374C22.7617 23.0186 24.1017 21.4223 24.9385 19.5376C25.7752 17.6529 26.0737 15.5588 25.7985 13.5035C25.5233 11.4482 24.686 9.51788 23.3858 7.94119C22.0856 6.3645 20.3769 5.20758 18.4624 4.60754C16.5479 4.00749 14.5077 3.98946 12.5836 4.55559C10.6595 5.12172 8.93214 6.24828 7.60623 7.80176C6.28033 9.35525 5.41145 11.2705 5.10256 13.3207C5.09191 13.3958 5.06703 13.4679 5.02936 13.533C4.99169 13.5981 4.94198 13.6548 4.88309 13.6998C4.8242 13.7449 4.7573 13.7773 4.68625 13.7954C4.61519 13.8135 4.54139 13.8168 4.4691 13.8052C4.39681 13.7935 4.32746 13.7671 4.26504 13.7275C4.20263 13.6879 4.1484 13.6359 4.10546 13.5744C4.06253 13.5129 4.03175 13.4433 4.0149 13.3694C3.99805 13.2955 3.99546 13.2189 4.00728 13.144C4.34926 10.8776 5.31022 8.76033 6.77632 7.04311C8.24242 5.3259 10.1522 4.08067 12.2795 3.45498C14.4067 2.82928 16.6622 2.84932 18.7788 3.51274C20.8954 4.17616 22.7844 5.45514 24.2219 7.19817C25.6594 8.94119 26.5853 11.0752 26.8898 13.3474C27.1943 15.6196 26.8647 17.9347 25.94 20.0185C25.0153 22.1023 23.5343 23.8675 21.6724 25.1048C19.8105 26.3421 17.6458 26.9999 15.4348 27Z"
        fill="#87C0DE"
        stroke="#87C0DE"
        strokeWidth="0.4"
      />
    </g>
    <defs>
      <clipPath id="clip0_11718_17522">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
