import { useMutation } from "@apollo/client";
import { SendReminderPayload } from "model/User";
import { useNotify } from "services/api";
import { SEND_REMINDER } from "services/graphql/user";

export function useLoginReminder() {
  const notify = useNotify();
  return useMutation<SendReminderPayload>(SEND_REMINDER, {
    onCompleted: (response) => {
      if (response?.sendReminder?.success) {
        notify.success({ message: "Send reminder to user successfully." });
      }
    },
  });
}

export default useLoginReminder;
