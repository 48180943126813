import { EmployeeId } from "model/Employee";

export const employee = {
  view: (id: EmployeeId) => `/employee/${id}/view`,
  edit: (id: EmployeeId) => `/employee/${id}/edit`,
  myProfileView: () => `/my_profile/view`,
  myProfileEdit: () => `/my_profile/edit`,
};

export default employee;
