import * as React from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import type { Blocker, Transition, History } from "history";
import { ConfirmModal, useModal } from "components/modal";

function useBlocker(blocker: Blocker, when = true): void {
  const navigator = React.useContext(NavigationContext).navigator as History;

  React.useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    // eslint-disable-next-line consistent-return
    return unblock;
  }, [navigator, blocker, when]);
}

export function UnsavedPrompt({ when }: { when: boolean }) {
  const { isModalVisible, hideModal, showModal } = useModal();
  const txRef = React.useRef<Transition>();
  const blocker = React.useCallback(
    (tx: Transition) => {
      showModal();
      txRef.current = tx;
    },
    [showModal],
  );

  useBlocker(blocker, when);

  return (
    <ConfirmModal
      onCancel={hideModal}
      open={isModalVisible}
      onOk={() => {
        if (txRef.current) txRef.current.retry();
        hideModal();
      }}
      width={310}
      title="You have unsaved changes. Close without saving?"
      okText="Discard changes"
      cancelText="Cancel"
    />
  );
}

export default UnsavedPrompt;
