import { ConfirmModal, useModal } from "components/modal";
import { useSafeContext } from "hooks";
import React, { useRef } from "react";

export interface PreventUnsavedFormContextProps {
  showConfirmModal: (func?: () => unknown) => void;
}

export const PreventUnsavedFormContext = React.createContext<PreventUnsavedFormContextProps | null>(null);
PreventUnsavedFormContext.displayName = "PreventUnsavedForm";

export const usePreventUnsavedForm = () => useSafeContext(PreventUnsavedFormContext);

export const PreventUnsavedFormProvider = ({ children }: { children: React.ReactNode }) => {
  const { isModalVisible, hideModal, showModal } = useModal();
  const onOkRef = useRef<() => unknown>(() => undefined);
  const showConfirmModal = (func?: () => unknown) => {
    if (!isModalVisible) {
      if (func) {
        onOkRef.current = func;
      }
      showModal();
    }
  };

  return (
    <PreventUnsavedFormContext.Provider value={{ showConfirmModal }}>
      {children}
      <ConfirmModal
        onCancel={hideModal}
        open={isModalVisible}
        onOk={() => {
          onOkRef.current();
          hideModal();
        }}
        width={310}
        title="You have unsaved changes. Close without saving?"
        okText="Discard changes"
        cancelText="Cancel"
      />
    </PreventUnsavedFormContext.Provider>
  );
};

export default PreventUnsavedFormProvider;
