export default () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.90312 0.43148C5.20054 0.153777 5.5931 0 6 0C6.40693 0 6.79952 0.153801 7.09695 0.431544L11.152 4.21676C11.152 4.21678 11.152 4.21674 11.152 4.21676C11.4194 4.46628 11.6328 4.7675 11.7787 5.10205C11.9247 5.43662 12 5.79735 12 6.16207C12 6.16209 12 6.16205 12 6.16207V10.4135C12 10.8363 11.8299 11.2405 11.5292 11.5375C11.2287 11.8343 10.8224 12 10.4 12H1.6C1.17763 12 0.77134 11.8343 0.47082 11.5375C0.170091 11.2405 0 10.8363 0 10.4135L2.98023e-08 6.16213C2.98023e-08 6.16211 2.98023e-08 6.16214 2.98023e-08 6.16213C-3.17991e-05 5.7974 0.0753286 5.43662 0.221283 5.10205C0.367227 4.7675 0.58059 4.46633 0.847966 4.21681M0.847966 4.21681L4.90312 0.43148C4.90314 0.431459 4.90309 0.431501 4.90312 0.43148M6 1C5.84481 1 5.6966 1.05873 5.58555 1.16242L1.53033 4.94782C1.36238 5.10455 1.22891 5.29319 1.13786 5.5019C1.04683 5.71059 0.999976 5.93519 1 6.16202V10.4135C1 10.567 1.06169 10.7156 1.17354 10.826C1.28561 10.9367 1.43889 11 1.6 11H10.4C10.5611 11 10.7144 10.9367 10.8265 10.826C10.9383 10.7156 11 10.567 11 10.4135V6.16207C11 5.93525 10.9532 5.71059 10.8621 5.5019C10.7711 5.29319 10.6377 5.1046 10.4697 4.94787L6.41452 1.16249C6.30347 1.05879 6.15519 1 6 1Z"
      fill="white"
    />
  </svg>
);
