import * as React from "react";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

interface BrowserRouterProps {
  basename?: string;
  children?: React.ReactNode;
  window?: Window;
}
export const history = createBrowserHistory({ window });

const BrowserRouter = ({ basename, children, window }: BrowserRouterProps) => (
  <HistoryRouter basename={basename} history={history}>
    {children}
  </HistoryRouter>
);

export default BrowserRouter;
