import "./assets/styles/ant-theme.less";
import { ConfigProvider, App as AntdApp } from "antd";
import Layout from "antd/lib/layout/layout";
import theme from "config/theme";
import { AuthProvider } from "contexts";
import ApolloProvider from "contexts/ApolloProvider";
import { LeaveRequestListProvider } from "contexts/LeaveRequestListProvider";
import PreventUnsavedFormProvider from "contexts/PreventUnsavedFormProvider";
import BrowserRouter from "components/BrowserRouter";
import styled from "styled-components";
import { NotificationProvider } from "contexts/NotificationProvider";
import RollbarProvider from "contexts/RollbarProvider";
import Routes from "./pages/Routes";

const App = () => (
  <RollbarProvider>
    <ConfigProvider theme={theme}>
      <AntdApp>
        <ApolloProvider>
          <AuthProvider>
            <StyledLayout>
              <BrowserRouter>
                <PreventUnsavedFormProvider>
                  <LeaveRequestListProvider>
                    <NotificationProvider>
                      <Routes />
                    </NotificationProvider>
                  </LeaveRequestListProvider>
                </PreventUnsavedFormProvider>
              </BrowserRouter>
            </StyledLayout>
          </AuthProvider>
        </ApolloProvider>
      </AntdApp>
    </ConfigProvider>
  </RollbarProvider>
);

export default App;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;
