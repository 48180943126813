import { CheckboxOptionType } from "antd";
import dayjs from "dayjs";
import { ImportEmployeeNotifyPoints } from "model/Employee";
import { NotificationMsg } from "model/Notification";
import DATE_FORMAT from "./dateFormat";

const databaseFields: Record<string, string> = {
  employeeNumber: "Employee number",
  name: "Name",
  gender: "Gender",
  personalPhone: "Personal phone",
  personalEmail: "Personal email",
  dateOfBirth: "Date of birth",
  address: "Address",
  jobTitle: "Job Title",
  department: "Department",
  lineManager: "Line Manager",
  location: "Work Location",
  startDate: "Start date",
  endDate: "End date (if applicable)",
  probationaryPeriod: "Probationary period",
  holidaysPerAnnum: "Number of holidays per annum",
  contractType: "Contract type",
  hoursPerWeek: "Hours per week",
  startTime: "Start time",
  endTime: "End time",
  daysPerWeek: "Days per week",
  workPhoneNumber: "Work phone number",
  workEmail: "Work email",
  emergencyName: "Emergency contact name",
  emergencyRelationship: "Emergency contact relationship",
  emergencyAddress: "Emergency contact address",
  emergencyPhoneNumber: "Emergency contact phone number",
  emergencyEmail: "Emergency contact email",
  salary: "Salary - amount",
  currency: "Currency",
  type: "Type",
};

const employeePersonalDetails: Record<string, string> = {
  firstName: "First name",
  preferredName: "Preferred name",
  lastName: "Last name",
  dateOfBirth: "Date of birth",
  nationality: "Nationality",
  gender: "Gender",
  workEmail: "Work email",
  workPhone: "Work phone",
  personalEmail: "Personal email",
  personalPhone: "Personal phone",
  fullAddress: "Full address",
  country: "Country",
  dietaryPreference: "Dietary preference",
  foodAllergies: "Food allergies",
  emergencyContacts: "Emergency contacts",
};

const employment: Record<string, string> = {
  jobTitle: "Job title",
  department: "Department",
  location: "Location",
  manager: "Manager",
  managerEmail: "Manager email",
  roleEffectiveDate: "Role effective date",
  contractType: "Contract type",
  employeeID: "EmployeeID",
  employmentStart: "Employment start",
  firstWorkingDay: "First working day",
  probationEnd: "Probation end",
  employmentEnd: "Employment end",
};

const compensation: Record<string, string> = {
  salary: "Salary",
};

const leave: Record<string, string> = {
  paidTimeOff: "Paid time off",
  unpaidLeave: "Unpaid leave",
  sickLeave: "Sick leave",
  parentalLeave: "Parental leave",
  otherTimeAway: "Other time away",
  annualLeaveBalance: "Annual leave balance",
  annualLeaveBalanceFullYear: "Annual leave balance full year",
};

const days: (CheckboxOptionType & { id: number })[] = [
  { id: 1, label: "Mon", value: "isMonday" },
  { id: 2, label: "Tue", value: "isTuesday" },
  { id: 3, label: "Wed", value: "isWednesday" },
  { id: 4, label: "Thu", value: "isThursday" },
  { id: 5, label: "Fri", value: "isFriday" },
  { id: 6, label: "Sat", value: "isSaturday" },
  { id: 7, label: "Sun", value: "isSunday" },
];

const messages: NotificationMsg = {
  birthdaysThisWeek: (date: string, name?: string): string =>
    `Birthday ${name ? `of ${name} is ` : ""}on ${dayjs(date)
      .set("year", dayjs().year())
      .format(DATE_FORMAT.fullDateSecondary)}`,
  probationaryPeriodEndingThisWeek: (date: string, name?: string) =>
    `Probationary Period ending ${name ? `of ${name} is ` : ""}on ${dayjs(date)
      .add(7, "month")
      .format(DATE_FORMAT.fullDateSecondary)}`,
  startingThisWeek: (date: string, name?: string) =>
    `Starting ${name ? `of ${name} is ` : ""}on ${dayjs(date).format(DATE_FORMAT.fullDateSecondary)}`,
  workAnniversaryThisWeek: (date: string, name?: string) =>
    `Work anniversary ${name ? `of ${name} is ` : ""}on ${dayjs(date)
      .set("year", dayjs().year())
      .format(DATE_FORMAT.fullDateSecondary)}`,
  finishingIn: (date: string, name?: string) =>
    `Finishing ${name ? `of ${name} is ` : ""}on ${dayjs(date)
      .set("year", dayjs().year())
      .format(DATE_FORMAT.fullDateSecondary)}`,
  leaveApproved: (fromDate: string, toDate: string, name: string) =>
    `Leave request approved from ${dayjs(fromDate).format(DATE_FORMAT.fullDate)} to ${dayjs(toDate).format(
      DATE_FORMAT.fullDate,
    )} by ${name}`,
  leaveCreated: (fromDate: string, toDate: string, name: string) =>
    `Leave request created from ${dayjs(fromDate).format(DATE_FORMAT.fullDate)} to ${dayjs(toDate).format(
      DATE_FORMAT.fullDate,
    )} by ${name}`,
  leaveDeclined: (fromDate: string, toDate: string, name: string) =>
    `Leave request declined from ${dayjs(fromDate).format(DATE_FORMAT.fullDate)} to ${dayjs(toDate).format(
      DATE_FORMAT.fullDate,
    )} by ${name}`,
};

const importEmployeeNotifyPoints: ImportEmployeeNotifyPoints[] = [
  {
    allow: true,
    point: "Employee information can be uploaded in Excel format",
  },
  {
    allow: true,
    point: "Our below downloadable templates will guide you in the format to use",
  },
  {
    allow: true,
    point: "You do not need to complete all fields, only what you wish to upload",
  },
  {
    allow: false,
    point: "Blank entries or deleted columns will not be uploaded",
  },
  {
    allow: false,
    point: "Column names not exactly matching template names will not be uploaded",
  },
  {
    allow: false,
    point: "Information uploaded in incorrect format will not be uploaded",
  },
];

export const EMPLOYEE = {
  databaseFields,
  employeePersonalDetails,
  employment,
  compensation,
  leave,
  days,
  messages,
  importEmployeeNotifyPoints,
};

export default EMPLOYEE;
