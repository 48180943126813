const DownSvg = () => (
  <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.83313 1L5.15313 5L0.83313 0.999999" stroke="#778496" strokeWidth="1.5" />
  </svg>
);

export default DownSvg;

DownSvg.defaultProps = {
  style: {},
};
