export default () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 1.49a.5.5 0 0 1 .5-.5h7a1.5 1.5 0 0 1 1.5 1.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 0-.5-.5H4a.5.5 0 0 1-.5-.5Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.5 5.49A1.5 1.5 0 0 1 2 3.99h6a1.5 1.5 0 0 1 1.5 1.5v6a1.5 1.5 0 0 1-1.5 1.5H2a1.5 1.5 0 0 1-1.5-1.5v-6Zm1.5-.5a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5H2Z"
      fill="#fff"
    />
  </svg>
);
