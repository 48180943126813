import { useAuthContext } from "contexts";
import { isAdmin } from "model/User";
import HolidayApprovedDeclinedNotifications from "../employeeNotifications/HolidayApprovedDeclinedNotifications";
import OtherUpdates from "../employeeNotifications/OtherUpdates";
import DocumentsActionedTable from "./DocumentReviewTable";

const MyNotifications = () => {
  const { user } = useAuthContext();
  return (
    <div>
      <div className="mb-4">
        <DocumentsActionedTable />
      </div>
      <div className={`${!isAdmin(user) ? "mb-4" : ""}`}>
        <HolidayApprovedDeclinedNotifications />
      </div>
      {!isAdmin(user) && <OtherUpdates />}
    </div>
  );
};

export default MyNotifications;
