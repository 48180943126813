import { gql } from "@apollo/client";
import { EMERGENCY_CONTACT_FRAGMENT, PERSONAL_DETAIL_FRAGMENT } from "./employee";
import { WORKING_CONDITION_FRAGMENT } from "./common";

export const USER_RESULT_FRAGMENT = gql`
  fragment UserResultFragment on User {
    email
    id
    name
    startedAt
    roles {
      id
      name
    }
  }
`;

export const ME_DOCUMENT = gql`
  query me {
    me {
      id
      permissionsList
      personalDetail {
        ...PersonalDetailFragment
      }
      managedUserIds
      paymentVat
      jobDetail {
        id
        email
        startedAt
        workingCondition {
          ...WorkingConditionFragment
        }
        phone
        departments {
          id
          name
        }
        jobTitle {
          id
          name
        }
        location {
          id
          name
        }
        lineManagers {
          id
          name
        }
      }
      emergencyContact {
        ...EmergencyContactFragment
      }
      roles {
        id
        name
      }
      subscriptionActive
    }
  }
  ${PERSONAL_DETAIL_FRAGMENT}
  ${EMERGENCY_CONTACT_FRAGMENT}
  ${WORKING_CONDITION_FRAGMENT}
`;

export const LOGIN_DOCUMENT = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        ...UserResultFragment
      }
    }
  }
  ${USER_RESULT_FRAGMENT}
`;

export const SIGN_OUT_DOCUMENT = gql`
  mutation signOut {
    signOut {
      user {
        id
      }
    }
  }
`;

export const FORGOT_PASSWORD_DOCUMENT = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      errors {
        details
        fullMessages
      }
      success
    }
  }
`;

export const RESET_PASSWORD_DOCUMENT = gql`
  mutation resetPassword($password: String!, $passwordConfirmation: String!, $resetPasswordToken: String!) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      errors {
        details
        fullMessages
      }
      success
    }
  }
`;

export const UPDATE_USER_DOCUMENT = gql`
  mutation updateMe($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
    updateMe(currentPassword: $currentPassword, password: $password, passwordConfirmation: $passwordConfirmation) {
      errors {
        details
        fullMessages
      }
      user {
        id
      }
    }
  }
`;

export const ACCEPT_INVITE = gql`
  mutation acceptInvite($password: String!, $passwordConfirmation: String!, $invitationToken: String!) {
    acceptInvite(password: $password, passwordConfirmation: $passwordConfirmation, invitationToken: $invitationToken) {
      errors {
        details
        fullMessages
      }
      success
      token
    }
  }
`;

export const SEND_REMINDER = gql`
  mutation sendReminder($userId: ID!) {
    sendReminder(userId: $userId) {
      errors {
        details
        fullMessages
      }
      success
    }
  }
`;

export default {
  ME_DOCUMENT,
  LOGIN_DOCUMENT,
  SIGN_OUT_DOCUMENT,
  FORGOT_PASSWORD_DOCUMENT,
  RESET_PASSWORD_DOCUMENT,
  UPDATE_USER_DOCUMENT,
  ACCEPT_INVITE,
};
