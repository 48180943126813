import { Form, SelectProps } from "antd";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { BaseButton, LinkButton } from "components/Buttons";
import ALERTS from "config/alerts";
import COLORS from "constants/colors";
import { useState, forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import InputText from "../InputText/InputText";
import InputDropDown, { BasicSelectProps, SelectRef } from "./InputDropDown";

type FormDataType = {
  newLabel: string;
};

interface Props extends BasicSelectProps {
  addBtnFor: string;
  onAdd: (data: FormDataType) => void;
}

export interface SelectRefType {
  onAddSuccessFully(): void;
}

export const InputAddDropDown = forwardRef<SelectRefType, SelectProps & Props>(
  ({ addBtnFor, onAdd, ...props }, ref) => {
    const [isShowAddForm, setIsShowAddForm] = useState<boolean>(false);
    const [form] = useForm();
    const selectRef = useRef<SelectRef>(null);

    useImperativeHandle(ref, () => ({
      onAddSuccessFully() {
        if (isShowAddForm) {
          setIsShowAddForm(false);
          if (selectRef.current) {
            selectRef.current.closeOnNewAdd();
          }
        }
      },
    }));

    return (
      <InputDropDown
        {...props}
        ref={selectRef}
        onDropdownVisibleChange={() => setIsShowAddForm(false)}
        dropdownRender={(menu) => (
          <div className={`d-flex flex-column ${isShowAddForm && "gap-1"}`}>
            <StyledWrapper>
              {!isShowAddForm ? (
                <LinkButton
                  className="link-btn p-0"
                  onClick={() => {
                    form.resetFields();
                    setIsShowAddForm(true);
                  }}
                >
                  + Add {addBtnFor}
                </LinkButton>
              ) : (
                <StyledSpan>New {addBtnFor}</StyledSpan>
              )}
              {isShowAddForm && (
                <Form form={form} layout="vertical" onFinish={onAdd}>
                  <FormItem name="newLabel" rules={[ALERTS.required]} className="m-0">
                    <StyledInputText
                      className="shadow-none"
                      autoFocus
                      onKeyDown={(e) => e.stopPropagation()}
                      suffix={
                        <>
                          <StyledBaseButton className="base-btn shadow-none p-0" icon="check" htmlType="submit" />
                          <StyledBaseButton
                            className="base-btn shadow-none p-0"
                            icon="remove"
                            onClick={() => {
                              form.resetFields();
                              setIsShowAddForm(false);
                            }}
                          />
                        </>
                      }
                    />
                  </FormItem>
                </Form>
              )}
            </StyledWrapper>
            {menu}
          </div>
        )}
      />
    );
  },
);

export default InputAddDropDown;

const StyledWrapper = styled.div`
  padding: 0px 15px;
  .link-btn {
    width: fit-content;
  }

  & .ant-form .ant-form-item-control-input-content .ant-input-affix-wrapper-status-error,
  & .ant-form .ant-form-item-control-input-content .ant-input-affix-wrapper-status-error:hover,
  & .ant-form .ant-form-item-control-input-content .ant-input-affix-wrapper-status-error:focus,
  & .ant-form .ant-form-item-has-success .ant-form-item-control-input-content .ant-input-affix-wrapper {
    border-color: ${COLORS.successColor};
  }

  & .ant-form .ant-form-item-has-error .ant-form-item-control-input-content .ant-input-affix-wrapper {
    border-color: ${COLORS.errorColor};
  }

  & .ant-form .ant-form-item .ant-form-item-explain-error {
    color: ${COLORS.errorColor};
  }
`;

const StyledInputText = styled(InputText)`
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${COLORS.successColor};
  }
  &:hover,
  &:active,
  &:focus {
    border: 1.5px solid ${COLORS.successColor};
    border-radius: 3px;
  }
  border: 1.5px solid ${COLORS.successColor};
  border-radius: 3px;
  height: 32px;
  padding: 8px 10px;
`;

const StyledBaseButton = styled(BaseButton)`
  &.base-btn {
    width: 20px;
    height: 20px;
    background: transparent;
  }
`;

const StyledSpan = styled.span`
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  display: flex;
  align-items: center;
}
`;
