import { Col, Modal, Row, Typography } from "antd";
import { SuccessButton } from "components/Buttons";
import useFormModal from "hooks/useFormModal";
import { useRef, useState } from "react";
import { DocumentRefetch } from "model/Document";
import DocumentsListTable from "./DocumentsListTable";
import UploadDocument from "./UploadDocument";

const Documents = () => {
  const { isModalVisible, hideModal, showModal } = useFormModal();
  const [destroyModal, setDestroyModal] = useState<boolean>(false);
  const documentListRef = useRef<DocumentRefetch>(null);

  const onCancel = () => {
    hideModal();
    setDestroyModal(true);
  };

  return (
    <div id="main" className="h-100">
      <Row id="document_header">
        <Col span={24}>
          <Row>
            <Col span={24} className="d-flex justify-content-between align-items-center">
              <Typography.Title level={1} className="mb-0">
                Documents
              </Typography.Title>
              <SuccessButton
                onClick={() => {
                  setDestroyModal(false);
                  showModal();
                }}
              >
                Upload a document
              </SuccessButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4 pt-1 section-box-shadow">
        <Col span={24}>
          <DocumentsListTable ref={documentListRef} />
        </Col>
      </Row>

      <Modal
        width={453}
        title="Upload a document"
        open={isModalVisible}
        footer={null}
        centered
        onCancel={onCancel}
        destroyOnClose={destroyModal}
      >
        <UploadDocument
          setDestroyModal={() => setDestroyModal(true)}
          onCloseModal={hideModal}
          documentListRef={documentListRef}
        />
      </Modal>
    </div>
  );
};

export default Documents;
