import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Checkbox, Form, Popover, Table, Tooltip, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ColumnType, ColumnsType } from "antd/es/table";
import Icon from "components/Icon";
import ADMIN_SETTING from "constants/adminSetting";
import COLORS from "constants/colors";
import TABLE from "constants/table";
import { apolloClient } from "contexts/ApolloProvider";
import UnsavedPrompt from "components/UnsavedPrompt";
import {
  AssignRoleArg,
  AssignRoleData,
  AssignRoleRef,
  CreateUserRoleConnectionResponse,
  DestroyUserRoleConnectionResponse,
  FetchRolesSettings,
  ModifyAssignRoleData,
  RoleConnectionResponse,
} from "model/AdminSetting";
import { PageInfo, ValidationErrors } from "model/Common";
import { Employee, EmployeeId, FetchEmployeeResponse } from "model/Employee";
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { useNotify } from "services/api";
import {
  CERATE_USER_ROLE_CONNECTION,
  DESTROY_USER_ROLE_CONNECTION,
  FETCH_ROLE_SETTINGS,
} from "services/graphql/adminSetting";
import { ERROR_FRAGMENT } from "services/graphql/common";
import { FETCH_USER } from "services/graphql/employee";
import styled from "styled-components";
import { useVT } from "virtualizedtableforantd4";
import { checkPermission as checkValueInArray, getTableHeight } from "utils";
import { BaseButton, SuccessButton } from "components/Buttons";

const AssignAdminAndManagersTab = forwardRef<AssignRoleRef>((_, ref) => {
  const [form] = useForm();
  const [data, setData] = useState<Employee[]>([]);
  const [isFieldsChanged, setIsFieldsChanged] = useState<boolean>(false);
  const [roleData, setRoleData] = useState<AssignRoleData[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [assignRoleLoading, setAssignRoleLoading] = useState<boolean>(false);
  const [isDisableSave, setIsDisableSave] = useState<boolean>(false);
  const notify = useNotify();

  const [refetch, { loading }] = useLazyQuery<FetchEmployeeResponse>(FETCH_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      setData((d) => [...d, ...(response.users.nodes ?? [])]);
      if (response.users.pageInfo) setPageInfo(response.users.pageInfo);
    },
  });

  const { data: userRoles, loading: userRoleLoading } = useQuery<FetchRolesSettings>(FETCH_ROLE_SETTINGS, {
    variables: { first: TABLE.rowsPerPage },
    onCompleted: (response) => {
      if (response.roleSettings.nodes)
        refetch({
          variables: { first: TABLE.rowsPerPage, fetchBirthdaysThisWeek: false, fetchWorkAnniversaryThisWeek: false },
        });
    },
  });

  const checkCommonCondition = (assignRoleArg: AssignRoleArg) => {
    const { deletedRoleId, roleId, roleName, selectedRole, deletedRoleName } = assignRoleArg;
    const isManagerOrSuperAdminByDeletedRoleName =
      deletedRoleName &&
      checkValueInArray([deletedRoleName], [ADMIN_SETTING.roleNames.superAdmin, ADMIN_SETTING.roleNames.manager]);
    const isManagerOrSuperAdminByRoleName =
      roleName && checkValueInArray([roleName], [ADMIN_SETTING.roleNames.superAdmin, ADMIN_SETTING.roleNames.manager]);
    const isRoleIdIncluded = roleId && selectedRole.map((item) => item.id).includes(roleId);
    const isDeletedRoleIdIncluded = deletedRoleId && selectedRole.map((item) => item.id).includes(deletedRoleId);

    return {
      isManagerOrSuperAdminByDeletedRoleName,
      isManagerOrSuperAdminByRoleName,
      isRoleIdIncluded,
      isDeletedRoleIdIncluded,
    };
  };

  const modifyRoleNameAndId = useCallback(
    (assignRoleArg: AssignRoleArg, forRoleId: boolean, prevData?: AssignRoleData) => {
      const { deletedRoleId, roleId, roleName, deletedRoleName } = assignRoleArg;
      const { isManagerOrSuperAdminByRoleName, isRoleIdIncluded, isDeletedRoleIdIncluded } =
        checkCommonCondition(assignRoleArg);

      if (!prevData) {
        return forRoleId ? (roleId ? [roleId] : []) : roleName ? [roleName] : [];
      }

      const currentRoleValues = forRoleId ? prevData.roleId : prevData.roleName;

      const updatedRoleValues = currentRoleValues.filter(
        (item) => item !== (forRoleId ? deletedRoleId : deletedRoleName),
      );

      if (
        (!isManagerOrSuperAdminByRoleName && isRoleIdIncluded) ||
        (isDeletedRoleIdIncluded && deletedRoleName !== ADMIN_SETTING.roleNames.manager)
      ) {
        return currentRoleValues;
      }

      if (deletedRoleName === ADMIN_SETTING.roleNames.manager) {
        return [];
      }

      if (roleName === ADMIN_SETTING.roleNames.superAdmin || roleName === ADMIN_SETTING.roleNames.manager) {
        if (roleName && roleId && !isRoleIdIncluded) {
          return [forRoleId ? roleId : roleName];
        }
        return [];
      }

      if (deletedRoleId && !isDeletedRoleIdIncluded) {
        return updatedRoleValues;
      }

      const newValueToAdd = forRoleId ? (roleId ? [roleId] : []) : roleName ? [roleName] : [];

      return [...updatedRoleValues, ...newValueToAdd];
    },
    [],
  );

  const modifyDeletedRoleId = useCallback((assignRoleArg: AssignRoleArg, prevData?: AssignRoleData) => {
    const { deletedRoleId, roleId, selectedRole } = assignRoleArg;
    const {
      isManagerOrSuperAdminByRoleName,
      isManagerOrSuperAdminByDeletedRoleName,
      isRoleIdIncluded,
      isDeletedRoleIdIncluded,
    } = checkCommonCondition(assignRoleArg);

    const allRoleIdForDelete = selectedRole.map((item) => item.id);

    if (!prevData) {
      if (isManagerOrSuperAdminByDeletedRoleName || isManagerOrSuperAdminByRoleName) {
        return allRoleIdForDelete;
      }
      if (deletedRoleId) {
        return [deletedRoleId];
      }
      return [];
    }

    if (deletedRoleId && !isManagerOrSuperAdminByDeletedRoleName && isDeletedRoleIdIncluded) {
      return [...prevData.deletedRoleId, deletedRoleId];
    }

    if (
      (isManagerOrSuperAdminByDeletedRoleName && isDeletedRoleIdIncluded) ||
      (isManagerOrSuperAdminByRoleName && !isRoleIdIncluded)
    ) {
      return allRoleIdForDelete;
    }

    if (roleId && prevData.deletedRoleId.includes(roleId)) {
      return [...prevData.deletedRoleId].filter((item) => item !== roleId);
    }

    return [...prevData.deletedRoleId];
  }, []);

  const modifyDeletedRoleName = useCallback((assignRoleArg: AssignRoleArg, prevData?: AssignRoleData) => {
    const { deletedRoleId, roleId, roleName, deletedRoleName } = assignRoleArg;
    const { isDeletedRoleIdIncluded } = checkCommonCondition(assignRoleArg);

    if (!prevData) {
      if (deletedRoleName) {
        if (deletedRoleName === ADMIN_SETTING.roleNames.superAdmin) {
          return [ADMIN_SETTING.roleNames.manager];
        }
        return [deletedRoleName];
      }
      if (roleName === ADMIN_SETTING.roleNames.superAdmin) {
        return [ADMIN_SETTING.roleNames.manager];
      }
      return [];
    }

    if (
      (deletedRoleName && isDeletedRoleIdIncluded) ||
      (deletedRoleName && prevData.roleName.includes(deletedRoleName))
    ) {
      return [...prevData.deletedRoleName, deletedRoleName];
    }

    if (
      (deletedRoleId && deletedRoleName === ADMIN_SETTING.roleNames.manager) ||
      roleName === ADMIN_SETTING.roleNames.superAdmin
    ) {
      return [ADMIN_SETTING.roleNames.manager];
    }

    if (roleName === roleId && checkValueInArray(prevData.deletedRoleName, ADMIN_SETTING.roleNames.manager)) {
      return [];
    }

    if (roleName) {
      return [...prevData.deletedRoleName].filter((item) => item !== roleName);
    }

    return [...prevData.deletedRoleName];
  }, []);

  const modifySelectedRole = useCallback((assignRoleArg: AssignRoleArg, prevData?: AssignRoleData) => {
    const { deletedRoleId, roleId, roleName, selectedRole, deletedRoleName } = assignRoleArg;

    const { isManagerOrSuperAdminByRoleName, isManagerOrSuperAdminByDeletedRoleName } =
      checkCommonCondition(assignRoleArg);

    const filterSelectedRole = [
      ...(prevData ? prevData.selectedRole : selectedRole).filter((item) => item.id !== deletedRoleId),
    ];
    const concatPrevAndNewValue = [
      ...(prevData ? prevData.selectedRole : selectedRole).filter((item) => item.id !== roleId),
      ...(roleId && roleName ? [{ id: roleId, name: roleName }] : []),
    ];

    if (isManagerOrSuperAdminByRoleName) {
      if (roleId && roleName) {
        return [{ id: roleId, name: roleName }];
      }
      return [];
    }

    if (isManagerOrSuperAdminByDeletedRoleName) {
      return [];
    }

    if (deletedRoleId && deletedRoleName) {
      return filterSelectedRole;
    }

    return concatPrevAndNewValue;
  }, []);

  const onChange = useCallback(
    (assignRoleArg: AssignRoleArg) => {
      setIsFieldsChanged(true);
      let updateUserRole: AssignRoleData[] = [];
      if (roleData.find((item) => item.userId === assignRoleArg.userId)) {
        updateUserRole = roleData.map((item) => {
          if (item.userId === assignRoleArg.userId) {
            return {
              roleName: modifyRoleNameAndId(assignRoleArg, false, item),
              roleId: modifyRoleNameAndId(assignRoleArg, true, item),
              deletedRoleId: modifyDeletedRoleId(assignRoleArg, item),
              deletedRoleName: modifyDeletedRoleName(assignRoleArg, item),
              userId: assignRoleArg.userId,
              selectedRole: modifySelectedRole(assignRoleArg, item),
            };
          }
          return item;
        });
      } else {
        updateUserRole = [
          ...roleData,
          {
            roleName: modifyRoleNameAndId(assignRoleArg, false),
            roleId: modifyRoleNameAndId(assignRoleArg, true),
            deletedRoleId: modifyDeletedRoleId(assignRoleArg),
            deletedRoleName: modifyDeletedRoleName(assignRoleArg),
            userId: assignRoleArg.userId,
            selectedRole: modifySelectedRole(assignRoleArg),
          },
        ];
      }

      const fetchDataSuperUsersId = data
        .filter((item) => item.roles.map((item) => item.name).includes(ADMIN_SETTING.roles.superUser))
        .map((item) => item.id);

      const superUserSettingId = userRoles?.roleSettings.nodes.find(
        (item) => item.name === ADMIN_SETTING.roleNames.superAdmin,
      )?.role.id;

      const isSaveDisabled = !updateUserRole.reduce(
        (prev, curr) => {
          if (superUserSettingId && curr.deletedRoleId.includes(superUserSettingId)) {
            return prev.filter((item) => item !== curr.userId);
          }
          if (superUserSettingId && curr.roleId.includes(superUserSettingId)) {
            return [...prev, curr.userId as EmployeeId];
          }
          return prev;
        },
        [...fetchDataSuperUsersId],
      ).length;
      setIsDisableSave(isSaveDisabled);

      setRoleData(updateUserRole);
    },
    [
      data,
      modifyDeletedRoleId,
      modifyDeletedRoleName,
      modifyRoleNameAndId,
      modifySelectedRole,
      roleData,
      userRoles?.roleSettings.nodes,
    ],
  );

  const onFinish = (roleData: AssignRoleData[]) => {
    setAssignRoleLoading(true);
    const assignRoleData = roleData.reduce(
      (object, { userId, roleId }: AssignRoleData) => ({
        ...object,
        ...(roleId.length && { [`${userId}`]: roleId }),
      }),
      {} as ModifyAssignRoleData,
    );

    const destroyRoleData = roleData.reduce(
      (object, { userId, deletedRoleId }: AssignRoleData) => ({
        ...object,
        ...(deletedRoleId.length && { [`${userId}`]: deletedRoleId }),
      }),
      {} as ModifyAssignRoleData,
    );

    if (Object.keys(assignRoleData).length) {
      apolloClient()
        .mutate<RoleConnectionResponse>({
          mutation: gql`
          mutation{
            ${CERATE_USER_ROLE_CONNECTION(assignRoleData)}
          }
          ${ERROR_FRAGMENT}
        `,
        })
        .then((response) => {
          if (response.data) {
            let errorMessages: string[] = [];
            const isError = Object.keys(response.data)
              .map(
                (item) => response?.data?.[item as keyof CreateUserRoleConnectionResponse].errors as ValidationErrors,
              )
              .some((item) => {
                if (item && item.fullMessages.length) {
                  errorMessages = item.fullMessages;
                  return true;
                }
                return false;
              });
            if (!isError) {
              setAssignRoleLoading(false);
              setData([]);
              refetch({
                variables: {
                  first: TABLE.rowsPerPage,
                  after: undefined,
                  fetchBirthdaysThisWeek: false,
                  fetchWorkAnniversaryThisWeek: false,
                },
              });

              return notify.success({ message: "User role assigned successfully." });
            }
            setAssignRoleLoading(false);
            return notify.error(errorMessages);
          }

          return false;
        })
        .catch((error) => {
          notify.error(undefined, error.message);
        });
    }

    if (Object.keys(destroyRoleData).length) {
      apolloClient()
        .mutate<DestroyUserRoleConnectionResponse>({
          mutation: gql`
        mutation{
          ${DESTROY_USER_ROLE_CONNECTION(destroyRoleData)}
        }
      `,
        })
        .then((response) => {
          if (response.data) {
            const success = Object.keys(response.data)
              .map((item) => response?.data?.[item as keyof CreateUserRoleConnectionResponse])
              .some((item) => item && item.success);
            if (success) {
              setAssignRoleLoading(false);
              setData([]);
              refetch({
                variables: {
                  first: TABLE.rowsPerPage,
                  after: undefined,
                  fetchBirthdaysThisWeek: false,
                  fetchWorkAnniversaryThisWeek: false,
                },
              });
              return notify.success({ message: "User role destroyed successfully." });
            }
            setAssignRoleLoading(false);
            return false;
          }

          return false;
        })
        .catch((error) => {
          notify.error(undefined, error.message);
        });
    }
    setRoleData([]);
    setIsFieldsChanged(false);
  };

  const [vt] = useVT(
    () => ({
      onScroll: async ({ isEnd }) => {
        if (isEnd && pageInfo?.hasNextPage && pageInfo?.endCursor) {
          refetch({
            variables: {
              first: TABLE.rowsPerPage,
              after: pageInfo?.endCursor,
              fetchBirthdaysThisWeek: false,
              fetchWorkAnniversaryThisWeek: false,
            },
          });
        }
      },
      offset: 80,
      scroll: {
        y:
          getTableHeight("main", [
            "#admin_settings_title",
            "#admin_settings_tabs .ant-tabs-nav",
            "#clearer_note",
            "#assign_role_save",
          ]) - 8,
      },
      debug: false,
    }),
    [data, pageInfo],
  );

  useImperativeHandle(ref, () => ({
    resetForm() {
      setRoleData([]);
    },
    setIsFieldsChanged(value) {
      setIsFieldsChanged(value);
    },
    isFieldsChanged,
  }));

  const columns: ColumnsType<Employee> = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
        fixed: "left",
        width: 130,
        render: (name) => <Typography.Text strong>{name}</Typography.Text>,
      },
      {
        width: 17,
        className: "gradient",
      },
      {
        title: "Job description",
        width: 130,
        dataIndex: "jobTitle",
        render: (_, { jobTitle }) => <span>{jobTitle?.name || "-"}</span>,
      },

      ...(userRoles?.roleSettings.nodes || []).map(
        (item): ColumnType<Employee> => ({
          title: () => {
            if (
              item.name === ADMIN_SETTING.roleNames.superAdmin ||
              item.name === ADMIN_SETTING.roleNames.leaveRequest
            ) {
              return (
                <span className="d-inline-flex">
                  <span className="me-2">{item.name}</span>
                  <Popover
                    title={
                      <StyledPopOverTitle
                        className={
                          item.name !== ADMIN_SETTING.roleNames.superAdmin ? ADMIN_SETTING.roleNames.leaveRequest : ""
                        }
                      >
                        {item.name === "Superadmin"
                          ? `This person will have access to all information on the software. 
                        We usually only recommend one superuser.`
                          : "This is the purpose of the Manager role - to approve leave"}
                      </StyledPopOverTitle>
                    }
                  >
                    <Icon name="info" />
                  </Popover>
                </span>
              );
            }
            return <span>{item.name}</span>;
          },
          width: 110,
          align: "center",
          dataIndex: "roles",
          render: (_, { id, roles }) => {
            let showRole = [];
            const isSuperadmin = () => {
              if (roleData.find((item) => item.userId === id)) {
                const data = roleData.find((item) => item.userId === id);
                return data?.deletedRoleName.includes(ADMIN_SETTING.roles.manager);
              }
              return roles?.map((item) => item.name).includes(ADMIN_SETTING.roles.superUser);
            };

            const isManager = () => {
              if (roleData.find((item) => item.userId === id)) {
                const data = roleData.find((item) => item.userId === id);
                return !data?.deletedRoleName.includes(ADMIN_SETTING.roles.manager);
              }
              return roles?.map((item) => item.name).includes(ADMIN_SETTING.roles.manager);
            };

            if (isSuperadmin() || (!isManager() && !isSuperadmin())) {
              showRole = [ADMIN_SETTING.roleNames.superAdmin, ADMIN_SETTING.roleNames.manager];
            } else {
              showRole = [...userRoles!.roleSettings.nodes.map((item) => item.name)];
            }
            if (showRole.includes(item.name)) {
              return (
                <StyledCheckbox
                  className="my-2"
                  aria-label="checkboxLabel"
                  checked={
                    roleData.find((item) => item.userId === id)
                      ? roleData
                          .find((item) => item.userId === id)
                          ?.selectedRole.map((item) => item.id)
                          .includes(item.role.id)
                      : roles?.map((item) => item.id).includes(item.role.id)
                  }
                  disabled={
                    item.name === ADMIN_SETTING.roleNames.superAdmin && data.length === 1 ? true : !item.editable
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      onChange({
                        roleName: item.name,
                        roleId: item.role.id,
                        userId: id,
                        selectedRole: roles?.length ? roles : [],
                      });
                    } else {
                      onChange({
                        deletedRoleId: item.role.id,
                        deletedRoleName: item.name,
                        userId: id,
                        selectedRole: roles?.length ? roles : [],
                      });
                    }
                  }}
                />
              );
            }
            return <></>;
          },
        }),
      ),
    ],
    [data.length, onChange, roleData, userRoles],
  );
  return (
    <StyledWrapper>
      <Form
        form={form}
        onFinish={() => {
          if (isFieldsChanged) {
            onFinish(roleData);
          }
        }}
      >
        <StyledClearerSpan id="clearer_note">
          By selecting these settings, managers will have access to the corresponding details of all employees, not just
          those they directly manage as line managers.
        </StyledClearerSpan>
        <Table
          dataSource={data}
          bordered={false}
          columns={columns}
          rowKey="id"
          className="section-box-shadow"
          loading={userRoleLoading || loading}
          pagination={false}
          components={vt}
          scroll={{
            scrollToFirstRowOnChange: false,

            y:
              getTableHeight("main", [
                "#admin_settings_title",
                "#admin_settings_tabs .ant-tabs-nav",
                "#clearer_note",
                "#assign_role_save",
              ]) - 8,
          }}
        />
        <div className="mt-5" id="assign_role_save">
          {isDisableSave ? (
            <Tooltip
              title="You cannot change superuser status until you assign at least one other superuser"
              placement="topLeft"
            >
              <BaseButton htmlType="submit" disabled>
                Save
              </BaseButton>
            </Tooltip>
          ) : (
            <SuccessButton htmlType="submit" loading={assignRoleLoading}>
              Save
            </SuccessButton>
          )}
        </div>
      </Form>
      <UnsavedPrompt when={isFieldsChanged} />
    </StyledWrapper>
  );
});

export default AssignAdminAndManagersTab;

const StyledWrapper = styled.div`
  .ant-table-wrapper {
    margin-top: 24px;
  }
  .ant-table {
    .ant-table-tbody tr td,
    .ant-table-tbody tr td strong {
      font-size: 13px;
    }

    .ant-table-tbody tr td.gradient,
    .ant-table-thead tr td.gradient {
      background: linear-gradient(to right, ${COLORS.borderShadowColor} 2%, ${COLORS.white} 75%);
    }
  }
`;

const StyledPopOverTitle = styled.div`
  width: 280px;
  display: block;
  text-align: center;
  &.leave-request {
    width: 230px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper:hover .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border-color: ${COLORS.primaryColor};
  }
`;

const StyledClearerSpan = styled.span`
  font-size: 13px;
  font-weight: 500;
`;
