import { useLazyQuery, useMutation } from "@apollo/client";
import { Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { IconButton } from "components/Buttons";
import { ReminderPopover } from "components/Popover";
import { ConfirmModal } from "components/modal";
import DATE_FORMAT from "constants/dateFormat";
import TABLE from "constants/table";
import dayjs from "dayjs";
import useFormModal from "hooks/useFormModal";
import { PageInfo } from "model/Common";
import {
  CompanyId,
  CompanyRefetch,
  CompanyResponse,
  DestroyCompany,
  FetchCompany,
  PaymentTypeLabel,
} from "model/Company";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import company from "routes/company";
import { useNotify } from "services/api";
import { COMPANIES, DESTROY_COMPANY } from "services/graphql/company";
import styled from "styled-components";
import { getTableHeight } from "utils";
import { useVT } from "virtualizedtableforantd4";

type CompanyTableProps = {
  onEdit: (id: CompanyId) => void;
  searchValue?: string;
};

const CompanyTable = forwardRef<CompanyRefetch, CompanyTableProps>(({ onEdit, searchValue }, ref) => {
  const navigate = useNavigate();
  const [data, setCompaniesData] = useState<FetchCompany[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [deleteId, setDeleteId] = useState<CompanyId>();
  const {
    isModalVisible: isDestroyConfirmModalVisible,
    hideModal: hideDestroyConfirmModal,
    showModal: showDestroyConfirmModal,
  } = useFormModal();
  const notify = useNotify();

  const columns: ColumnsType<FetchCompany> = useMemo(
    () => [
      {
        title: "Company ID",
        dataIndex: "companyId",
        key: "companyId",
        align: "left",
        width: 130,
        fixed: "left",
      },
      {
        title: "Company Name",
        dataIndex: "companyName",
        width: 150,
        render: (_, { companyName, superuser }) => (
          <span className="d-flex gap-2">
            <Typography.Text strong>{companyName}</Typography.Text>
            {superuser && !superuser.loggedIn && <ReminderPopover userId={superuser.id} type="login" />}
          </span>
        ),
      },

      {
        title: "Sign up date",
        dataIndex: "signedAt",
        width: 150,
        render: (_, { signedAt }) => <span>{dayjs(signedAt).format(DATE_FORMAT.fullDateDefault)}</span>,
      },
      {
        title: "Super User",
        dataIndex: "superuserName",
        width: 200,
      },
      {
        title: "Superuser Email",
        dataIndex: "superuserEmail",
        width: 250,
      },
      {
        title: "Superuser phone",
        dataIndex: "superuserPhone",
        width: 200,
      },
      {
        title: "Payment type",
        dataIndex: "paymentType",
        width: 150,
        render: (_, { paymentType }) => PaymentTypeLabel[paymentType],
      },

      {
        width: 40,
        title: "",
        dataIndex: "edit",
        fixed: "right",
        align: "center",
        render: (_, { id }) => (
          <Tooltip title="Edit">
            <IconButton
              icon="edit"
              data-testid="edit-company-btn"
              onClick={(event) => {
                event.stopPropagation();
                onEdit(id);
              }}
            />
          </Tooltip>
        ),
      },
      {
        width: 40,
        title: "",
        dataIndex: "remove",
        fixed: "right",
        align: "center",
        render: (_, { id }) => (
          <Tooltip title="Remove">
            <IconButton
              icon="remove"
              data-testid="remove-company-btn"
              onClick={(event) => {
                event.stopPropagation();
                setDeleteId(id);
                showDestroyConfirmModal();
              }}
            />
          </Tooltip>
        ),
      },
    ],
    [onEdit, showDestroyConfirmModal],
  );

  const [fetchCompaniesData, { loading }] = useLazyQuery<CompanyResponse>(COMPANIES, {
    variables: { first: TABLE.rowsPerPage, searchValue },
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      const companies: FetchCompany[] = response.companies.nodes.map((c: FetchCompany) => ({ ...c })) ?? [];
      setCompaniesData((d) => [...d, ...companies]);
      if (response.companies.pageInfo) setPageInfo(response.companies.pageInfo);
    },
  });

  const [removeCompany] = useMutation<DestroyCompany>(DESTROY_COMPANY, {
    onCompleted: (response) => {
      if (response?.destroyCompany?.success) {
        notify.success({ message: "Destroy company successfully." });
        hideDestroyConfirmModal();
        const companies = data.filter((company) => company.id !== deleteId);
        setCompaniesData(companies);
      }
    },
  });

  useImperativeHandle(ref, () => ({
    companyRefetch() {
      setCompaniesData([]);
      fetchCompaniesData({ variables: { first: TABLE.rowsPerPage, searchValue } });
    },
  }));

  const [vt] = useVT(
    () => ({
      onScroll: async ({ isEnd }) => {
        if (isEnd && pageInfo?.hasNextPage && pageInfo?.endCursor) {
          fetchCompaniesData({ variables: { first: TABLE.rowsPerPage, after: pageInfo?.endCursor, searchValue } });
        }
      },
      offset: 80,
      scroll: {
        y: getTableHeight("main", ["#company_filter"]),
      },
      debug: false,
    }),
    [data, pageInfo],
  );

  useEffect(() => {
    setCompaniesData([]);
    fetchCompaniesData({ variables: { first: TABLE.rowsPerPage, searchValue } });
  }, [fetchCompaniesData, searchValue]);

  return (
    <StyledTableWrapper>
      <Table
        columns={columns}
        dataSource={data}
        components={vt}
        pagination={false}
        rowKey="id"
        loading={loading}
        scroll={{
          scrollToFirstRowOnChange: false,
          y: getTableHeight("main", ["#company_filter"]),
        }}
        onRow={(record: FetchCompany) => ({
          onClick: () => {
            navigate(company.view(record.id));
          },
        })}
      />
      <ConfirmModal
        onCancel={hideDestroyConfirmModal}
        open={isDestroyConfirmModalVisible}
        onOk={() => removeCompany({ variables: { id: deleteId } })}
        width={310}
        title="Are you sure you want to delete this company?"
        okText="Yes"
        cancelText="No"
      />
    </StyledTableWrapper>
  );
});

export default CompanyTable;

const StyledTableWrapper = styled.div`
  .ant-table-tbody tr:hover {
    cursor: pointer;
  }
`;
