import { Elements } from "@stripe/react-stripe-js";
import { assertError } from "utils/misc";
import { loadStripe } from "@stripe/stripe-js";
import STRIPE from "constants/stripe";
import { BillingRef } from "model/AdminSetting";
import { forwardRef } from "react";
import BillingForm from "./Billing/BillingForm";

const stripePublishableKey =
  process.env.REACT_APP_BILLING_PUBLISHABLE_KEY ?? assertError("REACT_APP_BILLING_PUBLISHABLE_KEY is not defined");
const stripePromise = loadStripe(stripePublishableKey);

interface Props {
  fetchDataAfterMutation: () => void;
}

const BillingTab = forwardRef<BillingRef, Props>(({ fetchDataAfterMutation }, ref) => (
  <Elements stripe={stripePromise} options={STRIPE.stripeElementsOptions}>
    <BillingForm ref={ref} fetchDataAfterMutation={fetchDataAfterMutation} />
  </Elements>
));

export default BillingTab;
