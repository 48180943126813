import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, from } from "@apollo/client";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { OPERATION_ERRORS } from "constants/apolloSetting";
import { ReactNode } from "react";
import { UseNotifyResponse, useNotify } from "services/api";
import { assertError } from "utils/misc";
import storage from "utils/storage";

interface HandleStopeErrorProps extends Required<Pick<ErrorResponse, "graphQLErrors" | "operation">> {
  notify?: UseNotifyResponse;
}

export const wsUri = process.env.REACT_APP_WS_URL ?? assertError("REACT_APP_WS_URL is not defined");
const uri = process.env.REACT_APP_API_URL ?? assertError("REACT_APP_API_URL is not defined");

const httpLink = createUploadLink({ uri });

const authLink = new ApolloLink((operation, forward) => {
  const token = storage.getAuthToken();
  if (token) {
    operation.setContext({
      headers: {
        x_auth_token: `${token}`,
      },
    });
  }
  return forward(operation);
});

const handleStopErrors = ({ graphQLErrors, operation, notify }: HandleStopeErrorProps) => {
  if (Object.keys(OPERATION_ERRORS.stop).findIndex((item) => item === operation.operationName) >= 0 && notify) {
    const stopMessageItems = OPERATION_ERRORS.stop[operation.operationName as keyof typeof OPERATION_ERRORS.stop];
    const stopMessagePath = stopMessageItems.map((item) => item.path);
    const stopMessage = stopMessageItems.map((item) => item.errorMessage);
    graphQLErrors.forEach((error) => {
      if (error.path && stopMessagePath.includes(`${error?.path?.[0]}`)) {
        if (!stopMessage.includes(error.message)) {
          notify.error(undefined, error.message);
        }
      } else {
        notify.error(undefined, error.message);
      }
    });
  } else if (notify) notify.error(graphQLErrors.map((item) => item.message));
};

export const errorLink = (notify?: UseNotifyResponse) =>
  onError(({ graphQLErrors, operation, networkError }) => {
    if (graphQLErrors && notify) {
      if (graphQLErrors.find((item) => item.message === OPERATION_ERRORS.authenticationErrorMessage)) {
        notify.error(undefined, OPERATION_ERRORS.authenticationErrorMessage);
        setTimeout(() => {
          storage.clearAuthTokens();
          window.location.href = "/";
        }, 1000);
      } else {
        handleStopErrors({ graphQLErrors, operation, notify });
      }
    }
    if (networkError && notify) {
      notify.error(undefined, networkError.message);
    }
  });

export const apolloClient = (notify?: UseNotifyResponse) =>
  new ApolloClient({
    link: from([errorLink(notify), authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
    defaultOptions: {
      mutate: {
        errorPolicy: "all",
      },
    },
  });

export default ({ children }: { children: ReactNode }) => {
  const notify = useNotify();
  return <ApolloProvider client={apolloClient(notify)}>{children}</ApolloProvider>;
};
