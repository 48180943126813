export default () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4409_18130)">
      <path
        d="M14.7207 2.54744C15.0832 2.54744 15.4499 2.54744 15.8124 2.53876C15.8957 2.53876 15.9457 2.59083 15.9999 2.63857C15.9999 4.2139 15.9999 5.78488 15.9999 7.36021C15.9582 7.39493 15.9124 7.4253 15.8707 7.46002C15.8415 7.4904 15.804 7.50776 15.7624 7.51644C15.479 7.5772 15.1915 7.62927 14.904 7.68135C14.8749 7.68569 14.8415 7.68135 14.8124 7.66833C14.7957 7.65531 14.7874 7.63795 14.779 7.62059C14.754 7.52946 14.7624 7.43832 14.7624 7.34719C14.7624 6.06263 14.7624 4.77806 14.7624 3.4935C14.7624 3.17236 14.7832 2.85556 14.7207 2.54744Z"
        fill="#EA863A"
      />
      <path
        d="M14.721 2.54742C14.7877 2.62987 14.8168 2.72101 14.8168 2.8295C14.8127 4.4352 14.8127 6.04091 14.8127 7.64661C14.7668 7.70737 14.696 7.71171 14.6293 7.72473C12.9252 8.04587 11.221 8.36701 9.51683 8.69249C9.41266 8.70985 9.30433 8.7706 9.20016 8.68815C9.13766 8.53626 9.17516 8.38003 9.16683 8.2238C9.16266 8.0936 9.16683 7.96341 9.16266 7.83322C9.16266 7.69869 9.10016 7.63359 8.97099 7.63359C8.76683 7.63359 8.56683 7.62925 8.36266 7.63359C7.97099 7.63793 7.58349 7.63359 7.19183 7.63359C6.82516 7.63359 6.82099 7.63793 6.82099 8.02851C6.82099 8.1804 6.82099 8.33229 6.82099 8.48418C6.82099 8.55362 6.82516 8.62739 6.78766 8.68815C6.72099 8.74457 6.65016 8.72287 6.57933 8.70985C4.54183 8.32361 2.50433 7.94171 0.466826 7.55548C0.200159 7.5034 0.200159 7.5034 0.200159 7.78982C0.200159 9.72535 0.200159 11.6609 0.200159 13.5964C0.200159 13.6919 0.225159 13.7917 0.145992 13.8741C0.108492 13.8134 0.125159 13.7483 0.125159 13.6832C0.125159 11.6609 0.125159 9.63855 0.125159 7.62057C0.125159 7.53378 0.137659 7.45132 0.0459925 7.39491C-0.0206742 7.35585 -0.00400752 7.26905 -0.00400752 7.19962C-0.00400752 5.73712 -0.00400752 4.27463 -0.00400752 2.8078C-0.00400752 2.59081 0.0418258 2.54742 0.254326 2.54742C1.23349 2.54742 2.21266 2.54742 3.18766 2.54742C3.25016 2.54742 3.31266 2.54308 3.37516 2.54308C3.42516 2.491 3.48766 2.49534 3.55016 2.49534C3.88349 2.49534 4.21266 2.48666 4.54183 2.49968C4.68766 2.49534 4.82933 2.49968 4.97516 2.49968C5.22099 2.49968 5.22099 2.49968 5.22933 2.24797C5.22933 2.16986 5.21266 2.08306 5.28349 2.02231C5.37516 2.07439 5.35433 2.16986 5.35433 2.25231C5.36266 2.491 5.36266 2.49534 5.59599 2.49534C7.23766 2.49534 8.87933 2.49534 10.521 2.49534C10.5835 2.49534 10.646 2.49534 10.7085 2.49968C11.096 2.49534 11.4793 2.491 11.8668 2.49968C12.0585 2.491 12.2502 2.49534 12.4418 2.49534C12.5043 2.49534 12.5668 2.49534 12.6168 2.54742C13.3252 2.54308 14.021 2.54742 14.721 2.54742Z"
        fill="#F6A043"
      />
      <path
        d="M9.22122 8.69684C9.70872 8.6057 10.1962 8.51891 10.6837 8.42777C11.4712 8.28022 12.2587 8.12833 13.0462 7.97644C13.6337 7.86361 14.2254 7.75511 14.8129 7.64662V7.65096C14.8671 7.71172 14.8629 7.78983 14.8629 7.86795C14.8629 9.81215 14.8629 11.7564 14.8629 13.7006C14.8629 13.8221 14.8546 13.9436 14.7212 13.9957C14.6754 13.9957 14.6296 14 14.5796 14C9.85456 14 5.12539 14 0.400391 14C0.300391 14 0.183724 14.026 0.150391 13.8741C0.154557 13.8047 0.154557 13.7353 0.154557 13.6615C0.154557 11.6609 0.154557 9.6646 0.154557 7.66398C0.154557 7.46001 0.154557 7.45567 0.337891 7.49039C1.07539 7.62492 1.81289 7.76813 2.55039 7.90701C3.38789 8.06324 4.22956 8.21513 5.06706 8.3757C5.60456 8.47551 6.14206 8.58834 6.68372 8.6925C6.71289 8.69684 6.74622 8.69684 6.77539 8.70118C6.83789 8.76627 6.82539 8.85307 6.82539 8.93118C6.82956 9.22629 6.82539 9.52573 6.82539 9.82083C6.82956 10.0335 6.86706 10.0769 7.07539 10.0769C7.50456 10.0812 7.93789 10.0769 8.36706 10.0769C8.55456 10.0812 8.73789 10.0769 8.92539 10.0769C9.12539 10.0769 9.16289 10.0378 9.16706 9.83385C9.17122 9.52573 9.16706 9.22195 9.17122 8.91382C9.17539 8.83571 9.16289 8.75325 9.22122 8.69684Z"
        fill="#CB7538"
      />
      <path
        d="M14.7207 13.9913C14.7999 13.9176 14.8165 13.8221 14.8165 13.7136C14.8124 11.6913 14.8165 9.6733 14.8165 7.65098C15.1707 7.58588 15.5207 7.52078 15.8749 7.45569C15.8749 9.55612 15.8749 11.6566 15.8749 13.757C15.8749 13.9436 15.8207 13.9957 15.6415 13.9957C15.3332 13.9957 15.029 13.9957 14.7207 13.9913Z"
        fill="#B26232"
      />
      <path
        d="M11.8793 2.53875C11.4918 2.53875 11.1043 2.53875 10.7168 2.53875C10.6459 2.29139 10.6376 2.03534 10.7251 1.79232C10.7959 1.59269 10.9709 1.52759 11.1668 1.51457C11.3584 1.50155 11.3751 1.47985 11.3626 1.28023C11.3334 0.893989 11.0418 0.581528 10.6668 0.542471C10.6168 0.538131 10.5626 0.538131 10.5126 0.538131C8.84176 0.538131 7.17509 0.538131 5.50426 0.538131C5.27093 0.538131 5.06259 0.590208 4.89176 0.763797C4.73759 0.920028 4.65843 1.10664 4.65009 1.32796C4.64593 1.40174 4.65426 1.47985 4.64176 1.55363C4.52093 1.79666 4.64176 2.04836 4.61676 2.29139C4.60843 2.37384 4.62926 2.46498 4.57093 2.53875C4.17509 2.53875 3.78343 2.53875 3.38759 2.53875C3.38759 2.3695 3.38343 2.20459 3.38759 2.03534C3.39176 1.73156 3.55843 1.56665 3.85009 1.56231C4.12509 1.55797 4.12509 1.55797 4.13343 1.26721C4.16259 0.581528 4.70843 0.00434286 5.36676 0.00434286C7.12509 -0.00433662 8.88343 -0.00433662 10.6459 0.00434286C11.3043 0.0086826 11.8501 0.581528 11.8793 1.26721C11.8834 1.36268 11.8834 1.4625 11.8834 1.55797C11.9043 1.84005 11.9459 2.1178 11.9251 2.39988C11.9168 2.45196 11.9126 2.49969 11.8793 2.53875Z"
        fill="#4A4C56"
      />
      <path
        d="M11.8792 2.53876C11.8792 2.33913 11.8958 2.1395 11.8708 1.94421C11.8542 1.81402 11.8083 1.68817 11.8792 1.56232C11.9833 1.56232 12.0875 1.56232 12.1875 1.56666C12.4417 1.571 12.6167 1.74459 12.625 2.00931C12.6292 2.18724 12.625 2.36517 12.625 2.5431C12.375 2.53876 12.125 2.53876 11.8792 2.53876Z"
        fill="#3F4149"
      />
      <path
        d="M8.37513 10.1203C7.9168 10.1203 7.45846 10.1246 7.00013 10.1203C6.8418 10.1203 6.78346 10.0595 6.78346 9.89462C6.7793 9.49537 6.7793 9.09177 6.7793 8.69252C6.7793 8.40175 6.7793 8.11099 6.78346 7.81589C6.78346 7.6423 6.8418 7.58154 7.00846 7.58154C7.46263 7.58154 7.92096 7.58154 8.37513 7.58154C8.47513 7.60758 8.5168 7.68136 8.5168 7.78551C8.52096 8.48855 8.52096 9.19159 8.5168 9.89462C8.5168 10.0031 8.4918 10.0943 8.37513 10.1203Z"
        fill="#FCC96C"
      />
      <path
        d="M8.375 10.1203C8.45 10.0639 8.47083 9.9901 8.47083 9.89462C8.46667 9.19159 8.46667 8.48855 8.47083 7.78551C8.47083 7.69438 8.44167 7.62928 8.375 7.58154C8.5875 7.58154 8.8 7.58154 9.0125 7.58154C9.15833 7.58154 9.22083 7.65098 9.22083 7.79853C9.21667 8.09797 9.22083 8.39307 9.22083 8.69252C9.22083 9.09177 9.22083 9.49103 9.22083 9.89028C9.22083 10.0726 9.17083 10.1203 9 10.1203C8.79167 10.1246 8.58333 10.1203 8.375 10.1203Z"
        fill="#F6AE3C"
      />
      <path
        d="M4.59604 1.56232C4.66271 1.56232 4.72937 1.56666 4.79604 1.56666C5.12521 1.56666 5.25854 1.69251 5.29187 2.03969C5.28771 2.17422 5.28354 2.30875 5.28771 2.44762C5.28771 2.52574 5.26271 2.55178 5.18771 2.55178C4.97937 2.54744 4.77104 2.54744 4.56271 2.5431C4.54187 2.30007 4.60021 2.05271 4.51271 1.80968C4.47521 1.71421 4.60437 1.65779 4.59604 1.56232Z"
        fill="#3F4149"
      />
    </g>
    <defs>
      <clipPath id="clip0_4409_18130">
        <rect width="16" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
