import { Col, Row, Typography } from "antd";
import Icon from "components/Icon";
import COLORS, { ColorType } from "constants/colors";
import { ReactElement } from "react";
import styled from "styled-components";

type Props = {
  label: string;
  onClick: () => void;
};

export const BreadCrumb = ({ label, onClick }: Props): ReactElement => (
  <Row>
    <Col span={24} className="d-flex justify-content-start align-items-center">
      <StyledBreadCrumbsIcon
        name="left"
        size="medium"
        color={COLORS.breadCrumbs as ColorType}
        data-testid="breadcrumb-icon"
        onClick={onClick}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === "Enter") {
            onClick();
          }
        }}
        tabIndex={0}
        role="button"
      />
      <Typography.Title className="mb-0 ms-4" data-testid="breadcrumb-label">
        {label}
      </Typography.Title>
    </Col>
  </Row>
);

export default BreadCrumb;

const StyledBreadCrumbsIcon = styled(Icon)`
  stroke-width: 60;
  stroke: ${COLORS.breadCrumbs};
  &:focus-visible {
    outline: 1px solid ${COLORS.outlineColor};
    outline-offset: 0px;
    border-radius: 2px;
    transition: outline-offset 0s, outline 0s;
  }
`;
