import { Row, Col, Typography, Form, Radio, DatePicker, TimePicker, InputNumber, Image } from "antd";
import { BaseButton, DeclineButton, SuccessButton } from "components/Buttons";
import { InputAddDropDown, InputDropDown, InputFile, InputText, SelectRefType } from "components/Inputs";
import styled from "styled-components";
import { useForm } from "antd/lib/form/Form";
import { EMPLOYEE } from "constants/employee";
import dayjs from "dayjs";
import InputCheckboxButton from "components/Inputs/InputCheckboxButton/InputCheckboxButton";
import { usePreventUnsavedForm } from "contexts/PreventUnsavedFormProvider";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDepartments, useJobTitles, useLocations } from "hooks";
import Icon from "components/Icon";
import { Container } from "components/core/Container";
import DATE_FORMAT from "constants/dateFormat";
import TIME_FORMAT from "constants/timeFormat";
import { enumValues } from "utils/misc";
import {
  ContractType,
  Currency,
  EmergencyContact,
  EmployeeDetailArgs,
  Gender,
  ModifyEmergencyContact,
  ModifyEmployeeDetailArg,
  PaymentType,
  contractTypeLabel,
  genderLabel,
  ModifyJobDetailResponse,
  paymentTypeLabel,
  EditEmployeeDetailResponse,
  FetchEmployeeResponse,
  EmployeeFullDetail,
  EmployeeEditProfileFullDetail,
} from "model/Employee";
import { useMutation, useQuery } from "@apollo/client";
import { useNotify } from "services/api";
import {
  FETCH_EMPLOYEE_FULL_DETAIL,
  FETCH_MY_PROFILE_EDIT_DETAIL,
  UPSERT_MY_PROFILE_DETAIL,
  UPSERT_EMPLOYEE_DETAILS,
  FETCH_LINE_MANAGERS,
  FETCH_USER,
} from "services/graphql/employee";
import ALERTS, { fileSize } from "config/alerts";
import { useAuthContext } from "contexts";
import COLORS from "constants/colors";
import LABEL from "constants/label";
import { WorkingCondition } from "model/Common";
import { AnnualLeaveEnum, annualLeaveLabel } from "model/Leave";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import { FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE } from "services/graphql/leave";
import { Admins } from "model/User";
import useUploadProfileImage from "./useUploadProfileImage";

const EmployeeEditForm = () => {
  const { user, refetch } = useAuthContext();
  const departmentSelectRef = useRef<SelectRefType>(null);
  const locationSelectRef = useRef<SelectRefType>(null);
  const jobTitleSelectRef = useRef<SelectRefType>(null);
  const [isFieldsChanged, setIsFieldsChanged] = useState<boolean>(false);
  const [profilePicture, setProfilePicture] = useState<File | null>();
  const [removeProfilePicture, setRemoveProfilePicture] = useState<boolean>(false);
  const { showConfirmModal } = usePreventUnsavedForm();
  const navigate = useNavigate();
  const [form] = useForm();
  const notify = useNotify();

  const { id } = useParams();
  const userId = id || user?.id;
  const isMyProfile = userId === user?.id;
  const { updatePersonalDetails, updateJobDetails, updateEmergencyDetails, updateSalaryDetails } = PERMISSION;
  const { hasUpdatePersonalDetails, hasUpdateJobDetails, hasUpdateEmergencyDetails, hasUpdateSalaryDetails } =
    useCheckPermission({
      updatePersonalDetails,
      updateJobDetails,
      updateEmergencyDetails,
      updateSalaryDetails,
    });

  const selectedDepartment = Form.useWatch("departmentIds", form);
  const selectedLineManager = Form.useWatch("lineManagerIds", form);
  const selectedJobTitle = Form.useWatch("jobTitleId", form);

  const {
    departments,
    loading: departmentsLoading,
    handleDropdownScroll: handleDepartmentDropdownScroll,
    createDepartment,
  } = useDepartments({
    skip: !id,
    notify,
    after: true,
    onCompleted: (response) => {
      if (selectedDepartment && selectedDepartment.length) {
        form.setFieldValue("departmentIds", [...selectedDepartment, response.createDepartment.department.id]);
      } else {
        form.setFieldValue("departmentIds", [response.createDepartment.department.id]);
      }
      form.validateFields(["departmentIds"]);
      departmentSelectRef.current?.onAddSuccessFully();
      setIsFieldsChanged(true);
    },
  });
  const {
    locations,
    loading: locationsLoading,
    handleDropdownScroll: handleLocationDropdownScroll,
    createLocation,
  } = useLocations({
    skip: !id,
    notify,
    after: true,
    onCompleted: (response) => {
      form.setFieldValue("locationId", response.createLocation.location.id);
      locationSelectRef.current?.onAddSuccessFully();
      setIsFieldsChanged(true);
    },
  });
  const {
    jobTitles,
    loading: jobTitlesLoading,
    handleDropdownScroll: handleJobTitleDropdownScroll,
    createJobTitle,
  } = useJobTitles({
    skip: !id,
    notify,
    after: true,
    onCompleted: (response) => {
      form.setFieldValue("jobTitleId", response.createJobTitle.jobTitle.id);
      jobTitleSelectRef.current?.onAddSuccessFully();
      setIsFieldsChanged(true);
    },
  });

  const fetchDetail = id ? FETCH_EMPLOYEE_FULL_DETAIL : FETCH_MY_PROFILE_EDIT_DETAIL;
  const mutateDetail = id
    ? UPSERT_EMPLOYEE_DETAILS(
        hasUpdatePersonalDetails,
        hasUpdateJobDetails,
        hasUpdateEmergencyDetails,
        hasUpdateSalaryDetails,
      )
    : UPSERT_MY_PROFILE_DETAIL;

  const { data: lineManagers, loading: lineManagersLoading } = useQuery<FetchEmployeeResponse>(FETCH_LINE_MANAGERS, {
    variables: { first: LABEL.rowsPerPage, roles: [Admins.Superuser.toUpperCase(), Admins.Manager.toUpperCase()] },
    fetchPolicy: "no-cache",
    skip: !hasUpdateJobDetails || !id,
  });

  const onClose = () => {
    if (isFieldsChanged) {
      showConfirmModal(() => {
        setIsFieldsChanged(false);
        navigate(-1);
      });
    } else {
      navigate(-1);
    }
  };

  useQuery<EmployeeFullDetail | EmployeeEditProfileFullDetail>(fetchDetail, {
    variables: {
      ...(id
        ? {
            userId,
            hasFetchPersonalDetail: !hasUpdatePersonalDetails,
            hasFetchJobDetail: !hasUpdateJobDetails,
            hasFetchEmergencyContact: !hasUpdateEmergencyDetails,
            hasFetchSalary: !hasUpdateSalaryDetails,
          }
        : {}),
    },
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
    onCompleted: (response) => {
      if (response) {
        const responseData = !id
          ? (response as EmployeeEditProfileFullDetail)?.fetchEditProfileOverview
          : (response as EmployeeFullDetail);
        const emergencyDetail = responseData.fetchEmergencyContact
          ? Object.keys(responseData.fetchEmergencyContact).reduce(
              (prev, key) => ({
                ...prev,
                [`${
                  key.charAt(0) !== "_" && key !== "id" ? `emergency${key.charAt(0).toUpperCase() + key.slice(1)}` : key
                }`]: responseData.fetchEmergencyContact?.[key as keyof EmergencyContact],
              }),
              {} as ModifyEmergencyContact,
            )
          : {};
        const jobDetail =
          id && responseData.fetchJobDetail
            ? EMPLOYEE.days.reduce(
                (object, item) => ({
                  ...object,
                  daysPerWeek:
                    object.workingCondition && object.workingCondition[item.value as keyof WorkingCondition] === true
                      ? [...object.daysPerWeek, `${item.value}`]
                      : [...object.daysPerWeek],
                }),
                {
                  ...responseData.fetchJobDetail,
                  daysPerWeek: [],
                  startTime:
                    responseData.fetchJobDetail.workingCondition?.startTime &&
                    dayjs(responseData.fetchJobDetail.workingCondition.startTime, TIME_FORMAT.timeFormatWith24Hour),
                  startedAt: responseData.fetchJobDetail.startedAt && dayjs(responseData.fetchJobDetail.startedAt),
                  endTime:
                    responseData.fetchJobDetail.workingCondition?.endTime &&
                    dayjs(responseData.fetchJobDetail.workingCondition.endTime, TIME_FORMAT.timeFormatWith24Hour),
                  endedAt: responseData.fetchJobDetail.endedAt && dayjs(responseData.fetchJobDetail.endedAt),
                  departmentIds: responseData.fetchJobDetail.departments.map((item) => item.id),
                  jobTitleId: responseData.fetchJobDetail.jobTitle?.id,
                  lineManagerIds: responseData.fetchJobDetail.lineManagers.map((item) => item.id),
                  locationId: responseData.fetchJobDetail.location?.id,
                } as ModifyJobDetailResponse,
              )
            : ({} as ModifyJobDetailResponse);

        if (jobDetail && responseData.fetchJobDetail) {
          ["workingCondition", "departments", "jobTitle", "lineManagers", "location"].map(
            (key) => delete jobDetail[key as keyof typeof jobDetail],
          );
        }

        const salaryDetails = id && responseData.fetchSalary ? responseData.fetchSalary : {};

        form.setFieldsValue({
          ...jobDetail,
          ...emergencyDetail,
          ...salaryDetails,
          ...responseData.fetchPersonalDetail,
          dateOfBirth:
            responseData.fetchPersonalDetail.dateOfBirth && dayjs(responseData.fetchPersonalDetail.dateOfBirth),
        });
      }
    },
  });

  const [mutate, { loading }] = useMutation<EditEmployeeDetailResponse>(mutateDetail, {
    onCompleted: (response) => {
      const { upsertPersonalDetail, upsertJobDetail, upsertEmergencyContact, upsertSalary } = response;
      if (upsertPersonalDetail?.errors) {
        notify.error(upsertPersonalDetail.errors.fullMessages);
      }
      if (upsertJobDetail?.errors) {
        notify.error(upsertJobDetail.errors.fullMessages);
      }
      if (upsertEmergencyContact?.errors) {
        notify.error(upsertEmergencyContact.errors.fullMessages);
      }
      if (upsertSalary?.errors) {
        notify.error(upsertSalary.errors.fullMessages);
      }

      const permissions = {
        hasUpdatePersonalDetails: hasUpdatePersonalDetails ? "hasUpdatePersonalDetails" : `notFetchPersonalDetail`,
        hasUpdateJobDetails: hasUpdateJobDetails ? "hasUpdateJobDetails" : `notUpdateJobDetails`,
        hasUpdateEmergencyDetails: hasUpdateEmergencyDetails
          ? "hasUpdateEmergencyDetails"
          : `notUpdateEmergencyDetails`,
        hasUpdateSalaryDetails: hasUpdateSalaryDetails ? "hasUpdateSalaryDetails" : `notUpdateSalaryDetails`,
      };

      const upsertDetails = {
        [permissions.hasUpdatePersonalDetails]: !!upsertPersonalDetail?.personalDetail,
        [permissions.hasUpdateJobDetails]: !!upsertJobDetail?.jobDetail,
        [permissions.hasUpdateEmergencyDetails]: !!upsertEmergencyContact?.emergencyContact,
        [permissions.hasUpdateSalaryDetails]: !!upsertSalary?.salary,
      };

      const checkSuccess = Object.keys(permissions)
        .filter((item) => Object.keys(upsertDetails).includes(item))
        .map((item) => upsertDetails[item])
        .every((item) => !!item);

      if (id && checkSuccess) {
        notify.success({ message: "Update employee detail successfully." });
        navigate(-1);
      }

      if (!id && upsertPersonalDetail?.personalDetail && upsertEmergencyContact?.emergencyContact) {
        notify.success({ message: "Update profile successfully." });
        if (isMyProfile) {
          refetch();
        }
        navigate(-1);
      }
    },
    refetchQueries: [
      ...(id ? [{ query: FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE, variables: { userId } }] : []),
      {
        query: fetchDetail,
        variables: {
          ...(id
            ? {
                userId,
                hasFetchPersonalDetail: !hasUpdatePersonalDetails,
                hasFetchJobDetail: !hasUpdateJobDetails,
                hasFetchEmergencyContact: !hasUpdateEmergencyDetails,
                hasFetchSalary: !hasUpdateSalaryDetails,
              }
            : {}),
        },
      },
      ...(!isMyProfile
        ? [
            {
              query: FETCH_USER,
              variables: {
                userIds: [userId],
                fetchBirthdaysThisWeek: false,
                fetchWorkAnniversaryThisWeek: false,
                showLeavers: false,
              },
            },
          ]
        : []),
    ],
  });

  const { mutate: mutateUpload, loading: uploading, mutateDestroyProfile } = useUploadProfileImage();

  const onFinish = (data: EmployeeDetailArgs) => {
    const variables = id
      ? EMPLOYEE.days.reduce(
          (object, item) => ({ ...object, [`${item.value}`]: object?.daysPerWeek?.includes(`${item.value}`) }),
          {
            ...data,
            endTime: dayjs(data.endTime).format(TIME_FORMAT.timeFormatWith24Hour),
            startTime: dayjs(data.startTime).format(TIME_FORMAT.timeFormatWith24Hour),
            startedAt: data.startedAt ? dayjs(data.startedAt).format(DATE_FORMAT.isoFormat) : null,
            endedAt: data.endedAt ? dayjs(data.endedAt).format(DATE_FORMAT.isoFormat) : null,
            dateOfBirth: data.dateOfBirth ? dayjs(data.dateOfBirth).format(DATE_FORMAT.isoFormat) : null,
          } as ModifyEmployeeDetailArg,
        )
      : ({
          ...data,
          dateOfBirth: data.dateOfBirth ? dayjs(data.dateOfBirth).format(DATE_FORMAT.isoFormat) : null,
        } as ModifyEmployeeDetailArg);

    if (id) {
      delete variables.daysPerWeek;
    }

    if (isMyProfile) {
      delete variables.profilePicture;
    }

    if (isFieldsChanged) {
      mutate({
        variables: {
          ...variables,
          userId,
        },
      });
    }
    if (isFieldsChanged && profilePicture) {
      mutateUpload({
        variables: {
          profileImage: profilePicture,
        },
      });
    } else if (isFieldsChanged && removeProfilePicture) {
      mutateDestroyProfile();
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish} onValuesChange={(val) => setIsFieldsChanged(!!val)}>
      <Row className="align-items-center">
        <Col span={12}>
          <StyledTitle level={2}>Edit employee</StyledTitle>
        </Col>
      </Row>
      <Container className="p-0 section-box-shadow">
        <StyledContainer>
          {(hasUpdatePersonalDetails || !id) && (
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Typography.Title className="border-bottom pb-3 m-0" level={3}>
                      Personal Details
                    </Typography.Title>
                  </Col>
                </Row>
                <Row className="mt-4" gutter={[38, 0]}>
                  {id && (
                    <Col md={24} lg={8} xl={7}>
                      <Form.Item label="Employee number" name="employeeNumber">
                        <InputNumber className="w-100" controls={false} min={0} data-testid="employeeNumber" />
                      </Form.Item>
                    </Col>
                  )}
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Name" name="name" rules={[ALERTS.required, ALERTS.characterLength]}>
                      <InputText data-testid="name" />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Gender" name="gender" className="text-center gender">
                      <Radio.Group buttonStyle="solid" data-testid="gender">
                        {enumValues(Gender).map((l) => (
                          <Radio.Button value={l} key={l}>
                            {genderLabel[l]}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Personal phone" name="personalPhone">
                      <InputText data-testid="personalPhone" />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Personal email" name="personalEmail" rules={[ALERTS.email]}>
                      <InputText type="email" data-testid="personalEmail" />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Date of birth" name="dateOfBirth" className="m-0">
                          <DatePicker
                            className="w-100"
                            changeOnBlur
                            data-testid="dateOfBirth"
                            format={DATE_FORMAT.datePickerAllowDate}
                            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={24} lg={16} xl={14}>
                    <Form.Item label="Address" name="address" className="mb-0">
                      <InputText data-testid="address" />
                    </Form.Item>
                  </Col>
                </Row>
                {isMyProfile && (
                  <Row>
                    <Col span={12}>
                      <Row>
                        <Col span={24}>
                          <Typography.Title className="border-bottom py-3 mt-4 mb-0 profile-picture" level={4}>
                            Your “Profile picture”
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Col md={24} lg={16} xl={18} className="mt-3">
                        <Form.Item
                          label="Upload image"
                          rules={[ALERTS.imageFile, fileSize(profilePicture)]}
                          className="profilePicture"
                          name="profilePicture"
                        >
                          <InputFile
                            className="w-100"
                            onChange={(e) => {
                              if (e.target.files) {
                                setProfilePicture(e.target.files[0]);
                                setIsFieldsChanged(!!e.target.files[0]);
                              }
                            }}
                          />
                        </Form.Item>
                        {profilePicture ? (
                          <StyledPreviewImage>
                            <Image
                              className="rounded"
                              src={URL.createObjectURL(profilePicture)}
                              width={58}
                              preview={false}
                            />
                            <StyledDeclineButton
                              icon="remove"
                              onClick={() => {
                                setProfilePicture(null);
                                setIsFieldsChanged(false);
                                form.resetFields(["profilePicture"]);
                              }}
                            />
                          </StyledPreviewImage>
                        ) : (
                          form.getFieldValue("profileImageUrl") &&
                          !removeProfilePicture && (
                            <StyledPreviewImage>
                              <Image
                                className="rounded"
                                src={form.getFieldValue("profileImageUrl")}
                                width={58}
                                preview={false}
                              />
                              <StyledDeclineButton
                                icon="remove"
                                onClick={() => {
                                  setRemoveProfilePicture(true);
                                  setIsFieldsChanged(true);
                                }}
                              />
                            </StyledPreviewImage>
                          )
                        )}
                      </Col>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          )}
          {id && hasUpdateJobDetails && (
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Typography.Title className="border-bottom pb-3 mt-5 mb-0" level={3}>
                      Job details
                    </Typography.Title>
                  </Col>
                </Row>
                <Row className="mt-4" gutter={[38, 0]}>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Job title" name="jobTitleId">
                      <InputAddDropDown
                        options={jobTitles}
                        data-testid="jobTitleId"
                        placeholder="Please select"
                        loading={jobTitlesLoading}
                        onPopupScroll={handleJobTitleDropdownScroll}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        addBtnFor="job title"
                        onAdd={(data) => createJobTitle({ name: data.newLabel })}
                        ref={jobTitleSelectRef}
                        selectedValue={selectedJobTitle}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Department" name="departmentIds">
                      <InputAddDropDown
                        options={departments}
                        loading={departmentsLoading}
                        placeholder="Please select"
                        optionLabelProp="label"
                        showSearch={false}
                        maxTagCount="responsive"
                        data-testid="departmentIds"
                        mode="multiple"
                        selectedValue={selectedDepartment}
                        onPopupScroll={handleDepartmentDropdownScroll}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        addBtnFor="department"
                        onAdd={(data) => createDepartment({ name: data.newLabel })}
                        ref={departmentSelectRef}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Line manager" name="lineManagerIds">
                      <InputDropDown
                        options={lineManagers?.users.nodes.map((item) => ({ label: item.name, value: item.id }))}
                        loading={lineManagersLoading}
                        placeholder="Please select"
                        optionLabelProp="label"
                        showSearch={false}
                        data-testid="lineManagerIds"
                        maxTagCount="responsive"
                        mode="multiple"
                        selectedValue={selectedLineManager}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[38, 0]}>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Work location" name="locationId">
                      <InputAddDropDown
                        options={locations}
                        loading={locationsLoading}
                        data-testid="locationId"
                        onPopupScroll={handleLocationDropdownScroll}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        placeholder="Please select"
                        addBtnFor="work location"
                        onAdd={(data) => createLocation({ name: data.newLabel })}
                        ref={locationSelectRef}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Start date" name="startedAt">
                      <DatePicker
                        className="w-100"
                        changeOnBlur
                        data-testid="startedAt"
                        format={DATE_FORMAT.datePickerAllowDate}
                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="End date (if applicable)" name="endedAt">
                      <DatePicker
                        className="w-100"
                        changeOnBlur
                        data-testid="endedAt"
                        format={DATE_FORMAT.datePickerAllowDate}
                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[38, 0]} className="align-items-end">
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Probationary period (months)" name="probationaryPeriod">
                      <InputNumber
                        min={0}
                        max={12}
                        controls={false}
                        className="w-100"
                        data-testid="probationaryPeriod"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Annual leave" name="annualLeave" rules={[ALERTS.required]}>
                      <InputDropDown
                        placeholder="Please select"
                        popupMatchSelectWidth={false}
                        data-testid="annualLeave"
                        options={enumValues(AnnualLeaveEnum).map((l) => ({
                          label: annualLeaveLabel[l].label,
                          value: l,
                        }))}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Number of holidays per annum" name="holidaysPerYear" rules={[ALERTS.required]}>
                      <InputNumber min={0} controls={false} className="w-100" data-testid="holidaysPerYear" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[38, 0]} className="align-items-end">
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item
                      label="Holidays allowed to be carried over year to year"
                      name="maxHolidaysFromLastYear"
                      rules={[ALERTS.required]}
                    >
                      <InputNumber min={0} controls={false} className="w-100" data-testid="maxHolidaysFromLastYear" />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Hours per week" name="hoursPerWeek" rules={[ALERTS.required]}>
                      <InputNumber min={0} controls={false} className="w-100" data-testid="hoursPerWeek" />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Contract type" name="contractType">
                      <InputDropDown
                        placeholder="Please select"
                        options={enumValues(ContractType).map((l) => ({ label: contractTypeLabel[l], value: l }))}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        data-testid="contractType"
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[38, 0]}>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Start time" name="startTime" rules={[ALERTS.required]}>
                      <TimePicker
                        format={TIME_FORMAT.timeWithPeriodDefault}
                        className="w-100"
                        changeOnBlur
                        suffixIcon={<Icon name="down" />}
                        showNow={false}
                        data-testid="startTime"
                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="End time" name="endTime" rules={[ALERTS.required]}>
                      <TimePicker
                        format={TIME_FORMAT.timeWithPeriodDefault}
                        className="w-100"
                        changeOnBlur
                        suffixIcon={<Icon name="down" />}
                        showNow={false}
                        data-testid="endTime"
                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[38, 0]}>
                  <Col md={24} lg={12}>
                    <Form.Item label="Days per week" name="daysPerWeek" rules={[ALERTS.required]}>
                      <InputCheckboxButton options={EMPLOYEE.days} className="w-100" data-testid="daysPerWeek" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[38, 0]}>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Work phone number" name="phone" className="mb-0">
                      <InputText data-testid="phone" />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Work email" name="email" rules={[ALERTS.required, ALERTS.email]} className="mb-0">
                      <InputText type="email" data-testid="email" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {(hasUpdateEmergencyDetails || !id) && (
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Typography.Title className="border-bottom pb-3 mt-5 mb-0" level={3}>
                      Emergency contact
                    </Typography.Title>
                  </Col>
                </Row>
                <Row className="mt-4" gutter={[38, 0]}>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Name" name="emergencyName">
                      <InputText data-testid="emergencyName" />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Relationship" name="emergencyRelationship">
                      <InputText data-testid="emergencyRelationship" />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Email" name="emergencyEmail" rules={[ALERTS.email]}>
                      <InputText type="email" data-testid="emergencyEmail" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[38, 0]}>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Phone number" name="emergencyPhone" className="mb-0">
                      <InputText data-testid="emergencyPhone" />
                    </Form.Item>
                  </Col>

                  <Col md={24} lg={16} xl={14}>
                    <Form.Item label="Address" name="emergencyAddress" className="mb-0">
                      <InputText data-testid="emergencyAddress" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {id && hasUpdateSalaryDetails && (
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Typography.Title className="border-bottom pb-3 mt-5 mb-0" level={3}>
                      Salary information
                    </Typography.Title>
                  </Col>
                </Row>
                <Row className="mt-4" gutter={[38, 0]}>
                  <Col md={24} lg={8} xl={7}>
                    <Row gutter={[16, 0]}>
                      <Col span={16}>
                        <Form.Item label="Amount" name="amount" className="mb-0">
                          <InputNumber min={0} controls={false} className="w-100" data-testid="amount" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Currency" name="currency" className="mb-0">
                          <InputDropDown
                            placeholder="Please select"
                            options={enumValues(Currency).map((l) => ({ label: l, value: l }))}
                            allowClear
                            data-testid="currency"
                            getPopupContainer={(trigger) => trigger.parentNode}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={24} lg={8} xl={7}>
                    <Form.Item label="Type" name="paymentType" className="mb-0">
                      <InputDropDown
                        placeholder="Please select"
                        options={enumValues(PaymentType).map((l) => ({ label: paymentTypeLabel[l], value: l }))}
                        allowClear
                        data-testid="paymentType"
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </StyledContainer>

        <StyledFooterContainer className="border-top">
          <Row>
            <Col span={24}>
              <SuccessButton htmlType="submit" loading={loading || uploading}>
                Save changes
              </SuccessButton>
              <BaseButton className="mx-3" onClick={onClose}>
                Cancel
              </BaseButton>
            </Col>
          </Row>
        </StyledFooterContainer>
      </Container>
    </Form>
  );
};

export default EmployeeEditForm;

const StyledContainer = styled(Container)`
  padding-left: 33px;
  padding-right: 33px;
  margin-top: 28px;
  & .profile-picture {
    color: ${COLORS.labelColor};
    opacity: 0.8;
    font-weight: 500;
  }
  & .profilePicture .ant-form-item-explain.ant-form-item-explain-connected {
    margin-bottom: 15px;
  }

  & .gender .ant-radio-group {
    display: grid;
    grid-template-columns: auto auto auto;

    .ant-radio-button-wrapper {
      white-space: nowrap;
      padding-inline: unset;
      height: 36px;
    }
  }
`;

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 22px;
    margin-bottom: 0px;
  }
`;

const StyledDeclineButton = styled(DeclineButton)`
  &.ant-btn.ant-btn-icon-only {
    width: 18px;
    height: 18px;
    padding: 0px;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -6px;
    right: -6px;
    svg {
      width: 8px;
      path {
        fill: ${COLORS.dangerColor};
      }
    }
  }
`;

const StyledPreviewImage = styled.div`
  position: relative;
  width: fit-content;
`;

const StyledFooterContainer = styled(Container)`
  padding: 21px 33px 21px 33px;
  border-radius: 0px 0px 4px 4px;
`;
