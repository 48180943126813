import { useMutation, useQuery } from "@apollo/client";
import { Checkbox, Col, Form, InputNumber, Popover, Radio, Row, Space, TimePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
import Icon from "components/Icon";
import { InputDropDown } from "components/Inputs";
import InputCheckboxButton from "components/Inputs/InputCheckboxButton/InputCheckboxButton";
import UnsavedPrompt from "components/UnsavedPrompt";
import { Container } from "components/core";
import ALERTS from "config/alerts";
import ADMIN_SETTING from "constants/adminSetting";
import COLORS from "constants/colors";
import EMPLOYEE from "constants/employee";
import FORM from "constants/form";
import TIME_FORMAT from "constants/timeFormat";
import dayjs from "dayjs";
import {
  DefaultSettingInitialValue,
  DefaultSettingPayload,
  DefaultSettingRef,
  DefaultSettingResponse,
  ModifyDefaultSettingPayload,
  ModifyDefaultSettingResponse,
  ShowEmployeeCalendarType,
  UpsertDefaultSettingResponse,
  showEmployeeCalendarLabel,
} from "model/AdminSetting";
import { AnnualLeaveEnum, annualLeaveLabel } from "model/Leave";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useNotify } from "services/api";
import { FETCH_DEFAULT_SETTING, UPSERT_DEFAULT_SETTING } from "services/graphql/adminSetting";
import styled from "styled-components";
import { expectNumber, getTimeZone } from "utils";
import { enumValues } from "utils/misc";

const DefaultSettingsTab = forwardRef<DefaultSettingRef>((_, ref) => {
  const [form] = useForm();
  const [showEmployeeCalendarValue, setShowEmployeeCalendarValue] = useState<number[]>([]);
  const [isFieldsChanged, setIsFieldsChanged] = useState<boolean>(false);
  const [formInitialValues, setFormInitialValues] = useState<DefaultSettingInitialValue>();
  const notify = useNotify();

  const { refetch } = useQuery<DefaultSettingResponse>(FETCH_DEFAULT_SETTING, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (response.fetchDefaultSetting) {
        const data = EMPLOYEE.days.reduce(
          (object, item) => ({
            ...object,
            daysOfWork:
              object[item.value as keyof ModifyDefaultSettingResponse] === true
                ? [...object.daysOfWork, `${item.value}`]
                : [...object.daysOfWork],
          }),
          {
            ...response.fetchDefaultSetting,
            daysOfWork: [],
            startTime: dayjs(response.fetchDefaultSetting.startTime, TIME_FORMAT.timeFormatWith24Hour),
            endTime: dayjs(response.fetchDefaultSetting.endTime, TIME_FORMAT.timeFormatWith24Hour),
          } as ModifyDefaultSettingResponse,
        );
        EMPLOYEE.days.map((item) => delete data[item.value as keyof ModifyDefaultSettingResponse]);
        delete data.id;
        setFormInitialValues(data);
        const selectedCalendars =
          response.fetchDefaultSetting.selectedCalendars > 4
            ? [2, 4]
            : [response.fetchDefaultSetting.selectedCalendars];
        setShowEmployeeCalendarValue(selectedCalendars);
      } else {
        setFormInitialValues(ADMIN_SETTING.defaultSettingInitialValues);
      }
    },
  });

  const [mutate, { loading }] = useMutation<UpsertDefaultSettingResponse>(UPSERT_DEFAULT_SETTING, {
    onCompleted: (response) => {
      if (response?.upsertDefaultSetting) {
        if (response.upsertDefaultSetting?.errors?.fullMessages?.length) {
          response.upsertDefaultSetting.errors.fullMessages.map((error: string) => notify.error(undefined, error));
        } else {
          notify.success({ message: "Changes Saved successfully." });
          setIsFieldsChanged(false);
          refetch();
        }
      }
    },
  });

  const onFinish = (data: DefaultSettingPayload) => {
    const payload = EMPLOYEE.days.reduce(
      (object, item) => ({ ...object, [`${item.value}`]: !!object?.daysOfWork?.includes(`${item.value}`) }),
      { ...data } as ModifyDefaultSettingPayload,
    );
    delete payload.daysOfWork;
    if (isFieldsChanged) {
      const selectedCalendars = showEmployeeCalendarValue.reduce((a, b) => a + b, 0);
      const startTime = payload.startTime
        ? dayjs(payload.startTime).format(TIME_FORMAT.timeFormatWith24Hour)
        : undefined;
      const endTime = payload.endTime ? dayjs(payload.endTime).format(TIME_FORMAT.timeFormatWith24Hour) : undefined;
      mutate({ variables: { ...payload, selectedCalendars, startTime, endTime } });
    }
  };

  const onChange = (value: number, checked: boolean) => {
    if (value === expectNumber(ShowEmployeeCalendarType.All)) {
      setShowEmployeeCalendarValue(checked ? [value] : []);
    }
    if (value !== expectNumber(ShowEmployeeCalendarType.All)) {
      if (showEmployeeCalendarValue.includes(expectNumber(ShowEmployeeCalendarType.All))) {
        setShowEmployeeCalendarValue((prev) =>
          prev.filter((option) => option !== expectNumber(ShowEmployeeCalendarType.All)),
        );
      }
      if (checked) {
        setShowEmployeeCalendarValue((prev) => [...prev, value]);
      } else {
        setShowEmployeeCalendarValue((prev) => prev.filter((option) => option !== value));
      }
    }
  };

  useImperativeHandle(ref, () => ({
    resetForm() {
      form.resetFields();
      if (formInitialValues?.selectedCalendars) {
        const selectedCalendars =
          formInitialValues.selectedCalendars > expectNumber(ShowEmployeeCalendarType.SameLocation)
            ? [
                expectNumber(ShowEmployeeCalendarType.SameDepartment),
                expectNumber(ShowEmployeeCalendarType.SameLocation),
              ]
            : [formInitialValues.selectedCalendars];
        setShowEmployeeCalendarValue(selectedCalendars);
      }
    },
    setIsFieldsChanged(value) {
      setIsFieldsChanged(value);
    },
    isFieldsChanged,
    loading,
  }));

  useEffect(() => {
    if (formInitialValues) {
      form.resetFields();
    }
  }, [form, formInitialValues]);

  return (
    <StyledContainer className="section-box-shadow">
      <Row>
        <Col md={24} lg={24} xxl={18}>
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ lg: 10, xl: 8, xxl: 7 }}
            labelAlign="left"
            id={FORM.defaultSettingForm}
            onValuesChange={() => setIsFieldsChanged(true)}
            onFinish={onFinish}
            initialValues={formInitialValues}
          >
            <Form.Item
              label="Default holidays per year"
              name="holidaysPerYear"
              colon={false}
              wrapperCol={{ lg: 11, xl: 10 }}
              rules={[ALERTS.required]}
              required={false}
            >
              <InputNumber min={0} className="w-100" data-testid="holidaysPerYear" />
            </Form.Item>
            <Form.Item
              label="Default probationary period (months)"
              name="probationaryPeriod"
              colon={false}
              wrapperCol={{ lg: 11, xl: 10 }}
              rules={[ALERTS.required]}
              required={false}
            >
              <InputNumber min={0} className="w-100" data-testid="probationaryPeriod" />
            </Form.Item>
            <Form.Item
              label="Default hours per week"
              name="hoursPerWeek"
              colon={false}
              wrapperCol={{ lg: 11, xl: 10 }}
              rules={[ALERTS.required]}
              required={false}
            >
              <InputNumber min={0} className="w-100" data-testid="hoursPerWeek" />
            </Form.Item>
            <Form.Item
              label="Default start time"
              name="startTime"
              colon={false}
              wrapperCol={{ lg: 11, xl: 10 }}
              rules={[ALERTS.required]}
              required={false}
            >
              <TimePicker
                className="w-100"
                changeOnBlur
                format={TIME_FORMAT.timeWithPeriodSecondary}
                data-testid="startTime"
                showNow={false}
                getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              />
            </Form.Item>
            <Form.Item
              label="Default end time"
              name="endTime"
              colon={false}
              wrapperCol={{ lg: 11, xl: 10 }}
              rules={[ALERTS.required]}
              required={false}
            >
              <TimePicker
                className="w-100"
                changeOnBlur
                format={TIME_FORMAT.timeWithPeriodSecondary}
                data-testid="endTime"
                showNow={false}
                getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              />
            </Form.Item>
            <Form.Item
              label="Default days of work"
              name="daysOfWork"
              colon={false}
              wrapperCol={{ lg: 13, xl: 14 }}
              rules={[ALERTS.required]}
              required={false}
            >
              <InputCheckboxButton options={EMPLOYEE.days} className="w-100" data-testid="daysOfWork" />
            </Form.Item>
            <Form.Item
              label="Annual leave"
              name="annualLeave"
              colon={false}
              wrapperCol={{ lg: 24 }}
              rules={[ALERTS.required]}
              required={false}
            >
              <Radio.Group data-testid="annualLeave">
                <Space direction="vertical">
                  {enumValues(AnnualLeaveEnum).map((l) => (
                    <Radio value={l} key={l}>
                      <span className="me-2">{annualLeaveLabel[l].label}</span>
                      <Popover title={<StyledPopOverTitle>{annualLeaveLabel[l].popupTitle}</StyledPopOverTitle>}>
                        <Icon name="info" />
                      </Popover>
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Default time zone"
              name="timeZone"
              colon={false}
              wrapperCol={{ lg: 11, xl: 10 }}
              rules={[ALERTS.required]}
              required={false}
            >
              <InputDropDown
                options={getTimeZone()?.map((item) => ({ value: item, label: item }))}
                className="w-100"
                data-testid="timeZone"
                placeholder="Please Select a time zone"
              />
            </Form.Item>
            <Form.Item
              label="Whose calendar can employees see"
              name="selectedCalendars"
              colon={false}
              wrapperCol={{ lg: 18, xl: 16, style: { justifyContent: "center" } }}
              valuePropName="checked"
              className="mb-0"
            >
              <div>
                {enumValues(ShowEmployeeCalendarType).map((l) => (
                  <Checkbox
                    value={expectNumber(l)}
                    key={l}
                    checked={showEmployeeCalendarValue.includes(expectNumber(l))}
                    onChange={(e) => onChange(e.target.value, e.target.checked)}
                  >
                    {showEmployeeCalendarLabel[l]}
                  </Checkbox>
                ))}
              </div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <UnsavedPrompt when={isFieldsChanged} />
    </StyledContainer>
  );
});

export default DefaultSettingsTab;

const StyledContainer = styled(Container)`
  padding: 34px 42px;
`;

const StyledPopOverTitle = styled.div`
  width: 260px;
  display: block;
  text-align: center;
`;
