export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.579 25.76H6.362a.552.552 0 0 1-.552-.552V14.742a9.13 9.13 0 0 1 2.683-6.478c4.6-4.6 12.392-3.06 14.918 2.912.478 1.13.72 2.33.72 3.566v10.466a.552.552 0 0 1-.552.552Zm-.552-1.104v-9.914c0-4.443-3.614-8.057-8.057-8.057-4.442 0-8.056 3.614-8.056 8.057v9.914h16.113Z"
      fill="#E09597"
      stroke="#E09597"
      strokeWidth=".4"
    />
    <path
      d="M25.74 29.272a.552.552 0 0 1-.552-.553v-1.226c0-.955-.777-1.733-1.733-1.733H6.485c-.955 0-1.732.777-1.732 1.733v1.226a.552.552 0 0 1-1.104 0v-1.226a2.84 2.84 0 0 1 2.837-2.837h16.97a2.84 2.84 0 0 1 2.836 2.837v1.226a.552.552 0 0 1-.552.553Z"
      fill="#E09597"
      stroke="#E09597"
      strokeWidth=".4"
    />
    <path
      d="M27.389 29.272H2.552a.552.552 0 0 1 0-1.105H27.39a.552.552 0 0 1 0 1.105ZM15 4c-.305 0-.553-.2-.553-.446V1.032c0-.247.248-.447.553-.447.305 0 .552.2.552.447v2.522c0 .246-.247.446-.552.446Zm9.5 4a.5.5 0 0 1-.353-.854l2-2a.5.5 0 1 1 .707.708l-2 2a.499.499 0 0 1-.353.146Zm4.078 9.223h-2.382c-.233 0-.422-.247-.422-.552 0-.305.189-.553.422-.553h2.382c.233 0 .422.248.422.553 0 .305-.189.552-.422.552Zm-24.774 0H1.422c-.233 0-.422-.247-.422-.552 0-.305.189-.553.422-.553h2.382c.233 0 .422.248.422.553 0 .305-.189.552-.422.552Zm1.342-9.37-2-1.999a.5.5 0 1 1 .708-.707l2 1.999a.5.5 0 1 1-.708.707Z"
      fill="#E09597"
      stroke="#E09597"
      strokeWidth=".4"
    />
    <path
      d="M16.724 21.68h-3.448a.696.696 0 0 1-.696-.696V19.26h-1.724a.696.696 0 0 1-.696-.696v-3.447c0-.385.312-.697.696-.697h1.724v-1.724c0-.384.312-.696.696-.696h3.448c.384 0 .696.312.696.696v1.724h1.724c.384 0 .696.312.696.697v3.447a.696.696 0 0 1-.696.696H17.42v1.724a.696.696 0 0 1-.696.696Zm-2.752-1.392h2.056v-1.724c0-.385.311-.696.696-.696h1.724v-2.055h-1.724a.696.696 0 0 1-.697-.697v-1.724h-2.055v1.725a.696.696 0 0 1-.696.696h-1.724v2.055h1.724c.385 0 .697.311.697.696v1.724Z"
      fill="#E09597"
      stroke="#E09597"
      strokeWidth=".3"
    />
  </svg>
);
