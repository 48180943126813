import { useAuthContext } from "contexts";

import { checkPermission } from "utils/checkPermission";

type Props = {
  [x: string]: (string | string[])[] | string;
};

const useCheckPermission = (props: Props) => {
  const { user } = useAuthContext();
  const hasPermissionList = Object.keys(props).map((item) => ({
    key: `${item.substring(0, 1).toUpperCase() + item.substring(1)}`,
    value: checkPermission(user?.permissions, props[item]),
  }));
  const hasPermission = hasPermissionList.reduce(
    (obj, item) => ({ ...obj, [`has${item.key}`]: item.value }),
    {} as { [key: string]: boolean },
  );
  return { ...hasPermission };
};

export default useCheckPermission;
