import { useEffect, useState } from "react";

const useWindowResize = (): { resized: number } => {
  const [resized, setResized] = useState(window?.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", (e) => {
      // @ts-ignore
      setResized((prev) => e?.currentTarget?.innerWidth || prev);
    });
  }, []);

  return { resized };
};

export default useWindowResize;
