import { useMutation } from "@apollo/client";
import { Row } from "antd";
import Form, { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { LinkButton, PrimaryButton } from "components/Buttons";
import { InputPassword } from "components/Inputs";
import ALERTS from "config/alerts";
import AUTH from "constants/auth";
import { useAuthContext } from "contexts";
import { AcceptInviteArgs, AcceptInviteResponse } from "model/User";
import { ReactElement, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { useNotify } from "services/api";
import { ACCEPT_INVITE } from "services/graphql/user";

export const AcceptInviteForm = (): ReactElement => {
  const [form] = useForm();
  const [params] = useSearchParams();
  const token = params.get(AUTH.invitationToken);
  const notify = useNotify();
  const { login } = useAuthContext();

  const [mutate, { loading }] = useMutation<AcceptInviteResponse>(ACCEPT_INVITE, {
    onCompleted: (response) => {
      if (response.acceptInvite) {
        if (!response.acceptInvite.success) {
          notify.error(undefined, response.acceptInvite.errors?.fullMessages[0]);
        } else {
          notify.success({ message: "Password set successfully." });
          login(response.acceptInvite.token);
        }
      }
    },
  });

  const onFinish = ({
    password,
    passwordConfirmation,
  }: Pick<AcceptInviteArgs, "password" | "passwordConfirmation">) => {
    mutate({ variables: { password, passwordConfirmation, invitationToken: token } });
  };

  return (
    <Form labelCol={{ span: 24 }} form={form} onFinish={onFinish} autoComplete="off">
      <FormItem rules={[ALERTS.required]} label="Enter password here" name="password">
        <InputPassword placeholder="Password" />
      </FormItem>
      <FormItem
        dependencies={["password"]}
        hasFeedback
        rules={[
          ALERTS.required,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("The two passwords that you entered do not match!"));
            },
          }),
        ]}
        name="passwordConfirmation"
      >
        <InputPassword placeholder="Password confirmation" />
      </FormItem>
      <PrimaryButton htmlType="submit" className="w-100" loading={loading}>
        Confirm
      </PrimaryButton>
      <Row justify="center" className="mt-4">
        <Link to="/login">
          <LinkButton>Login Form</LinkButton>
        </Link>
      </Row>
    </Form>
  );
};

export default AcceptInviteForm;
