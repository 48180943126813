import { useMutation } from "@apollo/client";
import { Form, Radio, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DefaultButton, SuccessButton } from "components/Buttons";
import ALERTS from "config/alerts";
import { ShareType, UpdateDocumentResponse, documentShareTypeLabel } from "model/Document";
import { useNotify } from "services/api";
import { UPDATE_DOCUMENT } from "services/graphql/document";

import { enumValues } from "utils/misc";

type Props = {
  onCancel: () => void;
  documentId: string;
  initialData: ShareType;
  refetch: () => void;
};

const EditShareWith = ({ onCancel, refetch, initialData, documentId }: Props) => {
  const [form] = useForm();
  const notify = useNotify();
  const [mutate, { loading }] = useMutation<UpdateDocumentResponse>(UPDATE_DOCUMENT, {
    onCompleted: (response) => {
      if (response?.updateDocument) {
        if (response.updateDocument?.errors?.fullMessages?.length) {
          response.updateDocument.errors.fullMessages.map((error: string) => notify.error(undefined, error));
        } else {
          onCancel();
          refetch();
          notify.success({ message: "Document updated successfully." });
        }
      }
    },
  });

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{ sharedWithCompany: initialData }}
      onFinish={(value) => {
        if (initialData !== value.sharedWithCompany)
          mutate({ variables: { id: documentId, shareType: value.sharedWithCompany } });
      }}
    >
      <Form.Item name="sharedWithCompany" className="mb-0" rules={[ALERTS.required]} required={false}>
        <Radio.Group className="w-100" data-testid="sharedWithCompany">
          <Space direction="vertical" className="w-100">
            {enumValues(ShareType).map((value) => (
              <div key={value}>
                <Radio value={value}>
                  <span className="me-2">{documentShareTypeLabel[value]}</span>
                </Radio>
              </div>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item className="mt-5">
        <SuccessButton className="me-2" loading={loading} htmlType="submit">
          Save
        </SuccessButton>
        <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
      </Form.Item>
    </Form>
  );
};

export default EditShareWith;
