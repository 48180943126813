export default () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#a)">
      <rect x="11" y="6" width="34" height="34" rx="6.09" fill="#006FCF" />
      <rect x="10.873" y="5.873" width="34.254" height="34.254" rx="6.216" stroke="#D6DCE5" strokeWidth=".254" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.408 13.037h-4.02l-2.102 6.031-2.193-6.03H32.98v9.39l-4.204-9.39h-3.38l-4.204 9.594h2.924l.822-1.919h4.387l.822 1.919h5.483v-6.67l2.467 6.67h2.284l2.467-6.67v6.67h2.559v1.462h-3.381l-2.741 3.015-2.833-3.015H24.848v9.594h11.514l2.832-3.106 2.833 3.106h3.381v1.188h-4.02l-2.194-2.375-2.193 2.375H23.386V23.911H19l5.483-12.336h5.3L31.7 15.78v-4.204h6.58l1.096 3.199 1.097-3.199h4.934zm0 13.158-2.467 2.65 2.467 2.65v2.193l-4.477-4.843 4.477-4.751zm-12.427 7.493v-2.284h-5.483v-1.462h5.391V27.75H27.5v-1.462h5.482v-2.193l4.568 4.752zm-7.22-15.077 1.28-3.106 1.28 3.106z"
        fill="#fff"
      />
    </g>
    <defs>
      <filter
        id="a"
        x=".597"
        y=".671"
        width="54.96"
        height="54.806"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy=".507" />
        <feGaussianBlur stdDeviation="2.537" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_302_2824" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="5.075" />
        <feGaussianBlur stdDeviation="5.075" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="effect1_dropShadow_302_2824" result="effect2_dropShadow_302_2824" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_302_2824" result="shape" />
      </filter>
    </defs>
  </svg>
);
