import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import useWindowResize from "./useWindowResize";

type ResponsiveValue = {
  size: { [name: string]: boolean };
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isTouch: boolean;
};

export const useResponsive = (): ResponsiveValue => {
  const size = useBreakpoint();
  useWindowResize();
  const { lg, md, sm, xl, xs, xxl } = size;
  const isSmall = sm || xs || false;
  const isMedium = md || false;
  const isLarge = lg || xl || xxl || false;
  const isTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0;

  return { size, isSmall, isMedium, isLarge, isTouch };
};

export default useResponsive;
