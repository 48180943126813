import { gql } from "@apollo/client";
import { ERROR_FRAGMENT, PAGE_INFO_FRAGMENT, WORKING_CONDITION_FRAGMENT } from "./common";

export const ENHANCED_LEAVE_FRAGMENT = gql`
  fragment EnhancedLeaveFragment on EnhancedLeaveRequest {
    id
    accumulativeBalance
    approvedAt
    dayCompleteness
    declinedAt
    fromDate
    fromTime
    precedingUsedHolidayDays
    reason
    status
    toDate
    toTime
    usedDays
    attachmentUrl
    bookingStatus
  }
`;

export const CREATE_LEAVE_REQUEST = gql`
  mutation createLeaveRequest(
    $userId: ID!
    $dayCompleteness: LeaveRequestsDayCompletenessEnum
    $fromDate: ISO8601Date!
    $fromTime: String
    $reason: LeaveRequestsReasonEnum!
    $toDate: ISO8601Date!
    $toTime: String
    $employeeNote: String
    $attachment: Upload
  ) {
    createLeaveRequest(
      userId: $userId
      dayCompleteness: $dayCompleteness
      fromDate: $fromDate
      fromTime: $fromTime
      reason: $reason
      toDate: $toDate
      toTime: $toTime
      employeeNote: $employeeNote
      attachment: $attachment
    ) {
      errors {
        ...ErrorFragment
      }
      leaveRequest {
        id
        dayCompleteness
        fromDate
        fromTime
        reason
        status
        toDate
        toTime
        workingCondition {
          ...WorkingConditionFragment
        }
        employeeNote {
          id
          body
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${WORKING_CONDITION_FRAGMENT}
`;

export const FETCH_LEAVE_REQUESTS_USER_ID = gql`
  query leaveRequests($userId: ID!) {
    leaveRequests(userId: $userId) {
      edges {
        cursor
        node {
          id
          fromDate
          fromTime
          toDate
          toTime
          status
        }
      }
      nodes {
        ...EnhancedLeaveFragment
        workingCondition {
          ...WorkingConditionFragment
        }
        employeeNote {
          id
          body
        }
        lineManagerNote {
          id
          body
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${ENHANCED_LEAVE_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
  ${WORKING_CONDITION_FRAGMENT}
`;

export const FETCH_LEAVE_REQUESTS_STATUS = gql`
  query leaveRequests($statuses: [LeaveRequestsStatusEnum!], $userId: ID) {
    leaveRequests(statuses: $statuses, userId: $userId) {
      nodes {
        ...EnhancedLeaveFragment
        user {
          id
          name
        }
        workingCondition {
          ...WorkingConditionFragment
        }
        employeeNote {
          id
          body
        }
        lineManagerNote {
          id
          body
        }
      }
    }
  }
  ${ENHANCED_LEAVE_FRAGMENT}
  ${WORKING_CONDITION_FRAGMENT}
`;

export const CREATE_LEAVING_REQUEST = gql`
  mutation createLeavingRequest(
    $leavingAt: ISO8601Date!
    $reason: LeavingRequestsReasonEnum!
    $userId: ID!
    $attachment: Upload
  ) {
    createLeavingRequest(leavingAt: $leavingAt, reason: $reason, userId: $userId, attachment: $attachment) {
      errors {
        ...ErrorFragment
      }
      leavingRequest {
        id
        leavingAt
        reason
        attachmentUrl
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const DESTROY_LEAVE_REQUEST = gql`
  mutation destroyLeaveRequest($id: ID!) {
    destroyLeaveRequest(id: $id) {
      success
    }
  }
`;

export const UPDATE_LEAVE_REQUEST = gql`
  mutation updateLeaveRequest(
    $dayCompleteness: LeaveRequestsDayCompletenessEnum
    $employeeNote: String
    $fromDate: ISO8601Date
    $fromTime: String
    $id: ID!
    $reason: LeaveRequestsReasonEnum
    $toDate: ISO8601Date
    $toTime: String
    $attachment: Upload
  ) {
    updateLeaveRequest(
      id: $id
      dayCompleteness: $dayCompleteness
      employeeNote: $employeeNote
      fromDate: $fromDate
      fromTime: $fromTime
      reason: $reason
      toDate: $toDate
      toTime: $toTime
      attachment: $attachment
    ) {
      errors {
        ...ErrorFragment
      }
      leaveRequest {
        id
        dayCompleteness
        fromDate
        fromTime
        reason
        status
        toDate
        toTime
        workingCondition {
          ...WorkingConditionFragment
        }
        employeeNote {
          id
          body
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${WORKING_CONDITION_FRAGMENT}
`;

export const ESTIMATE_LEAVE_REQUEST = gql`
  mutation estimateLeaveRequest(
    $leaveRequestId: ID
    $dayCompleteness: LeaveRequestsDayCompletenessEnum
    $fromDate: ISO8601Date!
    $fromTime: String
    $reason: LeaveRequestsReasonEnum!
    $toDate: ISO8601Date!
    $toTime: String
    $userId: ID!
  ) {
    estimateLeaveRequest(
      leaveRequestId: $leaveRequestId
      userId: $userId
      dayCompleteness: $dayCompleteness
      fromDate: $fromDate
      fromTime: $fromTime
      reason: $reason
      toDate: $toDate
      toTime: $toTime
    ) {
      errors {
        ...ErrorFragment
      }
      usedDaysBeforeChange
      estimatedDays
      totalBalance
      usedDays
    }
  }
  ${ERROR_FRAGMENT}
`;

export const DECLINE_LEAVE_REQUEST = gql`
  mutation declineLeaveRequest($id: ID!, $lineManagerNote: String) {
    declineLeaveRequest(id: $id, lineManagerNote: $lineManagerNote) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const APPROVE_LEAVE_REQUEST = gql`
  mutation approveLeaveRequest($id: ID!, $lineManagerNote: String) {
    approveLeaveRequest(id: $id, lineManagerNote: $lineManagerNote) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE = gql`
  query fetchUsageStatsAndHolidayBalance($userId: ID!) {
    fetchUsageStats(userId: $userId) {
      amount
      reason
    }
    fetchHolidayBalance(userId: $userId) {
      accruedBalance
      accumulativeBalance
      numberOfUsedDays
      bookedDays
      takenDays
      usedDaysStats {
        amount
        status
      }
    }
  }
`;

export default {
  CREATE_LEAVING_REQUEST,
  CREATE_LEAVE_REQUEST,
  FETCH_LEAVE_REQUESTS_USER_ID,
  UPDATE_LEAVE_REQUEST,
  FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE,
};
