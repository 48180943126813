import { useModal } from "components/modal";
import { usePreventUnsavedForm } from "contexts/PreventUnsavedFormProvider";
import { useState } from "react";

type Props = {
  onCloseModalCallback?: () => unknown;
};

const useFormModal = ({ onCloseModalCallback }: Props = {}) => {
  const { isModalVisible, hideModal, showModal } = useModal();
  const [isFieldsChanged, setIsFieldsChanged] = useState<boolean>(false);
  const { showConfirmModal } = usePreventUnsavedForm();

  const onCloseModal = () => {
    if (isFieldsChanged) {
      showConfirmModal(() => {
        if (onCloseModalCallback) onCloseModalCallback();
        hideModal();
        setIsFieldsChanged(false);
      });
    } else {
      hideModal();
    }
  };

  return {
    isModalVisible,
    showModal,
    hideModal,
    onCloseModal,
    onFieldsChange: (isChanged: boolean = true) => setIsFieldsChanged(isChanged),
  };
};

export default useFormModal;
