import { App as AntdApp } from "antd";
import { ArgsProps } from "antd/es/notification/interface";

export type UseNotifyResponse = {
  error: (errors?: Array<any>, message?: string) => void;
  success: ({ message, ...rest }: ArgsProps) => void;
  info: ({ message, ...rest }: ArgsProps) => void;
};

export const useNotify = (): UseNotifyResponse => {
  const { notification } = AntdApp.useApp();

  const error = (errors?: Array<any>, message?: string) => {
    if (errors) {
      errors.forEach((error: any) => {
        notification.error({ message: error });
      });
    } else if (message) {
      notification.error({ message: message.toString() });
    }
  };

  const success = ({ message, ...rest }: ArgsProps) => {
    notification.success({ message, ...rest });
  };

  const info = ({ message, ...rest }: ArgsProps) => {
    notification.info({ message, ...rest });
  };

  return { error, success, info };
};

export default useNotify;
