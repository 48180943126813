import { Col, Row } from "antd";
import YourLeave from "./yourLeave/YourLeave";
import NotificationTabs from "./NotificationTabs";

const Notification = () => (
  <Row>
    <Col span={24}>
      <YourLeave />
      <NotificationTabs />
    </Col>
  </Row>
);

export default Notification;
