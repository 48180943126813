export default () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.2 3.056c-.93 0-1.7.726-1.7 1.64v5.28c0 .913.77 1.64 1.7 1.64h6.6c.93 0 1.7-.727 1.7-1.64v-5.28c0-.914-.77-1.64-1.7-1.64H3.2ZM.5 4.696c0-1.42 1.2-2.584 2.7-2.584h6.6c1.5 0 2.7 1.164 2.7 2.584v5.28c0 1.42-1.2 2.584-2.7 2.584H3.2c-1.5 0-2.7-1.165-2.7-2.584v-5.28Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5.75c0-.26.224-.472.5-.472h4c.276 0 .5.212.5.472s-.224.472-.5.472h-4A.486.486 0 0 1 4 5.75ZM9.5.56c.276 0 .5.21.5.471v.944c0 .26-.224.472-.5.472S9 2.236 9 1.975v-.944c0-.26.224-.471.5-.471Zm-6 0c.276 0 .5.21.5.471v.944c0 .26-.224.472-.5.472S3 2.236 3 1.975v-.944c0-.26.224-.471.5-.471ZM4 8.581c0-.26.224-.471.5-.471h4c.276 0 .5.21.5.471s-.224.472-.5.472h-4a.486.486 0 0 1-.5-.472Z"
      fill="#fff"
    />
  </svg>
);
