import { gql, useQuery } from "@apollo/client";
import { Checkbox, Form, InputNumber, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useForm } from "antd/lib/form/Form";
import UnsavedPrompt from "components/UnsavedPrompt";
import COLORS from "constants/colors";
import FORM from "constants/form";
import TABLE from "constants/table";
import { apolloClient } from "contexts/ApolloProvider";
import {
  DefaultNotificationSettingRef,
  DefaultNotificationSettings,
  DefaultNotificationSettingsEventType,
  DefaultNotificationSettingsEventTypeLabel,
  DefaultNotificationSettingsResponse,
  UpdateDefaultNotificationSetting,
  UpdateDefaultNotificationSettingResponse,
} from "model/AdminSetting";
import { ValidationErrors } from "model/Common";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useNotify } from "services/api";
import { DEFAULT_NOTIFICATION_SETTINGS, UPDATE_DEFAULT_NOTIFICATION_SETTING } from "services/graphql/adminSetting";
import { ERROR_FRAGMENT } from "services/graphql/common";
import styled from "styled-components";

const EmployeeNotificationsTab = forwardRef<DefaultNotificationSettingRef>((_, ref) => {
  const [form] = useForm();
  const [changedTableData, setChangedTableData] = useState<DefaultNotificationSettings[]>([]);
  const [isFieldsChanged, setIsFieldsChanged] = useState<boolean>(false);
  const [defaultNotificationLoading, setDefaultNotificationLoading] = useState<boolean>(false);
  const notify = useNotify();

  const { data, loading, refetch } = useQuery<DefaultNotificationSettingsResponse>(DEFAULT_NOTIFICATION_SETTINGS, {
    variables: { first: TABLE.rowsPerPage },
  });

  const onChange = (record: DefaultNotificationSettings, changedFieldName: string) => {
    setIsFieldsChanged(true);
    let formData: DefaultNotificationSettings[] = [];

    if (changedTableData.find((item) => item.id === record.id)) {
      formData = changedTableData.map((item) => {
        if (item.id === record.id) {
          return { ...item, [changedFieldName]: record[changedFieldName as keyof DefaultNotificationSettings] };
        }
        return item;
      });
    } else {
      formData = [...changedTableData, record];
    }
    const initialRecordData = data?.defaultNotificationSettings?.nodes?.find((item) => item.id === record.id);
    formData.forEach((item, index) => {
      if (item.id === (initialRecordData as DefaultNotificationSettings).id) {
        if (
          Object.entries(item).every(
            ([key, value]) =>
              (initialRecordData as DefaultNotificationSettings)[key as keyof DefaultNotificationSettings] === value,
          )
        ) {
          formData.splice(index, 1);
        }
      }
    });
    setChangedTableData(formData);
  };

  const onFinish = () => {
    setDefaultNotificationLoading(true);
    if (changedTableData.length) {
      apolloClient()
        .mutate<UpdateDefaultNotificationSettingResponse>({
          mutation: gql`
          mutation{
            ${UPDATE_DEFAULT_NOTIFICATION_SETTING(changedTableData)}
          }
          ${ERROR_FRAGMENT}
        `,
        })
        .then((response) => {
          if (response.data) {
            let errorMessages: string[] = [];
            const isError = Object.keys(response.data)
              .map(
                (item) => response?.data?.[item as keyof UpdateDefaultNotificationSetting].errors as ValidationErrors,
              )
              .some((item) => {
                if (item && item.fullMessages.length) {
                  errorMessages = item.fullMessages;
                  return true;
                }
                return false;
              });
            if (!isError) {
              setChangedTableData([]);
              setDefaultNotificationLoading(false);
              refetch();
            }
            return isError
              ? notify.error(errorMessages)
              : notify.success({ message: "Employee notification updated successfully." });
          }
          return false;
        })
        .catch((error) => {
          notify.error(undefined, error.message);
        });
    }
    setIsFieldsChanged(false);
  };

  useImperativeHandle(ref, () => ({
    resetForm() {
      setChangedTableData([]);
    },
    setIsFieldsChanged(value) {
      setIsFieldsChanged(value);
    },
    isFieldsChanged,
    loading: defaultNotificationLoading,
  }));

  const columns: ColumnsType<DefaultNotificationSettings> = [
    {
      dataIndex: "eventType",
      fixed: "left",
      width: 280,
      render: (_, { eventType }) => <span>{DefaultNotificationSettingsEventTypeLabel[eventType]}</span>,
    },
    {
      width: 200,
      align: "center",
      dataIndex: "period",
      render: (value, record) =>
        record.eventType !== DefaultNotificationSettingsEventType.IsOffSummary &&
        record.eventType !== DefaultNotificationSettingsEventType.LeaveRequested && (
          <span className="d-flex align-items-center">
            <InputNumber
              min={0}
              value={
                changedTableData.find((item) => item.id === record.id)
                  ? changedTableData.find((item) => item.id === record.id)?.period
                  : value
              }
              data-testid="period"
              aria-label="weeks"
              className="me-2"
              onChange={(value) => onChange({ ...record, period: value }, "period")}
            />
            weeks
          </span>
        ),
    },
    {
      title: "Humanshine",
      width: 130,
      align: "center",
      dataIndex: "isWeb",
      render: (value, record) =>
        record.eventType !== DefaultNotificationSettingsEventType.IsOffSummary && (
          <StyledCheckbox
            className="my-2"
            aria-label="checkboxLabel"
            checked={
              changedTableData.find((item) => item.id === record.id)
                ? changedTableData.find((item) => item.id === record.id)?.isWeb
                : value
            }
            disabled={record.eventType === DefaultNotificationSettingsEventType.LeaveRequested}
            onChange={(e) => onChange({ ...record, isWeb: e.target.checked }, "isWeb")}
          />
        ),
    },
    {
      title: "Email",
      width: 130,
      align: "center",
      dataIndex: "isEmail",
      render: (value, record) => (
        <StyledCheckbox
          className="my-2"
          aria-label="checkboxLabel"
          checked={
            changedTableData.find((item) => item.id === record.id)
              ? changedTableData.find((item) => item.id === record.id)?.isEmail
              : value
          }
          onChange={(e) => onChange({ ...record, isEmail: e.target.checked }, "isEmail")}
        />
      ),
    },
    {
      width: "auto",
    },
  ];
  return (
    <>
      <Form
        form={form}
        id={FORM.employeeNotificationForm}
        onFinish={() => {
          if (isFieldsChanged) {
            onFinish();
          }
        }}
      >
        <Table
          dataSource={data?.defaultNotificationSettings.nodes}
          bordered={false}
          columns={columns}
          loading={loading}
          rowKey="id"
          pagination={false}
          className="section-box-shadow"
          scroll={{
            scrollToFirstRowOnChange: false,
            y: TABLE.tableMaxHeight,
          }}
        />
      </Form>
      <UnsavedPrompt when={isFieldsChanged} />
    </>
  );
});

export default EmployeeNotificationsTab;

const StyledCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper:hover .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border-color: ${COLORS.primaryColor};
  }
`;
