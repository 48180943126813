import { useQuery } from "@apollo/client";
import { Popover, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import { BaseButton } from "components/Buttons";
import { ReminderPopover } from "components/Popover";
import { tableColumnCommonData, sortingTableData, SortTypeEnum, SortOrderEnum } from "components/Table";
import UserAvatar from "components/UserAvatar/UserAvatar";
import PERMISSION from "config/permission";
import COLORS from "constants/colors";
import TABLE from "constants/table";
import dayjs from "dayjs";
import { useWindowInfiniteScroll } from "hooks";
import useCheckPermission from "hooks/useCheckPermission";
import { PageInfo } from "model/Common";
import { Employee, EmployeeRefetch, FetchEmployeeResponse, FilterType } from "model/Employee";
import { forwardRef, useImperativeHandle, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import employee from "routes/employee";
import { FETCH_USER } from "services/graphql/employee";
import styled from "styled-components";

type Props = {
  filterValue?: FilterType;
};

const EmployeeList = forwardRef<EmployeeRefetch, Props>(({ filterValue }, ref) => {
  const { showEmployeeDetailPage } = PERMISSION;
  const { hasShowEmployeeDetailPage } = useCheckPermission({ showEmployeeDetailPage });
  const navigate = useNavigate();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<Employee>>({});
  const [data, setData] = useState<Employee[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();

  const { loading, refetch: fetchUserList } = useQuery<FetchEmployeeResponse>(FETCH_USER, {
    fetchPolicy: "no-cache",
    variables: {
      first: TABLE.rowsPerPage,
      after: undefined,
      searchValue: filterValue?.searchValue,
      departmentIds: filterValue?.departments,
      locationIds: filterValue?.locations,
      showLeavers: filterValue?.showLeavers,
      fetchBirthdaysThisWeek: true,
      fetchWorkAnniversaryThisWeek: true,
    },
    onCompleted: (response) => {
      const updatedUser = response.users.nodes.map((user) => {
        const matchingBirthday = response.birthdaysThisWeek?.nodes.find((birthday) => birthday.id === user.id);
        const matchingAnniversary = response.workAnniversaryThisWeek?.nodes.find(
          (anniversary) => anniversary.id === user.id,
        );

        let birthdayReminder;
        let anniversaryReminder;

        if (matchingBirthday) {
          const date = dayjs(matchingBirthday.dateOfBirth).set("year", dayjs().year());
          const difference = date.diff(dayjs(), "day");
          birthdayReminder =
            date.isBefore(dayjs()) && !date.isSame(dayjs(), "day")
              ? `Birthday ${Math.abs(difference)} days ago`
              : date.isAfter(dayjs())
              ? `Birthday in ${Math.abs(difference) + 1} days`
              : `Birthday today`;
        }

        if (matchingAnniversary) {
          const date = dayjs(matchingAnniversary.startedAt).set("year", dayjs().year());
          const difference = date.diff(dayjs(), "day");
          anniversaryReminder =
            date.isBefore(dayjs()) && !date.isSame(dayjs(), "day")
              ? `Work anniversary ${Math.abs(difference)} days ago`
              : date.isAfter(dayjs())
              ? `Work anniversary in ${Math.abs(difference) + 1} days`
              : `Work anniversary today`;
        }

        return {
          ...user,
          birthDayReminder: matchingBirthday ? birthdayReminder : undefined,
          anniversaryReminder: matchingAnniversary ? anniversaryReminder : undefined,
        };
      });

      setData((d) => [...d, ...(updatedUser ?? [])]);
      if (response.users.pageInfo) setPageInfo(response.users.pageInfo);
    },
  });

  useImperativeHandle(ref, () => ({
    refetch() {
      setData([]);
      fetchUserList({
        first: TABLE.rowsPerPage,
        after: undefined,
        searchValue: filterValue?.searchValue,
        departmentIds: filterValue?.departments,
        locationIds: filterValue?.locations,
        showLeavers: filterValue?.showLeavers,
        fetchBirthdaysThisWeek: true,
        fetchWorkAnniversaryThisWeek: true,
      });
    },
  }));

  useLayoutEffect(() => {
    setData([]);
  }, [filterValue]);

  useWindowInfiniteScroll({
    hasNextPage: pageInfo?.hasNextPage,
    endCursor: pageInfo?.endCursor,
    loading,
    callback: () => {
      fetchUserList({
        first: TABLE.rowsPerPage,
        after: pageInfo?.endCursor,
        searchValue: filterValue?.searchValue,
        departmentIds: filterValue?.departments,
        locationIds: filterValue?.locations,
        showLeavers: filterValue?.showLeavers,
        fetchBirthdaysThisWeek: true,
        fetchWorkAnniversaryThisWeek: true,
      });
    },
  });

  const columns: ColumnsType<Employee> = [
    {
      width: 75,
      align: "center",
      dataIndex: "image",
      render: (_, { profileImageUrl, loggedIn, id }) => (
        <>
          {!loggedIn && <ReminderPopover userId={id} type="login" />}
          <UserAvatar image={profileImageUrl} section="table" />
        </>
      ),
    },
    {
      ...tableColumnCommonData({
        title: "#",
        dataIndex: "employeeNumber",
        defaultSortOrder: SortOrderEnum.Descend,
        width: 70,
        align: "center",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.Number, firstData: a.employeeNumber, secondData: b.employeeNumber }),
      render: (_, { leavingRequest, employeeNumber }) => <span>{leavingRequest ? "/" : employeeNumber || "-"}</span>,
    },
    {
      ...tableColumnCommonData({
        title: "Name",
        dataIndex: "name",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { name, birthDayReminder, anniversaryReminder }) => (
        <div className="d-flex justify-content-start align-items-center gap-2">
          <Typography.Text strong>{name}</Typography.Text>
          {birthDayReminder && (
            <Popover title={birthDayReminder}>
              <StyledBaseButton icon="birthDay" onClick={(e) => e.stopPropagation()} />
            </Popover>
          )}
          {anniversaryReminder && (
            <Popover title={anniversaryReminder}>
              <StyledBaseButton icon="anniversary" onClick={(e) => e.stopPropagation()} />
            </Popover>
          )}
        </div>
      ),
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.String, firstData: a!.name, secondData: b!.name }),
    },
    {
      ...tableColumnCommonData({
        title: "Email",
        dataIndex: "email",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.String, firstData: a!.email, secondData: b!.email }),
    },
    {
      ...tableColumnCommonData({
        title: "Job description",
        dataIndex: "jobTitle",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { jobTitle }) => (jobTitle ? jobTitle.name : "-"),
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.String, firstData: a?.jobTitle?.name, secondData: b?.jobTitle?.name }),
    },
    {
      ...tableColumnCommonData({
        title: "Department",
        dataIndex: "departments",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { departments }) =>
        departments.length ? departments?.map((department) => department.name).join() : "-",
      sorter: (a, b) => {
        const firstData = a.departments.length ? a.departments?.map((department) => department.name).join() : "-";
        const secondData = b.departments.length ? b.departments?.map((department) => department.name).join() : "-";
        return sortingTableData({ type: SortTypeEnum.String, firstData, secondData });
      },
    },
    {
      ...tableColumnCommonData({
        title: "Location",
        dataIndex: "location",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { location }) => (location ? location.name : "-"),
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.String, firstData: a?.location?.name, secondData: b?.location?.name }),
    },
  ];

  return (
    <StyledWrapper>
      <Table
        rowKey="id"
        size="large"
        rowClassName={(record) => (record.leavingRequest ? "leavers-row" : "")}
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        onRow={({ id, leavingRequest }) => ({
          onClick: () => {
            if (hasShowEmployeeDetailPage) {
              navigate(employee.view(id), { state: { isLeaving: !!leavingRequest } });
            }
          },
          onKeyDown: (e) => {
            if (e.key === "Enter") {
              if (hasShowEmployeeDetailPage) {
                navigate(employee.view(id), { state: { isLeaving: !!leavingRequest } });
              }
            }
          },
          tabIndex: 0,
          role: "button",
        })}
        sortDirections={TABLE.tableSortDirections}
        onChange={(_pagination, _filter, sorter) => setSortedInfo(sorter as SorterResult<Employee>)}
        sticky
      />
    </StyledWrapper>
  );
});

export default EmployeeList;

const StyledWrapper = styled.div`
  .ant-table .ant-table-selection-col {
    width: 54px;
  }
  .ant-table-tbody .ant-table-row-selected td.ant-table-cell,
  .ant-table-tbody .ant-table-row-selected:hover td.ant-table-cell {
    background: transparent;
  }
  .ant-table-column-sorter {
    display: none;
  }
  .ant-table-tbody tr:hover {
    cursor: pointer;
  }
  .ant-table-container:has(.ant-table-tbody tr:focus-visible) table {
    border-collapse: collapse;
  }
  .ant-table-tbody tr:focus-visible {
    outline: 1px solid ${COLORS.outlineColor};
    outline-offset: -1px;
    background: ${COLORS.rowBgHover};
  }
  .ant-table-tbody tr {
    &:hover {
      background: ${COLORS.rowBgHover};
    }
    & td.ant-table-cell-row-hover {
      background: none !important;
    }
  }
  .ant-table-tbody tr.leavers-row {
    background-image: repeating-linear-gradient(135deg, transparent, transparent 4px, ${COLORS.lineColor} 7px);
    background-color: ${COLORS.rowBgLightColor};
    color: ${COLORS.disabledColor};

    .ant-checkbox-wrapper .ant-checkbox-inner {
      background-color: ${COLORS.white};
    }
  }
  .ant-table-tbody tr.leavers-row:hover {
    td {
      background: none;
    }
  }
  .popoverIcon {
    position: absolute;
    top: 2px;
    right: 9px;
    z-index: 1;
  }
`;

const StyledBaseButton = styled(BaseButton)`
  background-color: ${COLORS.backgroundLight};
`;
