import { Typography } from "antd";
import { Container } from "components/core";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { DocumentRefetch } from "model/Document";
import DocumentTable from "./DocumentTable";

const DocumentTab = forwardRef<DocumentRefetch>((_, ref) => {
  const { id } = useParams();
  const tableRef = useRef<DocumentRefetch>(null);

  useImperativeHandle(ref, () => ({
    documentRefetch() {
      tableRef.current?.documentRefetch();
    },
  }));

  return (
    <StyledWrapper>
      {id ? (
        <>
          <Container className="p-0 section-box-shadow">
            <Typography.Title level={2} className="p-4 m-0 table-title">
              This employee’s documents
            </Typography.Title>
            <DocumentTable tableFor="employeeDocuments" ref={tableRef} />
          </Container>
          <Container className="p-0 mt-4 section-box-shadow">
            <Typography.Title level={2} className="p-4 m-0 table-title">
              Company documents
            </Typography.Title>
            <DocumentTable tableFor="companyDocuments" />
          </Container>
        </>
      ) : (
        <Container className="p-0 section-box-shadow">
          <DocumentTable tableFor="allDocuments" />
        </Container>
      )}
    </StyledWrapper>
  );
});

export default DocumentTab;

const StyledWrapper = styled.div`
  .ant-table-column-sorter {
    display: none;
  }
`;
