import COLORS from "constants/colors";
import { ChartData } from "model/Leave";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  ReferenceArea,
  Line,
} from "recharts";
import styled from "styled-components";

type Props = {
  data: ChartData[];
};
const LeaveChart = ({ data }: Props) => {
  const LeaveTotal = data[0].taken + data[0].booked;
  const getTicks = (total: number) => {
    const multiples: number[] = [];
    let num = 0;
    while (num <= total) {
      multiples.push(num);
      num += 5;
    }
    if (total <= 5) {
      multiples.push(5);
    }

    return multiples;
  };

  return (
    <StyledChartWrapper height={50} width="100%" className={data[0].name === "accrued" ? "leave-accrued" : ""}>
      <BarChart data={data} layout="vertical">
        <XAxis
          type="number"
          axisLine={false}
          tickLine={false}
          tickSize={4}
          fontSize={10}
          ticks={getTicks(data[0].total)}
        />
        <YAxis type="category" dataKey="name" hide />
        {data[0].name === "accrued" && <CartesianGrid vertical={false} />}
        <Bar
          dataKey="taken"
          stackId="a"
          barSize={6}
          fill={COLORS.takenBarColor}
          radius={[10, 0, 0, 10]}
          background={data[0].name !== "accrued" && { radius: 6 }}
        />
        <Bar dataKey="booked" stackId="a" barSize={6} fill={COLORS.bookedBarColor} />
        <Bar dataKey="requested" stackId="a" barSize={6} fill={COLORS.requestedBarColor} />
        {LeaveTotal > data[0].total && (
          <>
            <ReferenceLine x={data[0].total} stroke={COLORS.referenceBarColor} />
            <ReferenceArea x1={data[0].total} x2={LeaveTotal} fill={COLORS.referenceBarColor} fillOpacity={0.3}>
              <Line />
            </ReferenceArea>
            <ReferenceLine x={LeaveTotal} stroke={COLORS.referenceBarColor} />
          </>
        )}
      </BarChart>
    </StyledChartWrapper>
  );
};

export default LeaveChart;

const StyledChartWrapper = styled(ResponsiveContainer)`
  .recharts-wrapper {
    cursor: pointer !important;
  }
  &.leave-accrued {
    .recharts-wrapper .recharts-cartesian-grid-horizontal line:not(:first-child) {
      stroke-opacity: 0;
    }
  }
`;
