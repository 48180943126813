import { Col, Collapse, CollapseProps, Modal, Row } from "antd";
import { Container } from "components/core";
import Icon from "components/Icon";
import COLORS from "constants/colors";
import styled from "styled-components";
import { LeaveRequestArg } from "model/Leave";
import LeaveForm from "features/users/LeaveForm";
import useFormModal from "hooks/useFormModal";
import { useAuthContext } from "contexts";
import { useFetchRequestList } from "contexts/LeaveRequestListProvider";
import useCreateLeaveRequest from "hooks/useCreateLeaveRequest";
import LeaveHeader from "./LeaveHeader";
import LeaveSummary from "./LeaveSummary";

const YourLeave = () => {
  const { refetchList } = useFetchRequestList();
  const { showModal, hideModal, isModalVisible, onCloseModal, onFieldsChange } = useFormModal();
  const { user } = useAuthContext();

  const { mutate, loading } = useCreateLeaveRequest(
    user?.id,
    () => {
      hideModal();
      refetchList(user?.id);
    },
    "request",
  );

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <LeaveHeader onClick={showModal} />,
      children: <LeaveSummary />,
    },
  ];

  return (
    <StyledContainer>
      <Row>
        <Col span={24}>
          <StyledCollapse
            collapsible="icon"
            expandIcon={({ isActive }) => (
              <StyledIcon
                name="down"
                role="button"
                style={{ transform: `rotate(${isActive ? "180deg" : "0deg"})`, transition: " transform 0.3s" }}
                tabIndex={0}
              />
            )}
            defaultActiveKey={["1"]}
            ghost
            items={items}
          />
        </Col>
      </Row>
      <Modal
        width={385}
        title="Request Leave"
        open={isModalVisible}
        footer={null}
        onCancel={onCloseModal}
        destroyOnClose
        centered
      >
        <LeaveForm
          leaveFormType="request"
          onFieldsChange={onFieldsChange}
          loading={loading}
          onSubmit={(values: LeaveRequestArg) => mutate({ variables: { ...values, userId: user?.id } })}
        />
      </Modal>
    </StyledContainer>
  );
};

export default YourLeave;
const StyledContainer = styled(Container)`
  background: ${COLORS.myLeaveBgColor};
  padding: 13px 9px;
  .chartWrapper {
    width: 90%;
  }
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    display: flex;
    align-items: center !important;
  }
  .ant-collapse-arrow {
    svg {
      stroke-width: 60;
      stroke: ${COLORS.disabledColor};
    }
`;

const StyledIcon = styled(Icon)`
  padding: 5px 0px;
  &:focus-visible {
    outline: 1px solid ${COLORS.outlineColor};
    border-radius: 2px;
    outline-offset: 0px;
    transition: outline-offset 0s, outline 0s;
  }
`;
