import { gql, useQuery } from "@apollo/client";
import { Checkbox, Form, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useForm } from "antd/lib/form/Form";
import COLORS from "constants/colors";
import FORM from "constants/form";
import TABLE from "constants/table";
import { apolloClient } from "contexts/ApolloProvider";
import { ValidationErrors } from "model/Common";
import {
  PersonalNotificationSettingRef,
  PersonalNotificationSettings,
  PersonalNotificationSettingsResponse,
  UpdatePersonalNotificationSetting,
  UpdatePersonalNotificationSettingResponse,
  personalNotificationSettingsEventTypeLabel,
} from "model/Employee";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useNotify } from "services/api";
import { ERROR_FRAGMENT } from "services/graphql/common";
import { PERSONAL_NOTIFICATION_SETTINGS, UPDATE_PERSONAL_NOTIFICATION_SETTING } from "services/graphql/employee";
import styled from "styled-components";

const NotificationSetting = forwardRef<PersonalNotificationSettingRef>((_, ref) => {
  const [form] = useForm();
  const [changedTableData, setChangedTableData] = useState<PersonalNotificationSettings[]>([]);
  const [isFieldsChanged, setIsFieldsChanged] = useState<boolean>(false);
  const [personalNotificationLoading, setPersonalNotificationLoading] = useState<boolean>(false);
  const notify = useNotify();

  const onChange = (record: PersonalNotificationSettings, changedFieldName: string) => {
    setIsFieldsChanged(true);
    let data: PersonalNotificationSettings[] = [];

    if (changedTableData.find((item) => item.id === record.id)) {
      data = changedTableData.map((item) => {
        if (item.id === record.id) {
          return { ...item, [changedFieldName]: record[changedFieldName as keyof PersonalNotificationSettings] };
        }
        return item;
      });
    } else {
      data = [...changedTableData, record];
    }

    setChangedTableData(data);
  };

  const { data, loading, refetch } = useQuery<PersonalNotificationSettingsResponse>(PERSONAL_NOTIFICATION_SETTINGS, {
    variables: { first: TABLE.rowsPerPage },
  });

  const onFinish = () => {
    setPersonalNotificationLoading(true);
    if (changedTableData.length) {
      apolloClient()
        .mutate<UpdatePersonalNotificationSettingResponse>({
          mutation: gql`
          mutation{
            ${UPDATE_PERSONAL_NOTIFICATION_SETTING(changedTableData)}
          }
          ${ERROR_FRAGMENT}
        `,
        })
        .then((response) => {
          if (response.data) {
            let errorMessages: string[] = [];
            const isError = Object.keys(response.data)
              .map(
                (item) => response?.data?.[item as keyof UpdatePersonalNotificationSetting].errors as ValidationErrors,
              )
              .some((item) => {
                if (item && item.fullMessages.length) {
                  errorMessages = item.fullMessages;
                  return true;
                }
                return false;
              });
            if (!isError) {
              setChangedTableData([]);
              setPersonalNotificationLoading(false);
              refetch();
            }
            return isError
              ? notify.error(errorMessages)
              : notify.success({ message: "Personal notification updated successfully." });
          }
          return false;
        })
        .catch((error) => {
          notify.error(undefined, error.message);
        });
    }
    setIsFieldsChanged(false);
  };

  useImperativeHandle(ref, () => ({
    resetForm() {
      setChangedTableData([]);
    },
    setIsFieldsChanged(value) {
      setIsFieldsChanged(value);
    },
    isFieldsChanged,
    loading: personalNotificationLoading,
  }));

  const columns: ColumnsType<PersonalNotificationSettings> = [
    {
      dataIndex: "eventType",
      fixed: "left",
      width: 400,
      render: (_, { eventType }) => <span>{personalNotificationSettingsEventTypeLabel[eventType]}</span>,
    },
    {
      title: "Humanshine",
      width: 130,
      align: "center",
      dataIndex: "isWeb",
      render: (value, record) => (
        <StyledCheckbox
          className="my-2"
          checked={
            changedTableData.find((item) => item.id === record.id)
              ? changedTableData.find((item) => item.id === record.id)?.isWeb
              : value
          }
          onChange={(e) => onChange({ ...record, isWeb: e.target.checked }, "isWeb")}
          aria-label="checkboxLabel"
        />
      ),
    },
    {
      title: "Email",
      width: 130,
      align: "center",
      dataIndex: "isEmail",
      render: (value, record) => (
        <StyledCheckbox
          className="my-2"
          checked={
            changedTableData.find((item) => item.id === record.id)
              ? changedTableData.find((item) => item.id === record.id)?.isEmail
              : value
          }
          onChange={(e) => onChange({ ...record, isEmail: e.target.checked }, "isEmail")}
          aria-label="checkboxLabel"
        />
      ),
    },
    {
      width: "auto",
    },
  ];
  return (
    <Form
      form={form}
      id={FORM.notificationSettingForm}
      onFinish={() => {
        if (isFieldsChanged) {
          onFinish();
        }
      }}
    >
      <Table
        dataSource={data?.personalNotificationSettings.nodes}
        bordered={false}
        columns={columns}
        loading={loading}
        rowKey="id"
        className="section-box-shadow"
        pagination={false}
        scroll={{
          scrollToFirstRowOnChange: false,
          y: TABLE.tableMaxHeight,
        }}
      />
    </Form>
  );
});

export default NotificationSetting;

const StyledCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper:hover .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border-color: ${COLORS.primaryColor};
  }
`;
