export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4302_12210)">
      <path
        d="M15.4691 4.62422C15.4065 4.68163 15.3179 4.65553 15.2449 4.67641C14.7965 4.81733 14.541 5.12526 14.4524 5.57933C14.4419 5.63152 14.4524 5.68371 14.4159 5.72547C14.3325 5.73069 14.296 5.79854 14.2438 5.84551C13.9571 6.12735 13.6755 6.41962 13.3836 6.69624C13.3523 6.66493 13.3158 6.63883 13.2845 6.60751C13.1802 6.50313 13.0342 6.4144 12.9769 6.28392C12.9091 6.13779 13.1072 6.04906 13.1541 5.91336C13.2167 5.72547 13.2428 5.53236 13.2793 5.34447C13.4826 4.30584 14.2282 3.61169 15.3126 3.44989C15.9383 3.35595 16.2615 3.05845 16.3554 2.42171C16.4701 1.64927 16.8351 1.0595 17.492 0.641962C17.81 0.438413 18.1698 0.39144 18.5243 0.318371C18.7537 0.271398 18.9779 0.203548 19.1343 0.00521851C19.15 0.00521851 19.1604 0.00521851 19.176 0.00521851C19.3429 0.120041 19.5097 0.240083 19.5879 0.438413C19.5827 0.511481 19.5306 0.553235 19.4837 0.600208C19.1656 0.91858 18.8424 1.23173 18.5087 1.52923C17.9352 1.63883 17.6328 2.00417 17.5337 2.55741C16.8455 3.23591 16.1573 3.93006 15.4691 4.62422Z"
        fill="#FC8415"
      />
      <path
        d="M18.5137 1.51879C18.5606 1.36744 18.6962 1.28915 18.8004 1.18998C19.0663 0.94468 19.327 0.699377 19.5669 0.427979C19.7806 0.48539 19.8797 0.662843 19.9944 0.824638C19.9944 0.840296 19.9944 0.850734 19.9944 0.866392C19.5773 1.24739 19.082 1.4666 18.5137 1.51879Z"
        fill="#FC8415"
      />
      <path
        d="M11.0843 11.4405C11.6265 10.9029 12.1688 10.3653 12.7058 9.82777C13.7068 8.82046 14.9164 8.32986 16.3294 8.38727C17.492 8.43424 18.5035 8.86222 19.3586 9.66075C19.4472 9.74426 19.468 9.79645 19.369 9.8904C19.1552 10.0887 18.9467 10.2975 18.7485 10.5115C18.6599 10.6054 18.6078 10.5846 18.5296 10.5115C18.0186 10.0313 17.4086 9.73382 16.7152 9.62422C15.6099 9.44677 14.6088 9.70773 13.7798 10.4593C13.1385 11.0386 12.5494 11.6806 11.9394 12.2912C11.9289 12.2965 11.9133 12.2965 11.9029 12.2912C11.6057 12.0512 11.3346 11.7798 11.0895 11.477C11.0739 11.4666 11.0791 11.4509 11.0843 11.4405Z"
        fill="#A591C7"
      />
      <path
        d="M7.7212 8.07411C8.41984 7.3643 9.19669 6.72756 9.79628 5.9238C10.7608 4.62422 10.6409 2.71399 9.5408 1.52401C9.41567 1.38831 9.43131 1.31524 9.55644 1.20564C9.75978 1.02296 9.94747 0.824635 10.1352 0.626305C10.2134 0.542798 10.2603 0.548017 10.3385 0.631524C11.0528 1.39353 11.4803 2.29123 11.595 3.32985C11.7723 4.93737 11.2405 6.28392 10.0935 7.40605C9.58251 7.9071 9.08199 8.4238 8.57626 8.93006C8.56583 8.93528 8.55019 8.93528 8.53976 8.93006C8.24257 8.68998 7.97146 8.41858 7.72641 8.11587C7.71598 8.10543 7.71598 8.08977 7.7212 8.07411Z"
        fill="#D7C1FA"
      />
      <path
        d="M13.0085 15.0835C12.4037 15.334 11.7989 15.5741 11.1785 15.7725C11.0899 15.7725 11.0429 15.6994 10.9908 15.6472C9.95327 14.6086 8.91573 13.5752 7.88341 12.5365C7.82606 12.4791 7.75828 12.4217 7.73221 12.3382C7.71135 12.2443 7.76349 12.1764 7.82606 12.1138C8.14931 11.785 8.47256 11.4614 8.80103 11.1378C8.84796 11.0908 8.90009 11.0491 8.96787 11.0439C8.98873 11.0439 9.00437 11.0439 9.02522 11.0491C9.10864 11.0752 9.16599 11.143 9.22335 11.2004C10.4434 12.4217 11.6634 13.643 12.8834 14.8643C12.9355 14.9217 13.0346 14.9687 13.0085 15.0835Z"
        fill="#FBBD00"
      />
      <path
        d="M8.97303 11.0752C8.51943 11.4144 8.15968 11.8424 7.76865 12.2495C7.753 12.2651 7.76343 12.3017 7.76343 12.3278C7.753 12.3434 7.73736 12.3486 7.71651 12.3434C7.64351 12.3121 7.59659 12.2443 7.53924 12.1921C6.49127 11.1482 5.44852 10.0992 4.40055 9.0501C4.33799 8.98747 4.25457 8.9405 4.24414 8.83611C4.31713 8.5334 4.44748 8.25156 4.55697 7.95929C4.67688 7.64091 4.80723 7.32254 4.93236 7.00417C5.02099 6.96764 5.0627 7.04592 5.10963 7.0929C6.37136 8.35073 7.62787 9.60856 8.88439 10.8716C8.93653 10.9238 9.03038 10.9708 8.97303 11.0752Z"
        fill="#FED82E"
      />
      <path
        d="M9.9786 16.2317C9.3321 16.4823 8.68038 16.7328 8.03387 16.9833C7.87224 17.0459 7.7054 17.0981 7.53856 17.1555C7.48642 17.1399 7.44993 17.1086 7.41343 17.0668C6.69914 16.3518 5.98486 15.6368 5.27057 14.9165C5.24972 14.8956 5.22886 14.8695 5.21322 14.8382C5.20801 14.8226 5.20801 14.8017 5.20801 14.7808C5.23408 14.6816 5.31228 14.619 5.38006 14.5459C5.79716 14.1284 6.21426 13.7056 6.63658 13.2881C6.71479 13.2098 6.78778 13.1211 6.90769 13.1159C7.00154 13.142 7.04325 13.2307 7.11103 13.2933C8.05472 14.2276 8.98277 15.1775 9.93168 16.1065C9.95253 16.1482 9.99946 16.1743 9.9786 16.2317Z"
        fill="#FBBD00"
      />
      <path
        d="M6.8919 13.1576C6.83976 13.1942 6.7772 13.2203 6.73027 13.2672C6.21932 13.7787 5.71359 14.2902 5.20264 14.8069C5.12965 14.8121 5.08794 14.7547 5.04101 14.7077C4.34237 14.0084 3.64372 13.309 2.94508 12.6096C2.90858 12.5731 2.87209 12.5365 2.85645 12.4844C3.04935 11.8789 3.29962 11.2892 3.52381 10.6889C3.60723 10.4697 3.69586 10.2558 3.7845 10.0365C3.85749 10.0209 3.88356 10.0887 3.92005 10.1253C4.83246 11.0386 5.75008 11.952 6.65728 12.8706C6.7407 12.9541 6.83976 13.0167 6.90754 13.1107C6.91797 13.1315 6.91275 13.1472 6.8919 13.1576Z"
        fill="#FED82E"
      />
      <path
        d="M6.31902 17.62C5.71422 17.8862 5.08857 18.1002 4.46813 18.3351C3.04999 18.8779 1.62663 19.4154 0.208485 19.9582C0.161562 19.9739 0.109424 19.9843 0.0625 20C0.0677138 19.8643 0.166775 19.7808 0.250196 19.6921C1.52757 18.4081 2.80494 17.1294 4.08753 15.8507C4.17616 15.762 4.26479 15.6733 4.40035 15.6681C4.46813 15.6785 4.51505 15.7255 4.56198 15.7724C5.11985 16.3309 5.67772 16.8841 6.23038 17.4426C6.27209 17.4896 6.34509 17.5313 6.31902 17.62Z"
        fill="#FBBD00"
      />
      <path
        d="M4.39498 15.6942C4.27506 15.7255 4.19164 15.8038 4.10822 15.8873C2.83607 17.166 1.56391 18.4395 0.291748 19.713C0.203114 19.8017 0.135335 19.9008 0.0571286 20C-0.0315054 20.0157 -0.000222818 19.9739 0.0154185 19.9269C0.620215 18.3403 1.22501 16.7537 1.82459 15.167C2.01229 14.6764 2.2052 14.1858 2.39811 13.6952C2.4711 13.6691 2.5076 13.7213 2.54931 13.7631C3.15932 14.3737 3.77454 14.9791 4.37934 15.595C4.39498 15.6107 4.40019 15.6263 4.41062 15.642C4.41584 15.6681 4.41062 15.6837 4.39498 15.6942Z"
        fill="#FED82E"
      />
      <path
        d="M13.0078 15.0835C12.9765 15.0418 12.9452 15 12.9087 14.9635C11.6418 13.6952 10.3696 12.4217 9.10265 11.1535C9.07658 11.1274 9.04009 11.1065 9.01402 11.0804C8.96188 10.9969 9.00359 10.929 9.06094 10.8716C9.25907 10.6681 9.4624 10.4645 9.66574 10.2662C9.71266 10.2244 9.75959 10.167 9.83258 10.214C10.1975 10.5741 10.5625 10.9342 10.9275 11.2944C10.9796 11.3466 11.0317 11.3883 11.0839 11.4405C11.355 11.738 11.6365 12.0198 11.9337 12.2912C12.6532 13.0167 13.3675 13.737 14.087 14.4624C14.1339 14.5146 14.2017 14.5512 14.2278 14.6399C13.8211 14.786 13.4144 14.9322 13.0078 15.0835Z"
        fill="#FE8F00"
      />
      <path
        d="M9.82737 10.214C9.5771 10.3497 9.42069 10.5845 9.22257 10.7724C9.13393 10.8559 8.99838 10.9186 9.0088 11.0752C8.99316 11.0752 8.98274 11.0752 8.96709 11.0752C8.97231 10.9969 8.90974 10.9603 8.86803 10.9186C7.59587 9.64509 6.32372 8.3716 5.05156 7.09812C5.01506 7.06158 4.97335 7.03549 4.93164 7.00417C5.07763 6.59707 5.22883 6.18998 5.40088 5.70459C6.19337 6.51879 6.95458 7.29645 7.71579 8.07933C7.99733 8.36117 8.28409 8.64822 8.56563 8.93006C8.93581 9.30584 9.3112 9.68684 9.68138 10.0626C9.73873 10.1096 9.78044 10.1618 9.82737 10.214Z"
        fill="#FBBD00"
      />
      <path
        d="M6.89256 13.1211C6.85085 13.0898 6.80914 13.0585 6.77264 13.0271C5.77681 12.0303 4.78099 11.0334 3.78516 10.0365C3.92071 9.62944 4.06149 9.22234 4.24918 8.83612C4.83834 9.42067 5.43271 10.0052 6.01665 10.595C6.59016 11.1691 7.15846 11.7484 7.73198 12.3226C7.58599 12.5731 7.34616 12.7401 7.15325 12.9436C7.08026 13.0219 7.0229 13.1315 6.89256 13.1211Z"
        fill="#FC8415"
      />
      <path
        d="M6.89258 13.1211C7.17412 12.8549 7.45045 12.5887 7.73199 12.3173C7.74242 12.3173 7.75285 12.3225 7.76849 12.3225C7.90405 12.4635 8.03439 12.6044 8.17516 12.7401C9.11886 13.6848 10.0678 14.6346 11.0115 15.5793C11.0688 15.6367 11.1262 15.7046 11.1835 15.762C10.7977 15.9499 10.391 16.0908 9.98434 16.2265C8.95723 15.2035 7.93012 14.1754 6.89779 13.1524C6.89258 13.1472 6.89258 13.1315 6.89258 13.1211Z"
        fill="#FC8415"
      />
      <path
        d="M4.3901 15.6628C3.85308 15.1305 3.31606 14.5981 2.77383 14.0605C2.6487 13.9353 2.52357 13.8152 2.39844 13.6952C2.534 13.2829 2.67998 12.8758 2.86246 12.4791C3.3317 12.9436 3.79573 13.4029 4.26497 13.8674C4.57779 14.1806 4.89583 14.4885 5.20866 14.8017C5.21387 14.8121 5.21387 14.8278 5.21387 14.8382C5.08353 15.0731 4.85933 15.2244 4.68207 15.4175C4.59343 15.5063 4.52044 15.6211 4.3901 15.6628Z"
        fill="#FC8415"
      />
      <path
        d="M4.38965 15.6628C4.65034 15.3758 4.92145 15.0992 5.21342 14.8382C5.41155 15.0313 5.61488 15.2244 5.81301 15.4175C6.39173 15.9969 6.96525 16.5762 7.53876 17.1555C7.14252 17.3278 6.73584 17.4843 6.32396 17.6148C5.68266 16.9729 5.04137 16.3309 4.40008 15.6889C4.39486 15.6837 4.38965 15.6733 4.38965 15.6628Z"
        fill="#FC8415"
      />
      <path
        d="M12.8883 1.12214C12.8987 0.970781 12.8101 0.735917 12.9196 0.621095C13.0395 0.495834 13.2741 0.589779 13.4618 0.579341C13.6234 0.568903 13.7851 0.579341 13.9519 0.574122C14.051 0.568903 14.0927 0.600218 14.0927 0.704602C14.0875 1.01254 14.0875 1.31525 14.0927 1.62318C14.0927 1.72757 14.0562 1.75888 13.9571 1.75888C13.6495 1.75366 13.3471 1.75366 13.0395 1.75888C12.9092 1.7641 12.8883 1.70669 12.8935 1.59709C12.8935 1.45617 12.8883 1.30481 12.8883 1.12214Z"
        fill="#9AEDAB"
      />
      <path
        d="M6.94448 2.37997C6.94448 2.53132 6.93927 2.67746 6.94448 2.82882C6.9497 2.92276 6.91841 2.96974 6.81414 2.96974C6.50653 2.96452 6.20413 2.96452 5.89652 2.96974C5.79224 2.96974 5.76096 2.92798 5.76617 2.82882C5.77138 2.51567 5.77138 2.20251 5.76617 1.88936C5.76617 1.78498 5.8131 1.75888 5.90694 1.75888C6.20413 1.7641 6.50653 1.7641 6.80371 1.75888C6.91841 1.75888 6.9497 1.80063 6.9497 1.91024C6.93927 2.06681 6.94448 2.22339 6.94448 2.37997Z"
        fill="#84DEFE"
      />
      <path
        d="M7.54946 5.3288C7.39305 5.3288 7.23664 5.32358 7.08022 5.3288C6.98116 5.33402 6.93945 5.29749 6.93945 5.1931C6.94467 4.88517 6.94467 4.58246 6.93945 4.27452C6.93945 4.1858 6.96552 4.14404 7.05937 4.14404C7.37741 4.14926 7.69545 4.14926 8.01349 4.14404C8.12298 4.14404 8.14383 4.19623 8.14383 4.28496C8.13862 4.58246 8.13862 4.88517 8.14383 5.18267C8.14383 5.29749 8.09691 5.3288 7.99263 5.32358C7.84665 5.32358 7.70066 5.3288 7.54946 5.3288Z"
        fill="#84DEFE"
      />
      <path
        d="M18.8271 5.92379C18.8271 6.08036 18.8219 6.23694 18.8271 6.39351C18.8323 6.49268 18.801 6.53443 18.6967 6.53443C18.3891 6.52921 18.0867 6.52921 17.7791 6.53443C17.6748 6.53443 17.6436 6.4979 17.6436 6.39873C17.6488 6.0908 17.6488 5.78809 17.6436 5.48015C17.6436 5.37055 17.6748 5.3288 17.7843 5.3288C18.0815 5.33402 18.3839 5.33402 18.6811 5.3288C18.7958 5.3288 18.8271 5.36533 18.8219 5.47493C18.8219 5.62107 18.8271 5.77243 18.8271 5.92379Z"
        fill="#55C7FE"
      />
      <path
        d="M17.0387 14.8382C16.898 14.8382 16.752 14.833 16.6112 14.8382C16.4965 14.8434 16.4444 14.8173 16.4496 14.6921C16.46 14.3946 16.4548 14.0919 16.4496 13.7944C16.4496 13.6952 16.4756 13.6534 16.5799 13.6587C16.8927 13.6639 17.2056 13.6639 17.5184 13.6587C17.6227 13.6587 17.6487 13.7004 17.6487 13.7944C17.6435 14.0919 17.6383 14.3946 17.6487 14.6921C17.654 14.8225 17.6018 14.8434 17.4871 14.8382C17.3359 14.833 17.1847 14.8382 17.0387 14.8382Z"
        fill="#7EC58E"
      />
      <path
        d="M15.8544 12.4478C15.6667 12.4478 15.4165 12.5209 15.307 12.4269C15.1818 12.3173 15.2757 12.0616 15.2653 11.8685C15.26 11.738 15.2757 11.6075 15.26 11.477C15.2444 11.3205 15.2861 11.2578 15.4582 11.2683C15.7345 11.2839 16.016 11.2787 16.2976 11.2683C16.4227 11.263 16.454 11.3048 16.4488 11.4248C16.4436 11.7171 16.4384 12.0094 16.4488 12.3017C16.454 12.4374 16.4019 12.4687 16.2819 12.4635C16.1412 12.453 15.9952 12.4635 15.8544 12.4635C15.8544 12.4582 15.8544 12.453 15.8544 12.4478Z"
        fill="#9AEDAB"
      />
      <path
        d="M15.4688 4.62421C15.4115 4.5668 15.4844 4.54592 15.5053 4.51983C16.1466 3.87265 16.7931 3.23068 17.4344 2.58872C17.4605 2.56262 17.4813 2.49478 17.5387 2.55219C17.4031 3.70563 16.6158 4.49373 15.4688 4.62421Z"
        fill="#FC8415"
      />
      <path
        d="M13.3887 6.69623C13.3887 6.63882 13.42 6.60229 13.4565 6.56575C13.738 6.28392 14.0195 6.00208 14.3063 5.72024C14.3428 5.68893 14.3845 5.60542 14.4158 5.72546C14.3636 6.20041 14.1864 6.62316 13.8631 6.98329C13.7953 7.06158 13.7484 7.08767 13.6702 6.99373C13.5816 6.88934 13.4825 6.7954 13.3887 6.69623Z"
        fill="#FC8415"
      />
    </g>
    <defs>
      <clipPath id="clip0_4302_12210">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
