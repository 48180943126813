import { useMutation } from "@apollo/client";
import { Modal, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { DefaultButton } from "components/Buttons";
import { tableColumnCommonData, sortingTableData, SortTypeEnum, SortOrderEnum } from "components/Table";
import { useModal } from "components/modal";
import COLORS from "constants/colors";
import DATE_FORMAT from "constants/dateFormat";
import TABLE from "constants/table";
import dayjs from "dayjs";
import { Actioned, actionedLabel, DeleteAccessResponse, UserDocumentConnections } from "model/Document";
import { useState } from "react";
import { useNotify } from "services/api";
import { DELETE_ACCESS } from "services/graphql/document";
import styled from "styled-components";

interface props {
  tableData?: UserDocumentConnections[];
  actionDescription?: string;
  loading: boolean;
  refetch: () => void;
}

const DocumentSharedListTable = ({ tableData, actionDescription, loading, refetch }: props) => {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<UserDocumentConnections>>({});
  const { isModalVisible, hideModal, showModal } = useModal();
  const notify = useNotify();

  const [deleteAccess, { loading: deleteAccessLoading }] = useMutation<DeleteAccessResponse>(DELETE_ACCESS, {
    onCompleted: (response) => {
      if (response?.destroyUserDocumentConnection?.success) {
        notify.success({ message: "Delete access successfully." });
        refetch();
      }
    },
  });

  const columns: ColumnsType<UserDocumentConnections> = [
    {
      ...tableColumnCommonData({
        title: "Employee name",
        dataIndex: "name",
        defaultSortOrder: SortOrderEnum.Descend,
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { user }) => (
        <Typography.Title level={3} className="mb-0 title">
          {user.name}
        </Typography.Title>
      ),
      sorter: (a, b) =>
        sortingTableData({ type: SortTypeEnum.String, firstData: a.user!.name, secondData: b.user!.name }),
    },
    {
      ...tableColumnCommonData({
        title: "Date first shared",
        dataIndex: "date",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { dateAdded }) => dateAdded && dayjs(dateAdded).format(DATE_FORMAT.fullDateDefault),
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.dateAdded, secondData: b.dateAdded }),
    },
    {
      ...tableColumnCommonData({
        title: "Actioned",
        dataIndex: "actioned",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { userActionConnection }) =>
        userActionConnection?.dateActioned ? actionedLabel[Actioned.Yes] : actionedLabel[Actioned.Pending],
      sorter: (a, b) => {
        const firstData = a.userActionConnection?.dateActioned
          ? actionedLabel[Actioned.Yes]
          : actionedLabel[Actioned.Pending];
        const secondData = b.userActionConnection?.dateActioned
          ? actionedLabel[Actioned.Yes]
          : actionedLabel[Actioned.Pending];
        return sortingTableData({ type: SortTypeEnum.String, firstData, secondData });
      },
    },
    {
      ...tableColumnCommonData({
        title: "Date actioned",
        dataIndex: "dateActioned",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { userActionConnection }) =>
        userActionConnection?.dateActioned
          ? dayjs(userActionConnection.dateActioned).format(DATE_FORMAT.fullDateDefault)
          : "/",
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.Date,
          firstData: a.userActionConnection?.dateActioned,
          secondData: b.userActionConnection?.dateActioned,
        }),
    },
    {
      title: "Details of action",
      dataIndex: "action",
      width: "7vw",
      render: () =>
        actionDescription && (
          <DefaultButton
            onClick={() => {
              showModal();
            }}
          >
            View
          </DefaultButton>
        ),
    },
    {
      title: "",
      dataIndex: "deleteAccess",
      align: "end",
      width: "15vw",
      render: (_, { id }) => (
        <DefaultButton
          className="me-1"
          loading={deleteAccessLoading}
          onClick={() => {
            deleteAccess({ variables: { id } });
          }}
        >
          Delete access
        </DefaultButton>
      ),
    },
  ];

  return (
    <StyledWrapper>
      <Table
        columns={columns}
        dataSource={tableData ?? []}
        pagination={false}
        rowKey="id"
        loading={loading}
        sortDirections={TABLE.tableSortDirections}
        onChange={(_pagination, _filter, sorter) => setSortedInfo(sorter as SorterResult<UserDocumentConnections>)}
        sticky
      />
      <Modal
        open={isModalVisible}
        closable={false}
        onCancel={hideModal}
        footer={null}
        width={308}
        centered
        destroyOnClose
      >
        <StyledDetailSection>
          <Typography.Text className="title">Details of Action</Typography.Text>
          <Typography.Title level={2} className="detail">
            {actionDescription}
          </Typography.Title>
          <DefaultButton onClick={hideModal} className="w-100">
            Ok
          </DefaultButton>
        </StyledDetailSection>
      </Modal>
    </StyledWrapper>
  );
};

export default DocumentSharedListTable;

const StyledWrapper = styled.div`
  .ant-table-column-sorter {
    display: none;
  }

  .title {
    font-size: 14px;
  }
`;

const StyledDetailSection = styled.div`
  text-align: center;
  .title {
    font-size: 13px;
    font-weight: 500;
    color: ${COLORS.headerText};
    opacity: 0.8;
  }
  & .ant-typography + h2.ant-typography.detail {
    margin: 10px 0 31px 0;
  }
`;
