import { Checkbox, Col, Form, Input, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Container } from "components/core";
import Icon from "components/Icon";
import { InputDropDown } from "components/Inputs";
import COLORS from "constants/colors";
import { useDepartments, useLocations } from "hooks";
import { FilterType } from "model/Employee";
import styled from "styled-components";

type Props = {
  onChange: (filterValue: FilterType) => void;
};

const ListFilter = ({ onChange }: Props) => {
  const [form] = useForm();
  const selectedDepartments = Form.useWatch("departments", form);
  const selectedLocations = Form.useWatch("locations", form);

  const {
    departments,
    loading: departmentsLoading,
    handleDropdownScroll: handleDepartmentDropdownScroll,
  } = useDepartments({});

  const { locations, loading: locationsLoading, handleDropdownScroll: handleLocationDropdownScroll } = useLocations({});

  return (
    <StyledContainer className="section-box-shadow" id="employee_filter">
      <Form
        layout="vertical"
        form={form}
        initialValues={{ showLeavers: false }}
        onValuesChange={(_, allFields) => onChange(allFields)}
      >
        <StyledRow gutter={27}>
          <Col lg={7} xl={8}>
            <Form.Item label="Search employees" name="searchValue" className="m-0">
              <Input placeholder="Search" data-testid="search" prefix={<Icon name="search" />} />
            </Form.Item>
          </Col>
          <Col lg={11} xl={12}>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="Location" name="locations" className="m-0">
                  <InputDropDown
                    className="w-100"
                    placeholder="Please select"
                    options={locations}
                    loading={locationsLoading}
                    optionLabelProp="label"
                    showSearch={false}
                    data-testid="locations"
                    maxTagCount="responsive"
                    mode="multiple"
                    onPopupScroll={handleLocationDropdownScroll}
                    selectedValue={selectedLocations}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Department" name="departments" className="m-0">
                  <InputDropDown
                    className="w-100"
                    placeholder="Please select"
                    options={departments}
                    loading={departmentsLoading}
                    optionLabelProp="label"
                    showSearch={false}
                    data-testid="departments"
                    maxTagCount="responsive"
                    mode="multiple"
                    onPopupScroll={handleDepartmentDropdownScroll}
                    selectedValue={selectedDepartments}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={6} xl={4} className="d-flex align-items-center">
            <Form.Item name="showLeavers" className="m-0 mt-4" valuePropName="checked">
              <Checkbox data-testid="showLeavers">Show leavers</Checkbox>
            </Form.Item>
          </Col>
        </StyledRow>
      </Form>
    </StyledContainer>
  );
};

export default ListFilter;

const StyledContainer = styled(Container)`
  padding: 18px 24px 24px 24px;
`;

const StyledRow = styled(Row)`
  .ant-form-item-label {
    font-weight: 500;
    opacity: 0.8;
  }
  .ant-select-selection-item {
    font-weight: 500;
    color: ${COLORS.inputFieldTextColor};
  }

  & .ant-checkbox + span {
    padding-right: 0;
  }
`;
