import COLORS from "constants/colors";
import styled from "styled-components";

export const Container = styled.div<{ isSmall?: boolean; isFilterBox?: boolean }>`
  border-radius: 4px;
  background-color: ${COLORS.white};
  padding: ${(p) => (p.isSmall ? "39px 38px" : "39px 20px")};
  padding-bottom: ${(p) => (p.isFilterBox ? "0px" : undefined)};
`;

export default Container;
