export default () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="11" fill="#E7B128" />
    <rect x="5" y="5" width="12" height="13" rx="6" fill="#E7B128" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.826 12.587a1.174 1.174 0 0 0 2.348 0V7.022a1.174 1.174 0 0 0-2.348 0zM11 17.152c.648 0 1.174-.57 1.174-1.272s-.526-1.272-1.174-1.272-1.174.57-1.174 1.272.526 1.272 1.174 1.272"
      fill="#fff"
    />
  </svg>
);
