import { useQuery } from "@apollo/client";
import { DatePicker, Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { SuccessButton } from "components/Buttons";
import { InputAddDropDown, InputDropDown, InputText, SelectRefType } from "components/Inputs";
import ALERTS from "config/alerts";
import DATE_FORMAT from "constants/dateFormat";
import LABEL from "constants/label";
import dayjs from "dayjs";
import { useDepartments, useJobTitles, useLocations } from "hooks";
import { CreateEmployeePayload, FetchEmployeeResponse } from "model/Employee";
import { Admins } from "model/User";
import { useRef, useState } from "react";
import { useNotify } from "services/api";
import { FETCH_LINE_MANAGERS } from "services/graphql/employee";

type Props = {
  onFieldsChange: () => void;
  onFinish: (data: CreateEmployeePayload) => void;
  loading: boolean;
};

const AddEmployeeForm = ({ onFieldsChange, onFinish, loading }: Props) => {
  const [form] = useForm();
  const notify = useNotify();
  const departmentSelectRef = useRef<SelectRefType>(null);
  const locationSelectRef = useRef<SelectRefType>(null);
  const jobTitleSelectRef = useRef<SelectRefType>(null);
  const selectedDepartment = Form.useWatch("departmentIds", form);
  const selectedLineManager = Form.useWatch("lineManagerIds", form);
  const selectedLocations = Form.useWatch("locationId", form);
  const selectedJobTitle = Form.useWatch("jobTitleId", form);
  const [notifyUser, setNotifyUser] = useState<boolean>(false);

  const {
    departments,
    loading: departmentLoading,
    createDepartment,
    handleDropdownScroll: handleDepartmentDropdownScroll,
  } = useDepartments({
    notify,
    after: true,
    onCompleted: (response) => {
      if (selectedDepartment && selectedDepartment.length) {
        form.setFieldValue("departmentIds", [...selectedDepartment, response.createDepartment.department.id]);
      } else {
        form.setFieldValue("departmentIds", [response.createDepartment.department.id]);
      }
      onFieldsChange();
      form.validateFields(["departmentIds"]);
      departmentSelectRef.current?.onAddSuccessFully();
    },
  });

  const {
    locations,
    loading: locationLoading,
    createLocation,
    handleDropdownScroll: handleLocationDropdownScroll,
  } = useLocations({
    notify,
    after: true,
    onCompleted: (response) => {
      form.setFieldValue("locationId", response.createLocation.location.id);
      onFieldsChange();
      locationSelectRef.current?.onAddSuccessFully();
    },
  });

  const {
    jobTitles,
    loading: jobTitleLoading,
    createJobTitle,
    handleDropdownScroll: handleJobTitleDropdownScroll,
  } = useJobTitles({
    notify,
    after: true,
    onCompleted: (response) => {
      form.setFieldValue("jobTitleId", response.createJobTitle.jobTitle.id);
      onFieldsChange();
      jobTitleSelectRef.current?.onAddSuccessFully();
    },
  });

  const { data: lineManagers, loading: lineManagersLoading } = useQuery<FetchEmployeeResponse>(FETCH_LINE_MANAGERS, {
    variables: { first: LABEL.rowsPerPage, roles: [Admins.Superuser.toUpperCase(), Admins.Manager.toUpperCase()] },
    fetchPolicy: "no-cache",
  });

  return (
    <div>
      <Form
        layout="vertical"
        className="p-0"
        form={form}
        onFieldsChange={onFieldsChange}
        onFinish={(values) =>
          onFinish({ ...values, notifyUser, startedAt: dayjs(values.startedAt).format(DATE_FORMAT.isoFormat) })
        }
      >
        <Form.Item label="Employee name" name="name" rules={[ALERTS.required, ALERTS.characterLength]}>
          <InputText data-testid="name" />
        </Form.Item>
        <Form.Item label="Work email" name="email" rules={[ALERTS.required, ALERTS.email]}>
          <InputText type="email" data-testid="email" />
        </Form.Item>
        <Form.Item label="Job title" name="jobTitleId">
          <InputAddDropDown
            options={jobTitles}
            loading={jobTitleLoading}
            placeholder="Please select"
            data-testid="jobTitleId"
            getPopupContainer={(trigger) => trigger.parentNode}
            addBtnFor="job title"
            onAdd={(data) => createJobTitle({ name: data.newLabel })}
            onPopupScroll={handleJobTitleDropdownScroll}
            ref={jobTitleSelectRef}
            selectedValue={selectedJobTitle}
          />
        </Form.Item>
        <Form.Item label="Department" name="departmentIds">
          <InputAddDropDown
            options={departments}
            loading={departmentLoading}
            placeholder="Please select"
            getPopupContainer={(trigger) => trigger.parentNode}
            optionLabelProp="label"
            showSearch={false}
            data-testid="departmentIds"
            maxTagCount="responsive"
            mode="multiple"
            addBtnFor="department"
            onAdd={(data) => createDepartment({ name: data.newLabel })}
            ref={departmentSelectRef}
            onPopupScroll={handleDepartmentDropdownScroll}
            selectedValue={selectedDepartment}
          />
        </Form.Item>
        <Form.Item label="Line manager" name="lineManagerIds">
          <InputDropDown
            options={lineManagers?.users.nodes.map((item) => ({ label: item.name, value: item.id }))}
            loading={lineManagersLoading}
            placeholder="Please select"
            getPopupContainer={(trigger) => trigger.parentNode}
            optionLabelProp="label"
            data-testid="lineManagerIds"
            showSearch={false}
            maxTagCount="responsive"
            mode="multiple"
            selectedValue={selectedLineManager}
          />
        </Form.Item>
        <Form.Item label="Work location" name="locationId">
          <InputAddDropDown
            options={locations}
            loading={locationLoading}
            placeholder="Please select"
            getPopupContainer={(trigger) => trigger.parentNode}
            addBtnFor="work location"
            data-testid="locationId"
            onPopupScroll={handleLocationDropdownScroll}
            onAdd={(data) => createLocation({ name: data.newLabel })}
            ref={locationSelectRef}
            selectedValue={selectedLocations}
          />
        </Form.Item>
        <Form.Item label="Start date" name="startedAt">
          <DatePicker
            className="w-100"
            changeOnBlur
            data-testid="startedAt"
            format={DATE_FORMAT.datePickerAllowDate}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          />
        </Form.Item>
        <div className="d-flex gap-3">
          <SuccessButton htmlType="submit" className="w-25" loading={loading}>
            Save
          </SuccessButton>
          <SuccessButton className="w-100" htmlType="submit" loading={loading} onClick={() => setNotifyUser(true)}>
            Save and notify new employee
          </SuccessButton>
        </div>
      </Form>
    </div>
  );
};

export default AddEmployeeForm;
