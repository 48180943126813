export default () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#a)">
      <rect x="11" y="6" width="34" height="34" rx="6.231" fill="#fff" />
      <rect x="10.87" y="5.87" width="34.26" height="34.26" rx="6.361" stroke="#D6DCE5" strokeWidth=".26" />
      <path
        d="M20.092 14.628h6.49c.905 0 1.47.739 1.258 1.648l-3.022 12.971c-.213.907-1.12 1.646-2.026 1.646h-6.49c-.906 0-1.469-.739-1.258-1.646l3.024-12.97c.21-.91 1.116-1.649 2.024-1.649"
        fill="#ED171F"
      />
      <path
        d="M26.042 14.628h7.463c.905 0 .497.739.284 1.648l-3.021 12.971c-.211.907-.145 1.646-1.053 1.646h-7.463c-.907 0-1.469-.739-1.255-1.646l3.02-12.97c.213-.91 1.119-1.649 2.025-1.649"
        fill="#082F67"
      />
      <path
        d="M33.21 14.628h6.488c.906 0 1.47.739 1.257 1.648l-3.02 12.971c-.213.907-1.12 1.646-2.029 1.646H29.42c-.908 0-1.47-.739-1.257-1.646l3.02-12.97c.212-.91 1.118-1.649 2.026-1.649"
        fill="#006A65"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.531 18.775h.272l.051.002c.533.011.98.02 1.207.331a.8.8 0 0 1 .124.445c0 .136-.025.259-.05.358-.098.42-.308.777-.629 1.011-.469.346-1.074.346-1.604.346h-.05a.4.4 0 0 0-.074.074c0 .025-.123.53-.123.53s-.148.704-.173.815c-.098-.012-.358-.012-.962 0 .024-.123.63-2.604.63-2.604l.246-1.172.037-.148c.111.012.395.025 1.098.012m.185 1.802c.222-.099.383-.32.444-.58.062-.284-.024-.506-.234-.592-.012 0-.568-.05-.568-.05l-.11-.012v.05l-.013.061-.271 1.16-.013.062h.148c.222 0 .445-.013.617-.1"
        fill="#fff"
      />
      <path
        d="m20.833 18.788-.469 2.184c-.098.42-.172.715-.407.913a.73.73 0 0 1-.481.16c-.296 0-.47-.148-.494-.42l-.012-.098.086-.568s.47-1.888.556-2.134c0-.013.012-.025.012-.025-.913.012-1.086 0-1.086-.012 0 .024-.025.135-.025.135l-.48 2.123-.038.185-.074.592c0 .173.037.321.099.444.222.383.839.445 1.197.445.456 0 .876-.1 1.172-.272.485-.284.619-.727.738-1.122l.015-.05.049-.21s.481-1.962.568-2.221v-.025l-.389-.007c-.361-.006-.49-.008-.537-.017"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m34.21 24.205-.79 2.603h.21l-.079.265.017-.055h.222a.35.35 0 0 0 .16-.05c.037-.012.074-.036.124-.098l.58-.814h-.778l.136-.445h.888l.124-.407h-.876l.148-.506h2.443l-.148.506h-.815l-.135.407h.827l-.136.445h-.889l-.16.185h.358l.086.567.01.051a.2.2 0 0 0 .015.06c.012.025.123.037.185.037h.11l-.16.543h-.468a.4.4 0 0 1-.135-.053q-.03-.02-.063-.033a.29.29 0 0 1-.148-.197l-.086-.568-.407.555a.68.68 0 0 1-.593.309h-.567l.048-.16h-.209l-.05.172h-.752l.05-.173h-1.53l.16-.493h.148l.962-3.184h.765l-.086.272s.21-.148.395-.198c.164-.04.957-.065 1.222-.072v-.002h.814l.012.309c0 .049.037.074.136.074h.173l-.148.506h-.445c-.382.024-.53-.136-.518-.321l-.014-.318-.084.28zm-2.148 2.616h.605l.185-.58h-.617zm.494-1.63-.21.692s.235-.123.358-.16c.123-.025.32-.05.32-.05l.149-.481zm.296-.986-.197.666s.222-.099.345-.136.309-.049.309-.049l.148-.481z"
        fill="#fff"
      />
      <path
        d="M26.127 24.834h2.06l-.148.494h-.826l-.124.42h.84l-.149.505h-.839l-.21.679c-.024.074.198.086.284.086l.42-.062-.173.568h-.95c-.059 0-.11-.008-.17-.017l-.052-.008c-.074-.012-.111-.061-.148-.11l-.02-.03c-.031-.047-.06-.09-.03-.193l.272-.9h-.469l.148-.519h.469l.123-.42h-.468zm2.221-.888h-.84l.124-.272h-.839l-.21.383a.5.5 0 0 1-.123.16.5.5 0 0 1-.185.025h-.111l-.148.493h.37a.76.76 0 0 0 .383-.098l.057-.016a.14.14 0 0 0 .09-.046l.124-.111h1.16z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.718 23.674h.716l-.099.358s.222-.185.383-.247c.16-.061.518-.11.518-.11h1.16L31 24.994c-.074.222-.148.37-.197.444a.63.63 0 0 1-.469.271c-.074.013-.198.013-.358.013h-1.123l-.32 1.048-.017.062c-.017.06-.027.095-.008.123.012.025.049.05.11.05l.494-.05-.173.58h-.555c-.173 0-.308 0-.395-.012l-.072-.006c-.06-.004-.118-.008-.162-.043l-.022-.021c-.047-.046-.102-.099-.102-.152 0-.058.022-.138.055-.26l.007-.024zm.42 1.123s.234-.21.63-.284c.085-.012.653-.012.653-.012l.087-.284H29.31zm1.11.21h-1.184l-.074.234h1.024a.5.5 0 0 1 .15 0h.01z"
        fill="#fff"
      />
      <path
        d="m30.754 26.13.062-.321h-.543l-.074.222c-.012.037-.025.062-.05.074a.3.3 0 0 1-.123.025h-.173l.013-.284h-.704l-.024 1.394c0 .099.012.16.086.21.074.062.296.074.605.074h.444l.16-.53-.382.024-.124.012-.016-.011q-.012-.006-.033-.025-.005-.01-.012-.015c-.012-.011-.025-.024-.025-.097v-.357l.395-.025a.53.53 0 0 0 .383-.136c.086-.062.11-.136.135-.234m-8.057-3.443c.382-.012.505-.012.826-.012l.037-.037c.04-.265.063-.382.12-.666l.053-.26.05-.222.003-.016c.096-.446.144-.665.07-.848-.086-.21-.283-.259-.456-.259a1.24 1.24 0 0 0-.53.124l-.1.055-.098.056.061-.223-.037-.037c-.397.085-.5.099-.849.145l-.027.004-.024.024-.001.008c-.05.354-.087.626-.246 1.337l-.093.395-.092.395.012.037c.383-.012.506-.012.84-.012l.024-.025c.02-.092.032-.154.046-.227.02-.104.044-.228.102-.489.016-.07.035-.166.056-.267.045-.216.096-.463.13-.572a.7.7 0 0 1 .246-.074c.185 0 .173.173.16.235a9 9 0 0 1-.148.777l-.05.197a4 4 0 0 1-.08.358q-.008.038-.018.074zm2.147-.012h-.445l-.174.005a5 5 0 0 1-.245.007l-.025-.025a3 3 0 0 1 .099-.32c.024-.062.05-.136.086-.321a6 6 0 0 0 .154-.79l.031-.21.025-.024q.14-.018.24-.035c.075-.011.14-.02.204-.027a3 3 0 0 0 .457-.087l.012.013v.012q-.027.119-.056.235l-.055.234q-.027.12-.056.234l-.055.235c-.074.333-.099.444-.123.543-.006.04-.012.073-.02.112l-.03.184z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.682 20.676c.11.148.197.382.086.876-.037.148-.136.518-.345.752-.284.309-.667.408-1.037.408-.148 0-.37-.025-.53-.087-.507-.21-.433-.752-.358-1.06.061-.297.185-.606.431-.84.346-.32.79-.333.975-.333.309 0 .605.061.778.284m-.78.91q.007-.037.015-.059c.086-.383.074-.63-.136-.605-.26 0-.358.309-.444.691l-.011.058a1.7 1.7 0 0 0-.039.313c0 .234.16.234.185.234.1 0 .173-.062.247-.16.093-.113.151-.346.182-.471m8.814 1.125c-.395 0-.506 0-.876.012l-.025-.025.04-.147c-.135.112-.28.19-.435.197a1.2 1.2 0 0 1-.444-.087c-.506-.197-.432-.752-.358-1.049.062-.296.185-.604.432-.839.358-.308.765-.395.938-.333a.56.56 0 0 1 .274.221l.01-.073.024-.024.211-.033c.238-.037.367-.057.69-.116l.012.037-.222.926c-.115.501-.17.814-.219 1.086v.001l-.04.221zm-.554-1.43c-.003-.213-.097-.335-.248-.335-.26 0-.358.32-.444.691l-.007.037a2 2 0 0 0-.043.333c0 .235.16.235.185.235.099 0 .26-.05.321-.148a1 1 0 0 0 .1-.21 18 18 0 0 0 .136-.604"
        fill="#fff"
      />
      <path
        d="M29.99 22.675c-.334 0-.445 0-.815.012l-.013-.025.019-.073c.03-.12.06-.235.08-.359l.05-.197c.073-.309.135-.666.148-.777.012-.062.024-.235-.16-.235a.7.7 0 0 0-.248.074 9 9 0 0 0-.128.572l-.057.267a8 8 0 0 0-.136.716l-.024.025c-.333 0-.457 0-.84.012l-.012-.037.093-.395q.046-.2.093-.395c.159-.71.196-.983.245-1.337l.001-.008.025-.024.027-.004c.348-.046.452-.06.85-.144l.036.037-.062.222q.047-.028.1-.056l.098-.055a1.2 1.2 0 0 1 .518-.124c.173 0 .37.05.457.26.073.182.025.401-.071.847l-.003.016-.05.222a14 14 0 0 0-.185.926zm8.945-2.258-.037-.038a8 8 0 0 1-.839.148l-.024.025v.012c-.145.337-.213.455-.278.569a4 4 0 0 0-.216.431v-.062l-.05-1.085-.036-.038-.237.05c-.209.045-.284.06-.578.099l-.025.024c0 .012 0 .025-.012.037.037.186.04.204.06.364l.027.216.037.29.037.29.036.297c.021.17.043.342.075.653-.099.165-.169.292-.232.406-.103.186-.188.34-.348.57l.012.036c.383-.012.469-.012.753-.012l.061-.074c.198-.432 1.814-3.209 1.814-3.209m-13.672-.197c.185.099.16.308-.062.457-.222.148-.543.185-.728.086-.185-.111-.16-.32.062-.469s.543-.185.728-.074"
        fill="#fff"
      />
    </g>
    <defs>
      <filter
        id="a"
        x=".356"
        y=".548"
        width="55.289"
        height="55.289"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy=".519" />
        <feGaussianBlur stdDeviation="2.596" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_302_2826" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="5.192" />
        <feGaussianBlur stdDeviation="5.192" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="effect1_dropShadow_302_2826" result="effect2_dropShadow_302_2826" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_302_2826" result="shape" />
      </filter>
    </defs>
  </svg>
);
