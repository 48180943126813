import COLORS from "constants/colors";
import { forwardRef, ReactElement } from "react";
import styled from "styled-components";
import BaseButton, { BaseBtnProps } from "./BaseButton";

const StyledButton = styled(BaseButton)`
  &.btn-icon {
    height: 36px;

    :hover,
    :focus-visible {
      background-color: ${COLORS.baseHoverBgColor} !important;
    }

    :focus-within {
      background-color: ${COLORS.baseActive};
    }
  }
`;

const IconButton = forwardRef<HTMLElement, Omit<BaseBtnProps, "type" | "shape">>(
  ({ className, ...props }, ref): ReactElement => (
    <StyledButton {...props} type="default" shape="circle" className={`btn-icon ${className ?? ""}`} ref={ref} />
  ),
);

export default IconButton;
