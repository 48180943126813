import { ReactElement, forwardRef } from "react";
import styled from "styled-components";
import COLORS from "constants/colors";
import BaseButton, { BaseBtnProps } from "./BaseButton";

const StyledButton = styled(BaseButton)`
  &.btn-link {
    font-weight: 600;
    font-size: 14px;

    :focus-visible,
    :hover {
      span {
        text-decoration: underline;
      }
    }
    :disabled {
      color: ${COLORS.linkDisabledColor};
    }
  }
`;
const LinkButton = forwardRef<HTMLElement, Omit<BaseBtnProps, "type">>(
  ({ className, ...props }, ref): ReactElement => (
    <StyledButton type="link" {...props} className={`btn-link ${className ?? ""}`} ref={ref} />
  ),
);

export default LinkButton;
