import { useMutation } from "@apollo/client";
import Form, { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { InputPassword } from "components/Inputs";
import { Container } from "components/core";
import { FeedBackModal } from "components/modal";
import ALERTS from "config/alerts";
import FORM from "constants/form";
import { useResponsive } from "hooks";
import useFormModal from "hooks/useFormModal";
import { ChangePasswordRef } from "model/Employee";
import { ChangePasswordPayload, MutationChangePasswordArgs } from "model/User";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useNotify } from "services/api";
import { UPDATE_USER_DOCUMENT } from "services/graphql/user";

export const ChangePassword = forwardRef<ChangePasswordRef>((_, ref) => {
  const { isSmall } = useResponsive();
  const { isModalVisible, hideModal, showModal } = useFormModal();
  const [isFieldsChanged, setIsFieldsChanged] = useState<boolean>(false);
  const [form] = useForm();
  const notify = useNotify();

  const [mutate, { loading }] = useMutation<ChangePasswordPayload>(UPDATE_USER_DOCUMENT, {
    onCompleted: (response) => {
      if (response?.updateMe) {
        if (!response?.updateMe?.user) {
          notify.error(undefined, response.updateMe?.errors?.fullMessages[0]);
        } else {
          showModal();
          form.resetFields();
          setIsFieldsChanged(false);
        }
      }
    },
  });

  const onFinish = ({ currentPassword, password, passwordConfirmation }: MutationChangePasswordArgs) => {
    mutate({ variables: { currentPassword, password, passwordConfirmation } });
  };

  useImperativeHandle(ref, () => ({
    resetForm() {
      form.resetFields();
    },
    setIsFieldsChanged(value) {
      setIsFieldsChanged(value);
    },
    isFieldsChanged,
    loading,
  }));

  return (
    <Container className="section-box-shadow" isSmall={isSmall}>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ lg: 10, xl: 6, xxl: 5 }}
        labelAlign="left"
        id={FORM.changePasswordForm}
        onFieldsChange={() => setIsFieldsChanged(true)}
        onFinish={onFinish}
      >
        <FormItem
          name="currentPassword"
          label="Current password"
          rules={[ALERTS.required]}
          wrapperCol={{ lg: 9, xl: 7 }}
        >
          <InputPassword data-testid="currentPassword" />
        </FormItem>
        <FormItem name="password" label="New password" rules={[ALERTS.required]} wrapperCol={{ lg: 9, xl: 7 }}>
          <InputPassword data-testid="password" />
        </FormItem>
        <FormItem
          name="passwordConfirmation"
          label="Retype new password"
          rules={[
            ALERTS.required,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The two passwords that you entered do not match!"));
              },
            }),
          ]}
          wrapperCol={{ lg: 9, xl: 7 }}
        >
          <InputPassword data-testid="passwordConfirmation" />
        </FormItem>
      </Form>
      <FeedBackModal
        type="success"
        open={isModalVisible}
        onCancel={hideModal}
        onOk={hideModal}
        title="Your changes have been saved"
      />
    </Container>
  );
});

export default ChangePassword;
