import { ReactElement } from "react";
import COLORS from "constants/colors";
import styled from "styled-components";
import BaseButton, { BaseBtnProps } from "./BaseButton";

const StyledButton = styled(BaseButton)`
  &.ant-btn.ant-btn-default.btn-success {
    background-color: ${COLORS.successColor};

    :hover,
    :focus-visible {
      border: none;
      background-color: ${COLORS.successHover} !important;
    }

    :focus-within {
      background-color: ${COLORS.successActive};
    }
  }
`;

function SuccessButton({ className, ...props }: Omit<BaseBtnProps, "type">): ReactElement {
  return <StyledButton type="default" {...props} className={`btn-success ${className ?? ""}`} />;
}

export default SuccessButton;
