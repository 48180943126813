export default () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#a)">
      <rect x="11" y="6" width="34" height="34" rx="6.115" fill="#fff" />
      <rect x="10.873" y="5.873" width="34.255" height="34.255" rx="6.243" stroke="#D6DCE5" strokeWidth=".255" />
      <path d="M41 29.084c0 2.216-1.812 4.02-4.037 4.02H15v-15.92c0-2.216 1.812-4.02 4.037-4.02H41z" fill="#fff" />
      <path
        d="M35.327 14.51a2.887 2.887 0 0 0-2.893 2.88v2.99h4.085c.095 0 .206 0 .286.016.922.048 1.605.523 1.605 1.345 0 .65-.461 1.203-1.32 1.314v.032c.938.063 1.654.585 1.654 1.392 0 .87-.795 1.44-1.844 1.44h-4.48v5.856h4.243a2.887 2.887 0 0 0 2.892-2.88V14.508z"
        fill="url(#b)"
      />
      <path
        d="M33.849 25.001h1.669c.023 0 .063-.004.103-.008.04-.003.08-.007.103-.007a.74.74 0 0 0 .588-.744c0-.38-.27-.665-.588-.744-.047-.016-.143-.016-.206-.016h-1.67z"
        fill="url(#c)"
      />
      <path
        d="M35.518 21.25c.317.048.588.302.588.681s-.27.633-.588.68c-.016.017-.112.017-.16.017h-1.51v-1.393h1.51c.027 0 .063.005.095.01q.04.005.065.006"
        fill="url(#d)"
      />
      <path
        d="M19.339 14.51a2.887 2.887 0 0 0-2.892 2.88v7.105c.81.396 1.652.649 2.495.649 1 0 1.541-.601 1.541-1.424v-3.355h2.48v3.339c0 1.297-.811 2.358-3.56 2.358-1.67 0-2.972-.364-2.972-.364v6.06h4.243a2.887 2.887 0 0 0 2.892-2.88V14.51z"
        fill="url(#e)"
      />
      <path
        d="M27.332 14.51a2.887 2.887 0 0 0-2.892 2.88v3.766c.731-.617 2.002-1.013 4.052-.918 1.097.047 2.273.348 2.273.348v1.219c-.588-.301-1.287-.57-2.193-.633-1.557-.111-2.495.648-2.495 1.978 0 1.345.938 2.105 2.495 1.978.906-.063 1.605-.348 2.193-.633v1.218s-1.16.301-2.273.349c-2.05.095-3.321-.301-4.052-.918v6.646h4.243a2.887 2.887 0 0 0 2.893-2.88v-14.4z"
        fill="url(#f)"
      />
    </g>
    <defs>
      <linearGradient id="b" x1="32.432" y1="21.929" x2="39.574" y2="21.929" gradientUnits="userSpaceOnUse">
        <stop stopColor="#007940" />
        <stop offset=".229" stopColor="#00873F" />
        <stop offset=".743" stopColor="#40A737" />
        <stop offset="1" stopColor="#5CB531" />
      </linearGradient>
      <linearGradient id="c" x1="32.432" y1="21.929" x2="39.574" y2="21.929" gradientUnits="userSpaceOnUse">
        <stop stopColor="#007940" />
        <stop offset=".229" stopColor="#00873F" />
        <stop offset=".743" stopColor="#40A737" />
        <stop offset="1" stopColor="#5CB531" />
      </linearGradient>
      <linearGradient id="d" x1="32.432" y1="21.929" x2="39.574" y2="21.929" gradientUnits="userSpaceOnUse">
        <stop stopColor="#007940" />
        <stop offset=".229" stopColor="#00873F" />
        <stop offset=".743" stopColor="#40A737" />
        <stop offset="1" stopColor="#5CB531" />
      </linearGradient>
      <linearGradient id="e" x1="16.443" y1="23.135" x2="23.695" y2="23.135" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1F286F" />
        <stop offset=".475" stopColor="#004E94" />
        <stop offset=".826" stopColor="#0066B1" />
        <stop offset="1" stopColor="#006FBC" />
      </linearGradient>
      <linearGradient id="f" x1="24.399" y1="23.135" x2="31.442" y2="23.135" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6C2C2F" />
        <stop offset=".173" stopColor="#882730" />
        <stop offset=".573" stopColor="#BE1833" />
        <stop offset=".859" stopColor="#DC0436" />
        <stop offset="1" stopColor="#E60039" />
      </linearGradient>
      <filter
        id="a"
        x=".553"
        y=".649"
        width="54.895"
        height="54.895"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy=".51" />
        <feGaussianBlur stdDeviation="2.548" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_302_2832" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="5.096" />
        <feGaussianBlur stdDeviation="5.096" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="effect1_dropShadow_302_2832" result="effect2_dropShadow_302_2832" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_302_2832" result="shape" />
      </filter>
    </defs>
  </svg>
);
