export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="8" fill="#547AFF" />
    <g clipPath="url(#clip0_4247_11790)">
      <path
        d="M7.89323 3.18652C8.53809 3.18652 9.05964 3.66972 9.05964 4.26717C9.05964 4.86461 8.53809 5.34781 7.89323 5.34781C7.24837 5.34781 6.72682 4.86461 6.72682 4.26717C6.72682 3.66972 7.24837 3.18652 7.89323 3.18652ZM8.95452 6.02579H8.9242H7.04824H6.97951C6.59542 6.02579 6.28613 6.31421 6.28613 6.66818C6.28613 7.02402 6.59744 7.31057 6.97951 7.31057H7.04824V11.0563H6.28613V12.343H9.71461V11.0563H8.95654V6.02579H8.95452Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4247_11790">
        <rect width="8" height="9" fill="white" transform="translate(4 3)" />
      </clipPath>
    </defs>
  </svg>
);
