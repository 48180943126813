import { ErrorBoundary, Provider } from "@rollbar/react";
import { ReactNode } from "react";
import Rollbar, { Configuration } from "rollbar";
import { getUserTimeZone } from "utils";
import { assertWarning } from "utils/misc";

interface changeRollbarConfigProps {
  id: string;
  name: string;
  roles: string[];
}

export const rollbarAccessToken = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN ?? assertWarning("Log is not configured");
export const rollbarEnvironment =
  process.env.REACT_APP_ROLLBAR_ENVIRONMENT ?? assertWarning("Log environment is not defined");

const rollbarConfig: Configuration = {
  accessToken: rollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: rollbarEnvironment,
  payload: {
    client: {
      javascript: {
        code_version: "0.1.0",
        source_map_enabled: true,
      },
      person: {
        language: navigator.language || "en",
        timeZone: getUserTimeZone(),
      },
    },
  },
};

export const rollbarConfigAfterLogin = ({ id, name, roles }: changeRollbarConfigProps) => {
  if (rollbarAccessToken && rollbarEnvironment) {
    const rollbar = new Rollbar(rollbarConfig);
    rollbar.configure({
      ...rollbarConfig,
      payload: {
        ...rollbarConfig.payload,
        person: {
          ...rollbarConfig.payload?.person,
          id,
          username: name,
          roles,
        },
      },
    });
  }
};

export default ({ children }: { children: ReactNode }) => (
  <Provider config={rollbarAccessToken && rollbarEnvironment ? rollbarConfig : {}}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </Provider>
);
