import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DefaultButton, PrimaryButton } from "components/Buttons";
import { InputText } from "components/Inputs";
import ALERTS from "config/alerts";
import COLORS from "constants/colors";
import { Tag } from "model/AdminSetting";
import styled from "styled-components";

type Props = {
  onCancel: () => void;
  onSubmit: (data: Omit<Tag, "id">) => void;
  onFieldsChange: () => void;
};
const AddForm = ({ onCancel, onSubmit, onFieldsChange }: Props) => {
  const [form] = useForm();
  return (
    <StyledWrapper>
      <Form
        form={form}
        layout="inline"
        className="justify-content-between align-items-center p-2"
        onFinish={onSubmit}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item name="name" className="m-0 input" rules={[ALERTS.required, ALERTS.characterLength]}>
          <StyledInputText className="shadow-none" placeholder="Add new" autoFocus data-testid="addLabels" />
        </Form.Item>
        <Form.Item className="m-0">
          <DefaultButton className="me-2" onClick={onCancel}>
            Cancel
          </DefaultButton>
          <PrimaryButton htmlType="submit">Save</PrimaryButton>
        </Form.Item>
      </Form>
    </StyledWrapper>
  );
};

export default AddForm;

const StyledWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.borderLight};
  .input {
    width: 58%;
    padding-left: 15px;
  }
`;

const StyledInputText = styled(InputText)`
  :hover,
  :active,
  :focus {
    border: 1.5px solid ${COLORS.successColor};
    border-radius: 3px;
  }
  border: 1.5px solid ${COLORS.successColor};
  border-radius: 3px;
`;
