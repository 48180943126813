import { gql } from "@apollo/client";
import { DefaultNotificationSettings } from "model/AdminSetting";
import { ERROR_FRAGMENT, PAGE_INFO_FRAGMENT } from "./common";

export const FETCH_DEPARTMENT = gql`
  query departments($first: Int!, $after: String) {
    departments(first: $first, after: $after) {
      edges {
        node {
          id
          name
        }
      }
      nodes {
        id
        name
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const CREATE_DEPARTMENT = gql`
  mutation createDepartment($name: String!) {
    createDepartment(name: $name) {
      department {
        id
        name
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const UPDATE_DEPARTMENT = gql`
  mutation updateDepartment($id: ID!, $name: String!) {
    updateDepartment(id: $id, name: $name) {
      department {
        id
        name
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const FETCH_LOCATION = gql`
  query locations($first: Int!, $after: String) {
    locations(first: $first, after: $after) {
      edges {
        node {
          id
          name
        }
      }
      nodes {
        id
        name
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const CREATE_LOCATION = gql`
  mutation createLocation($name: String!) {
    createLocation(name: $name) {
      location {
        id
        name
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation($id: ID!, $name: String!) {
    updateLocation(id: $id, name: $name) {
      location {
        id
        name
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const FETCH_JOB_TITLE = gql`
  query jobTitles($first: Int!, $after: String) {
    jobTitles(first: $first, after: $after) {
      edges {
        node {
          id
          name
        }
      }
      nodes {
        id
        name
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const CREATE_JOB_TITLE = gql`
  mutation createJobTitle($name: String!) {
    createJobTitle(name: $name) {
      jobTitle {
        id
        name
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const UPDATE_JOB_TITLE = gql`
  mutation updateJobTitle($id: ID!, $name: String!) {
    updateJobTitle(id: $id, name: $name) {
      jobTitle {
        id
        name
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const DESTROY_JOB_TITLE = gql`
  mutation destroyJobTitle($id: ID!) {
    destroyJobTitle(id: $id) {
      errors {
        ...ErrorFragment
      }
      success
    }
  }
  ${ERROR_FRAGMENT}
`;

export const DESTROY_LOCATION = gql`
  mutation destroyLocation($id: ID!) {
    destroyLocation(id: $id) {
      errors {
        ...ErrorFragment
      }
      success
    }
  }
  ${ERROR_FRAGMENT}
`;

export const DESTROY_DEPARTMENT = gql`
  mutation destroyDepartment($id: ID!) {
    destroyDepartment(id: $id) {
      errors {
        ...ErrorFragment
      }
      success
    }
  }
  ${ERROR_FRAGMENT}
`;

export const FETCH_DEFAULT_SETTING = gql`
  query fetchDefaultSetting {
    fetchDefaultSetting {
      id
      annualLeave
      endTime
      holidaysPerYear
      hoursPerWeek
      isMonday
      isTuesday
      isWednesday
      isThursday
      isFriday
      isSaturday
      isSunday
      probationaryPeriod
      startTime
      timeZone
      selectedCalendars
    }
  }
`;

export const UPSERT_DEFAULT_SETTING = gql`
  mutation upsertDefaultSetting(
    $annualLeave: DefaultSettingsAnnualLeaveEnum!
    $endTime: String!
    $holidaysPerYear: Float!
    $hoursPerWeek: Float!
    $isFriday: Boolean!
    $isMonday: Boolean!
    $isSaturday: Boolean!
    $isSunday: Boolean!
    $isThursday: Boolean!
    $isTuesday: Boolean!
    $isWednesday: Boolean!
    $probationaryPeriod: Int!
    $startTime: String!
    $timeZone: String!
    $selectedCalendars: Int
  ) {
    upsertDefaultSetting(
      annualLeave: $annualLeave
      endTime: $endTime
      holidaysPerYear: $holidaysPerYear
      hoursPerWeek: $hoursPerWeek
      isFriday: $isFriday
      isMonday: $isMonday
      isSaturday: $isSaturday
      isSunday: $isSunday
      isThursday: $isThursday
      isTuesday: $isTuesday
      isWednesday: $isWednesday
      probationaryPeriod: $probationaryPeriod
      startTime: $startTime
      timeZone: $timeZone
      selectedCalendars: $selectedCalendars
    ) {
      defaultSetting {
        id
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const FETCH_ROLE_SETTINGS = gql`
  query roleSettings($first: Int!, $after: String) {
    roleSettings(first: $first, after: $after) {
      edges {
        cursor
        node {
          name
          role {
            id
            name
          }
        }
      }
      nodes {
        editable
        name
        position
        role {
          id
          name
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const CERATE_USER_ROLE_CONNECTION = (assignRole?: { [userId: string]: string[] }) => {
  const createMutation =
    assignRole &&
    Object.keys(assignRole)
      .map((id) => {
        const mutations = assignRole[id].map(
          (item) =>
            `${`createUserRoleConnection${item}${id}`}: createUserRoleConnection(roleId: ${item}, userId: ${id}) {
              errors {
                 ...ErrorFragment
             }
               success
            }`,
        );
        return mutations.join("\n");
      })
      .join("\n");
  return createMutation;
};

export const DESTROY_USER_ROLE_CONNECTION = (destroyRole?: { [userId: string]: string[] }) => {
  const destroyMutation =
    destroyRole &&
    Object.keys(destroyRole)
      .map((id) => {
        const mutations = destroyRole[id]
          .map(
            (item) =>
              `
              ${`destroyUserRoleConnection${item}${id}`}: destroyUserRoleConnection(roleId: ${item}, userId: ${id}) {
               success
            }`,
          )
          .join("\n");
        return mutations;
      })
      .join("\n");
  return destroyMutation;
};

export const DEFAULT_NOTIFICATION_SETTINGS = gql`
  query defaultNotificationSettings($first: Int!, $after: String) {
    defaultNotificationSettings(first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          eventType
        }
      }
      nodes {
        id
        eventType
        isEmail
        isWeb
        period
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
`;

export const UPDATE_DEFAULT_NOTIFICATION_SETTING = (changedTableData: DefaultNotificationSettings[]) => {
  const updateDefaultNotificationSetting =
    changedTableData &&
    changedTableData
      .map((item) => {
        const mutations = `${`updateDefaultNotificationSetting${item.id}`}: 
        updateDefaultNotificationSetting(id : ${item.id}, isEmail : ${item.isEmail},isWeb:${item.isWeb} ,period: ${
          item.period
        }) 
            {
              errors {
                 ...ErrorFragment
             }
             defaultNotificationSetting {
              eventType
              id
              isEmail
              isWeb
              period
             }
            }`;
        return mutations;
      })
      .join("\n");
  return updateDefaultNotificationSetting;
};

export const BILLING_PRICE_FRAGMENT = gql`
  fragment BillingPriceFragment on Price {
    currency
    id
    interval
    unitAmount
  }
`;

export const FETCH_BILLING_PACKAGES = gql`
  query products($first: Int, $after: String) {
    products(first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          name
        }
      }
      nodes {
        id
        name
        prices {
          ...BillingPriceFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${PAGE_INFO_FRAGMENT}
  ${BILLING_PRICE_FRAGMENT}
`;

export const PROMO_CODE_FRAGMENT = gql`
  fragment PromoCodeFragment on PromotionCode {
    id
    code
    coupon {
      id
      percentOff
    }
    paymentType
  }
`;

export const SUBSCRIPTION_FRAGMENT = gql`
  fragment SubscriptionFragment on SubscriptionModelType {
    id
    currentPeriodEnd
    invoices {
      currency
      failed
      fromDate
      id
      invoicePdf
      total
    }
    price {
      ...BillingPriceFragment
    }
    productName
    quantity
    promotionCode {
      ...PromoCodeFragment
    }
  }
  ${BILLING_PRICE_FRAGMENT}
  ${PROMO_CODE_FRAGMENT}
`;

export const BILLING_UPSERT_CUSTOMER = gql`
  mutation upsertCustomer(
    $city: String
    $country: String
    $email: String
    $line1: String
    $line2: String
    $name: String
    $phone: String
    $postalCode: String
    $state: String
  ) {
    upsertCustomer(
      city: $city
      country: $country
      email: $email
      line1: $line1
      line2: $line2
      name: $name
      phone: $phone
      postalCode: $postalCode
      state: $state
    ) {
      customer {
        city
        country
        email
        line1
        line2
        name
        phone
        postalCode
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const FETCH_BILLING_CUSTOMER = gql`
  query fetchCustomer {
    fetchCustomer {
      city
      country
      email
      id
      line1
      line2
      name
      phone
      paymentMethods {
        id
        cardBrand
        cardExpMonth
        cardExpYear
        cardLast4
        default
      }
      postalCode
      state
      subscription {
        ...SubscriptionFragment
      }
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
`;

export const CREATE_BILLING_SETUP_INTENT = gql`
  mutation createSetupIntent {
    createSetupIntent {
      clientSecret
      success
    }
  }
`;

export const UPSERT_BILLING_SUBSCRIPTION = gql`
  mutation upsertSubscription($priceId: ID, $promotionCode: String, $quantity: Int, $stripePaymentMethodId: String) {
    upsertSubscription(
      priceId: $priceId
      promotionCode: $promotionCode
      quantity: $quantity
      stripePaymentMethodId: $stripePaymentMethodId
    ) {
      subscription {
        ...SubscriptionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${SUBSCRIPTION_FRAGMENT}
`;

export const DESTROY_BILLING_CUSTOMER = gql`
  mutation destroyCustomer {
    destroyCustomer {
      success
    }
  }
`;

export const FETCH_BILLING_PROMOTION_CODE = gql`
  query fetchPromotionCode($paymentType: PromotionCodesPaymentTypeEnum!, $promotionCode: String!) {
    fetchPromotionCode(paymentType: $paymentType, promotionCode: $promotionCode) {
      ...PromoCodeFragment
    }
  }
  ${PROMO_CODE_FRAGMENT}
`;

export default {
  FETCH_DEPARTMENT,
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
  FETCH_LOCATION,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  FETCH_JOB_TITLE,
  CREATE_JOB_TITLE,
  UPDATE_JOB_TITLE,
  FETCH_DEFAULT_SETTING,
  UPSERT_DEFAULT_SETTING,
  FETCH_ROLE_SETTINGS,
  DEFAULT_NOTIFICATION_SETTINGS,
  UPDATE_DEFAULT_NOTIFICATION_SETTING,
  BILLING_UPSERT_CUSTOMER,
  CREATE_BILLING_SETUP_INTENT,
  DESTROY_BILLING_CUSTOMER,
  FETCH_BILLING_PROMOTION_CODE,
};
