import { useMutation } from "@apollo/client";
import dayjs, { Dayjs } from "dayjs";
import {
  ApproveLeaveRequestPayload,
  ApproveLeaveRequestResponse,
  DeclineLeaveRequestPayload,
  DeclineLeaveRequestResponse,
  LeaveBalanceCalculationArg,
  LeaveRequestsReason,
} from "model/Leave";
import { useNotify } from "services/api";
import { APPROVE_LEAVE_REQUEST, DECLINE_LEAVE_REQUEST } from "services/graphql/leave";

export const useApproveLeaveRequest = (successCallback?: () => void) => {
  const notify = useNotify();
  const [mutate, { loading }] = useMutation<ApproveLeaveRequestResponse, ApproveLeaveRequestPayload>(
    APPROVE_LEAVE_REQUEST,
    {
      onCompleted: (response) => {
        if (response?.approveLeaveRequest) {
          if (response?.approveLeaveRequest?.errors) {
            notify.error(response.approveLeaveRequest.errors.fullMessages);
          } else {
            notify.success({ message: "Approve leave request successfully." });
            successCallback?.();
          }
        }
      },
    },
  );

  return { mutate, loading };
};

export const useDeclineLeaveRequest = (successCallback?: () => void) => {
  const notify = useNotify();
  const [mutate, { loading }] = useMutation<DeclineLeaveRequestResponse, DeclineLeaveRequestPayload>(
    DECLINE_LEAVE_REQUEST,
    {
      onCompleted: (response) => {
        if (response?.declineLeaveRequest) {
          if (response?.declineLeaveRequest?.errors) {
            notify.error(response.declineLeaveRequest.errors.fullMessages);
          } else {
            notify.success({ message: "Decline leave request successfully." });
            successCallback?.();
          }
        }
      },
    },
  );

  return { mutate, loading };
};

export const getFilenameFromUrl = (url: string) => {
  const decodedUrl = decodeURIComponent(url);
  const pathArray = decodedUrl.split("/");
  const lastPathSegment = pathArray[pathArray.length - 1];
  // eslint-disable-next-line no-bitwise
  const filename = lastPathSegment.slice(0, ((lastPathSegment.lastIndexOf(".") - 1) >>> 0) + 1);

  return filename;
};

// eslint-disable-next-line no-bitwise
export const removeExtensionFromName = (name: string) => name.slice(0, ((name.lastIndexOf(".") - 1) >>> 0) + 1);

export const getLeaveBalanceCalculation = ({
  estimateData,
  initialValues,
  numberOfDays,
}: LeaveBalanceCalculationArg) => {
  let annualBalanceFollowing = 0;
  let annualBalance = 0;
  if (estimateData) {
    const { totalBalance = 0, estimatedDays = 0, usedDays = 0, usedDaysBeforeChange = 0 } = estimateData;
    annualBalance = totalBalance - (usedDays - (usedDays ? usedDaysBeforeChange : 0));
    annualBalanceFollowing = Number.isNaN(numberOfDays)
      ? totalBalance - (usedDays - (usedDays ? usedDaysBeforeChange : 0))
      : totalBalance - (usedDays - (usedDays ? usedDaysBeforeChange : 0)) - estimatedDays;
  } else if (initialValues) {
    const { accumulativeBalance = 0, precedingUsedHolidayDays = 0, usedDays = 0 } = initialValues;
    annualBalance = accumulativeBalance - precedingUsedHolidayDays;
    annualBalanceFollowing = Number.isNaN(numberOfDays)
      ? accumulativeBalance - precedingUsedHolidayDays
      : accumulativeBalance - precedingUsedHolidayDays - usedDays;
  }

  return { annualBalance, annualBalanceFollowing };
};

export const isShowingAnnualBalance = (fromDate: Dayjs, toDate: Dayjs, reason?: LeaveRequestsReason) => {
  const currentYear = dayjs().year();
  if (reason !== undefined && reason !== LeaveRequestsReason.Holiday) {
    return false;
  }
  return fromDate && toDate ? dayjs(fromDate).year() === currentYear && dayjs(toDate).year() === currentYear : false;
};

export default {
  useApproveLeaveRequest,
  useDeclineLeaveRequest,
  getLeaveBalanceCalculation,
  getFilenameFromUrl,
  isShowingAnnualBalance,
  removeExtensionFromName,
};
