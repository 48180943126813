import { Checkbox, Select, SelectProps } from "antd";
import Icon from "components/Icon";
import { forwardRef, useImperativeHandle, useState } from "react";
import styled from "styled-components";

export interface BasicSelectProps {
  selectedValue?: string[];
}

export interface SelectRef {
  closeOnNewAdd(): void;
}

export const InputDropDown = forwardRef<SelectRef, SelectProps & BasicSelectProps>(
  ({ open, options, selectedValue, onDropdownVisibleChange, ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      closeOnNewAdd() {
        setIsOpen(false);
      },
    }));

    return (
      <StyledSelectWrapper>
        <Select
          {...props}
          open={open || isOpen}
          onDropdownVisibleChange={(open) => {
            setIsOpen(open);
            if (onDropdownVisibleChange) {
              onDropdownVisibleChange(open);
            }
          }}
          suffixIcon={<Icon name="down" />}
          onSelect={() => setIsOpen(false)}
          onDeselect={() => setIsOpen(false)}
        >
          {options?.map((item) => (
            <Select.Option value={item.value} key={item.value} label={item.label}>
              {props.mode ? (
                <Checkbox checked={selectedValue?.includes(item.value as string)}>{item.label}</Checkbox>
              ) : (
                item.label
              )}
            </Select.Option>
          ))}
        </Select>
      </StyledSelectWrapper>
    );
  },
);

export default InputDropDown;

const StyledSelectWrapper = styled.div`
  .ant-select.ant-select-multiple,
  .ant-select.ant-select-single {
    height: 36px;
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      height: 28px;
      margin-top: 0px;
      margin-bottom: 2px;
    }
    .ant-select-selection-item-content {
      display: flex;
      align-items: center;
    }
  }
`;
