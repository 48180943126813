import { useMutation } from "@apollo/client";
import { Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { BaseButton, DefaultButton } from "components/Buttons";
import Icon from "components/Icon";
import { FeedBackModal } from "components/modal";
import PERMISSION from "config/permission";
import COLORS from "constants/colors";
import DATE_FORMAT from "constants/dateFormat";
import { useNotificationContext } from "contexts/NotificationProvider";
import dayjs from "dayjs";
import { getFilenameFromUrl, removeExtensionFromName } from "features/employee/leave";
import useCheckPermission from "hooks/useCheckPermission";
import useFormModal from "hooks/useFormModal";
import { ActionCompletedResponse, DocumentReviewDataType } from "model/Document";
import { NotificationType } from "model/Notification";
import { useState } from "react";
import { useNotify } from "services/api";
import { COMPLETE_ACTIONS } from "services/graphql/document";
import styled from "styled-components";
import { downloadLink, expectNumber } from "utils";
import storage from "utils/storage";

const DocumentsActionedTable = () => {
  const { myDocumentData: data, refresh, myDocumentReviewRefetch, employeeDocumentRefetch } = useNotificationContext();
  const { employeeDocumentNotification } = PERMISSION;
  const { hasEmployeeDocumentNotification } = useCheckPermission({ employeeDocumentNotification });
  const [actionRequired, setActionRequired] = useState<string>();
  const [actionId, setActionId] = useState<string>();
  const { isModalVisible, hideModal, showModal } = useFormModal();
  const notify = useNotify();

  const [actionComplete, { loading }] = useMutation<ActionCompletedResponse>(COMPLETE_ACTIONS, {
    onCompleted: (response) => {
      if (response.createUserActionConnection) {
        if (!response.createUserActionConnection.errors) {
          notify.success({ message: "Action completed successfully." });
          if (actionId) {
            storage.addReadNotification(expectNumber(actionId), NotificationType.MyDocument);
          }
          refresh();
          myDocumentReviewRefetch();
          if (hasEmployeeDocumentNotification) {
            employeeDocumentRefetch();
          }
        }
      }
    },
  });

  const columns: ColumnsType<DocumentReviewDataType> = [
    {
      title: "Document name",
      key: "id",
      dataIndex: "name",
      render: (_, { attachmentName, attachmentUrl }) => (
        <div className="d-flex align-items-center gap-2">
          <Icon name="document" className="pt-2" />
          <Typography.Title level={4} className="mb-0">
            {removeExtensionFromName(attachmentName)}
          </Typography.Title>
          <StyledBaseButton
            icon="download"
            className="btn-base"
            onClick={() => {
              if (attachmentUrl) {
                downloadLink(attachmentUrl as string, getFilenameFromUrl(attachmentUrl as string));
              }
            }}
          />
        </div>
      ),
    },
    {
      title: "Deadline date",
      dataIndex: "date",
      render: (_, { action }) =>
        action?.deadlineAt ? dayjs(action.deadlineAt).format(DATE_FORMAT.fullDateDefault) : "/",
    },
    {
      title: "",
      dataIndex: "actions",
      align: "end",
      render: (_, r) => (
        <div className="d-flex justify-content-end align-items-center gap-2 pe-2">
          {r.action?.description ? (
            <>
              <DefaultButton
                onClick={() => {
                  setActionRequired(r.action?.description);
                  showModal();
                }}
              >
                View details of action
              </DefaultButton>
              <DefaultButton
                loading={loading}
                onClick={() => {
                  actionComplete({ variables: { actionId: r?.action?.id } });
                  setActionId(r?.action?.id);
                }}
              >
                Action complete
              </DefaultButton>
            </>
          ) : (
            <DefaultButton
              onClick={() => {
                storage.addReadNotification(expectNumber(r.id), NotificationType.MyDocument);
                refresh();
              }}
            >
              Ok
            </DefaultButton>
          )}
        </div>
      ),
      width: "25vw",
    },
  ];

  return (
    <StyledWrapper className="section-box-shadow">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        title={() => (
          <Typography.Title level={1} className="m-0 p-2 table-title">
            Documents review
          </Typography.Title>
        )}
      />
      <FeedBackModal
        open={isModalVisible}
        type="warning"
        onCancel={hideModal}
        onOk={hideModal}
        title="Action required"
        description={actionRequired}
      />
    </StyledWrapper>
  );
};

export default DocumentsActionedTable;

const StyledWrapper = styled.div`
  .ant-table-column-sorter {
    display: none;
  }
`;

const StyledBaseButton = styled(BaseButton)`
  &.btn-base {
    background: ${COLORS.downloadIconBg};
    width: 23px;
    height: 23px;
    border-radius: 2px;
    svg {
      color: ${COLORS.tertiary};
    }
  }
`;
