import { PageInfo, ValidationErrors } from "./Common";

export type CompanyId = string & { readonly __tag: unique symbol };

export enum PaymentTypes {
  Annual = "ANNUAL",
  Monthly = "MONTHLY",
}

export type label = {
  id: string;
  name: string;
};
export type SuperUser = {
  id: string;
  name: string;
  email: string;
  departments: label[];
  jobTitle: label;
  lineManagers: label[];
  location: label;
  loggedIn: boolean;
  phone: string;
  startedAt: string;
};
export interface Company {
  id: CompanyId;
  companyId: string;
  companyName: string;
  superuserName: string;
  superuserEmail: string;
  superuserPhone: string;
  paymentType: PaymentTypes;
  signedAt: string;
  notifySuperuser: boolean;
}

export interface CompanyResponse {
  companies: {
    nodes: FetchCompany[];
    edges: Object;
    pageInfo: PageInfo;
  };
}
export interface FetchCompany extends Omit<Company, "notifySuperuser"> {
  superuser: SuperUser;
}
export interface FetchCompanyResponse {
  fetchCompany: FetchCompany;
}

export const PaymentTypeLabel: Record<PaymentTypes, string> = {
  [PaymentTypes.Annual]: "Annual",
  [PaymentTypes.Monthly]: "Monthly",
};

export interface CreateCompanyResponse {
  createCompany: {
    company: FetchCompany;
    errors: ValidationErrors;
  };
}

export interface UpdateCompanyResponse {
  updateCompany: {
    company: FetchCompany;
    errors: ValidationErrors;
  };
}

export interface CompanyRefetch {
  companyRefetch(): void;
}

export interface DestroyCompany {
  destroyCompany: { success: boolean };
}
