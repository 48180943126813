export default () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#a)">
      <rect x="11" y="6" width="34" height="34" rx="6.09" fill="#000008" />
      <rect x="10.873" y="5.873" width="34.254" height="34.254" rx="6.216" stroke="#D6DCE5" strokeWidth=".254" />
      <path d="M31.245 16.895h-6.49v11.662h6.49z" fill="#FF5F00" />
      <path
        d="M25.167 22.726A7.4 7.4 0 0 1 28 16.895a7.418 7.418 0 1 0 0 11.662 7.4 7.4 0 0 1-2.833-5.831"
        fill="#EB001B"
      />
      <path
        d="M40 22.726a7.416 7.416 0 0 1-12 5.831 7.418 7.418 0 0 0 0-11.662 7.417 7.417 0 0 1 12 5.83m-.708 4.597v-.239h.097v-.049h-.245v.049h.096v.239zm.476 0v-.288h-.075l-.086.198-.087-.198h-.075v.288h.053v-.217l.081.187h.055l.081-.188v.218z"
        fill="#F79E1B"
      />
    </g>
    <defs>
      <filter
        id="a"
        x=".597"
        y=".671"
        width="54.806"
        height="54.806"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy=".507" />
        <feGaussianBlur stdDeviation="2.537" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_302_2797" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="5.075" />
        <feGaussianBlur stdDeviation="5.075" />
        <feColorMatrix values="0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0 0.717647 0 0 0 0.08 0" />
        <feBlend in2="effect1_dropShadow_302_2797" result="effect2_dropShadow_302_2797" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_302_2797" result="shape" />
      </filter>
    </defs>
  </svg>
);
