import { Button, ButtonProps } from "antd";
import { customIconList, ICustomIconList } from "components/Icon/Icon";
import COLORS from "constants/colors";
import React, { forwardRef, ReactElement } from "react";
import styled from "styled-components";

export interface BaseBtnProps extends ButtonProps {
  icon?: ICustomIconList;
}

const StyledButton = styled(Button)`
  line-height: 15px;
  font-weight: 500;
  font-size: 13px;
  border: none;

  &.ant-btn:focus-visible {
    outline: 1px solid ${COLORS.buttonFocusBorderColor};
    outline-offset: 0px;
    border: none;
  }

  &:not(.ant-btn-dangerous):not(.btn-decline):not(.btn-default):not(.btn-icon):not(.btn-link):not(.btn-primary):not(
      .btn-success
    ) {
    border: 1px solid ${COLORS.baseBorderColor};

    :hover,
    :focus-visible {
      background-color: ${COLORS.baseHoverBgColor} !important;
    }
    :focus-within {
      background-color: ${COLORS.baseActive};
    }
  }

  &.ant-btn.ant-btn-default:disabled,
  &.ant-btn.ant-btn-default:disabled:hover {
    background-color: ${COLORS.disabledBgColor} !important;
    border: none !important;
    opacity: 60%;
  }

  ${(props) => (props.loading ? `display: inline-flex; align-items: center; justify-content: center;` : "")}
`;

const BaseButton = forwardRef<HTMLElement, BaseBtnProps>(({ icon, tabIndex, ...props }, ref): ReactElement => {
  const SelectedIcon = icon && customIconList[icon] ? customIconList[icon] : React.Fragment;
  return <StyledButton icon={icon ? <SelectedIcon /> : undefined} {...props} ref={ref} tabIndex={tabIndex ?? 0} />;
});

export default BaseButton;
